import store from '@/store';

/**
 * State
 */
const getDefaultState = () => {
  return {
    loading: true,
    success: true,
    error: false,
  };
};
const state = getDefaultState();

/**
 * Actions
 */
const actions = {
  /**
   * Reset state in module
   * @param commit
   */
  reset({ commit }) {
    commit('SET_DEFAULT_STATE');
  },
  /**
   *
   * @param commit
   * @returns {Promise<void>}
   */
  startLoading({ commit }) {
    commit('DEACTIVATE_LOADING_SUCCESS');
    commit('ACTIVATE_LOADING');
  },
  /**
   *
   * @param commit
   * @returns {Promise<void>}
   */
  finishLoading({ commit }) {
    commit('DEACTIVATE_LOADING');
    commit('ACTIVATE_LOADING_SUCCESS');
    commit('FADE_LOADING_SUCCESS');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  ACTIVATE_LOADING: (state) => {
    state.loading = true;
    // Object.assign(state, { loading: true, success: false, error: false });
  },
  DEACTIVATE_LOADING: (state) => {
    state.loading = false;
  },
  ACTIVATE_LOADING_SUCCESS: (state) => (state.success = true),
  DEACTIVATE_LOADING_SUCCESS: (state) => (state.success = false),
  FADE_LOADING_SUCCESS: async (state) => {
    await new Promise((r) => setTimeout(r, 1000));
    state.success = false;
  },
};

/**
 * Getters
 */
const getters = {
  isLoading: (state) => state.loading,
  isSuccess: (state) => state.success,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
