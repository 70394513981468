import gql from 'graphql-tag';
import apolloClient from '../apollo';
import dayjs from 'dayjs';

import relativeTime from 'dayjs/plugin/relativeTime';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekday from 'dayjs/plugin/weekday';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import 'dayjs/locale/de';
import Vue from 'vue';

dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(isoWeek);
dayjs.extend(weekday);
dayjs.extend(dayOfYear);
dayjs.locale('de');

/**
 * State
 */
const defaultAppointment = () => {
  return {
    id: '_new',
    number: '_new',
    appointmentType: 'AC',
    appointmentState: 'SCHEDULED',
    appointmentApprovedState: 'NO',
    installationStartAt: dayjs()
      .startOf('day')
      .add(8, 'hour')
      .toDate(),
    installationEndAt: dayjs()
      .startOf('day')
      .add(16, 'hour')
      .toDate(),
    project: null,
    employees: [],
    backlog: null,
    itemStorages: [],
    isOutdated: null,
    markedAsChanged: null,
  };
};

const getDefaultState = () => {
  return {
    appointments: [],
    appointmentCount: 0,
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an appointment
 * used for all queries/mutations which return an appointment
 * @type {DocumentNode}
 */
const APPOINTMENT_REQUEST_FIELDS = gql`
  fragment appointmentFields on Appointment {
    id
    number
    project {
      id
      number
      employerProjectNumber
      substructureVerificationState
      dcVerificationState
      acVerificationState
      measurementVerificationState
      measurementApprovementVerificationState
      serviceVerificationState
      serviceApprovementVerificationState
      serviceApprovementNote
      acceptanceApprovementVerificationState
      acceptanceApprovementNote
      invoiceApprovementVerificationState
      invoiceApprovementNote
      technicalReview {
        installationTeamNotes
        dcNotes
        acNotes
      }
      customer {
        firstname
        lastname
        street
        streetNumber
        streetNumberSuffix
        zip
        city
        email
        phone
        mobile
      }
      solarPlant {
        solarPanelCount
      }
      employer {
        id
        number
        name
      }
      powerCompany {
        number
        _id
        company
      }
    }
    employees {
      _id
      number
      firstname
      lastname
    }
    itemStorages {
      _id
      number
      name
      itemStorageType
    }
    installationStartAt
    installationEndAt
    appointmentType
    appointmentState
    appointmentApprovedState
    description
    backlog {
      id
      backlogReason
      description
      remainingWork
      remainingTimeExpenditure
      createdAt
      updatedAt
    }
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} appointment
 */
const createPayload = (appointment) => {
  // console.log(appointment);

  /** Only send ID's for employees */
  const employeeIds = [];
  if (appointment.employees) {
    appointment.employees.map((employee) => {
      employeeIds.push(employee._id);
    });
  }

  /** Only send ID's for itemStorages */
  const itemStorageIds = [];
  if (appointment.itemStorages) {
    appointment.itemStorages.map((employee) => {
      itemStorageIds.push(employee._id);
    });
  }

  if (appointment.backlog) {
    delete appointment.backlog.__typename;
    delete appointment.backlog.id;
    delete appointment.backlog.createdAt;
    delete appointment.backlog.updatedAt;
  }

  const payload = {
    ...(appointment.installationStartAt
      ? { installationStartAt: appointment.installationStartAt }
      : {}),
    ...(appointment.installationEndAt ? { installationEndAt: appointment.installationEndAt } : {}),
    ...(appointment.appointmentType ? { appointmentType: appointment.appointmentType } : {}),
    ...(appointment.appointmentState ? { appointmentState: appointment.appointmentState } : {}),
    ...(appointment.appointmentApprovedState
      ? { appointmentApprovedState: appointment.appointmentApprovedState }
      : {}),
    project: appointment.project ? appointment.project.id : null,
    employees: employeeIds,
    itemStorages: itemStorageIds,
    description: appointment.description,
    backlog: appointment.backlog,
  };
  return payload;
};

/**
 * Actions
 */
const actions = {
  async fetchAppointmentPages(
    { commit, dispatch },
    { page, pageSize, sortField, sortOrder, filters }
  ) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query appointmentPages(
            $page: Int!
            $pageSize: Int!
            $sortField: String
            $sortOrder: Int
            $filters: AppointmentFilterInput
          ) {
            appointmentPages(
              page: $page
              pageSize: $pageSize
              sortField: $sortField
              sortOrder: $sortOrder
              filters: $filters
            ) {
              appointments {
                ...appointmentFields
              }
              appointmentCount
            }
          }
          ${APPOINTMENT_REQUEST_FIELDS}
        `,
        variables: {
          page: page,
          pageSize: pageSize,
          sortField: sortField,
          sortOrder: sortOrder,
          filters: filters,
        },
        fetchPolicy: 'network-only',
      });
      commit('SET_APPOINTMENTS', response.data.appointmentPages.appointments);
      commit('SET_APPOINTMENT_COUNT', response.data.appointmentPages.appointmentCount);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchAppointments({ commit, dispatch }, { filters }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query appointments($filters: AppointmentFilterInput) {
            appointments(filters: $filters) {
              ...appointmentFields
            }
          }
          ${APPOINTMENT_REQUEST_FIELDS}
        `,
        variables: {
          filters: filters,
        },
      });
      commit('SET_APPOINTMENTS', response.data.appointments);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchAppointmentByNumber({ commit, dispatch }, appointmentNumber) {
    // console.log('fetchAppointmentByNumber()', appointmentNumber);
    try {
      const { data, errors } = await apolloClient.query({
        query: gql`
          query appointmentByNumber($appointmentNumber: Int!) {
            appointmentByNumber(appointmentNumber: $appointmentNumber) {
              ...appointmentFields
            }
          }
          ${APPOINTMENT_REQUEST_FIELDS}
        `,
        variables: {
          appointmentNumber: parseInt(appointmentNumber),
        },
      });

      if (errors) {
        // dispatch('handleErrors', errors);
        return errors;
      }

      commit('ADD_OR_UPDATE_APPOINTMENT', data.appointmentByNumber);
    } catch (err) {
      // dispatch('handleErrors', errors);
      throw new Error(err);
    }
  },

  async createAppointment({ commit, dispatch }, appointment) {
    const payload = createPayload(appointment);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createAppointment($payload: AppointmentInput!) {
            createAppointment(appointmentInput: $payload) {
              ...appointmentFields
            }
          }
          ${APPOINTMENT_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Termin gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_APPOINTMENT', '_new'); // remove temporary _new appointment
      commit('ADD_OR_UPDATE_APPOINTMENT', response.data.createAppointment);
      return response.data.createAppointment.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateAppointment({ commit, dispatch }, appointment) {
    const payload = createPayload(appointment);
    // console.log(payload);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateAppointment($appointmentNumber: Int!, $payload: AppointmentInput!) {
            updateAppointment(appointmentNumber: $appointmentNumber, appointmentInput: $payload) {
              ...appointmentFields
            }
          }
          ${APPOINTMENT_REQUEST_FIELDS}
        `,
        variables: {
          appointmentNumber: appointment.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Termin aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_APPOINTMENT', response.data.updateAppointment);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteAppointment({ commit, dispatch }, appointmentNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteAppointment($appointmentNumber: Int!) {
            deleteAppointment(appointmentNumber: $appointmentNumber)
          }
        `,
        variables: {
          appointmentNumber: appointmentNumber,
        },
      });
      dispatch('message', {
        message: 'Termin gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      // console.log(response.data.deleteAppointment);

      commit('REMOVE_APPOINTMENT', appointmentNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async sendAppointmentMail({ commit, dispatch }, { appointmentNumber }) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation sendAppointmentMail($appointmentNumber: Int!) {
            sendAppointmentMail(appointmentNumber: $appointmentNumber) {
              ...appointmentFields
            }
          }
          ${APPOINTMENT_REQUEST_FIELDS}
        `,
        variables: {
          appointmentNumber: appointmentNumber,
        },
      });
      dispatch('message', {
        message: 'Nachricht verschickt!',
        type: 'success',
        lifetime: 1,
      });

      commit('ADD_OR_UPDATE_APPOINTMENT', response.data.sendAppointmentMail);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Versand!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new appointment object in store and return it
   * @param {*} param0
   * @param {*} appointment
   */
  async initAppointment({ commit }) {
    commit('ADD_OR_UPDATE_APPOINTMENT', defaultAppointment());
  },

  /**
   * create a new appointment with existing data
   * @param {*} param0
   * @param {*} appointment
   */
  async duplicateAppointment({ commit }, { appointment, installationStartAt }) {
    const duplicate = Object.assign(defaultAppointment(), {
      appointmentType: appointment.appointmentType,
      project: appointment.project,
      employees: appointment.employees,
      description: appointment.description,
      installationStartAt: installationStartAt,
      installationEndAt: dayjs(installationStartAt)
        .add(8, 'hour')
        .toDate(),
    });

    // console.log(duplicate);
    commit('ADD_OR_UPDATE_APPOINTMENT', duplicate);
    return duplicate;
  },

  appointmentLocalUpdate({ commit }, appointment) {
    commit('ADD_OR_UPDATE_APPOINTMENT', appointment);
  },

  appointmentCleanup({ commit }) {
    commit('REMOVE_APPOINTMENT', '_new');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_APPOINTMENT_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_APPOINTMENTS: (state, appointments) =>
    appointments ? (state.appointments = appointments) : (state.appointments = []),
  ADD_APPOINTMENT: (state, appointment) => state.appointments.push(appointment),
  ADD_OR_UPDATE_APPOINTMENT: (state, updatedAppointment) => {
    const existingAppointment = state.appointments.find(
      (appointment) => appointment.id === updatedAppointment.id
    );
    if (existingAppointment) {
      Object.assign(existingAppointment, updatedAppointment);
    } else {
      state.appointments.push(updatedAppointment);
    }
  },
  REMOVE_APPOINTMENT: (state, appointmentNumber) => {
    // console.log(appointmentNumber);
    const index = state.appointments.findIndex(
      (appointment) => appointment.number === appointmentNumber
    );
    state.appointments.splice(index, 1);
  },
  SET_APPOINTMENT_COUNT: (state, appointmentCount) => (state.appointmentCount = appointmentCount),
};

/**
 * Getters
 */
const getters = {
  getAppointments: (state) => {
    /**
     * appointments are grouped by weekday in the table view, map each installationStartAt date to the weekday
     */
    state.appointments.map((appointment) => {
      appointment.weekday = dayjs(appointment.installationStartAt).format('dddd DD.MM.YYYY');
      appointment.installationStartAt = dayjs(appointment.installationStartAt).toDate();
      appointment.installationEndAt = dayjs(appointment.installationEndAt).toDate();
      appointment.title =
        appointment.project && appointment.project.customer
          ? appointment.project.customer.firstname + ' ' + appointment.project.customer.lastname
          : '';
      appointment.start = appointment.installationStartAt;
      appointment.end = appointment.installationEndAt;
      Vue.set(appointment, 'isOutdated', false);
      Vue.set(appointment, 'markedAsChanged', false);
    });

    return state.appointments;
  },
  getAppointment: (state) => (appointmentId) =>
    state.appointments.find((appointment) => appointment.id == appointmentId),
  getAppointmentByNumber: (state) => (appointmentNumber) =>
    state.appointments.find((appointment) => appointment.number == appointmentNumber),
  getAppointmentCount: (state) => state.appointmentCount,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
