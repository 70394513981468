import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const defaultCourse = () => {
  return {
    _id: '_new',
    number: '_new',
    name: '',
    sessions: [],
    trainers: [],
  };
};

const getDefaultState = () => {
  return {
    courses: [],
    courseCount: 0,
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an course
 * used for all queries/mutations which return an course
 * @type {DocumentNode}
 */
const COURSE_REQUEST_FIELDS = gql`
  fragment courseFields on Course {
    _id
    number
    name
    sessions {
      session
    }
    trainers {
      trainer
    }
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} course
 */
const createPayload = (course) => {
  const payload = {
    name: course.name.trim(),
    sessions: course.sessions ? course.sessions.map((sessionId) => ({ session: sessionId })) : [],
    trainers: course.trainers ? course.trainers.map((trainerId) => ({ trainer: trainerId })) : [],
  };
  return payload;
};

// Ensure your actions utilize this payload creation logic

// Other actions and methods...

/**
 * Actions
 */
const actions = {
  /**
   * Fetch a paginated, sorted and filtered course list
   * @param {*} param0
   * @param {*} payload
   */
  async fetchCoursesPaginated({ commit, dispatch }, { pagination, sorting, filters }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query coursesPaginated(
            $pagination: PaginationInput
            $sorting: SortingInput
            $filters: CourseFilterInput
          ) {
            coursesPaginated(pagination: $pagination, sorting: $sorting, filters: $filters) {
              courses {
                ...courseFields
              }
              courseCount
            }
          }
          ${COURSE_REQUEST_FIELDS}
        `,
        variables: {
          pagination: pagination,
          sorting: sorting,
          filters: filters,
        },
        fetchPolicy: 'network-only',
      });
      commit('SET_COURSES', response.data.coursesPaginated.courses);
      commit('SET_COURSE_COUNT', response.data.coursesPaginated.courseCount);
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },

  async fetchCourseByNumber({ commit, dispatch }, courseNumber) {
    try {
      // console.log(courseNumber);
      const response = await apolloClient.query({
        query: gql`
          query courseByNumber($courseNumber: Int!) {
            courseByNumber(courseNumber: $courseNumber) {
              ...courseFields
            }
          }
          ${COURSE_REQUEST_FIELDS}
        `,
        variables: {
          courseNumber: parseInt(courseNumber),
        },
      });
      commit('ADD_OR_UPDATE_COURSE', response.data.courseByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async createCourse({ commit, dispatch }, course) {
    const payload = createPayload(course);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createCourse($payload: CourseInput!) {
            createCourse(courseInput: $payload) {
              ...courseFields
            }
          }
          ${COURSE_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Kurs gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_COURSE', '_new'); // remove temporary _new course
      commit('ADD_OR_UPDATE_COURSE', response.data.createCourse);
      return response.data.createCourse.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateCourse({ commit, dispatch }, course) {
    const payload = createPayload(course);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateCourse($courseNumber: Int!, $payload: CourseInput!) {
            updateCourse(courseNumber: $courseNumber, courseInput: $payload) {
              ...courseFields
            }
          }
          ${COURSE_REQUEST_FIELDS}
        `,
        variables: {
          courseNumber: course.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Kurs aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_COURSE', response.data.updateCourse);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteCourse({ commit, dispatch }, courseNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteCourse($courseNumber: Int!) {
            deleteCourse(courseNumber: $courseNumber)
          }
        `,
        variables: {
          courseNumber: courseNumber,
        },
      });
      dispatch('message', {
        message: 'Kurs gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_COURSE', courseNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new course object in store and return it
   * @param {*} param0
   * @param {*} course
   */
  async initCourse({ commit }) {
    commit('ADD_OR_UPDATE_COURSE', defaultCourse());
  },

  courseLocalUpdate({ commit }, course) {
    commit('ADD_OR_UPDATE_COURSE', course);
  },

  courseCleanup({ commit }) {
    commit('REMOVE_COURSE', '_new');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_COURSE_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_COURSES: (state, courses) => (courses ? (state.courses = courses) : (state.courses = [])),
  ADD_COURSE: (state, course) => state.courses.push(course),
  ADD_OR_UPDATE_COURSE: (state, updatedCourse) => {
    const existingCourse = state.courses.find((course) => course._id === updatedCourse._id);
    if (existingCourse) {
      Object.assign(existingCourse, updatedCourse);
    } else {
      state.courses.push(updatedCourse);
    }
  },
  REMOVE_COURSE: (state, courseId) => {
    const index = state.courses.findIndex((course) => course._id === courseId);
    state.courses.splice(index, 1);
  },
  UPDATE_COURSE: (state, updatedCourse) => {
    const course = state.courses.find((course) => course._id === updatedCourse._id);
    Object.assign(course, updatedCourse);
  },
  SET_COURSE_COUNT: (state, courseCount) => (state.courseCount = courseCount),
};

/**
 * Getters
 */
const getters = {
  getCourses: (state) => state.courses,
  getCourse: (state) => (courseId) => state.courses.find((course) => course._id == courseId),

  getCourseByNumber: (state) => (courseNumber) =>
    state.courses.find((course) => course.number == courseNumber),
  getCourseCount: (state) => state.courseCount,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
