export default {
  // Batteriespeicher
  key: '1.3',
  label: 'battery_old',
  children: [
    {
      key: '1.3.0',
      name: 'composite.batteryManufacturer_batteryType',
      label: 'composite.batteryManufacturer_batteryType',
    },
    {
      key: '1.3.1',
      name: 'solarPlant.storageSystem.battery.manufacturer',
      label: 'manufacturer',
    },
    { key: '1.3.2', name: 'solarPlant.storageSystem.battery.type', label: 'type' },
    { key: '1.3.3', name: 'solarPlant.storageSystem.batteryCount', label: 'batteryCount' },
    {
      key: '1.3.4',
      name: 'solarPlant.storageSystem.battery.capacity',
      label: 'capacity_kwh',
    },
    {
      key: '1.3.5',
      name: 'solarPlant.storageSystem.battery.dischargePower',
      label: 'dischargePower',
    },
    {
      key: '1.3.6',
      name: 'solarPlant.storageSystem.battery.batteryModuleCount',
      label: 'batteryModuleCount',
    },
    {
      key: '1.3.7',
      name: 'solarPlant.storageSystem.battery.batteryModuleCapacity',
      label: 'batteryModuleCapacity',
    },
  ],
};
