/**
 * Activate loading state on start of all actions which perform graphql queries
 * deactivate loading state at the end of those actions
 * @returns {function(...[*]=)}
 */
export default function loadingPlugin() {
  return (store) => {
    store.subscribeAction({
      before: (action, state) => {
        if (
          action.type !== 'layout/appInit' &&
          action.type !== 'layout/switchSidebar' &&
          action.type !== 'layout/changeSidebarActive' &&
          action.type !== 'startLoading' &&
          action.type !== 'finishLoading' &&
          action.type !== 'auth/loginFromStorage' &&
          action.type !== 'fetchProjectIsOutdated' &&
          action.type !== 'fetchPostsOfProjectByNumber' &&
          action.type !== 'fetchProjectEditingState' &&
          action.type !== 'fetchAppointmentByNumber' &&
          action.type !== 'auth/refreshUSer'
        ) {
          store.dispatch('startLoading');
          // console.log(`before action ${action.type}`);
        }
      },
      after: (action, state) => {
        if (
          action.type !== 'layout/appInit' &&
          action.type !== 'layout/switchSidebar' &&
          action.type !== 'layout/changeSidebarActive' &&
          action.type !== 'startLoading' &&
          action.type !== 'finishLoading' &&
          action.type !== 'fetchPostsOfProjectByNumber' &&
          action.type !== 'auth/loginFromStorage' &&
          action.type !== 'fetchProjectIsOutdated' &&
          action.type !== 'fetchProjectEditingState' &&
          action.type !== 'fetchAppointmentByNumber' &&
          action.type !== 'auth/refreshUSer'
        ) {
          store.dispatch('finishLoading');
          // console.log(`after action ${action.type}`);
        }
      },
    });

    // store.subscribe((mutation, state) => {
    //     console.log(mutation.type)
    //     // console.log(mutation.payload)
    // })
  };
}
