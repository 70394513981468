<template>
  <div
    :class="[
      { root: true, chatOpen, sidebarClose, sidebarStatic },
      dashboardThemeClass,
      'sing-dashboard',
    ]"
  >
    <Sidebar />
    <!--  <Helper />-->
    <div class="wrap">
      <Header />
      <!-- <SidebarChat /> -->
      <div class="content">
        <transition name="router-animation">
          <router-view />
        </transition>
        <footer class="contentFooter">
          <small>Q Power Solution GmbH - v.{{ version }}</small>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('layout');

import Sidebar from '@/components/Sidebar/Sidebar';
import Header from '@/components/Header/Header';
// import Helper from "@/components/Helper/Helper";

import './Layout.scss';
// import SidebarChat from "../SidebarChat";

export default {
  name: 'Layout',
  components: { Sidebar, Header },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },
  methods: {
    ...mapActions(['switchSidebar', 'handleSwipe', 'changeSidebarActive', 'toggleSidebar']),
    handleWindowResize() {
      const width = window.innerWidth;

      if (width <= 768 && this.sidebarStatic) {
        this.toggleSidebar();
        this.changeSidebarActive(null);
      }
    },
  },
  computed: {
    ...mapState(['sidebarClose', 'sidebarStatic', 'chatOpen', 'dashboardTheme']),
    dashboardThemeClass: function() {
      return 'dashboard-' + this.dashboardTheme;
    },
    // version() {
    //   return process.env.VUE_APP_VERSION
    // }
  },
  created() {
    const staticSidebar = JSON.parse(localStorage.getItem('sidebarStatic'));

    if (staticSidebar) {
      this.$store.state.layout.sidebarStatic = true;
    } else if (!this.sidebarClose) {
      setTimeout(() => {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      }, 2500);
    }

    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
  },
};
</script>

<style src="./Layout.scss" lang="scss" />
