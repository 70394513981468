import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const defaultSolarPanel = () => {
  return {
    id: '_new',
    number: '_new',
    manufacturer: '',
    type: '',
    attachments: [],
  };
};

const getDefaultState = () => {
  return {
    solarPanels: [],
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an solarPanel
 * used for all queries/mutations which return an solarPanel
 * @type {DocumentNode}
 */
const SOLAR_PANEL_REQUEST_FIELDS = gql`
  fragment solarPanelFields on SolarPanel {
    id
    number
    manufacturer
    type
    power
    createdAt
    updatedAt
    attachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
  }
`;

/**
 * Actions
 */
const actions = {
  async fetchSolarPanels({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            solarPanels {
              ...solarPanelFields
            }
          }
          ${SOLAR_PANEL_REQUEST_FIELDS}
        `,
      });
      commit('SET_SOLAR_PANELS', response.data.solarPanels);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchSolarPanelByNumber({ commit, dispatch }, solarPanelNumber) {
    try {
      // console.log(solarPanelNumber);
      const response = await apolloClient.query({
        query: gql`
          query solarPanelByNumber($solarPanelNumber: Int!) {
            solarPanelByNumber(solarPanelNumber: $solarPanelNumber) {
              ...solarPanelFields
            }
          }
          ${SOLAR_PANEL_REQUEST_FIELDS}
        `,
        variables: {
          solarPanelNumber: parseInt(solarPanelNumber),
        },
      });
      commit('ADD_OR_UPDATE_SOLAR_PANEL', response.data.solarPanelByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async createSolarPanel({ commit, dispatch }, solarPanel) {
    /**
     * for text fields differentiate between "" and null: "" are sent to the server as empty value
     * deadlineAt date field: send NULL to server to remove a date
     */
    const payload = {
      ...(solarPanel.manufacturer ? { manufacturer: solarPanel.manufacturer } : {}),
      ...(solarPanel.type ? { type: solarPanel.type } : {}),
      ...(solarPanel.power ? { power: parseFloat(solarPanel.power) } : {}),
    };
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createSolarPanel($payload: SolarPanelInput!) {
            createSolarPanel(solarPanelInput: $payload) {
              ...solarPanelFields
            }
          }
          ${SOLAR_PANEL_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Solarmodul gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_SOLAR_PANEL', '_new'); // remove temporary _new solarPanel
      commit('ADD_OR_UPDATE_SOLAR_PANEL', response.data.createSolarPanel);
      return response.data.createSolarPanel.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateSolarPanel({ commit, dispatch }, solarPanel) {
    /**
     * for text fields differentiate between "" and null: "" are sent to the server as empty value
     * deadlineAt date field: send NULL to server to remove a date
     */
    const payload = {
      ...(solarPanel.manufacturer ? { manufacturer: solarPanel.manufacturer } : {}),
      ...(solarPanel.type ? { type: solarPanel.type } : {}),
      ...(solarPanel.power ? { power: parseFloat(solarPanel.power) } : {}),
    };

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateSolarPanel($solarPanelNumber: Int!, $payload: SolarPanelInput!) {
            updateSolarPanel(solarPanelNumber: $solarPanelNumber, solarPanelInput: $payload) {
              ...solarPanelFields
            }
          }
          ${SOLAR_PANEL_REQUEST_FIELDS}
        `,
        variables: {
          solarPanelNumber: solarPanel.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Solarmodul aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_SOLAR_PANEL', response.data.updateSolarPanel);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteSolarPanel({ commit, dispatch }, solarPanelNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteSolarPanel($solarPanelNumber: Int!) {
            deleteSolarPanel(solarPanelNumber: $solarPanelNumber)
          }
        `,
        variables: {
          solarPanelNumber: solarPanelNumber,
        },
      });
      dispatch('message', {
        message: 'Solarmodul gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_SOLAR_PANEL', solarPanelNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Upload an image belonging to the solarPanel
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async uploadSolarPanelAttachment({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation uploadSolarPanelAttachment($attachmentInput: AttachmentInput!) {
            uploadSolarPanelAttachment(attachmentInput: $attachmentInput) {
              ...solarPanelFields
            }
          }
          ${SOLAR_PANEL_REQUEST_FIELDS}
        `,
        variables: {
          attachmentInput: {
            file: payload.file,
            referenceId: payload.parentId,
          },
        },
      });
      commit('UPDATE_SOLAR_PANEL', response.data.uploadSolarPanelAttachment);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Upload!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Delete SolarPanel Atrachment
   * @param commit
   * @param payload
   */
  async deleteSolarPanelAttachment({ commit, dispatch }, payload) {
    // console.log(payload)
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteSolarPanelAttachment($solarPanelId: ID!, $attachmentId: ID!) {
            deleteSolarPanelAttachment(solarPanelId: $solarPanelId, attachmentId: $attachmentId) {
              ...solarPanelFields
            }
          }
          ${SOLAR_PANEL_REQUEST_FIELDS}
        `,
        variables: {
          solarPanelId: payload.solarPanelId,
          attachmentId: payload.attachmentId,
        },
      });
      // console.log(response)
      commit('UPDATE_SOLAR_PANEL', response.data.deleteSolarPanelAttachment);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new solarPanel object in store and return it
   * @param {*} param0
   * @param {*} solarPanel
   */
  async initSolarPanel({ commit }) {
    commit('ADD_OR_UPDATE_SOLAR_PANEL', defaultSolarPanel());
  },

  solarPanelLocalUpdate({ commit }, solarPanel) {
    commit('ADD_OR_UPDATE_SOLAR_PANEL', solarPanel);
  },

  solarPanelCleanup({ commit }) {
    commit('REMOVE_SOLAR_PANEL', '_new');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_SOLAR_PANEL_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_SOLAR_PANELS: (state, solarPanels) =>
    solarPanels ? (state.solarPanels = solarPanels) : (state.solarPanels = []),
  ADD_SOLAR_PANEL: (state, solarPanel) => state.solarPanels.push(solarPanel),
  ADD_OR_UPDATE_SOLAR_PANEL: (state, updatedSolarPanel) => {
    const existingSolarPanel = state.solarPanels.find(
      (solarPanel) => solarPanel.id === updatedSolarPanel.id
    );
    if (existingSolarPanel) {
      Object.assign(existingSolarPanel, updatedSolarPanel);
    } else {
      state.solarPanels.push(updatedSolarPanel);
    }
  },
  SET_SOLAR_PANEL_TYPES: (state, solarPanelTypes) => {
    state.solarPanelTypes = [];
    solarPanelTypes.map((solarPanelType) => {
      state.solarPanelTypes.push(solarPanelType.name);
    });
  },
  REMOVE_SOLAR_PANEL: (state, solarPanelId) => {
    const index = state.solarPanels.findIndex((solarPanel) => solarPanel.id === solarPanelId);
    state.solarPanels.splice(index, 1);
  },
  UPDATE_SOLAR_PANEL: (state, updatedSolarPanel) => {
    const solarPanel = state.solarPanels.find(
      (solarPanel) => solarPanel.id === updatedSolarPanel.id
    );
    Object.assign(solarPanel, updatedSolarPanel);
  },
};

/**
 * Getters
 */
const getters = {
  getSolarPanels: (state) => state.solarPanels,
  getSolarPanel: (state) => (solarPanelId) =>
    state.solarPanels.find((solarPanel) => solarPanel.id == solarPanelId),
  getSolarPanelByNumber: (state) => (solarPanelNumber) =>
    state.solarPanels.find((solarPanel) => solarPanel.number == solarPanelNumber),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
