import de from './de';
import en from './en';
import it from './it';
import cs from './cs';
import sk from './sk';
import bs from './bs';
import hr from './hr';
import ro from './ro';
import hu from './hu';
import pl from './pl';
import bg from './bg';
import ru from './ru';
import ko from './ko';
import fa from './fa';
export default {
  de,
  en,
  it,
  cs,
  sk,
  bs,
  hr,
  ro,
  hu,
  pl,
  bg,
  ru,
  ko,
  fa,
};
