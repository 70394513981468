import apolloClient from '../apollo';
import gql from 'graphql-tag';
import Vue from 'vue';

const defaultContent = () => {
  return {
    _id: '_new',
    number: '_new',
    name: '',
    description: '',
    contentAttachments: [],
  };
};

const getDefaultState = () => {
  return {
    contents: [],
    contentCount: 0,
  };
};
const state = getDefaultState();

/**
 * Sanitize inputs and create payload to send to the GraphQL API for content.
 * @param {*} content
 */
const createContentPayload = (content) => {
  const payload = {
    ...(content.name ? { name: content.name.trim() } : {}),
    ...(content.description ? { description: content.description.trim() } : {}),
    ...(content.contentAttachments && content.contentAttachments.length > 0
      ? { attachmentIds: content.contentAttachments.map((attachment) => attachment.id) }
      : {}),
  };

  return payload;
};

export const actions = {
  async fetchContents({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            contents {
              contentCount
              contents {
                id
                number
                description
                name
                contentAttachments {
                  id
                  path
                  thumbnail
                  filename
                  displayFilename
                  size
                  mimetype
                }
              }
            }
          }
        `,
      });
      commit('SET_CONTENTS', response.data.contents.contents);
    } catch (err) {
      console.error('Error fetching contents:', err);
    }
  },
  async fetchContentByNumber({ commit }, contentNumber) {
    const parsedNumber = parseInt(contentNumber, 10);
    if (isNaN(parsedNumber)) {
      console.error('Invalid content number:', contentNumber);
      return; // Exit the function if the content number is not valid
    }

    try {
      const response = await apolloClient.query({
        query: gql`
          query ContentByNumber($contentNumber: Int!) {
            contentByNumber(contentNumber: $contentNumber) {
              id
              number
              name
              description
              contentAttachments {
                id
                path
                thumbnail
                filename
                displayFilename
                size
                mimetype
              }
            }
          }
        `,
        variables: { contentNumber: parsedNumber },
      });
      commit('ADD_OR_UPDATE_CONTENT', response.data.contentByNumber);
    } catch (err) {
      console.error('Error fetching content by number:', err);
      throw new Error(err.message);
    }
  },

  async createContent({ commit }, content) {
    const payload = createContentPayload(content);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createContent($payload: ContentInput!) {
            createContent(contentInput: $payload) {
              id
              number
              name
              description
              contentAttachments {
                id
                path
                thumbnail
                filename
                displayFilename
                size
                mimetype
              }
            }
          }
        `,
        variables: { payload },
      });
      commit('ADD_CONTENT', response.data.createContent);
      return response.data.createContent.number;
    } catch (err) {
      console.error('Error creating content:', err);
      throw new Error(err);
    }
  },

  async updateContent({ commit }, content) {
    const payload = createContentPayload(content);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateContent($number: Int!, $payload: ContentInput!) {
            updateContent(contentNumber: $number, contentInput: $payload) {
              number
              name
              description
              contentAttachments {
                id
                path
                thumbnail
                filename
                displayFilename
                size
                mimetype
              }
            }
          }
        `,
        variables: { number: content.number, payload },
      });
      commit('UPDATE_CONTENT', response.data.updateContent);
    } catch (err) {
      console.error('Error updating content:', err);
      throw new Error(err);
    }
  },
  /**
   * create a new content object in store and return it
   * @param {*} param0
   * @param {*} content
   */
  async initContent({ commit }) {
    commit('ADD_OR_UPDATE_CONTENT', defaultContent());
  },

  async deleteContent({ commit }, number) {
    try {
      await apolloClient.mutate({
        mutation: gql`
          mutation deleteContent($number: Int!) {
            deleteContent(contentNumber: $number)
          }
        `,
        variables: { number },
      });
      commit('REMOVE_CONTENT', number);
    } catch (err) {
      console.error('Error deleting content:', err);
    }
  },
  /**
   * Upload an image belonging to the content
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async uploadContentAttachment({ commit, dispatch }, payload) {
    console.log(payload);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation uploadContentAttachment($attachmentInput: AttachmentInput!) {
            uploadContentAttachment(attachmentInput: $attachmentInput) {
              contentAttachments {
                id
                path
                thumbnail
                filename
                displayFilename
                size
                mimetype
              }
            }
          }
        `,
        variables: {
          attachmentInput: {
            file: payload.file,
            referenceId: payload.parentId,
          },
        },
      });
      commit('UPDATE_CONTENT_ATTACHMENTS', {
        updatedContent: response.data.uploadContentAttachment,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Upload!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },
};

export const mutations = {
  ADD_OR_UPDATE_CONTENT: (state, updatedContent) => {
    const existingContent = state.contents.find((content) => content._id === updatedContent._id);
    if (existingContent) {
      Object.assign(existingContent, updatedContent);
    } else {
      state.contents.push(updatedContent);
    }
  },
  SET_CONTENTS(state, contents) {
    state.contents = contents;
  },
  ADD_CONTENT(state, content) {
    state.contents.push(content);
  },
  UPDATE_CONTENT(state, updatedContent) {
    const index = state.contents.findIndex((content) => content.number === updatedContent.number);
    if (index !== -1) {
      state.contents[index] = updatedContent;
    }
  },
  REMOVE_CONTENT(state, number) {
    state.contents = state.contents.filter((content) => content.number !== number);
  },
  UPDATE_CONTENT_ATTACHMENTS: (state, { updatedContent }) => {
    const content = state.contents.find((content) => content.id === updatedContent.id);
    if (content) {
      Object.keys(updatedContent).forEach((key) => {
        Vue.set(content, key, updatedContent[key]);
      });
    }
  },
};

export const getters = {
  getContents: (state) => state.contents,
  getContentCount: (state) => state.contents.length,
  getContentByNumber: (state) => (number) => {
    return state.contents.find((content) => content.number === number);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
