import store from '@/store';
import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */

const defaultUser = () => {
  return {
    id: '_new',
    number: '_new',
    firstname: '',
    lastname: '',
    password: '',
    gender: 'MALE',
  };
};

const getDefaultState = () => {
  return {
    users: [],
    userCount: 0,
    genders: [],
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for a user
 * used for all queries/mutations which return a user
 * @type {DocumentNode}
 */
const USER_REQUEST_FIELDS = gql`
  fragment userFields on User {
    id
    number
    firstname
    lastname
    email
    role
    createdAt
    position
    gender
    company
    projectExportBuildState
    client {
      number
      name
      street
      zip
      city
      type
      electricians {
        name
        id
        number
      }
    }
    clients {
      id
      number
      name
      street
      zip
      city
      type
      electricians {
        name
        id
        number
      }
    }
    avatar {
      id
      path
    }
  }
`;

const USER_LIST_REQUEST_FIELDS = gql`
  fragment userListFields on User {
    id
    number
    firstname
    lastname
    email
    role
    createdAt
    position
    gender
    company
    client {
      number
      name
    }
    clients {
      number
      name
    }
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} project
 */
const createPayload = (user) => {
  // console.log(user);

  /** Only send ID's for clients */
  const clientIds = [];
  if (user.clients) {
    user.clients.map((client) => {
      clientIds.push(client.id);
    });
  }

  const payload = {
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    position: user.position,
    password: user.password ? user.password : null,
    company: user.company,
    role: user.role ? user.role : null,
    gender: user.gender ? user.gender : null,
    employer: user.employer ? user.employer.id : null,
    client: user.client ? user.client.id : null,
    clients: clientIds,
  };
  return payload;
};

/**
 * Actions
 */
const actions = {
  /**
   * Reset state in module
   * @param commit
   */
  reset({ commit }) {
    commit('SET_DEFAULT_STATE');
  },

  /**
   * Fetch a paginated list of users from GraphQL
   * @param {*} param0
   * @param {*} param1
   */
  async fetchUsersPaginated({ commit, dispatch }, { pagination, sorting, filters }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query usersPaginated(
            $pagination: PaginationInput
            $sorting: SortingInput
            $filters: UserFilterInput
          ) {
            usersPaginated(pagination: $pagination, sorting: $sorting, filters: $filters) {
              users {
                ...userListFields
              }
              userCount
            }
          }
          ${USER_LIST_REQUEST_FIELDS}
        `,
        variables: {
          pagination: pagination,
          sorting: sorting,
          filters: filters,
        },
        fetchPolicy: 'network-only',
      });
      // console.log(response.data);
      commit('SET_USERS', response.data.usersPaginated.users);
      commit('SET_USER_COUNT', response.data.usersPaginated.userCount);
    } catch (err) {
      throw new Error(err);
    }
  },

  /**
   * Fetch all users from GraphQL API
   * @deprecated refactor to fetchUsersPaginated
   * @param commit
   * @returns {Promise<void>}
   */
  async fetchUsers({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            users {
              ...userFields
            }
          }
          ${USER_REQUEST_FIELDS}
        `,
      });
      commit('SET_USERS', response.data.users);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  async fetchUserByNumber({ commit, dispatch }, userNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query userByNumber($userNumber: Int!) {
            userByNumber(userNumber: $userNumber) {
              ...userFields
            }
          }
          ${USER_REQUEST_FIELDS}
        `,
        variables: {
          userNumber: parseInt(userNumber),
        },
      });
      // console.log(response.data.userByNumber);
      commit('ADD_OR_UPDATE_USER', response.data.userByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchGenders({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "Gender") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_GENDERS', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  /**
   * Fetch an URL for a specified attachment type
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  async fetchUserAttachmentUrl({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query userAttachmentUrl($userId: ID!, $attachmentType: String!) {
            userAttachmentUrl(userId: $userId, attachmentType: $attachmentType)
          }
        `,
        variables: {
          userId: payload.userId,
          attachmentType: payload.attachmentType,
        },
      });
      return response.data.userAttachmentUrl;
    } catch (err) {
      throw new Error(err);
    }
  },

  /**
   *
   * @param commit
   * @param domainId
   * @returns {Promise<void>}
   */
  async createUser({ commit, dispatch }, user) {
    const payload = createPayload(user);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createUser($payload: UserCreateInput!) {
            createUser(userInput: $payload) {
              ...userFields
            }
          }
          ${USER_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Benutzer erstellt!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_USER', '_new'); // remove temporary _new user
      commit('ADD_OR_UPDATE_USER', response.data.createUser);
      return response.data.createUser.number;
    } catch (err) {
      const message = err.message;
      dispatch('message', {
        message: message,
        type: 'danger',
        lifetime: 1,
      });
    }
  },

  /**
   * update a user
   * @param commit
   * @param userId
   * @param payload
   * @param domainId
   * @returns {Promise<void>}
   */
  async updateUser({ commit, dispatch }, user) {
    const payload = createPayload(user);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateUser($userId: ID!, $payload: UserUpdateInput!) {
            updateUser(userId: $userId, userInput: $payload) {
              ...userFields
            }
          }
          ${USER_REQUEST_FIELDS}
        `,
        variables: {
          userId: user.id,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Benutzer aktualisiert!',
        type: 'success',
        lifetime: 1,
      });
      commit('ADD_OR_UPDATE_USER', response.data.updateUser);
    } catch (err) {
      const message = err.message;
      dispatch('message', {
        message: message,
        type: 'danger',
        lifetime: 1,
      });
    }
  },

  /**
   * Delete User (only if not in use)
   * @param commit
   * @param payload
   */
  async deleteUser({ commit, dispatch }, id) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteUser($userId: ID!) {
            deleteUser(userId: $userId)
          }
        `,
        variables: {
          userId: id,
        },
      });
      // console.log(response)
      dispatch('message', {
        message: 'Benutzer gelöscht!',
        type: 'success',
        lifetime: 1,
      });
      commit('REMOVE_USER', id);
    } catch (err) {
      const message = err.message;
      dispatch('message', {
        message: message,
        type: 'danger',
        lifetime: 1,
      });
    }
  },

  async deleteUserByNumber({ commit, dispatch }, userNumber) {
    console.log(userNumber);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteUserByNumber($userNumber: Int!) {
            deleteUserByNumber(userNumber: $userNumber)
          }
        `,
        variables: {
          userNumber: userNumber,
        },
      });
      dispatch('message', {
        message: 'User gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_USER_BY_NUMBER', userNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Delete Image
   * @param commit
   * @param payload
   */
  async deleteAvatar({ commit }, userId) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteUserAvatarImage($userId: ID!) {
            deleteUserAvatarImage(userId: $userId) {
              id
              number
              firstname
              lastname
              email
              role
              createdAt
              position
              gender
              company
              avatar {
                id
                path
              }
            }
          }
        `,
        variables: {
          userId: userId,
        },
      });
      // console.log(response)
      commit('UPDATE_USER', response.data.deleteUserAvatarImage);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  async uploadUserAvatarImage({ commit }, payload) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation uploadUserAvatarImage($imageInput: ImageInput!) {
            uploadUserAvatarImage(imageInput: $imageInput) {
              id
              number
              firstname
              lastname
              email
              role
              createdAt
              position
              gender
              company
              avatar {
                id
                path
              }
            }
          }
        `,
        variables: {
          imageInput: {
            file: payload.file,
            userId: payload.parentId,
          },
        },
      });
      // console.log(response)
      commit('UPDATE_USER', response.data.uploadUserAvatarImage);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  /**
   * add the currently logged in user to the user state
   * so that the standard user operations can be done on the logged in user
   * @param commit
   */
  async addOrUpdateCurrentUser({ commit }, user) {
    try {
      // console.log(user)
      // const currentUser = store.getters['auth/getCurrentUser']
      if (!store.getters.getUser(user.id)) {
        // console.log('addOrUpdateCurrentUser: ADD_USER')
        commit('ADD_USER', user);
      } else {
        // console.log('addOrUpdateCurrentUser: UPDATE_USER')
        commit('UPDATE_USER', user);
      }
      // console.log(store.getters['getUsers'])
    } catch (e) {
      // console.log(e)
    }
  },

  /**
   * Save filters, sorting, etc on server
   * @param {*} param0
   */
  async updateUserSettings({ commit, dispatch }, payload) {
    // console.log(payload);
    const settingsObject = {};
    settingsObject.settings = JSON.stringify(payload.settings);
    // console.log(settingsObject);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateUserSettings($userId: ID!, $payload: UserSettingsInput!) {
            updateUserSettings(userId: $userId, userSettingsInput: $payload)
          }
        `,
        variables: {
          userId: payload.userId,
          payload: settingsObject,
        },
      });
      // console.log(response);
    } catch (err) {
      // console.log(err);
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  async messageCredentialsToUser({ commit, dispatch }, user) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation messageCredentialsToUser($payload: UserCreateInput!) {
            messageCredentialsToUser(userInput: $payload)
          }
        `,
        variables: {
          payload: {
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            password: user.password,
            gender: user.gender ? user.gender : null,
          },
        },
      });
      dispatch('message', {
        message: 'Benutzer gespeichert und E-Mail versendet an ' + user.email,
        type: 'success',
        lifetime: 2,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim versenden der E-Mail!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new battery object in store and return it
   * @param {*} param0
   * @param {*} battery
   */
  async initUser({ commit }) {
    commit('ADD_USER', defaultUser());
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_USERS: (state, users) => (state.users = users),
  ADD_USER: (state, user) => state.users.push(user),
  ADD_OR_UPDATE_USER: (state, updatedUser) => {
    const existingUser = state.users.find((user) => user.id === updatedUser.id);
    if (existingUser) {
      Object.assign(existingUser, updatedUser);
    } else {
      state.users.push(updatedUser);
    }
  },
  UPDATE_USER: (state, updatedUser) => {
    const user = state.users.find((user) => user.id === updatedUser.id);
    Object.assign(user, updatedUser);
  },
  // UPDATE_USER_BY_NAME: (state, updatedUser) => {
  //     const user = state.users.find(user => user.name === updatedUser.name)
  //     Object.assign(user, updatedUser)
  //     delete user.isNew
  // },
  REMOVE_USER: (state, userId) => {
    const index = state.users.findIndex((user) => user.id === userId);
    state.users.splice(index, 1);
  },
  REMOVE_USER_BY_NUMBER: (state, userNumber) => {
    const index = state.users.findIndex((user) => user.number === userNumber);
    state.users.splice(index, 1);
  },
  REMOVE_POST_NOTIFICATION_FROM_USER: (state, payload) => {
    // console.log(payload)
    const user = state.users.find((user) => user.id === payload.userId);
    // console.log(user)
    const notificationIndex = user.postNotifications.findIndex(
      (notification) => notification.id === payload.notificationId
    );
    // console.log(notificationIndex)
    user.postNotifications.splice(notificationIndex, 1);
  },
  REMOVE_AVATAR: (state, updatedUser) => {
    const user = state.users.find((user) => user.id === updatedUser.id);
    Object.assign(user.avatar, null);
  },
  SET_GENDERS: (state, genders) => {
    state.genders = [];
    genders.map((gender) => {
      state.genders.push(gender.name);
    });
  },
  SET_USER_COUNT: (state, userCount) => (state.userCount = userCount),
};

/**
 * Getters
 */
const getters = {
  getUsers: (state) => state.users,
  getUser: (state) => (userId) => state.users.find((user) => user.id === userId),
  getUserByNumber: (state) => (userNumber) => state.users.find((user) => user.number == userNumber),
  getGenders: (state) => state.genders,
  getUserCount: (state) => state.userCount,

  /**
   * Group users by company for display in a select dropdown
   */
  getUsersGroupedByCompany: (state) => {
    const groupedUsers = [];
    state.users.map((user) => {
      // push company into groupedUsers if not already exists
      let companyExists = false;
      groupedUsers.map((item) => {
        if (item.company === user.company.name) {
          companyExists = true;
        }
      });
      if (!companyExists) {
        groupedUsers.push({
          company: user.company.name,
          users: [],
        });
      }
    });

    state.users.map((user) => {
      groupedUsers.map((group) => {
        if (group.company === user.company.name) {
          group.users.push(user);
        }
      });
    });

    return groupedUsers;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
