import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const defaultDocument = () => {
  return {
    id: '_new',
    number: '_new',
    name: '',
    type: 'NET_REGISTRATION',
    approvedState: 'NO',
    pageBackgroundAttachments: [],
    netRegistrationPowerCompanies: [],
    operationRegistrationPowerCompanies: [],
    fields: [],
    powerCompanies: [],
  };
};

const getDefaultState = () => {
  return {
    documents: [],
    documentTypes: [],
    documentApprovedStates: [],
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an document
 * used for all queries/mutations which return an document
 * @type {DocumentNode}
 */
const DOCUMENT_REQUEST_FIELDS = gql`
  fragment documentFields on Document {
    id
    number
    name
    fieldCounter
    type
    approvedState
    mailConstraintState
    description
    fields {
      number
      name
      key
      label
      page
      x
      y
      width
      height
      value
      translate
      type
    }
    createdAt
    updatedAt
    netRegistrationPowerCompanies {
      _id
      number
      company
    }
    operationRegistrationPowerCompanies {
      _id
      company
      number
    }
    pageBackgroundAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} project
 */
const createPayload = (document) => {
  // console.log(document);

  // remove __typename from position objects
  if (document.fields) {
    document.fields.map((field) => {
      delete field.__typename;
      delete field.i18n;
    });
  }

  let netRegistrationPowerCompaniesIds = document.netRegistrationPowerCompanies.map(
    (powerCompany) => {
      return powerCompany._id;
    }
  );
  let operationRegistrationPowerCompaniesIds = document.operationRegistrationPowerCompanies.map(
    (powerCompany) => {
      return powerCompany._id;
    }
  );
  // Document ist either net registration or operation registration, clear the other one
  if (document.type === 'NET_REGISTRATION') {
    operationRegistrationPowerCompaniesIds = [];
  }
  if (document.type === 'OPERATION_REGISTRATION') {
    netRegistrationPowerCompaniesIds = [];
  }
  if (document.type === 'OTHER') {
    netRegistrationPowerCompaniesIds = [];
    operationRegistrationPowerCompaniesIds = [];
  }

  const payload = {
    name: document.name,
    description: document.description,
    type: document.type,
    approvedState: document.approvedState,
    mailConstraintState: document.mailConstraintState,
    fields: document.fields,
    fieldCounter: document.fieldCounter,
    netRegistrationPowerCompanies: netRegistrationPowerCompaniesIds,
    operationRegistrationPowerCompanies: operationRegistrationPowerCompaniesIds,
  };
  return payload;
};

/**
 * Actions
 */
const actions = {
  async fetchDocuments({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            documentsWithReferences {
              ...documentFields
            }
          }
          ${DOCUMENT_REQUEST_FIELDS}
        `,
      });
      commit('SET_DOCUMENTS', response.data.documentsWithReferences);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchDocumentByNumber({ commit, dispatch }, documentNumber) {
    try {
      // console.log(documentNumber);
      const response = await apolloClient.query({
        query: gql`
          query documentByNumber($documentNumber: Int!) {
            documentByNumber(documentNumber: $documentNumber) {
              ...documentFields
            }
          }
          ${DOCUMENT_REQUEST_FIELDS}
        `,
        variables: {
          documentNumber: parseInt(documentNumber),
        },
      });
      commit('ADD_OR_UPDATE_DOCUMENT', response.data.documentByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async createDocument({ commit, dispatch }, document) {
    const payload = createPayload(document);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createDocument($payload: DocumentInput!) {
            createDocument(documentInput: $payload) {
              ...documentFields
            }
          }
          ${DOCUMENT_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Dokument gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_DOCUMENT', '_new'); // remove temporary _new document
      commit('ADD_OR_UPDATE_DOCUMENT', response.data.createDocument);
      return response.data.createDocument.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateDocument({ commit, dispatch }, document) {
    const payload = createPayload(document);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateDocument($documentNumber: Int!, $payload: DocumentInput!) {
            updateDocument(documentNumber: $documentNumber, documentInput: $payload) {
              ...documentFields
            }
          }
          ${DOCUMENT_REQUEST_FIELDS}
        `,
        variables: {
          documentNumber: document.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Dokument aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_DOCUMENT', response.data.updateDocument);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteDocument({ commit, dispatch }, documentNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteDocument($documentNumber: Int!) {
            deleteDocument(documentNumber: $documentNumber)
          }
        `,
        variables: {
          documentNumber: documentNumber,
        },
      });
      dispatch('message', {
        message: 'Dokument gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_DOCUMENT', documentNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Upload an image belonging to the document
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async uploadDocumentAttachment({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation uploadDocumentAttachment($attachmentInput: AttachmentInput!) {
            uploadDocumentAttachment(attachmentInput: $attachmentInput) {
              ...documentFields
            }
          }
          ${DOCUMENT_REQUEST_FIELDS}
        `,
        variables: {
          attachmentInput: {
            file: payload.file,
            referenceId: payload.parentId,
          },
        },
      });
      commit('UPDATE_DOCUMENT', response.data.uploadDocumentAttachment);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Upload!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Delete Document Atrachment
   * @param commit
   * @param payload
   */
  async deleteDocumentAttachment({ commit, dispatch }, payload) {
    // console.log(payload)
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteDocumentAttachment($documentId: ID!, $attachmentId: ID!) {
            deleteDocumentAttachment(documentId: $documentId, attachmentId: $attachmentId) {
              ...documentFields
            }
          }
          ${DOCUMENT_REQUEST_FIELDS}
        `,
        variables: {
          documentId: payload.documentId,
          attachmentId: payload.attachmentId,
        },
      });
      // console.log(response)
      commit('UPDATE_DOCUMENT', response.data.deleteDocumentAttachment);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new document object in store and return it
   * @param {*} param0
   * @param {*} document
   */
  async initDocument({ commit }) {
    commit('ADD_OR_UPDATE_DOCUMENT', defaultDocument());
  },

  documentLocalUpdate({ commit }, document) {
    commit('ADD_OR_UPDATE_DOCUMENT', document);
  },

  documentCleanup({ commit }) {
    commit('REMOVE_DOCUMENT', '_new');
  },

  addDocumentField({ commit }, { document, field }) {
    commit('ADD_DOCUMENT_FIELD', { document, field });
  },
  deleteDocumentField({ commit }, { document, fieldNumber }) {
    commit('DELETE_DOCUMENT_FIELD', { document, fieldNumber });
  },
  updateDocumentField({ commit }, { document, field }) {
    commit('UPDATE_DOCUMENT_FIELD', { document, field });
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_DOCUMENT_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_DOCUMENTS: (state, documents) =>
    documents ? (state.documents = documents) : (state.documents = []),
  ADD_DOCUMENT: (state, document) => state.documents.push(document),
  ADD_OR_UPDATE_DOCUMENT: (state, updatedDocument) => {
    const existingDocument = state.documents.find((document) => document.id === updatedDocument.id);
    if (existingDocument) {
      Object.assign(existingDocument, updatedDocument);
    } else {
      state.documents.push(updatedDocument);
    }
  },
  SET_DOCUMENT_TYPES: (state, documentTypes) => {
    state.documentTypes = [];
    documentTypes.map((documentType) => {
      state.documentTypes.push(documentType.name);
    });
  },
  SET_DOCUMENT_APPROVED_STATES: (state, documentApprovedStates) => {
    state.documentApprovedStates = [];
    documentApprovedStates.map((approvedState) => {
      state.documentApprovedStates.push(approvedState.name);
    });
  },
  REMOVE_DOCUMENT: (state, documentId) => {
    const index = state.documents.findIndex((document) => document.id === documentId);
    state.documents.splice(index, 1);
  },
  UPDATE_DOCUMENT: (state, updatedDocument) => {
    const document = state.documents.find((document) => document.id === updatedDocument.id);
    Object.assign(document, updatedDocument);
  },

  /** @deprecated */
  ADD_DOCUMENT_FIELD: (state, { document, field }) => {
    const existingDocument = state.documents.find(
      (existingDocument) => existingDocument.number == document.number
    );

    existingDocument.fieldCounter += 1;
    console.log(existingDocument);
    field.number = existingDocument.fieldCounter;
    existingDocument.fields.push(field);
  },
  /** @deprecated */
  DELETE_DOCUMENT_FIELD: (state, { document, fieldNumber }) => {
    const existingDocument = state.documents.find(
      (existingDocument) => existingDocument.number == document.number
    );
    const fieldIndex = existingDocument.fields.findIndex(
      (existingField) => existingField.number == fieldNumber
    );
    // console.log(fieldIndex);
    if (fieldIndex >= 0) {
      existingDocument.fields.splice(fieldIndex, 1);
    }
  },
  /** @deprecated */
  UPDATE_DOCUMENT_FIELD: (state, { document, field }) => {
    const existingDocument = state.documents.find(
      (existingDocument) => existingDocument.number == document.number
    );
    const existingField = existingDocument.fields.find(
      (existingField) => existingField.number == field.number
    );
    if (existingField) {
      Object.assign(existingField, field);
    }
  },
};

/**
 * Getters
 */
const getters = {
  getDocuments: (state) => state.documents,
  getDocument: (state) => (documentId) =>
    state.documents.find((document) => document.id == documentId),
  getDocumentByNumber: (state) => (documentNumber) =>
    state.documents.find((document) => document.number == documentNumber),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
