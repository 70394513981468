import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const defaultEmployee = () => {
  return {
    _id: '_new',
    number: '_new',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    activeState: 'ACTIVE',
    users: [],
  };
};

const getDefaultState = () => {
  return {
    employees: [],
    employeeCount: 0,
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an employee
 * used for all queries/mutations which return an employee
 * @type {DocumentNode}
 */
const EMPLOYEE_REQUEST_FIELDS = gql`
  fragment employeeFields on Employee {
    _id
    number
    firstname
    lastname
    email
    phone
    activeState
    users {
      id
      number
      firstname
      lastname
      email
    }
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} employee
 */
const createPayload = (employee) => {
  /** Only send ID's for users */
  if (employee.users) {
    const ids = [];
    employee.users.map((user) => {
      ids.push(user.id);
    });
    employee.users = ids;
  }

  const payload = {
    ...(employee.firstname ? { firstname: employee.firstname } : {}),
    ...(employee.lastname ? { lastname: employee.lastname } : {}),
    ...(employee.email !== null ? { email: employee.email } : {}),
    ...(employee.phone !== null ? { phone: employee.phone } : {}),
    ...(employee.phone !== null ? { phone: employee.phone } : {}),
    ...(employee.activeState !== null ? { activeState: employee.activeState } : {}),
    users: employee.users,
  };

  console.log(payload);
  return payload;
};

/**
 * Actions
 */
const actions = {
  /**
   * Fetch a paginated, sorted and filtered employee list
   * @param {*} param0
   * @param {*} payload
   */
  async fetchEmployees({ commit, dispatch }, { page, pageSize, sortField, sortOrder, filters }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query employees(
            $page: Int!
            $pageSize: Int!
            $sortField: String
            $sortOrder: Int
            $filters: EmployeeFilterInput
          ) {
            employees(
              page: $page
              pageSize: $pageSize
              sortField: $sortField
              sortOrder: $sortOrder
              filters: $filters
            ) {
              employees {
                ...employeeFields
              }
              employeeCount
            }
          }
          ${EMPLOYEE_REQUEST_FIELDS}
        `,
        variables: {
          page: page,
          pageSize: pageSize,
          sortField: sortField,
          sortOrder: sortOrder,
          filters: filters,
        },
        fetchPolicy: 'network-only',
      });
      commit('SET_EMPLOYEES', response.data.employees.employees);
      commit('SET_EMPLOYEE_COUNT', response.data.employees.employeeCount);
      // commit('ACTIVATE_EMPLOYEE_CACHE');
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },

  async fetchEmployeeByNumber({ commit, dispatch }, employeeNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query employeeByNumber($employeeNumber: Int!) {
            employeeByNumber(employeeNumber: $employeeNumber) {
              ...employeeFields
            }
          }
          ${EMPLOYEE_REQUEST_FIELDS}
        `,
        variables: {
          employeeNumber: parseInt(employeeNumber),
        },
      });
      commit('ADD_OR_UPDATE_EMPLOYEE', response.data.employeeByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async createEmployee({ commit, dispatch }, employee) {
    const payload = createPayload(employee);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createEmployee($payload: EmployeeInput!) {
            createEmployee(employeeInput: $payload) {
              ...employeeFields
            }
          }
          ${EMPLOYEE_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Auftraggeber gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_EMPLOYEE', '_new'); // remove temporary _new employee
      commit('ADD_OR_UPDATE_EMPLOYEE', response.data.createEmployee);
      return response.data.createEmployee.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateEmployee({ commit, dispatch }, employee) {
    const payload = createPayload(employee);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateEmployee($employeeNumber: Int!, $payload: EmployeeInput!) {
            updateEmployee(employeeNumber: $employeeNumber, employeeInput: $payload) {
              ...employeeFields
            }
          }
          ${EMPLOYEE_REQUEST_FIELDS}
        `,
        variables: {
          employeeNumber: employee.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Auftraggeber aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_EMPLOYEE', response.data.updateEmployee);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteEmployee({ commit, dispatch }, employeeNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteEmployee($employeeNumber: Int!) {
            deleteEmployee(employeeNumber: $employeeNumber)
          }
        `,
        variables: {
          employeeNumber: employeeNumber,
        },
      });
      dispatch('message', {
        message: 'Auftraggeber gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_EMPLOYEE', employeeNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new employee object in store and return it
   * @param {*} param0
   * @param {*} employee
   */
  async initEmployee({ commit }) {
    commit('ADD_OR_UPDATE_EMPLOYEE', defaultEmployee());
  },

  employeeLocalUpdate({ commit }, employee) {
    commit('ADD_OR_UPDATE_EMPLOYEE', employee);
  },

  employeeCleanup({ commit }) {
    commit('REMOVE_EMPLOYEE', '_new');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_EMPLOYEE_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_EMPLOYEES: (state, employees) =>
    employees ? (state.employees = employees) : (state.employees = []),
  ADD_EMPLOYEE: (state, employee) => state.employees.push(employee),
  ADD_OR_UPDATE_EMPLOYEE: (state, updatedEmployee) => {
    const existingEmployee = state.employees.find(
      (employee) => employee._id === updatedEmployee._id
    );
    if (existingEmployee) {
      Object.assign(existingEmployee, updatedEmployee);
    } else {
      state.employees.push(updatedEmployee);
    }
  },
  REMOVE_EMPLOYEE: (state, employeeId) => {
    const index = state.employees.findIndex((employee) => employee._id === employeeId);
    state.employees.splice(index, 1);
  },
  SET_EMPLOYEE_COUNT: (state, employeeCount) => (state.employeeCount = employeeCount),
};

/**
 * Getters
 */
const getters = {
  getEmployeeCount: (state) => state.employeeCount,
  getEmployees: (state) => state.employees,
  getEmployee: (state) => (employeeId) =>
    state.employees.find((employee) => employee._id == employeeId),
  getEmployeeByNumber: (state) => (employeeNumber) =>
    state.employees.find((employee) => employee.number == employeeNumber),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
