export default {
  // EnFluRi
  key: '1.4',
  label: 'energyFlowDirectionSensor_short_old',
  children: [
    {
      key: '1.4.0',
      name: 'composite.energyFlowDirectionSensorManufacturer_energyFlowDirectionSensorType',
      label: 'composite.energyFlowDirectionSensorManufacturer_energyFlowDirectionSensorType',
    },
    {
      key: '1.4.1',
      name: 'solarPlant.storageSystem.energyFlowDirectionSensor.manufacturer',
      label: 'manufacturer',
    },
    {
      key: '1.4.2',
      name: 'solarPlant.storageSystem.energyFlowDirectionSensor.type',
      label: 'type',
    },
    {
      key: '1.4.3',
      name: 'solarPlant.storageSystem.energyFlowDirectionSensorCount',
      label: 'energyFlowDirectionSensorCount',
    },
  ],
};
