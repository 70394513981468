import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const getDefaultState = () => {
  return {
    meta: {
      count_project_state_new_without_measurement_appointment: 0,
      count_project_state_planning_due_diligence_without_ac_or_dc_appointment: 0,
      count_project_with_dc_appointment_and_without_ac_appointment: 0,
      count_projects_with_dc_or_ac_appointments_in_state_backlog: 0,
      count_projects_with_dc_appointments_in_state_backlog: 0,
      count_projects_with_ac_appointments_in_state_backlog: 0,
      count_project_phases_needs_analysis: 0,
      count_project_phases_waiting_for_planning: 0,
      count_project_phases_waiting_for_images: 0,
      count_project_phases_planning_successfull: 0,
      count_project_phases_sc1_scheduled: 0,
      count_project_phases_follow_up: 0,
      count_project_phases_sc1_successfull: 0,
      count_project_phases_sc2_scheduled: 0,
      count_project_phases_closure: 0,
    },
  };
};

const state = getDefaultState();

/**
 * Actions
 */
const actions = {
  async fetchMeta({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            meta {
              count_project_state_new_without_measurement_appointment
              count_project_state_planning_due_diligence_without_ac_or_dc_appointment
              count_project_with_dc_appointment_and_without_ac_appointment
              count_projects_with_dc_or_ac_appointments_in_state_backlog
              count_projects_with_dc_appointments_in_state_backlog
              count_projects_with_ac_appointments_in_state_backlog
              count_project_phases_needs_analysis
              count_project_phases_waiting_for_planning
              count_project_phases_waiting_for_images
              count_project_phases_planning_successfull
              count_project_phases_sc1_scheduled
              count_project_phases_follow_up
              count_project_phases_sc1_successfull
              count_project_phases_sc2_scheduled
              count_project_phases_closure
            }
          }
        `,
      });
      commit('SET_META', response.data.meta);
    } catch (err) {
      throw new Error(err);
    }
  },

  reset({ commit }) {
    commit('SET_DEFAULT_STATE');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_META: (state, meta) => (state.meta = meta),
};

/**
 * Getters
 */
const getters = {
  getMeta: (state) => state.meta,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
