import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const defaultTrainer = () => {
  return {
    _id: '_new',
    number: '_new',
    firstname: '',
    lastname: '',
    users: [],
    trainerType: '',
  };
};

const getDefaultState = () => {
  return {
    trainers: [],
    trainerCount: 0,
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an trainer
 * used for all queries/mutations which return an trainer
 * @type {DocumentNode}
 */
const TRAINER_REQUEST_FIELDS = gql`
  fragment trainerFields on Trainer {
    _id
    number
    firstname
    lastname
    trainerType
    users {
      id
      number
      firstname
      lastname
      email
    }
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} trainer
 */
const createPayload = (trainer) => {
  /** Only send ID's for users */
  if (trainer.users) {
    const ids = [];
    trainer.users.map((user) => {
      ids.push(user.id);
    });
    trainer.users = ids;
  }

  const payload = {
    ...(trainer.firstname ? { firstname: trainer.firstname } : {}),
    ...(trainer.lastname ? { lastname: trainer.lastname } : {}),
    ...(trainer.trainerType !== null ? { trainerType: trainer.trainerType } : {}),

    users: trainer.users,
  };

  console.log(payload);
  return payload;
};

/**
 * Actions
 */
const actions = {
  /**
   * Fetch a paginated, sorted and filtered trainer list
   * @param {*} param0
   * @param {*} payload
   */
  /**
   * Fetch all users from GraphQL API
   * @param commit
   * @returns {Promise<void>}
   */
  async fetchTrainers({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            trainers {
              ...trainerFields
            }
          }
          ${TRAINER_REQUEST_FIELDS}
        `,
      });
      commit('SET_TRAINERS', response.data.trainers);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },
  async fetchTrainersPagainated(
    { commit, dispatch },
    { page, pageSize, sortField, sortOrder, filters }
  ) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query trainersPaginated(
            $page: Int!
            $pageSize: Int!
            $sortField: String
            $sortOrder: Int
            $filters: TrainerFilterInput
          ) {
            trainersPaginated(
              page: $page
              pageSize: $pageSize
              sortField: $sortField
              sortOrder: $sortOrder
              filters: $filters
            ) {
              trainers {
                ...trainerFields
              }
              trainerCount
            }
          }
          ${TRAINER_REQUEST_FIELDS}
        `,
        variables: {
          page: page,
          pageSize: pageSize,
          sortField: sortField,
          sortOrder: sortOrder,
          filters: filters,
        },
        fetchPolicy: 'network-only',
      });
      commit('SET_TRAINERS', response.data.trainersPaginated.trainers);
      commit('SET_TRAINER_COUNT', response.data.trainersPaginated.trainerCount);
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },

  async fetchTrainerByNumber({ commit, dispatch }, trainerNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query trainerByNumber($trainerNumber: Int!) {
            trainerByNumber(trainerNumber: $trainerNumber) {
              ...trainerFields
            }
          }
          ${TRAINER_REQUEST_FIELDS}
        `,
        variables: {
          trainerNumber: parseInt(trainerNumber),
        },
      });
      commit('ADD_OR_UPDATE_TRAINER', response.data.trainerByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async createTrainer({ commit, dispatch }, trainer) {
    const payload = createPayload(trainer);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createTrainer($payload: TrainerInput!) {
            createTrainer(trainerInput: $payload) {
              ...trainerFields
            }
          }
          ${TRAINER_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Auftraggeber gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_TRAINER', '_new'); // remove temporary _new trainer
      commit('ADD_OR_UPDATE_TRAINER', response.data.createTrainer);
      return response.data.createTrainer.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateTrainer({ commit, dispatch }, trainer) {
    const payload = createPayload(trainer);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateTrainer($trainerNumber: Int!, $payload: TrainerInput!) {
            updateTrainer(trainerNumber: $trainerNumber, trainerInput: $payload) {
              ...trainerFields
            }
          }
          ${TRAINER_REQUEST_FIELDS}
        `,
        variables: {
          trainerNumber: trainer.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Auftraggeber aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_TRAINER', response.data.updateTrainer);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteTrainer({ commit, dispatch }, trainerNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteTrainer($trainerNumber: Int!) {
            deleteTrainer(trainerNumber: $trainerNumber)
          }
        `,
        variables: {
          trainerNumber: trainerNumber,
        },
      });
      dispatch('message', {
        message: 'Auftraggeber gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_TRAINER', trainerNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new trainer object in store and return it
   * @param {*} param0
   * @param {*} trainer
   */
  async initTrainer({ commit }) {
    commit('ADD_OR_UPDATE_TRAINER', defaultTrainer());
  },

  trainerLocalUpdate({ commit }, trainer) {
    commit('ADD_OR_UPDATE_TRAINER', trainer);
  },

  trainerCleanup({ commit }) {
    commit('REMOVE_TRAINER', '_new');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TRAINER_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TRAINERS: (state, trainers) =>
    trainers ? (state.trainers = trainers) : (state.trainers = []),
  ADD_TRAINER: (state, trainer) => state.trainers.push(trainer),
  ADD_OR_UPDATE_TRAINER: (state, updatedTrainer) => {
    const existingTrainer = state.trainers.find((trainer) => trainer._id === updatedTrainer._id);
    if (existingTrainer) {
      Object.assign(existingTrainer, updatedTrainer);
    } else {
      state.trainers.push(updatedTrainer);
    }
  },
  REMOVE_TRAINER: (state, trainerId) => {
    const index = state.trainers.findIndex((trainer) => trainer._id === trainerId);
    state.trainers.splice(index, 1);
  },
  SET_TRAINER_COUNT: (state, trainerCount) => (state.trainerCount = trainerCount),
};

/**
 * Getters
 */
const getters = {
  getTrainerCount: (state) => state.trainerCount,
  getTrainers: (state) => state.trainers,
  getTrainer: (state) => (trainerId) => state.trainers.find((trainer) => trainer._id == trainerId),
  getTrainerByNumber: (state) => (trainerNumber) =>
    state.trainers.find((trainer) => trainer.number == trainerNumber),
  getTrainerTypes: (state) => state.getTrainerTypes,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
