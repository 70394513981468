import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const defaultMeasurementPointOperator = () => {
  return {
    id: '_new',
    number: '_new',
    name: '',
    msbNumber: '',
    street: '',
    streetNumber: '',
    zip: '',
    city: '',
  };
};

const getDefaultState = () => {
  return {
    measurementPointOperators: [],
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an measurementPointOperator
 * used for all queries/mutations which return an measurementPointOperator
 * @type {DocumentNode}
 */
const MEASUREMENT_POINT_OPERATOR_REQUEST_FIELDS = gql`
  fragment measurementPointOperatorFields on MeasurementPointOperator {
    id
    number
    name
    street
    streetNumber
    zip
    city
    msbNumber
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} measurementPointOperator
 */
const createPayload = (measurementPointOperator) => {
  const payload = {
    ...(measurementPointOperator.name ? { name: measurementPointOperator.name } : {}),
    ...(measurementPointOperator.street ? { street: measurementPointOperator.street } : {}),
    ...(measurementPointOperator.streetNumber
      ? { streetNumber: measurementPointOperator.streetNumber }
      : {}),
    ...(measurementPointOperator.msbNumber
      ? { msbNumber: measurementPointOperator.msbNumber }
      : {}),
    ...(measurementPointOperator.zip !== null ? { zip: measurementPointOperator.zip } : {}),
    ...(measurementPointOperator.city !== null ? { city: measurementPointOperator.city } : {}),
  };
  return payload;
};

/**
 * Actions
 */
const actions = {
  async fetchMeasurementPointOperators({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            measurementPointOperators {
              ...measurementPointOperatorFields
            }
          }
          ${MEASUREMENT_POINT_OPERATOR_REQUEST_FIELDS}
        `,
      });
      commit('SET_MEASUREMENT_POINT_OPERATORS', response.data.measurementPointOperators);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchMeasurementPointOperatorByNumber(
    { commit, dispatch },
    measurementPointOperatorNumber
  ) {
    try {
      // console.log(measurementPointOperatorNumber);
      const response = await apolloClient.query({
        query: gql`
          query measurementPointOperatorByNumber($measurementPointOperatorNumber: Int!) {
            measurementPointOperatorByNumber(
              measurementPointOperatorNumber: $measurementPointOperatorNumber
            ) {
              ...measurementPointOperatorFields
            }
          }
          ${MEASUREMENT_POINT_OPERATOR_REQUEST_FIELDS}
        `,
        variables: {
          measurementPointOperatorNumber: parseInt(measurementPointOperatorNumber),
        },
      });
      commit(
        'ADD_OR_UPDATE_MEASUREMENT_POINT_OPERATOR',
        response.data.measurementPointOperatorByNumber
      );
    } catch (err) {
      throw new Error(err);
    }
  },

  async createMeasurementPointOperator({ commit, dispatch }, measurementPointOperator) {
    const payload = createPayload(measurementPointOperator);
    console.log(payload);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createMeasurementPointOperator($payload: MeasurementPointOperatorInput!) {
            createMeasurementPointOperator(measurementPointOperatorInput: $payload) {
              ...measurementPointOperatorFields
            }
          }
          ${MEASUREMENT_POINT_OPERATOR_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Messstellenbetreiber gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_MEASUREMENT_POINT_OPERATOR', '_new'); // remove temporary _new measurementPointOperator
      commit(
        'ADD_OR_UPDATE_MEASUREMENT_POINT_OPERATOR',
        response.data.createMeasurementPointOperator
      );
      return response.data.createMeasurementPointOperator.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateMeasurementPointOperator({ commit, dispatch }, measurementPointOperator) {
    const payload = createPayload(measurementPointOperator);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateMeasurementPointOperator(
            $measurementPointOperatorNumber: Int!
            $payload: MeasurementPointOperatorInput!
          ) {
            updateMeasurementPointOperator(
              measurementPointOperatorNumber: $measurementPointOperatorNumber
              measurementPointOperatorInput: $payload
            ) {
              ...measurementPointOperatorFields
            }
          }
          ${MEASUREMENT_POINT_OPERATOR_REQUEST_FIELDS}
        `,
        variables: {
          measurementPointOperatorNumber: measurementPointOperator.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Messstellenbetreiber aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit(
        'ADD_OR_UPDATE_MEASUREMENT_POINT_OPERATOR',
        response.data.updateMeasurementPointOperator
      );
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteMeasurementPointOperator({ commit, dispatch }, measurementPointOperatorNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteMeasurementPointOperator($measurementPointOperatorNumber: Int!) {
            deleteMeasurementPointOperator(
              measurementPointOperatorNumber: $measurementPointOperatorNumber
            )
          }
        `,
        variables: {
          measurementPointOperatorNumber: measurementPointOperatorNumber,
        },
      });
      dispatch('message', {
        message: 'Messstellenbetreiber gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_MEASUREMENT_POINT_OPERATOR', measurementPointOperatorNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new measurementPointOperator object in store and return it
   * @param {*} param0
   * @param {*} measurementPointOperator
   */
  async initMeasurementPointOperator({ commit }) {
    commit('ADD_OR_UPDATE_MEASUREMENT_POINT_OPERATOR', defaultMeasurementPointOperator());
  },

  measurementPointOperatorLocalUpdate({ commit }, measurementPointOperator) {
    commit('ADD_OR_UPDATE_MEASUREMENT_POINT_OPERATOR', measurementPointOperator);
  },

  measurementPointOperatorCleanup({ commit }) {
    commit('REMOVE_MEASUREMENT_POINT_OPERATOR', '_new');
  },
  addEmptyMeasurementPointOperatorContact({ commit }, measurementPointOperator) {
    commit('ADD_EMPTY_MEASUREMENT_POINT_OPERATOR_CONTACT', measurementPointOperator);
  },
  removeMeasurementPointOperatorContact({ commit }, index) {
    commit('REMOVE_MEASUREMENT_POINT_OPERATOR_CONTACT', index);
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_MEASUREMENT_POINT_OPERATOR_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_MEASUREMENT_POINT_OPERATORS: (state, measurementPointOperators) =>
    measurementPointOperators
      ? (state.measurementPointOperators = measurementPointOperators)
      : (state.measurementPointOperators = []),
  ADD_MEASUREMENT_POINT_OPERATOR: (state, measurementPointOperator) =>
    state.measurementPointOperators.push(measurementPointOperator),
  ADD_OR_UPDATE_MEASUREMENT_POINT_OPERATOR: (state, updatedMeasurementPointOperator) => {
    const existingMeasurementPointOperator = state.measurementPointOperators.find(
      (measurementPointOperator) =>
        measurementPointOperator.id === updatedMeasurementPointOperator.id
    );
    if (existingMeasurementPointOperator) {
      Object.assign(existingMeasurementPointOperator, updatedMeasurementPointOperator);
    } else {
      state.measurementPointOperators.push(updatedMeasurementPointOperator);
    }
  },
  REMOVE_MEASUREMENT_POINT_OPERATOR: (state, measurementPointOperatorId) => {
    const index = state.measurementPointOperators.findIndex(
      (measurementPointOperator) => measurementPointOperator.id === measurementPointOperatorId
    );
    state.measurementPointOperators.splice(index, 1);
  },
  UPDATE_MEASUREMENT_POINT_OPERATOR: (state, updatedMeasurementPointOperator) => {
    const measurementPointOperator = state.measurementPointOperators.find(
      (measurementPointOperator) =>
        measurementPointOperator.id === updatedMeasurementPointOperator.id
    );
    Object.assign(measurementPointOperator, updatedMeasurementPointOperator);
  },
};

/**
 * Getters
 */
const getters = {
  getMeasurementPointOperators: (state) => state.measurementPointOperators,
  getMeasurementPointOperator: (state) => (measurementPointOperatorId) =>
    state.measurementPointOperators.find(
      (measurementPointOperator) => measurementPointOperator.id == measurementPointOperatorId
    ),
  getMeasurementPointOperatorByNumber: (state) => (measurementPointOperatorNumber) =>
    state.measurementPointOperators.find(
      (measurementPointOperator) =>
        measurementPointOperator.number == measurementPointOperatorNumber
    ),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
