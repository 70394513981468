import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const defaultInverter = () => {
  return {
    id: '_new',
    number: '_new',
    manufacturer: '',
    type: '',
    attachments: [],
    gridAndPlantProtectionIntegrated: 1.1,
  };
};

const getDefaultState = () => {
  return {
    inverters: [],
    gridAndPlantProtectionTypes: [],
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an inverter
 * used for all queries/mutations which return an inverter
 * @type {DocumentNode}
 */
const INVERTER_REQUEST_FIELDS = gql`
  fragment inverterFields on Inverter {
    id
    number
    manufacturer
    type
    nominalPower
    nominalVoltage
    apparentPower
    activePower
    ratedCurrent
    shortCircuitCurrent
    nominalDcPower
    allowedChargeDischargeCurrent
    gridAndPlantProtectionIntegrated
    gridAndPlantProtectionType
    attachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} project
 */
const createPayload = (inverter) => {
  /**
   * for text fields differentiate between "" and null: "" are sent to the server as empty value
   * deadlineAt date field: send NULL to server to remove a date
   */
  const payload = {
    ...(inverter.manufacturer ? { manufacturer: inverter.manufacturer } : {}),
    ...(inverter.type ? { type: inverter.type } : {}),
    ...(inverter.nominalPower ? { nominalPower: parseFloat(inverter.nominalPower) } : {}),
    ...(inverter.nominalVoltage ? { nominalVoltage: parseFloat(inverter.nominalVoltage) } : {}),
    ...(inverter.apparentPower ? { apparentPower: parseFloat(inverter.apparentPower) } : {}),
    ...(inverter.activePower ? { activePower: parseFloat(inverter.activePower) } : {}),
    ...(inverter.ratedCurrent ? { ratedCurrent: parseFloat(inverter.ratedCurrent) } : {}),
    ...(inverter.gridAndPlantProtectionIntegrated
      ? { gridAndPlantProtectionIntegrated: parseFloat(inverter.gridAndPlantProtectionIntegrated) }
      : {}),
    ...(inverter.shortCircuitCurrent
      ? { shortCircuitCurrent: parseFloat(inverter.shortCircuitCurrent) }
      : {}),
    ...(inverter.nominalDcPower ? { nominalDcPower: parseFloat(inverter.nominalDcPower) } : {}),
    ...(inverter.allowedChargeDischargeCurrent
      ? { allowedChargeDischargeCurrent: parseFloat(inverter.allowedChargeDischargeCurrent) }
      : {}),
    gridAndPlantProtectionType: inverter.gridAndPlantProtectionType,
  };

  return payload;
};

/**
 * Actions
 */
const actions = {
  async fetchInverters({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            inverters {
              ...inverterFields
            }
          }
          ${INVERTER_REQUEST_FIELDS}
        `,
      });
      commit('SET_INVERTERS', response.data.inverters);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchInverterByNumber({ commit, dispatch }, inverterNumber) {
    try {
      // console.log(inverterNumber);
      const response = await apolloClient.query({
        query: gql`
          query inverterByNumber($inverterNumber: Int!) {
            inverterByNumber(inverterNumber: $inverterNumber) {
              ...inverterFields
            }
          }
          ${INVERTER_REQUEST_FIELDS}
        `,
        variables: {
          inverterNumber: parseInt(inverterNumber),
        },
      });
      commit('ADD_OR_UPDATE_INVERTER', response.data.inverterByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async createInverter({ commit, dispatch }, inverter) {
    const payload = createPayload(inverter);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createInverter($payload: InverterInput!) {
            createInverter(inverterInput: $payload) {
              ...inverterFields
            }
          }
          ${INVERTER_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Wechselrichter gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_INVERTER', '_new'); // remove temporary _new inverter
      commit('ADD_OR_UPDATE_INVERTER', response.data.createInverter);
      return response.data.createInverter.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateInverter({ commit, dispatch }, inverter) {
    const payload = createPayload(inverter);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateInverter($inverterNumber: Int!, $payload: InverterInput!) {
            updateInverter(inverterNumber: $inverterNumber, inverterInput: $payload) {
              ...inverterFields
            }
          }
          ${INVERTER_REQUEST_FIELDS}
        `,
        variables: {
          inverterNumber: inverter.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Wechselrichter aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_INVERTER', response.data.updateInverter);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteInverter({ commit, dispatch }, inverterNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteInverter($inverterNumber: Int!) {
            deleteInverter(inverterNumber: $inverterNumber)
          }
        `,
        variables: {
          inverterNumber: inverterNumber,
        },
      });
      dispatch('message', {
        message: 'Wechselrichter gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_INVERTER', inverterNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Upload an image belonging to the inverter
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async uploadInverterAttachment({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation uploadInverterAttachment($attachmentInput: AttachmentInput!) {
            uploadInverterAttachment(attachmentInput: $attachmentInput) {
              ...inverterFields
            }
          }
          ${INVERTER_REQUEST_FIELDS}
        `,
        variables: {
          attachmentInput: {
            file: payload.file,
            referenceId: payload.parentId,
          },
        },
      });
      commit('UPDATE_INVERTER', response.data.uploadInverterAttachment);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Upload!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Delete Inverter Atrachment
   * @param commit
   * @param payload
   */
  async deleteInverterAttachment({ commit, dispatch }, payload) {
    // console.log(payload)
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteInverterAttachment($inverterId: ID!, $attachmentId: ID!) {
            deleteInverterAttachment(inverterId: $inverterId, attachmentId: $attachmentId) {
              ...inverterFields
            }
          }
          ${INVERTER_REQUEST_FIELDS}
        `,
        variables: {
          inverterId: payload.inverterId,
          attachmentId: payload.attachmentId,
        },
      });
      // console.log(response)
      commit('UPDATE_INVERTER', response.data.deleteInverterAttachment);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async fetchGridAndPlantProtectionTypes({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "GridAndPlantProtectionType") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_INVERTER_GRID_AND_PLANT_PROTECTION_TYPES', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  /**
   * create a new inverter object in store and return it
   * @param {*} param0
   * @param {*} inverter
   */
  async initInverter({ commit }) {
    commit('ADD_OR_UPDATE_INVERTER', defaultInverter());
  },

  inverterLocalUpdate({ commit }, inverter) {
    commit('ADD_OR_UPDATE_INVERTER', inverter);
  },

  inverterCleanup({ commit }) {
    commit('REMOVE_INVERTER', '_new');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_INVERTER_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_INVERTERS: (state, inverters) =>
    inverters ? (state.inverters = inverters) : (state.inverters = []),
  ADD_INVERTER: (state, inverter) => state.inverters.push(inverter),
  ADD_OR_UPDATE_INVERTER: (state, updatedInverter) => {
    const existingInverter = state.inverters.find((inverter) => inverter.id === updatedInverter.id);
    if (existingInverter) {
      Object.assign(existingInverter, updatedInverter);
    } else {
      state.inverters.push(updatedInverter);
    }
  },
  SET_INVERTER_TYPES: (state, inverterTypes) => {
    state.inverterTypes = [];
    inverterTypes.map((inverterType) => {
      state.inverterTypes.push(inverterType.name);
    });
  },
  REMOVE_INVERTER: (state, inverterId) => {
    const index = state.inverters.findIndex((inverter) => inverter.id === inverterId);
    state.inverters.splice(index, 1);
  },
  UPDATE_INVERTER: (state, updatedInverter) => {
    const inverter = state.inverters.find((inverter) => inverter.id === updatedInverter.id);
    Object.assign(inverter, updatedInverter);
  },
  SET_INVERTER_GRID_AND_PLANT_PROTECTION_TYPES: (state, gridAndPlantProtectionTypes) => {
    state.gridAndPlantProtectionTypes = [];
    gridAndPlantProtectionTypes.map((gridAndPlantProtectionType) => {
      state.gridAndPlantProtectionTypes.push(gridAndPlantProtectionType.name);
    });
  },
};

/**
 * Getters
 */
const getters = {
  getInverters: (state) => state.inverters,
  getInverter: (state) => (inverterId) =>
    state.inverters.find((inverter) => inverter.id == inverterId),
  getInverterByNumber: (state) => (inverterNumber) =>
    state.inverters.find((inverter) => inverter.number == inverterNumber),
  getGridAndPlantProtectionTypes: (state) => state.gridAndPlantProtectionTypes,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
