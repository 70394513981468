<template>
  <div class="print-page">
    <router-view layoutType="print" />
  </div>
</template>

<script>
export default {
  name: 'Print',
  methods: {},
};
</script>
