<template>
  <div class="loading-spinner-success">
    <div v-if="isLoading" class="loading-spinner level-item has-text-centered">
      <Spinner :size="size" class=""></Spinner>
    </div>
    <transition name="fade">
      <div
        v-if="isSuccess"
        transition="expand"
        class="loading-success-fader level-item has-text-centered"
      >
        <i class="fa fa-2x fa-check text-success"></i>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Spinner from './Spinner';
export default {
  name: 'LoadingSuccessSpinner',
  components: { Spinner },
  props: {
    size: {
      default: 25,
    },
  },
  computed: {
    ...mapGetters(['isLoading', 'isSuccess']),
  },
};
</script>

<style lang="scss" scoped>
.loading-spinner-success {
  min-width: 80px;
  display: inline-block;
}
.loading-spinner {
  margin-right: 10px;
  display: inline-block;
}
.loading-success-fader {
  display: inline-block;
}
</style>
