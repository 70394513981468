import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

// Auth
import Store from '../store';
import { isAuthenticated } from '../mixins/auth';
import { getAuthToken } from '../helpers/localStorage';
import { hasRole, authorize, authorizeByUser } from '../helpers/userCapabilities';

// Layouts
import Layout from '@/components/Layout/Layout';
import MobileLayout from '@/components/Layout/MobileLayout';
import Print from '@/components/Layout/Print';

// Views
import Login from '../views/Login/CustomLogin.vue';

import { isMobile } from '@/helpers/browser.js';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'home',
    beforeEnter: (to, from, next) => {
      const user = Store.getters['auth/getCurrentUser'];
      // console.log('User role: ' + user.role + ' - Required role:' + to.meta.requiredRole);

      if (isMobile()) {
        next({ name: 'MobileProjectsPage' });
      } else {
        next({ name: 'ProjectsPaginatedPage' });
      }
    },
  },
  {
    path: '*',
    component: () => import('@/views/Error/404'),
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/Error/403'),
  },
  {
    path: '/',
    component: Print,
    meta: {
      requiresAuthentication: true,
    },
    children: [
      {
        path: '/projekte/:projectNumber/drucken',
        name: 'ProjectPrintPage',
        component: () => import('@/views/Projects/ProjectPrint'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Project :projectNumber Print ',
        },
      },
      {
        path: '/projekte/:projectNumber/drucken/:documentNumber',
        name: 'ProjectDocumentPrintPage',
        component: () => import('@/views/Projects/ProjectDocumentPrint'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Project :projectNumber Document Print',
        },
      },
      {
        path: '/angebote/:offerNumber/drucken',
        name: 'OfferPrintPage',
        component: () => import('@/views/Offers/OfferPrint'),
        props: true,
        meta: {
          requiredRole: 'EDITOR',
          title: 'Offer Print ',
        },
      },
      {
        path: '/projekte/:projectNumber/berichte/drucken',
        name: 'ReportPrintPage',
        component: () => import('@/views/Report/ReportPrint'),
        props: true,
        meta: {
          requiredRole: 'EDITOR',
          title: 'Report Print ',
        },
      },
      {
        path: '/projekte/:projectNumber/aufmass/drucken',
        name: 'MeasurementPrintPage',
        component: () => import('@/views/Measurement/MeasurementPrint'),
        props: true,
        meta: {
          requiredRole: 'EDITOR',
          title: 'Measurment Print ',
        },
      },
      {
        path: '/projekte/:projectNumber/service/drucken',
        name: 'ServicePrintPage',
        component: () => import('@/views/Service/ServicePrint'),
        props: true,
        meta: {
          requiredRole: 'EDITOR',
          title: 'Service Print ',
        },
      },
      {
        path: '/projekte/:projectNumber/angebot/drucken',
        name: 'ProjectOfferPrintPage',
        component: () => import('@/views/Projects/ProjectQcellsOfferPrint'),
        props: true,
        meta: {
          requiredRole: 'EDITOR',
          title: 'Project :projectNumber Offer Print ',
        },
      },
      {
        path: '/projekte/:projectNumber/materialliste/drucken',
        name: 'ConsumablesListPrintPage',
        component: () => import('@/views/Projects/ConsumablesListPrint'),
        props: true,
        meta: {
          requiredRole: 'EDITOR',
          title: 'Consumables List ',
        },
      },
    ],
  },
  {
    path: '/mobile',
    component: MobileLayout,
    meta: {
      requiresAuthentication: true,
    },
    children: [
      /**
       * Mobile Project search
       */
      {
        path: 'projekte',
        name: 'MobileProjectsPage',
        component: () => import('@/views/Projects/MobileProjects'),
        meta: {
          authorizedRoles: ['TEAM_DC', 'TEAM_AC', 'ADMIN', 'CLIENT'],
          title: 'Mobile Projects ',
        },
      },
    ],
  },
  {
    path: '/app',
    component: Layout,
    meta: {
      requiresAuthentication: true,
    },
    children: [
      /**
       * Lager
       */
      {
        path: '/material/lager',
        name: 'ItemStoragesPage',
        component: () => import('@/views/Items/ItemStorages'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Lager',
        },
      },
      {
        path: '/material/lager/:itemStorageNumber/bearbeiten',
        name: 'ItemStorageEditPage',
        component: () => import('@/views/Items/ItemStorageEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Lager :itemStorageNumber bearbeiten',
        },
      },
      {
        path: '/material/lager/neu',
        name: 'ItemStorageCreatePage',
        component: () => import('@/views/Items/ItemStorageEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Lager erstellen',
        },
      },
      /**
       * Material Stammdaten
       */
      {
        path: '/material/stammdaten',
        name: 'ItemsPage',
        component: () => import('@/views/Items/Items'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Material',
        },
      },
      {
        path: '/material/stammdaten/:itemNumber/bearbeiten',
        name: 'ItemEditPage',
        component: () => import('@/views/Items/ItemEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Material :itemNumber bearbeiten',
        },
      },
      {
        path: '/material/stammdaten/neu',
        name: 'ItemCreatePage',
        component: () => import('@/views/Items/ItemEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Material erstellen',
        },
      },

      /**
       * Berichte
       */
      {
        path: '/berichte/beispielbilder',
        name: 'ReportTemplateImagesPage',
        component: () => import('@/views/Report/ReportTemplateImages'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Report Template Images',
        },
      },
      {
        path: '/berichte/beispielbilder/:reportTemplateImageNumber/bearbeiten',
        name: 'ReportTemplateImageEditPage',
        component: () => import('@/views/Report/ReportTemplateImageEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Report Template Image Edit',
        },
      },
      /**
       * Projekte
       */
      {
        path: '/projekte',
        name: 'ProjectsPaginatedPage',
        component: () => import('@/views/Projects/ProjectsPaginated'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT', 'GUEST'],
          title: 'Projekte',
        },
      },
      {
        path: '/projekte_alt',
        name: 'ProjectsPage',
        component: () => import('@/views/Projects/Projects'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT'],
          title: 'Projekte',
        },
      },
      {
        path: '/projekte/:projectNumber/bearbeiten',
        name: 'ProjectEditPage',
        component: () => import('@/views/Projects/ProjectEdit'),
        props: (route) => ({
          projectNumber: route.params.projectNumber,
          projectTab: route.query.projectTab,
        }),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT', 'GUEST'],
          title: 'Projekt :projectNumber bearbeiten',
        },
      },
      {
        path: '/projekte/neu',
        name: 'ProjectCreatePage',
        component: () => import('@/views/Projects/ProjectEdit'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT'],
          title: 'Projekt erstellen',
        },
      },
      /**
       * Tourenplanung
       */
      {
        path: '/tourenplanung_alt',
        name: 'RouteSchedulingPage',
        component: () => import('@/views/RouteSchedulings/RouteSchedulings'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Tourenplanung',
        },
      },
      {
        path: '/tourenplanung',
        name: 'RouteSchedulingsPaginatedPage',
        component: () => import('@/views/RouteSchedulings/RouteSchedulingsPaginated'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Tourenplanung',
        },
      },
      /**
       * Speichersysteme
       */
      {
        path: '/speichersysteme',
        name: 'StorageSystemsPage',
        component: () => import('@/views/StorageSystems/StorageSystems'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Speichersysteme',
        },
      },
      {
        path: '/speichersysteme/:storageSystemNumber/bearbeiten',
        name: 'StorageSystemEditPage',
        component: () => import('@/views/StorageSystems/StorageSystemEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Speichersyteme :storageSystemNumber bearbeiten',
        },
      },
      {
        path: '/speichersysteme/neu',
        name: 'StorageSystemCreatePage',
        component: () => import('@/views/StorageSystems/StorageSystemEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Speichersysteme erstellen',
        },
      },
      /**
       * Batterien
       */
      {
        path: '/batterien',
        name: 'BatteriesPage',
        component: () => import('@/views/Batteries/Batteries'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Batterien',
        },
      },
      {
        path: '/batterien/:batteryNumber/bearbeiten',
        name: 'BatteryEditPage',
        component: () => import('@/views/Batteries/BatteryEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Batterien :batteryNumber bearbeiten',
        },
      },
      {
        path: '/batterien/neu',
        name: 'BatteryCreatePage',
        component: () => import('@/views/Batteries/BatteryEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Batterien erstellen',
        },
      },
      /**
       * Wechselrichter
       */
      {
        path: '/wechselrichter',
        name: 'InvertersPage',
        component: () => import('@/views/Inverters/Inverters'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Wechselrichter',
        },
      },
      {
        path: '/wechselrichter/:inverterNumber/bearbeiten',
        name: 'InverterEditPage',
        component: () => import('@/views/Inverters/InverterEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Wechselrichter :inverterNumber bearbeiten',
        },
      },
      {
        path: '/wechselrichter/neu',
        name: 'InverterCreatePage',
        component: () => import('@/views/Inverters/InverterEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Wechselrichter :inverterNumber erstellen',
        },
      },
      /**
       * Wallboxen
       */
      {
        path: '/wallboxen',
        name: 'WallboxesPage',
        component: () => import('@/views/Wallboxes/Wallboxes'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Wallboxen',
        },
      },
      {
        path: '/wallboxen/:wallboxNumber/bearbeiten',
        name: 'WallboxEditPage',
        component: () => import('@/views/Wallboxes/WallboxEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Wallbox :wallboxNumber bearbeiten',
        },
      },
      {
        path: '/wallboxen/neu',
        name: 'WallboxCreatePage',
        component: () => import('@/views/Wallboxes/WallboxEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Wallbox erstellen',
        },
      },
      /**
       * Solar Panels
       */
      {
        path: '/module',
        name: 'SolarPanelsPage',
        component: () => import('@/views/SolarPanels/SolarPanels'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Module',
        },
      },
      {
        path: '/module/:solarPanelNumber/bearbeiten',
        name: 'SolarPanelEditPage',
        component: () => import('@/views/SolarPanels/SolarPanelEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Module :solarPanelNumber bearbeiten',
        },
      },
      {
        path: '/module/neu',
        name: 'SolarPanelCreatePage',
        component: () => import('@/views/SolarPanels/SolarPanelEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Module erstellen',
        },
      },
      /**
       * Energieflussrichtungssensoren
       */
      {
        path: '/energieflussrichtungssensoren',
        name: 'EnergyFlowDirectionSensorsPage',
        component: () => import('@/views/EnergyFlowDirectionSensors/EnergyFlowDirectionSensors'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Energieflussrichtungssensoren',
        },
      },
      {
        path: '/energieflussrichtungssensoren/:energyFlowDirectionSensorNumber/bearbeiten',
        name: 'EnergyFlowDirectionSensorEditPage',
        component: () => import('@/views/EnergyFlowDirectionSensors/EnergyFlowDirectionSensorEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Sensoren :energyFlowDirectionSensorNumber bearbeiten',
        },
      },
      {
        path: '/energieflussrichtungssensoren/neu',
        name: 'EnergyFlowDirectionSensorCreatePage',
        component: () => import('@/views/EnergyFlowDirectionSensors/EnergyFlowDirectionSensorEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Energieflussrichtungssensoren erstellen',
        },
      },
      /**
       * EVU's
       */
      {
        path: '/evu',
        name: 'PowerCompaniesPage',
        component: () => import('@/views/PowerCompanies/PowerCompanies'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT'],
          title: 'EVU',
        },
      },
      {
        path: '/evu/:powerCompanyNumber/bearbeiten',
        name: 'PowerCompanyEditPage',
        component: () => import('@/views/PowerCompanies/PowerCompanyEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'EVU :powerCompanyNumber bearbeiten',
        },
      },
      {
        path: '/evu/neu',
        name: 'PowerCompanyCreatePage',
        component: () => import('@/views/PowerCompanies/PowerCompanyEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'EVU erstellen',
        },
      },
      /**
       * Dokumente
       */
      {
        path: '/dokumente',
        name: 'DocumentsPage',
        component: () => import('@/views/Documents/Documents'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Dokumente',
        },
      },
      {
        path: '/dokumente/:documentNumber/bearbeiten',
        name: 'DocumentEditPage',
        component: () => import('@/views/Documents/DocumentEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Dokumente :documentNumber bearbeiten',
        },
      },
      {
        path: '/dokumente/neu',
        name: 'DocumentCreatePage',
        component: () => import('@/views/Documents/DocumentEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Dokumente erstellen',
        },
      },
      /**
       * Angebote
       */
      {
        path: '/angebote',
        name: 'OffersPage',
        component: () => import('@/views/Offers/Offers'),
        meta: {
          requiredRole: 'ADMIN',
          title: ' Angebote',
        },
      },
      {
        path: '/angebote/:offerNumber/bearbeiten',
        name: 'OfferEditPage',
        component: () => import('@/views/Offers/OfferEdit'),
        props: true,
        meta: {
          equiredRole: 'ADMIN',
          title: 'Angebot :offerNumber bearbeiten',
        },
      },
      {
        path: '/angebote/neu',
        name: 'OfferCreatePage',
        component: () => import('@/views/Offers/OfferEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Angebot erstellen',
        },
      },
      /**
       * Auftraggeber
       */
      {
        path: '/auftraggeber',
        name: 'EmployersPage',
        component: () => import('@/views/Employers/Employers'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT'],
          title: 'Auftraggeber',
        },
      },
      {
        path: '/auftraggeber/:employerNumber/bearbeiten',
        name: 'EmployerEditPage',
        component: () => import('@/views/Employers/EmployerEdit'),
        props: true,
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT'],
          title: 'Auftraggeber :employerNumber bearbeiten',
        },
      },
      {
        path: '/auftraggeber/neu',
        name: 'EmployerCreatePage',
        component: () => import('@/views/Employers/EmployerEdit'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT'],
          title: 'Auftraggeber erstellen',
        },
      },
      /**
       * Mandant
       */
      {
        path: '/mandanten',
        name: 'ClientsPage',
        component: () => import('@/views/Clients/Clients'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Mandanten',
        },
      },
      {
        path: '/mandanten/:clientNumber/bearbeiten',
        name: 'ClientEditPage',
        component: () => import('@/views/Clients/ClientEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Mandaten :clientNumber bearbeiten',
        },
      },
      {
        path: '/mandanten/neu',
        name: 'ClientCreatePage',
        component: () => import('@/views/Clients/ClientEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Mandaten erstellen',
        },
      },
      /**
       * Mitarbeiter
       */
      {
        path: '/mitarbeiter',
        name: 'EmployeesPage',
        component: () => import('@/views/Employees/Employees'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Mitarbeiter',
        },
      },
      {
        path: '/mitarbeiter/:employeeNumber/bearbeiten',
        name: 'EmployeeEditPage',
        component: () => import('@/views/Employees/EmployeeEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN,',
          title: 'Mitarbeiter :employeeNumber bearbeiten',
        },
      },
      {
        path: '/mitarbeiter/neu',
        name: 'EmployeeCreatePage',
        component: () => import('@/views/Employees/EmployeeEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Mitarbeiter erstellen',
        },
      },
      /**
       * Elektrofachkraft
       */
      {
        path: '/elektrofachkraft',
        name: 'ElectriciansPage',
        component: () => import('@/views/Electricians/Electricians'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Elektrofachkraft',
        },
      },
      {
        path: '/elektrofachkraft/:electricianNumber/bearbeiten',
        name: 'ElectricianEditPage',
        component: () => import('@/views/Electricians/ElectricianEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Elektrofachkraft :electricianNumber bearbeiten',
        },
      },
      {
        path: '/elektrofachkraft/neu',
        name: 'ElectricianCreatePage',
        component: () => import('@/views/Electricians/ElectricianEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Elektrofachkraft erstellen',
        },
      },
      /**
       * Messstellenbetreiber
       */
      {
        path: '/messstellenbetreiber',
        name: 'MeasurementPointOperatorsPage',
        component: () => import('@/views/MeasurementPointOperators/MeasurementPointOperators'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Messstellenbetreiber',
        },
      },
      {
        path: '/messstellenbetreiber/:measurementPointOperatorNumber/bearbeiten',
        name: 'MeasurementPointOperatorEditPage',
        component: () => import('@/views/MeasurementPointOperators/MeasurementPointOperatorEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Messstellenbetreiber :measurementPointOperatorNumber bearbeiten',
        },
      },
      {
        path: '/messstellenbetreiber/neu',
        name: 'MeasurementPointOperatorCreatePage',
        component: () => import('@/views/MeasurementPointOperators/MeasurementPointOperatorEdit'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Messtellenbetreiber erstellen',
        },
      },
      /**
       * Users
       */
      {
        path: '/benutzer',
        name: 'UsersPage',
        component: () => import('@/views/Users/Users'),
        meta: {
          requiredRole: 'ADMIN',
          breadcrumbs: [{ name: 'Home', link: 'home' }, { name: 'Benutzer' }],
          title: 'Benutzer',
        },
      },
      {
        path: '/benutzer/erstellen',
        name: 'UserCreatePage',
        component: () => import('@/views/Users/UserEdit'),
        meta: {
          breadcrumbs: [
            { name: 'Home', link: 'home' },
            { name: 'Benutzer', link: 'UsersPage' },
            { name: 'Benutzer erstellen' },
          ],
          title: 'Benutzer erstellen',
        },
      },
      {
        path: '/benutzer/:userNumber/bearbeiten',
        name: 'UserEditPage',
        component: () => import('@/views/Users/UserEdit'),
        props: true,
        meta: {
          requiredRole: 'ADMIN',
          title: 'Benutzer bearbeiten',
        },
      },
      /**
       * Changelog
       */
      {
        path: '/updates',
        name: 'ChangelogPage',
        component: () => import(/* webpackChunkName: "about" */ '../views/Changelog.vue'),
      },
      /**
       * Terminplanung
       */
      {
        path: '/planungskalender',
        name: 'AppointmentsPage',
        component: () => import('@/views/Appointments/Appointments'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT'],
          title: 'Planungskalender',
        },
      },
      {
        path: '/kalender',
        name: 'AppointmentsCalendarPage',
        component: () => import('@/views/Appointments/AppointmentsCalendar'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Kalender',
        },
      },
      {
        path: '/terminkapazitaet',
        name: 'AppointmentCapacitiesPage',
        component: () => import('@/views/Appointments/AppointmentCapacities'),
        meta: {
          requiredRole: 'ADMIN',
          title: 'Terminkapazitaet',
        },
      },
      /**
       * Aufgaben
       */
      {
        path: '/aufgaben',
        name: 'TasksPage',
        component: () => import('@/views/Tasks/Tasks'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT'],
          title: 'Aufgaben',
        },
      },
      /**
       * Bewerber
       */
      {
        path: '/bewerber',
        name: 'ApplicantsPage',
        component: () => import('@/views/Applicants/Applicants'),
        meta: {
          authorizedUsers: [
            'c.minte@q-p-s.com',
            'sebastian@mertenonline.de',
            'a.gladstone@eway-energy.com',
            'e.sciacca@ssp-elektro.com',
            'e.sciacca@q-p-s.com',
            'p.sciacca@q-p-s.com',
            'p.sciacca@ssp-elektro.com',
            'd.hackmann@eway-energy.com',
            'd.hopp@eway-energy.com',
            'w.gunsch@q-p-s.com',
            't.nguyen@q-p-s.com',
            'a.traunfellner@best-in-biz.com',
            's.tam@ssp-elektro.com',
          ],
          title: 'Bewerber',
        },
      },
      /**
       * Dashboard Termine
       */
      {
        path: '/dashboard',
        name: 'DashboardPage',
        component: () => import('@/views/Dashboards/Dashboard'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT'],
          title: 'Dashboard',
        },
      },
      /**
       * DashboardProjekte
       */
      {
        path: '/dashboards/projekte',
        name: 'DashboardProjectsPage',
        component: () => import('@/views/Dashboards/DashboardProjects'),
        meta: {
          requiredRole: 'ADMIN',
          authorizedRoles: ['ADMIN', 'CLIENT'],
          title: 'Dashboard Projekte',
        },
      },
      /**
       * Dashboard Dev
       */
      {
        path: '/dashboards/dev',
        name: 'DashboardDevPage',
        component: () => import('@/views/Dashboards/DashboardDev'),
        meta: {
          authorizedRoles: ['ADMIN'],
          title: 'Dashboard Projekte',
        },
      },
      /**
       * AdminTimeTracking Dev
       */
      {
        path: '/zeiterfassung',
        name: 'AdminTimeTrackingPage',
        component: () => import('@/views/TimeTracking/AdminTimeTracking'),
        meta: {
          authorizedRoles: ['ADMIN'],
          title: 'Zeiterfassung',
        },
      },
      /**
       * AdminSales Dev
       */
      {
        path: '/sales/overview',
        name: 'AdminSalesPage',
        component: () => import('@/views/Sales/AdminSales'),
        meta: {
          authorizedRoles: ['ADMIN'],
          title: 'Sales',
        },
      },
      /**
       * Academy Dev
       */
      {
        path: '/academy',
        name: 'AcademyPage',
        component: () => import('@/views/Academy/AcademyPage'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT', 'STUDENT'],
          title: 'Academy',
        },
      },
      {
        path: '/academy/stundenplan',
        name: 'AcademyCalendar',
        component: () => import('@/views/Academy/AcademyCalendar'),
        meta: {
          authorizedUsers: [
            'sebastian@mertenonline.de',
            'e.sciacca@ssp-elektro.com',
            'e.sciacca@q-p-s.com',
            'd.hopp@eway-energy.com',
          ],
          title: 'Stundenplan',
        },
      },
      {
        path: '/academy/lehrplan',
        name: 'Timetable',
        component: () => import('@/views/Academy/Timetable'),
        meta: {
          authorizedUsers: [
            'sebastian@mertenonline.de',
            'e.sciacca@ssp-elektro.com',
            'e.sciacca@q-p-s.com',
            'd.hopp@eway-energy.com',
          ],
          title: 'Stundenplantermine',
        },
      },

      {
        path: '/academy/lehrmaterial',
        name: 'Contents',
        component: () => import('@/views/Academy/Contents'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT', 'STUDENT'],
          title: 'Lehrinhalte',
        },
      },

      {
        path: '/academy/klassen',
        name: 'Sessions',
        component: () => import('@/views/Academy/Sessions'),
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT', 'STUDENT'],
          title: 'Klassen',
        },
      },
      {
        path: '/academy/schueler',
        name: 'Students',
        component: () => import('@/views/Academy/Students'),
        meta: {
          authorizedUsers: ['sebastian@mertenonline.de', 'd.hopp@eway-energy.com'],
          title: 'Schüler',
        },
      },

      {
        path: '/academy/schueler/neu',
        name: 'StudentCreatePage',
        component: () => import('@/views/Academy/StudentEdit'),
        props: true,
        meta: {
          authorizedUsers: ['sebastian@mertenonline.de', 'd.hopp@eway-energy.com'],
          title: 'Schüler erstellen',
        },
      },

      {
        path: '/academy/schueler/:studentNumber/bearbeiten',
        name: 'StudentEditPage',
        component: () => import('@/views/Academy/StudentEdit'),
        props: true,
        meta: {
          authorizedUsers: ['sebastian@mertenonline.de', 'd.hopp@eway-energy.com'],
          title: 'Schüler :studentNumber bearbeiten',
        },
      },
      {
        path: '/academy/trainer',
        name: 'Trainers',
        component: () => import('@/views/Academy/Trainers'),
        meta: {
          authorizedUsers: ['sebastian@mertenonline.de', 'd.hopp@eway-energy.com'],
          title: 'Trainer',
        },
      },

      {
        path: '/academy/trainer/neu',
        name: 'TrainerCreatePage',
        component: () => import('@/views/Academy/TrainerEdit'),
        props: true,
        meta: {
          authorizedUsers: ['sebastian@mertenonline.de', 'd.hopp@eway-energy.com'],
          title: 'Trainer erstellen',
        },
      },

      {
        path: '/academy/trainer/:trainerNumber/bearbeiten',
        name: 'TrainerEditPage',
        component: () => import('@/views/Academy/TrainerEdit'),
        props: true,
        meta: {
          authorizedUsers: ['sebastian@mertenonline.de', 'd.hopp@eway-energy.com'],
          title: 'Trainer :trainerNumber bearbeiten',
        },
      },
      {
        path: '/academy/kurse',
        name: 'Courses',
        component: () => import('@/views/Academy/Courses'),
        meta: {
          authorizedUsers: ['sebastian@mertenonline.de', 'd.hopp@eway-energy.com'],
          title: 'Kurse',
        },
      },

      {
        path: '/academy/kurs/neu',
        name: 'CourseCreatePage',
        component: () => import('@/views/Academy/CourseEdit'),
        props: true,
        meta: {
          authorizedUsers: ['sebastian@mertenonline.de', 'd.hopp@eway-energy.com'],
          title: 'Kurs erstellen',
        },
      },

      {
        path: '/academy/kurs/:courseNumber/bearbeiten',
        name: 'CourseEditPage',
        component: () => import('@/views/Academy/CourseEdit'),
        props: true,
        meta: {
          authorizedUsers: ['sebastian@mertenonline.de', 'd.hopp@eway-energy.com'],
          title: 'Kurs :courseNumber bearbeiten',
        },
      },

      {
        path: '/academy/klassen/neu',
        name: 'SessionCreatePage',
        component: () => import('@/views/Academy/SessionEdit'),

        meta: {
          authorizedUsers: [
            'sebastian@mertenonline.de',
            'e.sciacca@ssp-elektro.com',
            'e.sciacca@q-p-s.com',
            'd.hopp@eway-energy.com',
          ],
          title: 'Klasse erstellen',
        },
      },

      {
        path: '/academy/klassen/:sessionNumber/bearbeiten',
        name: 'SessionEditPage',
        component: () => import('@/views/Academy/SessionEdit'),
        props: true,
        meta: {
          authorizedUsers: [
            'sebastian@mertenonline.de',
            'e.sciacca@ssp-elektro.com',
            'e.sciacca@q-p-s.com',
            'd.hopp@eway-energy.com',
          ],
          title: 'Klasse :sessionNumber bearbeiten',
        },
      },

      {
        path: '/academy/lehrmaterial/neu',
        name: 'ContentCreatePage',
        component: () => import('@/views/Academy/ContentEdit'),
        meta: {
          authorizedUsers: [
            'sebastian@mertenonline.de',
            'e.sciacca@ssp-elektro.com',
            'e.sciacca@q-p-s.com',
            'd.hopp@eway-energy.com',
          ],
          title: 'Lehrmaterial erstellen',
        },
      },

      {
        path: '/academy/lehrmaterial/:contentNumber/bearbeiten',
        name: 'ContentEditPage',
        component: () => import('@/views/Academy/ContentEdit'),
        props: true,
        meta: {
          authorizedUsers: [
            'sebastian@mertenonline.de',
            'e.sciacca@ssp-elektro.com',
            'e.sciacca@q-p-s.com',
            'd.hopp@eway-energy.com',
          ],
          title: 'Lehrmaterial :contentNumber bearbeiten',
        },
      },

      /**
       * sales
       */
      {
        path: '/sales/cockpit',
        name: 'SalesCockpitPage',
        component: () => import('@/views/Sales/SalesCockpit'),
        meta: {
          authorizedRoles: ['ADMIN'],
          title: 'Sales Cockpit',
        },
      },
      {
        path: '/sales/:projectNumber/bearbeiten',
        name: 'SalesEditPage',
        component: () => import('@/views/Sales/SalesEdit'),
        props: true,
        meta: {
          authorizedRoles: ['ADMIN', 'CLIENT'],
          title: 'Kunde :projectNumber bearbeiten',
        },
      },
      /**
       * Aktivitätsprotokoll
       */
      {
        path: '/aktivitaetsprotokoll',
        name: 'AuditsPage',
        component: () => import('@/views/Audit/Audits'),
        meta: {
          authorizedRoles: ['ADMIN'],
          title: 'Aktivitätsprotokoll',
        },
      },
    ],
  },
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  Store.dispatch('fadeFlashMessage');
  // Updating the document title
  const pageTitle = to.meta.title;
  if (pageTitle) {
    // Replace :projectNumber with the actual value from the route parameters
    const title = pageTitle.replace(':projectNumber', to.params.projectNumber);
    document.title = title;
  }
  Store.dispatch('auth/loginFromStorage').then(
    (response) => {
      const user = Store.getters['auth/getCurrentUser'];
      // console.log('User role: ' + user.role + ' - Required role:' + to.meta.requiredRole)
      if (!user) {
        next('/login');
      } else if (
        to.matched.some((record) => record.meta.requiresAuthentication) &&
        !isAuthenticated(getAuthToken())
      ) {
        next('/login');
      } else if (
        // route has one assigned role
        to.matched.some((record) => record.meta.requiredRole) &&
        !hasRole(user, to.meta.requiredRole)
      ) {
        next({ name: '403' });
      } else if (
        // route has multiple assigned roles
        to.matched.some((record) => record.meta.authorizedRoles) &&
        !authorize(user, to.meta.authorizedRoles, to.params.slug)
      ) {
        next({ name: '403' });
      } else if (
        // route has an array of assigned users
        to.matched.some((record) => record.meta.authorizedUsers) &&
        !authorizeByUser(user, to.meta.authorizedUsers)
      ) {
        next({ name: '403' });
      } else {
        // always update user information to get new notifications
        Store.dispatch('auth/refreshUser');

        next();
      }
    },
    (error) => {
      // console.log(error)
      next({ name: '403' });
    }
  );
});

export default router;
