export default {
  // Speichersystem
  key: '1.0',
  label: 'storageSystem_old',
  children: [
    {
      key: '1.0.0',
      name: 'composite.storageSystemManufacturer_storageSystemType',
      label: 'composite.storageSystemManufacturer_storageSystemType',
    },
    { key: '1.0.1', name: 'solarPlant.storageSystem.manufacturer', label: 'manufacturer' },
    { key: '1.0.2', name: 'solarPlant.storageSystem.type', label: 'type' },
    {
      key: '1.0.3',
      name: 'solarPlant.storageSystem.overallCapacity',
      label: 'solarPlantOverallCapacity',
    },
    {
      key: '1.0.4',
      name: 'solarPlant.storageSystem.inverterOverallPower',
      label: 'inverterOverallPowerNominal',
    },
    {
      key: '1.0.5',
      name: 'solarPlant.storageSystem.inverterOverallPower70',
      label: 'inverterOverallPower70',
    },
  ],
};
