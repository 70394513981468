import gql from 'graphql-tag';
import apolloClient from '../apollo';
import Vue from 'vue';

/**
 * State
 */
const getDefaultState = () => {
  return {
    enums: {},
    types: [],
  };
};
const state = getDefaultState();

const GET_SCHEMA_INFO = gql`
  query GetSchemaInfo {
    __schema {
      types {
        name
        kind
        fields {
          name
          type {
            name
            kind
          }
        }
      }
    }
  }
`;

const GET_TYPE_INFO = gql`
  query GetTypeInfo($typeName: String!) {
    __type(name: $typeName) {
      name
      fields {
        name
        type {
          name
          kind
          fields {
            name
            type {
              name
              kind
            }
          }
        }
      }
    }
  }
`;

/**
 * Actions
 */
const actions = {
  async fetchTypes({ commit }, typeName) {
    try {
      const response = await apolloClient.query({
        query: GET_TYPE_INFO,
        variables: { typeName },
      });
      // console.log(response.data.__type);
      commit('SET_TYPES', {
        // types: response.data.__schema.types,
        typeName: typeName,
        types: response.data.__type.fields,
      });
    } catch (err) {
      console.log(err);
    }
  },

  async fetchEnumValues({ commit }, enumType) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query __type($enumType: String!) {
            __type(name: $enumType) {
              name
              enumValues {
                name
              }
            }
          }
        `,
        variables: {
          enumType: enumType,
        },
      });
      const values = response.data.__type ? response.data.__type.enumValues : [];
      commit('SET_ENUM_VALUES', {
        enumType: enumType,
        enumValues: values,
      });
    } catch (err) {
      console.log(err);
    }
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_ENUM_VALUES: (state, { enumType, enumValues }) => {
    const values = [];
    enumValues.map((value) => {
      values.push(value.name);
    });
    Vue.set(state.enums, enumType, values);
  },
  SET_TYPES: (state, { typeName, types }) => {
    state.types[typeName] = types;
  },
};

/**
 * Getters
 */
const getters = {
  getEnumValues: (state) => (enumType) => {
    return state.enums[enumType] ? state.enums[enumType] : [];
  },
  getTypes: (state) => (typeName) => state.types[typeName],
};

export default {
  state,
  getters,
  actions,
  mutations,
};
