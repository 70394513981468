import gql from 'graphql-tag';
import apolloClient from '../apollo';
import dayjs from 'dayjs';

import relativeTime from 'dayjs/plugin/relativeTime';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekday from 'dayjs/plugin/weekday';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import 'dayjs/locale/de';

import { getAppointmentTypeHexColor } from '@/helpers/colors';

dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(isoWeek);
dayjs.extend(weekday);
dayjs.extend(dayOfYear);
dayjs.locale('de');

/**
 * State
 */
const defaultAppointmentCapacity = () => {
  return {
    _id: '_new',
    appointmentType: 'AC',
    date: dayjs()
      .startOf('day')
      .toDate(),
    zipArea: 1,
    employees: [],
    itemStorages: [],
  };
};

const getDefaultState = () => {
  return {
    appointmentCapacities: [],
    appointmentCapacityCount: 0,
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an appointmentCapacity
 * used for all queries/mutations which return an appointmentCapacity
 * @type {DocumentNode}
 */
const APPOINTMENT_CAPACITY_REQUEST_FIELDS = gql`
  fragment appointmentCapacityFields on AppointmentCapacity {
    _id
    date
    appointmentType
    zipArea
    employees {
      _id
      number
      firstname
      lastname
    }
    itemStorages {
      _id
      number
      name
      itemStorageType
    }
    appointment {
      id
      number
      installationStartAt
      installationEndAt
      appointmentType
      appointmentState
      appointmentApprovedState
      description
      employees {
        _id
        number
        firstname
        lastname
      }
      itemStorages {
        _id
        number
        name
        itemStorageType
      }
      project {
        id
        number
        technicalReviewDescription
        projectState
        customer {
          salutation
          company
          firstname
          lastname
          street
          streetNumber
          streetNumberSuffix
          zip
          city
          cityPart
          phone
          phone2
          phone3
          mobile
          mobile2
          email
          email2
          birthdayAt
          taxNumber
          bank
          iban
          bic
          accountHolder
          field
          fieldSector
          boundary
          electricMeterNumber
          electricMeterNumber2
          electricMeterNumber3
          electricMeterNumberDescription
          electricMeterNumber2Description
          electricMeterNumber3Description
          longitude
          latitude
          createdAt
          updatedAt
        }
        employer {
          id
          number
          name
        }
        technicalReview {
          dcTimeExpenditure
          acTimeExpenditure
          dcNotes
          acNotes
        }
      }
    }
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} appointmentCapacity
 */
const createPayload = (appointmentCapacity) => {
  /** Only send ID's for employees */
  const employeeIds = [];
  if (appointmentCapacity.employees) {
    appointmentCapacity.employees.map((employee) => {
      employeeIds.push(employee._id);
    });
  }

  /** Only send ID's for itemStorages */
  const itemStorageIds = [];
  if (appointmentCapacity.itemStorages) {
    appointmentCapacity.itemStorages.map((employee) => {
      itemStorageIds.push(employee._id);
    });
  }

  const payload = {
    ...(appointmentCapacity.date ? { date: appointmentCapacity.date } : {}),
    ...(appointmentCapacity.appointmentType
      ? { appointmentType: appointmentCapacity.appointmentType }
      : {}),
    zipArea: parseInt(appointmentCapacity.zipArea),
    appointment: appointmentCapacity.appointment ? appointmentCapacity.appointment.id : null,
    employees: employeeIds,
    itemStorages: itemStorageIds,
  };
  return payload;
};

/**
 * Actions
 */
const actions = {
  async fetchAppointmentCapacityPages(
    { commit, dispatch },
    { page, pageSize, sortField, sortOrder, filters }
  ) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query appointmentCapacityPages(
            $page: Int!
            $pageSize: Int!
            $sortField: String
            $sortOrder: Int
            $filters: AppointmentCapacityFilterInput
          ) {
            appointmentCapacityPages(
              page: $page
              pageSize: $pageSize
              sortField: $sortField
              sortOrder: $sortOrder
              filters: $filters
            ) {
              appointmentCapacities {
                ...appointmentCapacityFields
              }
              appointmentCapacityCount
            }
          }
          ${APPOINTMENT_CAPACITY_REQUEST_FIELDS}
        `,
        variables: {
          page: page,
          pageSize: pageSize,
          sortField: sortField,
          sortOrder: sortOrder,
          filters: filters,
        },
        fetchPolicy: 'network-only',
      });
      commit(
        'SET_APPOINTMENT_CAPACITIES',
        response.data.appointmentCapacityPages.appointmentCapacities
      );
      commit(
        'SET_APPOINTMENT_CAPACITY_COUNT',
        response.data.appointmentCapacityPages.appointmentCapacityCount
      );
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchAppointmentCapacities({ commit, dispatch }, { filters }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query appointmentCapacities($filters: AppointmentCapacityFilterInput) {
            appointmentCapacities(filters: $filters) {
              ...appointmentCapacityFields
            }
          }
          ${APPOINTMENT_CAPACITY_REQUEST_FIELDS}
        `,
        variables: {
          filters: filters,
        },
      });
      commit('SET_APPOINTMENT_CAPACITIES', response.data.appointmentCapacities);
    } catch (err) {
      throw new Error(err);
    }
  },

  async createAppointmentCapacity({ commit, dispatch }, appointmentCapacity) {
    const payload = createPayload(appointmentCapacity);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createAppointmentCapacity($payload: AppointmentCapacityInput!) {
            createAppointmentCapacity(appointmentCapacityInput: $payload) {
              ...appointmentCapacityFields
            }
          }
          ${APPOINTMENT_CAPACITY_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Termin gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      // commit('REMOVE_APPOINTMENT_CAPACITY', '_new'); // remove temporary _new appointmentCapacity
      // console.log(response.data.createAppointmentCapacity);
      commit('ADD_OR_UPDATE_APPOINTMENT_CAPACITY', response.data.createAppointmentCapacity);

      // commit('ADD_APPOINTMENT_CAPACITY', response.data.createAppointmentCapacity);
      return response.data.createAppointmentCapacity.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateAppointmentCapacity({ commit, dispatch }, appointmentCapacity) {
    const payload = createPayload(appointmentCapacity);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateAppointmentCapacity(
            $appointmentCapacityId: ID!
            $payload: AppointmentCapacityInput!
          ) {
            updateAppointmentCapacity(
              appointmentCapacityId: $appointmentCapacityId
              appointmentCapacityInput: $payload
            ) {
              ...appointmentCapacityFields
            }
          }
          ${APPOINTMENT_CAPACITY_REQUEST_FIELDS}
        `,
        variables: {
          appointmentCapacityId: appointmentCapacity._id,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Termin aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      // console.log(response.data.updateAppointmentCapacity);
      commit('ADD_OR_UPDATE_APPOINTMENT_CAPACITY', response.data.updateAppointmentCapacity);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteAppointmentCapacity({ commit, dispatch }, appointmentCapacityId) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteAppointmentCapacity($appointmentCapacityId: ID!) {
            deleteAppointmentCapacity(appointmentCapacityId: $appointmentCapacityId)
          }
        `,
        variables: {
          appointmentCapacityId: appointmentCapacityId,
        },
      });
      dispatch('message', {
        message: 'Termin gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      // console.log(response.data.deleteAppointmentCapacity);

      commit('REMOVE_APPOINTMENT_CAPACITY', appointmentCapacityId);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new appointmentCapacity object in store and return it
   * @param {*} param0
   * @param {*} appointmentCapacity
   */
  async initAppointmentCapacity({ commit }) {
    commit('ADD_OR_UPDATE_APPOINTMENT_CAPACITY', defaultAppointmentCapacity());
  },

  async addAppointmentCapacity({ commit }, appointmentCapacity) {
    commit('ADD_APPOINTMENT_CAPACITY', appointmentCapacity);
  },

  /**
   * create a new appointmentCapacity with existing data
   * @param {*} param0
   * @param {*} appointmentCapacity
   */
  async duplicateAppointmentCapacity({ commit }, { appointmentCapacity, installationStartAt }) {
    const duplicate = Object.assign(defaultAppointmentCapacity(), {
      appointmentCapacityType: appointmentCapacity.appointmentCapacityType,
      project: appointmentCapacity.project,
      employees: appointmentCapacity.employees,
      description: appointmentCapacity.description,
      installationStartAt: installationStartAt,
      installationEndAt: dayjs(installationStartAt)
        .add(8, 'hour')
        .toDate(),
    });

    // console.log(duplicate);
    commit('ADD_OR_UPDATE_APPOINTMENT_CAPACITY', duplicate);
    return duplicate;
  },

  appointmentCapacityLocalUpdate({ commit }, appointmentCapacity) {
    commit('ADD_OR_UPDATE_APPOINTMENT_CAPACITY', appointmentCapacity);
  },

  appointmentCapacityCleanup({ commit }) {
    commit('REMOVE_APPOINTMENT_CAPACITY', '_new');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_APPOINTMENT_CAPACITY_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_APPOINTMENT_CAPACITIES: (state, appointmentCapacities) =>
    appointmentCapacities
      ? (state.appointmentCapacities = appointmentCapacities)
      : (state.appointmentCapacities = []),
  ADD_APPOINTMENT_CAPACITY: (state, appointmentCapacity) =>
    state.appointmentCapacities.push(appointmentCapacity),
  ADD_OR_UPDATE_APPOINTMENT_CAPACITY: (state, updatedAppointmentCapacity) => {
    const existingAppointmentCapacity = state.appointmentCapacities.find(
      (appointmentCapacity) => appointmentCapacity._id === updatedAppointmentCapacity._id
    );
    if (existingAppointmentCapacity) {
      Object.assign(existingAppointmentCapacity, updatedAppointmentCapacity);
    } else {
      state.appointmentCapacities.unshift(updatedAppointmentCapacity);
    }
  },
  REMOVE_APPOINTMENT_CAPACITY: (state, appointmentCapacityId) => {
    // console.log(appointmentCapacityId);
    const index = state.appointmentCapacities.findIndex(
      (appointmentCapacity) => appointmentCapacity._id === appointmentCapacityId
    );
    state.appointmentCapacities.splice(index, 1);
  },
  SET_APPOINTMENT_CAPACITY_COUNT: (state, appointmentCapacityCount) =>
    (state.appointmentCapacityCount = appointmentCapacityCount),
};

/**
 * Getters
 */
const getters = {
  getAppointmentCapacities: (state) => {
    /**
     * appointmentCapacities are grouped by weekday in the table view, map each date to the weekday
     */
    let i = 0;
    state.appointmentCapacities.map((appointmentCapacity) => {
      appointmentCapacity.weekday = dayjs(appointmentCapacity.date).format('dddd DD.MM.YYYY');
      appointmentCapacity.date = dayjs(appointmentCapacity.date).toDate();
      appointmentCapacity.start = appointmentCapacity.date;
      appointmentCapacity.end = appointmentCapacity.date;
      appointmentCapacity.allDay = true;
      appointmentCapacity.color = '#ddd';
      appointmentCapacity.textColor = 'black';
      appointmentCapacity.id = appointmentCapacity._id;
      appointmentCapacity.prio = i;
      i++;
    });
    return state.appointmentCapacities;
  },
  getAppointmentCapacity: (state) => (appointmentCapacityId) =>
    state.appointmentCapacities.find(
      (appointmentCapacity) => appointmentCapacity._id == appointmentCapacityId
    ),
  getAppointmentCapacityByNumber: (state) => (appointmentCapacityId) =>
    state.appointmentCapacities.find(
      (appointmentCapacity) => appointmentCapacity._id == appointmentCapacityId
    ),
  getAppointmentCapacityCount: (state) => state.appointmentCapacityCount,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
