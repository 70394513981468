import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const getDefaultState = () => {
  return {
    bricks: [],
  };
};
const state = getDefaultState();

/**
 * Actions
 */
const actions = {
  /**
   * Fetch bricks from GrapQL
   * @param {*} param0
   * @param {*} payload
   */
  async fetchBricks({ commit, dispatch }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            bricks {
              id
              number
              manufacturer
              type
            }
          }
        `,
      });
      commit('SET_BRICKS', response.data.bricks);
    } catch (err) {
      throw new Error(err);
    }
  },

  /**
   * Reset state in module
   * @param commit
   */
  reset({ commit }) {
    commit('SET_DEFAULT_STATE');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_BRICKS: (state, bricks) => (state.bricks = bricks),
};

/**
 * Getters
 */
const getters = {
  getBricks: (state) => state.bricks,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
