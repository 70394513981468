import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const defaultMessageBoilerplate = () => {
  return {
    id: '_new',
    number: '_new',
    subject: '',
    body: '',
    type: '',
    attachmentType: 'UPLOADED',
  };
};

const getDefaultState = () => {
  return {
    messageBoilerplates: [],
    messageBoilerplateTypes: [],
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an messageBoilerplate
 * used for all queries/mutations which return an messageBoilerplate
 * @type {DocumentNode}
 */
const MESSAGE_BOILERPLATE_REQUEST_FIELDS = gql`
  fragment messageBoilerplateFields on MessageBoilerplate {
    id
    number
    type
    attachmentType
    subject
    body
  }
`;

/**
 * Actions
 */
const actions = {
  async fetchMessageBoilerplates({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            messageBoilerplates {
              ...messageBoilerplateFields
            }
          }
          ${MESSAGE_BOILERPLATE_REQUEST_FIELDS}
        `,
      });
      commit('SET_MESSAGE_BOILERPLATES', response.data.messageBoilerplates);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchMessageBoilerplateTypes({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "MessageBoilerplateType") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_MESSAGE_BOILERPLATE_TYPES', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_MESSAGE_BOILERPLATE_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_MESSAGE_BOILERPLATES: (state, messageBoilerplates) =>
    messageBoilerplates
      ? (state.messageBoilerplates = messageBoilerplates)
      : (state.messageBoilerplates = []),
  ADD_MESSAGE_BOILERPLATE: (state, messageBoilerplate) =>
    state.messageBoilerplates.push(messageBoilerplate),
  SET_MESSAGE_BOILERPLATE_TYPES: (state, messageBoilerplateTypes) => {
    state.messageBoilerplateTypes = [];
    messageBoilerplateTypes.map((messageBoilerplateType) => {
      state.messageBoilerplateTypes.push(messageBoilerplateType.name);
    });
  },
};

/**
 * Getters
 */
const getters = {
  getMessageBoilerplates: (state) => state.messageBoilerplates,
  getMessageBoilerplateTypes: (state) => state.messageBoilerplateTypes,
  getMessageBoilerplate: (state) => (messageBoilerplateId) =>
    state.messageBoilerplates.find(
      (messageBoilerplate) => messageBoilerplate.id == messageBoilerplateId
    ),
  getMessageBoilerplateByNumber: (state) => (messageBoilerplateNumber) =>
    state.messageBoilerplates.find(
      (messageBoilerplate) => messageBoilerplate.number == messageBoilerplateNumber
    ),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
