import gql from 'graphql-tag';
import apolloClient from '../apollo';
import Vue from 'vue';
import dayjs from 'dayjs';

/**
 * State
 */
const getDefaultState = () => {
  return {
    offers: [],
    offerCustomerStates: [],
  };
};
const state = getDefaultState();

const defaultOffer = () => {
  return {
    id: '_new',
    pageCount: 2,
    title: 'Angebot',
    customerName: '',
    introduction:
      'Sehr geehrter Herr Mustermann,<br/>' +
      'vielen Dank für Ihr Interesse an unseren Produkten und Leistungen.<br/><br/>' +
      'Eine eigene PV+Speicher Anlage reduziert einerseits Ihre eigenen Stromkosten und stellt andererseits einen wichtigen Beitrag zum nachhaltigen Umweltschutz dar.<br/><br/>' +
      'Wir dürfen Ihnen nun Ihre neue PV+Speicher Anlage wie folgt anbieten:',
    date: dayjs().format('DD.MM.YYYY'),
    editor: 'Enrico Sciacca',
    phone: '08165 / 907 49 93',
    email: 'e.sciacca@ssp-elektro.com',
    editingState: 'DRAFT',
    customerState: 'OPEN',
    positions: [],
    systemPositions: [],
    terms:
      '<strong>Weitere Informationen</strong><br/><br/>' +
      'Mögliche Kosten von Netzbetreiber/Messstellenbetreiber (z.B. für den Zähleranschluss) sind nicht im Umfang dieses Angebots enthalten.<br/><br/>' +
      'Es hängt von den Vorgaben des Erneuerbare-Energien-Gesetzes in der jeweils geltnenden Fassung ab, ob und in welcher Höhe Sie einen Anspruch auf Auszahlung einer Einspeisevergütung gegenüber Ihrem zuständigen Netzbetreiber haben werden. Es ist nicht voraussehbar, ob und wann die gesetzliche Förderung entfällt. Da die Q Power Solution GmbH auf die gesetzlichen Regelungen zur Förderung keinerlei Einfluss hat, übernimmt die Q Power Solution GmbH dafür ausdrücklich keine Gewähr oder Garantie.<br/><br/>' +
      'Hinweis für die Inanspruchnahme von Fördermitteln: Sie haben sich über die Voraussetzungen für die Bewilligung der Fördermittel erkundigt. Teilweise wird eine Förderung nur bewilligt, wenn diese vor Vertragsunterschrift beantragt wurde. Eine nachträgliche Teilnahme am Förderprogramm ist in diesen Fällen dann nicht mehr möglich.<br/><br/>' +
      'Voraussetzung für die Installation Ihrer Anlage sind die positive technische Machbarkeitsprüfung sowie Netzverträglichkeitsprüfung durch Ihren Netzbetreiber.<br/><br/>' +
      'Bei der technischen Machbarkeitsprüfung können Abweichungen zu dem vorliegenden Angebot entstehen. In diesem Fall schicken wir Ihnen ein aktualisiertes Angebot zu.<br/><br/>' +
      'Die Bearbeitung der Netzanmeldung Ihrer Anlage sowie der zugehörigen Inbetriebnahmemeldung obliegt ausschließlich dem Netzbetreiber. Auf die Bearbeitungszeiten haben wir keinen Einfluss.<br/><br/>' +
      'Des Weiteren gelten unsere AGBs.<br/><br/>' +
      'Das vorliegende Angebot ist bis zur Freigabe durch die Q Power Solution GmbH nach erfolgter technischer Prüfung unverbindlich.<br/><br/>' +
      'Mit freundlichen Grüßen,<br/>' +
      'Ihr Team der Q Power Solution GmbH',
  };
};

/**
 * Defines the fields which are requested for an offer
 * used for all queries/mutations which return an offer
 * @type {DocumentNode}
 */
const OFFER_REQUEST_FIELDS = gql`
  fragment offerFields on Offer {
    id
    number
    title
    introduction
    date
    totalCost
    address
    terms
    editingState
    customerState
    pageCount
    pdf {
      id
      path
      filename
      size
      mimetype
    }
    positions {
      name
      description
      quantity
      price
      totalCost
      priceUnit
      quantityUnit
    }
    systemPositions {
      name
      price
      totalCost
      solarPanelCount
      inverterCount
      batteryCount
      solarPanel {
        manufacturer
        type
        power
      }
      inverter {
        manufacturer
        type
        activePower
      }
      battery {
        manufacturer
        type
        capacity
      }
    }
    createdAt
    updatedAt
    editor
    phone
    email
    customerName
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} project
 */
const createPayload = (offer) => {
  if (offer.positions) {
    offer.positions.map((position) => {
      delete position.__typename;
      position.price = parseFloat(position.price);
      position.totalCost = parseFloat(position.totalCost);
      position.quantity = parseFloat(position.quantity);
    });
  }
  if (offer.systemPositions) {
    offer.systemPositions.map((position) => {
      delete position.__typename;
      delete position.solarPanel.__typename;
      delete position.inverter.__typename;
      delete position.battery.__typename;
      position.price = parseFloat(position.price);
      position.totalCost = parseFloat(position.totalCost);
    });
  }
  // console.log(offer)
  /**
   * for text fields differentiate between "" and null: "" are sent to the server as empty value
   * deadlineAt date field: send NULL to server to remove a date
   */
  const payload = {
    ...(offer.recipient ? { recipient: offer.recipient.id } : {}),
    ...(offer.editor ? { editor: offer.editor } : {}),
    ...(offer.phone ? { phone: offer.phone } : {}),
    ...(offer.email ? { email: offer.email } : {}),
    ...(offer.customerName ? { customerName: offer.customerName } : {}),
    ...(offer.number !== null ? { number: offer.number } : {}),
    ...(offer.editingState ? { editingState: offer.editingState } : {}),
    ...(offer.customerState ? { customerState: offer.customerState } : {}),
    ...(offer.date !== null ? { date: offer.date } : {}),
    ...(offer.address !== null ? { address: offer.address } : {}),
    ...(offer.title !== null ? { title: offer.title } : {}),
    ...(offer.introduction !== null ? { introduction: offer.introduction } : {}),
    ...(offer.terms !== null ? { terms: offer.terms } : {}),
    ...(offer.totalCost !== null ? { totalCost: offer.totalCost } : {}),
    positions: offer.positions,
    systemPositions: offer.systemPositions,
  };
  // console.log(payload)
  return payload;
};

/**
 * Actions
 */
const actions = {
  async fetchOffers({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            offers {
              ...offerFields
            }
          }
          ${OFFER_REQUEST_FIELDS}
        `,
      });
      console.log(response.data);
      commit('SET_OFFERS', response.data.offers);
    } catch (err) {
      throw new Error(err);
    }
  },
  async fetchOffersByCustomer({ commit }, customerId) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query offersByCompany($companyId: ID!) {
            offersByCompany(companyId: $companyId) {
              ...offerFields
            }
          }
          ${OFFER_REQUEST_FIELDS}
        `,
        variables: {
          companyId: customerId,
        },
      });
      commit('SET_OFFERS', response.data.offersByCompany);
    } catch (err) {
      //
    }
  },
  async fetchOfferByNumber({ commit, dispatch }, slug) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query offerByNumber($offerNumber: String!) {
            offerByNumber(offerNumber: $offerNumber) {
              ...offerFields
            }
          }
          ${OFFER_REQUEST_FIELDS}
        `,
        variables: {
          offerNumber: slug,
        },
      });
      // console.log(response.data)
      commit('ADD_OR_UPDATE_OFFER', response.data.offerByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchOfferPdfUrl({ commit }, offerNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query offerPdfUrl($offerNumber: String!) {
            offerPdfUrl(offerNumber: $offerNumber)
          }
        `,
        variables: {
          offerNumber: offerNumber,
        },
      });
      return response.data.offerPdfUrl;
    } catch (err) {
      throw new Error(err);
    }
  },

  /**
   *
   * @param {*} param0
   */
  async fetchOfferCustomerStates({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "OfferCustomerState") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_OFFER_CUSTOMER_STATTES', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  async createOffer({ commit, dispatch }, offer) {
    const payload = createPayload(offer);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createOffer($payload: OfferInput!) {
            createOffer(offerInput: $payload) {
              ...offerFields
            }
          }
          ${OFFER_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Angebot gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_OFFER', '_new');
      commit('ADD_OR_UPDATE_OFFER', response.data.createOffer);
      return response.data.createOffer.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim speichern des Angebotes! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateOffer({ commit, dispatch }, offer) {
    const payload = createPayload(offer);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateOffer($offerId: ID!, $payload: OfferInput!) {
            updateOffer(offerId: $offerId, offerInput: $payload) {
              ...offerFields
            }
          }
          ${OFFER_REQUEST_FIELDS}
        `,
        variables: {
          offerId: offer.id,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Angebot aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_OFFER', response.data.updateOffer);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten des Angebotes! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async messageOfferToCustomer({ commit, dispatch }, offer) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation messageOfferToCustomer($offerId: ID!) {
            messageOfferToCustomer(offerId: $offerId)
          }
        `,
        variables: {
          offerId: offer.id,
        },
      });
      dispatch('message', {
        message: 'Angebot gespeichert und E-Mail versendet an ' + offer.recipient.email,
        type: 'success',
        lifetime: 2,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim versenden des Angebotes!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },
  async messageOfferStateChangedToOperator({ commit, dispatch }, payload) {
    // console.log(offer)
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation messageOfferStateChangedToOperator($offerId: ID!, $comment: String) {
            messageOfferStateChangedToOperator(offerId: $offerId, comment: $comment)
          }
        `,
        variables: {
          offerId: payload.offerId,
          comment: payload.comment,
        },
      });
      dispatch('message', {
        message: 'Vielen Dank für die Aktualisierung des Angebotsstatus!',
        type: 'success',
        lifetime: 2,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim versenden des Angebotes!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new offer object in store and return it
   * @param {*} param0
   * @param {*} offer
   */
  async initOffer({ commit }) {
    commit('ADD_OR_UPDATE_OFFER', defaultOffer());
  },

  localUpdate({ commit }, offer) {
    commit('ADD_OR_UPDATE_OFFER', offer);
  },
  offerCleanup({ commit }) {
    commit('REMOVE_OFFER', '_new');
  },
  addDefaultPosition({ commit }, offer) {
    commit('ADD_DEFAULT_POSITION', offer);
  },
  offerAddPosition({ commit }, payload) {
    commit('ADD_POSITION', payload);
  },
  offerAddSystemPosition({ commit }, payload) {
    commit('ADD_SYSTEM_POSITION', payload);
  },
  offerRemovePosition({ commit }, payload) {
    commit('REMOVE_OFFER_POSITION', payload);
  },
  calculateOfferTotalCost({ commit }, offer) {
    offer.totalCost = offer.positions.reduce((accumulator, position) => {
      return accumulator + parseFloat(position.quantity) * parseFloat(position.price);
    }, 0);

    commit('ADD_OR_UPDATE_OFFER', offer);
  },
  // offerPositionCount({commit},offer) {
  //   const positionCount  =  offer.positions.reduce((accumulator, position) => {
  //       console.log(position.quantity)
  //       return accumulator + parseFloat(position.quantity);
  //     }, 0);
  //     console.log(positionCount)
  //     return positionCount
  // },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_OFFER_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_OFFERS: (state, offers) => (state.offers = offers),
  ADD_DEFAULT_POSITION: (state, updatedOffer) => {
    const existingOffer = state.offers.find((offer) => offer.id === updatedOffer.id);
    existingOffer.positions.push({});
  },
  ADD_POSITION: (state, { offer, position }) => {
    const existingOffer = state.offers.find((existingOffer) => existingOffer.id === offer.id);
    existingOffer.positions.push(position);
  },
  ADD_SYSTEM_POSITION: (state, { offer, systemPosition }) => {
    const existingOffer = state.offers.find((existingOffer) => existingOffer.id === offer.id);
    existingOffer.systemPositions = [];
    existingOffer.systemPositions.push(systemPosition);
  },
  /** Remove Offer position by array index */
  REMOVE_OFFER_POSITION: (state, { offer, positionIndex }) => {
    const existingOffer = state.offers.find((existingOffer) => existingOffer.id === offer.id);
    existingOffer.positions.splice(positionIndex, 1);
  },
  ADD_OFFER: (state, offer) => state.offers.push(offer),
  ADD_OR_UPDATE_OFFER: (state, updatedOffer) => {
    const existingOffer = state.offers.find((offer) => offer.id === updatedOffer.id);
    if (existingOffer) {
      Object.assign(existingOffer, updatedOffer);
    } else {
      state.offers.push(updatedOffer);
    }
  },
  REMOVE_OFFER: (state, offerId) => {
    const index = state.offers.findIndex((offer) => offer.id === offerId);
    state.offers.splice(index, 1);
  },
  SET_OFFER_CUSTOMER_STATTES: (state, offerCustomerStates) => {
    state.offerCustomerStates = [];
    offerCustomerStates.map((offerCustomerState) => {
      state.offerCustomerStates.push(offerCustomerState.name);
    });
  },
};

/**
 * Getters
 */
const getters = {
  getOffers: (state) => state.offers,
  getOffer: (state) => (offerId) => state.offers.find((offer) => offer.id == offerId),
  getOfferByNumber: (state) => (offerNumber) => {
    const offer = state.offers.find((offer) => {
      if (offer.number == offerNumber) {
        return offer;
      }
    });
    if (offer) {
      return offer;
    }
    // else {
    //     return Vue.set(state.offers, state.offers.length, defaultOffer())
    // }
  },
  getOfferPositionCount: (state) => (offerId) => {
    const offer = state.offers.find((offer) => offer.id == offerId);
    const positionCount = offer.positions.reduce((accumulator, position) => {
      // console.log(position.quantity)
      return accumulator + parseFloat(position.quantity);
    }, 0);
    return positionCount;
  },
  getOfferCustomerStates: (state) => state.offerCustomerStates,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
