export default {
  // EVU
  key: '4',
  label: 'powerCompany',
  children: [
    { key: '4.0', name: 'powerCompany.company', label: 'company' },
    { key: '4.1', name: 'powerCompany.street', label: 'street_streetNumber' },
    { key: '4.2', name: 'powerCompany.zip', label: 'zip' },
    { key: '4.3', name: 'powerCompany.city', label: 'city' },
    {
      key: '4.4',
      name: 'composite.powerCompanyZip_powerCompanyCity',
      label: 'composite.powerCompanyZip_powerCompanyCity',
    },
    { key: '4.5', name: 'powerCompany.cosinusPhi', label: 'cosinusPhi' },
    { key: '4.6', name: 'powerCompanyProjectNumber', label: 'powerCompanyProjectNumber' },
    { key: '4.7', name: 'powerCompanyProjectNumber2', label: 'powerCompanyProjectNumber2' },
    { key: '4.8', name: 'powerCompanyProjectNumber3', label: 'powerCompanyProjectNumber3' },
    {
      key: 'measurementPointOperator_composite_name_number',
      name: 'measurementPointOperator_composite_name_number',
      label: 'measurementPointOperator_thirdParty_long',
    },
    {
      key: 'measurementPointOperator_composite_name_number_or_powerCompany',
      name: 'measurementPointOperator_composite_name_number_or_powerCompany',
      label: 'measurementPointOperator_name_or_powerCompany',
    },
  ],
};
