import Vue from 'vue';
import Vuex from 'vuex';

/**
 * Basic store modules
 */
import auth from './auth';
import roles from './roles';
import loading from './loading';
import loadingPlugin from './loadingPlugin';
import flash from './flash';
import layout from './layout';

/**
 * Application logic store modules
 */
import users from './users';
import powerCompanies from './powerCompanies';
import projects from './projects';
import energyFlowDirectionSensors from './energyFlowDirectionSensors';
import solarPanels from './solarPanels';
import inverters from './inverters';
import wallboxes from './wallboxes';
import batteries from './batteries';
import storageSystems from './storageSystems';
import employers from './employers';
import employees from './employees';
import clients from './clients';
import electricians from './electricians';
import items from './items';
import itemStorages from './itemStorages';
import messageBoilerplates from './messageBoilerplates';
import attachments from './attachments';
import documents from './documents';
import documentFields from './documentFields';
import offers from './offers';
import offerBoilerplates from './offerBoilerplates';
import reportTemplateImages from './reportTemplateImages';
import measurementPointOperators from './measurementPointOperators';
import appointments from './appointments';
import appointmentCapacities from './appointmentCapacities';
import bricks from './bricks';
import tasks from './tasks';
import timeTracking from './timeTracking';
import applicants from './applicants';
import meta from './meta';
import enums from './enums';
import posts from './posts';
import contents from './contents';
import sessions from './sessions';
import timetables from './timetables';
import students from './students';
import trainers from './trainers';
import courses from './courses';
import orders from './orders';
import audits from './audits';

Vue.use(Vuex);
const plugin = loadingPlugin();

export default new Vuex.Store({
  strict: false,
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    roles,
    loading,
    flash,
    layout,
    users,
    powerCompanies,
    projects,
    energyFlowDirectionSensors,
    solarPanels,
    inverters,
    wallboxes,
    batteries,
    storageSystems,
    employers,
    clients,
    electricians,
    items,
    itemStorages,
    messageBoilerplates,
    attachments,
    documents,
    documentFields,
    offers,
    offerBoilerplates,
    reportTemplateImages,
    measurementPointOperators,
    employees,
    appointments,
    appointmentCapacities,
    bricks,
    tasks,
    timeTracking,
    applicants,
    meta,
    enums,
    posts,
    contents,
    sessions,
    timetables,
    students,
    trainers,
    courses,
    orders,
    audits,
  },
  plugins: [plugin],
});
