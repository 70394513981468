import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const defaultOfferBoilerplate = () => {
  return {
    id: '_new',
    title: '',
    type: 'OFFER_SYSTEM_POSITION',
    content: '',
    position: {
      name: '',
      price: 0,
      quantityUnit: '',
      totalCost: 0,
      solarPanelCount: 0,
      inverterCount: 0,
      batteryCount: 0,
      solarPanel: {},
      inverter: {},
      battery: {},
    },
  };
};

const getDefaultState = () => {
  return {
    offerBoilerplates: [],
    offerBoilerplateTypes: [],
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an offerBoilerplate
 * used for all queries/mutations which return an offerBoilerplate
 * @type {DocumentNode}
 */
const OFFER_BOILERPLATE_REQUEST_FIELDS = gql`
  fragment offerBoilerplateFields on OfferBoilerplate {
    id
    number
    title
    type
    content
    systemPosition {
      name
      price
      totalCost
      solarPanelCount
      inverterCount
      batteryCount
      solarPanel {
        manufacturer
        type
        power
      }
      inverter {
        manufacturer
        type
        activePower
      }
      battery {
        manufacturer
        type
        capacity
      }
    }
    createdAt
    updatedAt
  }
`;

/**
 * Actions
 */
const actions = {
  async fetchOfferBoilerplates({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            offerBoilerplates {
              ...offerBoilerplateFields
            }
          }
          ${OFFER_BOILERPLATE_REQUEST_FIELDS}
        `,
      });
      commit('SET_OFFER_BOILERPLATES', response.data.offerBoilerplates);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchOfferBoilerplateByNumber({ commit, dispatch }, offerBoilerplateNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query offerBoilerplateByNumber($offerBoilerplateNumber: String!) {
            offerBoilerplateByNumber(offerBoilerplateNumber: $offerBoilerplateNumber) {
              ...offerBoilerplateFields
            }
          }
          ${OFFER_BOILERPLATE_REQUEST_FIELDS}
        `,
        variables: {
          offerBoilerplateNumber: offerBoilerplateNumber,
        },
      });
      commit('ADD_OR_UPDATE_OFFER_BOILERPLATE', response.data.offerBoilerplateByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchOfferBoilerplateTypes({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "OfferBoilerplateType") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_OFFER_BOILERPLATE_TYPES', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  async createOfferBoilerplate({ commit, dispatch }, offerBoilerplate) {
    if (
      offerBoilerplate.type === 'TITLE' ||
      offerBoilerplate.type === 'INTRODUCTION' ||
      offerBoilerplate.type === 'TERMS'
    ) {
      offerBoilerplate.position = null;
    } else {
      offerBoilerplate.position.price = parseFloat(offerBoilerplate.position.price);
      offerBoilerplate.position.totalCost = parseFloat(offerBoilerplate.position.totalCost);
      offerBoilerplate.position.quantity = parseFloat(offerBoilerplate.position.quantity);
    }

    /**
     * for text fields differentiate between "" and null: "" are sent to the server as empty value
     * deadlineAt date field: send NULL to server to remove a date
     */
    const payload = {
      ...(offerBoilerplate.type !== null ? { type: offerBoilerplate.type } : {}),
      ...(offerBoilerplate.position ? { position: offerBoilerplate.position } : {}),
      ...(offerBoilerplate.title !== null ? { title: offerBoilerplate.title } : {}),
      ...(offerBoilerplate.content !== null ? { content: offerBoilerplate.content } : {}),
    };
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createOfferBoilerplate($payload: OfferBoilerplateInput!) {
            createOfferBoilerplate(offerBoilerplateInput: $payload) {
              ...offerBoilerplateFields
            }
          }
          ${OFFER_BOILERPLATE_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', { message: 'Vorlage gespeichert!', type: 'success', lifetime: 2 });
      commit('REMOVE_OFFER_BOILERPLATE', '_new'); // remove temporary _new offerBoilerplate
      commit('ADD_OR_UPDATE_OFFER_BOILERPLATE', response.data.createOfferBoilerplate);
      return response.data.createOfferBoilerplate.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen der Vorlage! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateOfferBoilerplate({ commit, dispatch }, offerBoilerplate) {
    if (
      offerBoilerplate.type === 'TITLE' ||
      offerBoilerplate.type === 'INTRODUCTION' ||
      offerBoilerplate.type === 'TERMS'
    ) {
      offerBoilerplate.position = null;
    } else {
      delete offerBoilerplate.position.__typename;
      offerBoilerplate.position.price = parseFloat(offerBoilerplate.position.price);
      offerBoilerplate.position.totalCost = parseFloat(offerBoilerplate.position.totalCost);
      offerBoilerplate.position.quantity = parseFloat(offerBoilerplate.position.quantity);
    }
    /**
     * for text fields differentiate between "" and null: "" are sent to the server as empty value
     * deadlineAt date field: send NULL to server to remove a date
     */
    const payload = {
      ...(offerBoilerplate.type ? { type: offerBoilerplate.type } : {}),
      ...(offerBoilerplate.position ? { position: offerBoilerplate.position } : {}),
      ...(offerBoilerplate.title !== null ? { title: offerBoilerplate.title } : {}),
      ...(offerBoilerplate.content !== null ? { content: offerBoilerplate.content } : {}),
    };
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateOfferBoilerplate(
            $offerBoilerplateId: ID!
            $payload: OfferBoilerplateInput!
          ) {
            updateOfferBoilerplate(
              offerBoilerplateId: $offerBoilerplateId
              offerBoilerplateInput: $payload
            ) {
              ...offerBoilerplateFields
            }
          }
          ${OFFER_BOILERPLATE_REQUEST_FIELDS}
        `,
        variables: {
          offerBoilerplateId: offerBoilerplate.id,
          payload: payload,
        },
      });
      dispatch('message', { message: 'Vorlage aktualisiert!', type: 'success', lifetime: 2 });
      commit('ADD_OR_UPDATE_OFFER_BOILERPLATE', response.data.updateOfferBoilerplate);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten der Vorlage! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteOfferBoilerplate({ commit, dispatch }, id) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteOfferBoilerplate($offerBoilerplateId: ID!) {
            deleteOfferBoilerplate(offerBoilerplateId: $offerBoilerplateId)
          }
        `,
        variables: {
          offerBoilerplateId: id,
        },
      });
      dispatch('message', { message: 'Vorlage gelöscht!', type: 'success', lifetime: 2 });
      commit('REMOVE_OFFER_BOILERPLATE', id);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen der Vorlage!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new offerBoilerplate object in store and return it
   * @param {*} param0
   * @param {*} offerBoilerplate
   */
  async initOfferBoilerplate({ commit }) {
    commit('ADD_OR_UPDATE_OFFER_BOILERPLATE', defaultOfferBoilerplate());
  },

  offerBoilerplateLocalUpdate({ commit }, offerBoilerplate) {
    commit('ADD_OR_UPDATE_OFFER_BOILERPLATE', offerBoilerplate);
  },

  offerBoilerplateCleanup({ commit }) {
    commit('REMOVE_OFFER_BOILERPLATE', '_new');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_OFFER_BOILERPLATE_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_OFFER_BOILERPLATES: (state, offerBoilerplates) =>
    offerBoilerplates
      ? (state.offerBoilerplates = offerBoilerplates)
      : (state.offerBoilerplates = []),
  ADD_OFFER_BOILERPLATE: (state, offerBoilerplate) =>
    state.offerBoilerplates.push(offerBoilerplate),
  ADD_OR_UPDATE_OFFER_BOILERPLATE: (state, updatedOfferBoilerplate) => {
    const existingOfferBoilerplate = state.offerBoilerplates.find(
      (offerBoilerplate) => offerBoilerplate.id === updatedOfferBoilerplate.id
    );
    if (existingOfferBoilerplate) {
      Object.assign(existingOfferBoilerplate, updatedOfferBoilerplate);
    } else {
      state.offerBoilerplates.push(updatedOfferBoilerplate);
    }
  },
  SET_OFFER_BOILERPLATE_TYPES: (state, offerBoilerplateTypes) => {
    state.offerBoilerplateTypes = [];
    offerBoilerplateTypes.map((offerBoilerplateType) => {
      state.offerBoilerplateTypes.push(offerBoilerplateType.name);
    });
  },
  REMOVE_OFFER_BOILERPLATE: (state, offerBoilerplateId) => {
    const index = state.offerBoilerplates.findIndex(
      (offerBoilerplate) => offerBoilerplate.id === offerBoilerplateId
    );
    state.offerBoilerplates.splice(index, 1);
  },
};

/**
 * Getters
 */
const getters = {
  getOfferBoilerplates: (state) => state.offerBoilerplates,
  getGroupedOfferBoilerplates: (state) => {
    state.offerBoilerplates.push({
      title: 'Leere Angebotsposition',
      type: 'OFFER_POSITION',
      position: {
        name: '',
        description: '',
        quantity: 1,
        price: 0,
        quantityUnit: '',
        totalCost: 0,
      },
    });
    return state.offerBoilerplates;
  },
  getOfferBoilerplate: (state) => (offerBoilerplateId) =>
    state.offerBoilerplates.find((offerBoilerplate) => offerBoilerplate.id == offerBoilerplateId),
  getOfferBoilerplateByNumber: (state) => (offerBoilerplateNumber) =>
    state.offerBoilerplates.find(
      (offerBoilerplate) => offerBoilerplate.number == offerBoilerplateNumber
    ),
  getOfferBoilerplateTypes: (state) => state.offerBoilerplateTypes,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
