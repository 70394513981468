import dayjs from 'dayjs';
import 'dayjs/locale/de';
import timezone from 'dayjs/plugin/timezone.js';
import utc from 'dayjs/plugin/utc';

import { i18n } from '@/helpers/i18n';

import {
  inverterCount,
  inverterIdenticalCount,
  formatInverterApparentPowerOverall,
  formatInverterCompositeType,
  formatInverterCompositeManufacturer,
  formatInverterActivePowerOverall,
  formatInverterNominalPowerOverall,
  formatInverterNominalPowerPerPhase,
  formatInverterActivePowerPerPhase,
  formatInverterCompositeManufacturerType,
  formatInverterActivePowerByPhase,
  formatInverterNominalPowerByPhase,
} from './inverter';

import { guestEntryNumberByProject } from './electrician';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('de');

/** Format solar plant power */
export const formatSolarPanelPower = (project) => {
  if (project.solarPlant.solarPanel) {
    return i18n.n(project.solarPlant.solarPanel.power);
  }
  return i18n.n(0);
};

export const formatBatteryCapacity = (project) => {
  if (project.solarPlant.battery) {
    return i18n.n(project.solarPlant.battery.capacity, 'decimal_single');
  }
  return i18n.n(0, 'decimal_single');
};

/** Format solar panels overall power */
export const formatSolarPanelsOverallPower = (project) => {
  if (project.solarPlant.solarPanel && project.solarPlant.solarPanelCount) {
    return i18n.n(
      (project.solarPlant.solarPanelCount * project.solarPlant.solarPanel.power) / 1000,
      'decimal_triple'
    );
  }
  return i18n.n(0, 'decimal');
};

/**
 * Calculate solar plants overall power
 */
export const solarPlantOverallPower = (project) => {
  if (project.solarPlant.solarPanel && project.solarPlant.solarPanelCount) {
    return (project.solarPlant.solarPanelCount * project.solarPlant.solarPanel.power) / 1000;
  }
  return 0;
};

/**
 * Extend the project data with
 * - static fields needed for pdf export
 * - calculations
 */
export class ExtendedProject {
  constructor(project) {
    this.project = project;
    this.date();
    this.common();
    this.composite();
    this.electrician(); // order matters!
    this.translate();
    this.calculation();
    this.electricianComposite();
    this.customerComposite();
    this.solarPlantComposite();
    this.inverterComposite();
    this.batteryComposite();
    this.wallboxComposite();
    this.extendedConfigurationComposite();
    this.solarPanelComposite();
    return this.project;
  }

  /**
   * Static fields for pdf exports
   */
  common() {
    this.project.common = {
      date: dayjs().format('DD.MM.YYYY'),
      operationRegistrationAt_today_plus_20_days: dayjs()
        .add(20, 'day')
        .tz('Europe/Berlin')
        .format('DD.MM.YYYY'),
      signature: '/img/signature.png',
      measuringConcept: 'M23',
      selection: 'x', // print an x to select checkboxes
    };

    /**
     * IN-Datum
     * old value this.project.installation.operationRegistrationInstallationAppointment.installationAt is obsolete, map to this.project.installation.operationRegistrationAt
     */
    // this.project.installation.operationRegistrationInstallationAppointment = {
    //   installationAt: dayjs(this.project.installation.operationRegistrationAt)
    //     .tz('Europe/Berlin')
    //     .format('DD.MM.YYYY'),
    // };
    this.project.installation.operationRegistrationInstallationAppointment = {
      installationAt: this.project.installation.operationRegistrationAt,
    };
  }

  electricianComposite() {
    /** Elektrofachkraft - Gasteintragungsnummer für ausgewähltes EVU  */
    this.project.composite.electricianGuestEntryNumber = guestEntryNumberByProject(this.project);
  }

  customerComposite() {
    /** Ortsteil/Flurstück */
    this.project.customer.cityPart_fieldSector =
      this.project.customer.cityPart + ' / ' + this.project.customer.fieldSector;

    const birthdayAt = this.project.customer.birthdayAt;
    // ? dayjs(this.project.customer.birthdayAt).format('DD.MM.YYYY')
    // : '';

    this.project.composite.customerLastname_customerFirstname_customerBirthday =
      this.project.customer.lastname + ', ' + this.project.customer.firstname + ', ' + birthdayAt;

    /** Hausnr Hausnr_Zusatz */
    this.project.composite.customerStreetNumber_customerStreetNumberSuffix =
      this.project.customer.streetNumber + this.project.customer.streetNumberSuffix;

    /** Straße Hausnr Hausnr_Zusatz */
    this.project.composite.customerStreet_customerStreetNumber_customerStreetNumberSuffix =
      this.project.customer.street +
      ' ' +
      this.project.customer.streetNumber +
      this.project.customer.streetNumberSuffix;

    /** Straße Hausnr Hausnr_Zusatz, PLZ Ort */
    this.project.composite.customerStreet_customerStreetNumber_customerStreetNumberSuffix_customerZip_customerCity =
      this.project.customer.street +
      ' ' +
      this.project.customer.streetNumber +
      this.project.customer.streetNumberSuffix +
      ', ' +
      this.project.customer.zip +
      ' ' +
      this.project.customer.city;
  }

  /**
   * combined fields for batteries
   */
  batteryComposite() {
    /** Batteriespeicher (Hersteller - Typ) */
    this.project.solarPlant.battery_manufacturer_type = this.project.solarPlant.battery
      ? this.project.solarPlant.battery.manufacturer + ' - ' + this.project.solarPlant.battery.type
      : '';

    this.project.solarPlant.batteryQuantity = this.project.solarPlant.battery ? '1' : '';

    // Speicher ja / nein
    this.project.solarPlant.hasBattery = this.project.solarPlant.battery ? 'YES' : 'NO';

    // Speicher Text
    this.project.solarPlant.hasBatteryText = this.project.solarPlant.battery ? 'storage' : '';
  }

  /**
   * combined fields for solarPanels
   */
  solarPanelComposite() {
    /**
     * Solarmodul - Wirkleistung Pav,e (70%) kWp bzw. Eingestellte Leistung am Wechselrichter
     * Dieses Feld soll nur einen Wert darstellen, wenn unter Projekte/Konfiguration im Feld Einspeisemanagement „Dauerhafte Begrenzung“ UND im Feld Dauerhafte Begrenzung „70%“ ausgewählt sind
     */
    this.project.solarPlant.presetInverterPower =
      this.project.solarPlant.networkSupplyManagementState === 'PERMANENT_RESTRICTION' &&
      this.project.solarPlant.networkSupplyManagementPermanentRestrictionState === 'PERCENT_70'
        ? solarPlantOverallPower(this.project) * 0.7
        : '';
  }

  wallboxComposite() {
    // Wallbox ja / nein
    this.project.solarPlant.hasWallbox = this.project.solarPlant.wallbox ? 'YES' : 'NO';

    /** Art der Ladung AC */
    this.project.solarPlant.wallboxChargingTypeAC =
      this.project.solarPlant.wallbox && this.project.solarPlant.wallbox.chargingType === 'AC'
        ? 'YES'
        : 'NO';

    /** Art der Ladung DC */
    this.project.solarPlant.wallboxChargingTypeDC =
      this.project.solarPlant.wallbox && this.project.solarPlant.wallbox.chargingType === 'DC'
        ? 'YES'
        : 'NO';

    /** Anzahl Wallboxen */
    this.project.solarPlant.wallboxQuantity = this.project.solarPlant.wallbox ? '1' : '';

    /** Anzahl Ladepunkte */
    this.project.solarPlant.wallboxChargingPointQuantity = this.project.solarPlant.wallbox
      ? '1'
      : '';

    // Anschluss – Wechselstrom
    this.project.solarPlant.wallboxConnectionTypeAlternatingCurrent =
      this.project.solarPlant.wallbox &&
      this.project.solarPlant.wallbox.connectionTypes.find(
        (element) => element === 'ALTERNATING_CURRENT'
      )
        ? 'YES'
        : 'NO';

    // Anschluss – Drehstrom
    this.project.solarPlant.wallboxConnectionTypeThreewayCurrent =
      this.project.solarPlant.wallbox &&
      this.project.solarPlant.wallbox.connectionTypes.find(
        (element) => element === 'THREEWAY_CURRENT'
      )
        ? 'YES'
        : 'NO';

    // // Anschluss – L1
    this.project.solarPlant.wallboxConnectionTypeL1 =
      this.project.solarPlant.wallbox &&
      (this.project.solarPlant.wallbox.connectionTypes.find((element) => element === 'L1') ||
        this.project.solarPlant.wallbox.connectionTypes.find(
          (element) => element === 'THREEWAY_CURRENT'
        ))
        ? 'YES'
        : 'NO';

    // // Anschluss – L2
    this.project.solarPlant.wallboxConnectionTypeL2 =
      this.project.solarPlant.wallbox &&
      (this.project.solarPlant.wallbox.connectionTypes.find((element) => element === 'L2') ||
        this.project.solarPlant.wallbox.connectionTypes.find(
          (element) => element === 'THREEWAY_CURRENT'
        ))
        ? 'YES'
        : 'NO';

    // // Anschluss – L3
    this.project.solarPlant.wallboxConnectionTypeL3 =
      this.project.solarPlant.wallbox &&
      (this.project.solarPlant.wallbox.connectionTypes.find((element) => element === 'L3') ||
        this.project.solarPlant.wallbox.connectionTypes.find(
          (element) => element === 'THREEWAY_CURRENT'
        ))
        ? 'YES'
        : 'NO';

    // Verschiebungsfaktor cos phi – induktiv
    this.project.solarPlant.wallbox_shiftFactorCosphiType_inductive =
      this.project.solarPlant.wallbox &&
      this.project.solarPlant.wallbox.shiftFactorCosphiType === 'INDUCTIVE'
        ? 'YES'
        : 'NO';

    // Verschiebungsfaktor cos phi – kapazitiv
    this.project.solarPlant.wallbox_shiftFactorCosphiType_capacitive =
      this.project.solarPlant.wallbox &&
      this.project.solarPlant.wallbox.shiftFactorCosphiType === 'CAPACITIVE'
        ? 'YES'
        : 'NO';
  }

  /**
   * combined fields for inverters
   */
  inverterComposite() {
    /** Wechselrichter 1 (Hersteller - Typ) */
    this.project.solarPlant.inverter1_manufacturer_type =
      this.project.solarPlant && this.project.solarPlant.inverter1
        ? this.project.solarPlant.inverter1.manufacturer +
          ' - ' +
          this.project.solarPlant.inverter1.type
        : '';
    /** Wechselrichter 2 (Hersteller - Typ) */
    this.project.solarPlant.inverter2_manufacturer_type =
      this.project.solarPlant && this.project.solarPlant.inverter2
        ? this.project.solarPlant.inverter2.manufacturer +
          ' - ' +
          this.project.solarPlant.inverter2.type
        : '';

    // Anzahl Wechselrichter
    this.project.solarPlant.inverterCount = inverterCount(this.project);

    // Anzahl Wechselrichter baugleich
    this.project.solarPlant.inverterIdenticalCount = inverterIdenticalCount(this.project);

    // Zusammengesetzter Wechselrichter-Hersteller aus Wechselrichter 1 und 2 (mit / getrennt)
    this.project.solarPlant.inverterCompositeManufacturer = formatInverterCompositeManufacturer(
      this.project
    );

    // Zusammengesetzter Wechselrichter-Hersteller und Typ aus Wechselrichter 1 und 2 (mit / und - getrennt)
    this.project.solarPlant.inverterCompositeManufacturerType = formatInverterCompositeManufacturerType(
      this.project
    );

    // Zusammengesetzter Wechselrichter-Typ aus Wechselrichter 1 und 2 (mit / getrennt)
    this.project.solarPlant.inverterCompositeType = formatInverterCompositeType(this.project);

    // Scheinleistung Gesamt
    this.project.solarPlant.apparentPowerOverall = formatInverterApparentPowerOverall(this.project);

    // Wirkleistung Gesamt
    this.project.solarPlant.activePowerOverall = formatInverterActivePowerOverall(this.project);

    // Nennleistung Gesamt
    this.project.solarPlant.nominalPowerOverall = formatInverterNominalPowerOverall(this.project);

    // Nennleistung pro Phase
    this.project.solarPlant.nominalPowerPerPhase = formatInverterNominalPowerPerPhase(this.project);

    // Wirkleistung pro Phase
    this.project.solarPlant.activePowerPerPhase = formatInverterActivePowerPerPhase(this.project);

    // Wirkleistung Phase 1
    this.project.solarPlant.activePowerPhase1 = formatInverterActivePowerByPhase(
      this.project,
      'L1'
    );
    // Wirkleistung Phase 2
    this.project.solarPlant.activePowerPhase2 = formatInverterActivePowerByPhase(
      this.project,
      'L2'
    );
    // Wirkleistung Phase 1
    this.project.solarPlant.activePowerPhase3 = formatInverterActivePowerByPhase(
      this.project,
      'L3'
    );
    // Nennleistung Phase 1
    this.project.solarPlant.nominalPowerPhase1 = formatInverterNominalPowerByPhase(
      this.project,
      'L1'
    );
    // Nennleistung Phase 2
    this.project.solarPlant.nominalPowerPhase2 = formatInverterNominalPowerByPhase(
      this.project,
      'L2'
    );
    // Nennleistung Phase 1
    this.project.solarPlant.nominalPowerPhase3 = formatInverterNominalPowerByPhase(
      this.project,
      'L3'
    );

    // Inverter 1 Anzahl
    this.project.solarPlant.inverter1_count = this.project.solarPlant.inverter1 ? '1' : '';

    // Inverter 2 Anzahl
    this.project.solarPlant.inverter2_count = this.project.solarPlant.inverter2 ? '1' : '';

    // Inverter 2 ja / nein
    this.project.solarPlant.hasInverter2 = this.project.solarPlant.inverter2 ? 'YES' : 'NO';

    // Integrierter NA-Schutz U> 253 V
    this.project.solarPlant.gridAndPlantProtectionIntegratedMultipliedBy230 = this.project
      .solarPlant.inverter1
      ? Math.round(this.project.solarPlant.inverter1.gridAndPlantProtectionIntegrated * 230) + 'V'
      : '';
  }

  extendedConfigurationComposite() {
    if (this.project.solarPlant.solarPlantType === 'NEW_CONSTRUCTION') {
      this.project.solarPlant.solarPlantTypeValueA = '0';
      this.project.solarPlant.solarPlantTypeValueB = '1';
      this.project.solarPlant.solarPlantTypeValueC = '1';
    }
    if (this.project.solarPlant.solarPlantType === 'EXPANSION') {
      this.project.solarPlant.solarPlantTypeValueA = '1';
      this.project.solarPlant.solarPlantTypeValueB = '1';
      this.project.solarPlant.solarPlantTypeValueC = '2';
    }
    if (this.project.solarPlant.solarPlantType === 'DEMOLITION') {
      this.project.solarPlant.solarPlantTypeValueA = '1';
      this.project.solarPlant.solarPlantTypeValueB = '0';
      this.project.solarPlant.solarPlantTypeValueC = '0';
    }
  }

  /** create combined fields and attach it to the project */
  solarPlantComposite() {
    /** EnFluRi (Hersteller - Typ) */
    this.project.solarPlant.energyFlowDirectionSensor_manufacturer_type = this.project.solarPlant
      .energyFlowDirectionSensor
      ? this.project.solarPlant.energyFlowDirectionSensor.manufacturer +
        ' - ' +
        this.project.solarPlant.energyFlowDirectionSensor.type
      : '';

    /** Wallbox (Hersteller - Typ) */
    this.project.solarPlant.wallbox_manufacturer_type = this.project.solarPlant.wallbox
      ? this.project.solarPlant.wallbox.manufacturer + ' - ' + this.project.solarPlant.wallbox.type
      : '';

    /** Messtellenbetreiber - nur Dritter  */
    if (this.project.measurementPointOperator && this.project.powerCompany) {
      this.project.measurementPointOperator_composite_name_number =
        this.project.measurementPointOperator.name +
        ' - ' +
        this.project.measurementPointOperator.msbNumber;
    } else if (this.project.powerCompany) {
      this.project.measurementPointOperator_composite_name_number = '';
    }

    /** Messtellenbetreiber - Name */
    if (this.project.measurementPointOperator && this.project.powerCompany) {
      this.project.measurementPointOperator_composite_name_number_or_powerCompany =
        this.project.measurementPointOperator.name +
        ' - ' +
        this.project.measurementPointOperator.msbNumber;
    } else if (this.project.powerCompany) {
      this.project.measurementPointOperator_composite_name_number_or_powerCompany = this.project.powerCompany.company;
    }

    /** MSB EVU */
    this.project.measurementPointOperator_powerCompany =
      this.project.powerCompany && !this.project.measurementPointOperator ? 'YES' : 'NO';

    /** MSB Dritter */
    this.project.measurementPointOperator_thirdParty =
      this.project.powerCompany && this.project.measurementPointOperator ? 'YES' : 'NO';

    /** bis 7,69 kWp */
    this.project.powerRatings_overall_power_lt_7690 =
      solarPlantOverallPower(this.project) <= 7.69 ? 'YES' : 'NO';

    /** bis >7,69 kWp bis 10 kWp */
    this.project.powerRatings_overall_power_7690_to_10000 =
      solarPlantOverallPower(this.project) > 7.69 && solarPlantOverallPower(this.project) <= 10
        ? 'YES'
        : 'NO';

    /** über 10 kWp */
    this.project.powerRatings_overall_power_get_10000 =
      solarPlantOverallPower(this.project) > 10 ? 'YES' : 'NO';

    /** <30 kWp */
    this.project.powerRatings_overall_power_lt_30000 =
      solarPlantOverallPower(this.project) < 30.0 ? 'YES' : 'NO';

    /** <=30 kWp */
    this.project.powerRatings_overall_power_lte_30000 =
      solarPlantOverallPower(this.project) <= 30.0 ? 'YES' : 'NO';

    /** >30 kWp */
    this.project.powerRatings_overall_power_gt_30000 =
      solarPlantOverallPower(this.project) > 30.0 ? 'YES' : 'NO';

    /** <=25 kWp */
    this.project.powerRatings_overall_power_lte_25000 =
      solarPlantOverallPower(this.project) <= 25.0 ? 'YES' : 'NO';

    /** >25 kWp */
    this.project.powerRatings_overall_power_gt_25000 =
      solarPlantOverallPower(this.project) > 25.0 ? 'YES' : 'NO';

    /** >100 kWp */
    this.project.powerRatings_overall_power_gt_100000 =
      solarPlantOverallPower(this.project) > 100.0 ? 'YES' : 'NO';

    /** >3,42 kW */
    this.project.powerRatings_battery_power_gt_3420 =
      this.project.solarPlant.battery && this.project.solarPlant.battery.dischargePower > 3.42
        ? 'YES'
        : 'NO';

    /** <=10 kW */
    this.project.powerRatings_battery_power_lte_10000 =
      this.project.solarPlant.battery && this.project.solarPlant.battery.dischargePower <= 10.0
        ? 'YES'
        : 'NO';

    /** >10 kW */
    this.project.powerRatings_battery_power_gt_10000 =
      this.project.solarPlant.battery && this.project.solarPlant.battery.dischargePower > 10.0
        ? 'YES'
        : 'NO';
  }

  /**
   * combine fields to create composite fields
   */
  composite() {
    this.project.composite = {
      customerCity_deliveryAt: this.project.customer.city + ', ' + dayjs().format('DD.MM.YYYY'),
      // this.project.customer.city + ', ' + dayjs(this.project.deliveryAt).format('DD.MM.YYYY'),

      /**
       * IN - Ort, Datum
       * old value this.project.installation.operationRegistrationInstallationAppointment.installationAt is obsolete, map to this.project.installation.operationRegistrationAt
       */
      customerCity_operationRegistrationInstallationAppointment:
        this.project.customer.city + ', ' + this.project.installation.operationRegistrationAt,

      // dayjs(this.project.installation.operationRegistrationAt)
      //   .tz('Europe/Berlin')
      //   .format('DD.MM.YYYY'),

      customerLastname_customerFirstname:
        this.project.customer.lastname + ', ' + this.project.customer.firstname,

      customerFirstname_customerLastname:
        this.project.customer.firstname + ', ' + this.project.customer.lastname,

      customerFirstname_customerLastname_no_comma:
        this.project.customer.firstname + ' ' + this.project.customer.lastname,

      customerStreet_customerStreetNumber:
        this.project.customer.street + ' ' + this.project.customer.streetNumber,

      /** Kunde - Straße Hausnr, PLZ Ort */
      customerStreet_customerStreetNumber_customerZip_customerCity:
        this.project.customer.street +
        ' ' +
        this.project.customer.streetNumber +
        ', ' +
        this.project.customer.zip +
        ' ' +
        this.project.customer.city,

      customerZip_customerCity: this.project.customer.zip + ' ' + this.project.customer.city,

      customerZip_customerCity_customerCityPart:
        this.project.customer.zip +
        ' ' +
        this.project.customer.city +
        ', ' +
        this.project.customer.cityPart,

      customerPhone_customerEmail:
        (this.project.customer.phone || this.project.customer.mobile) +
        ', ' +
        this.project.customer.email,

      storageSystemManufacturer_storageSystemType: this.project.solarPlant.storageSystem
        ? this.project.solarPlant.storageSystem.manufacturer +
          ' - ' +
          this.project.solarPlant.storageSystem.type
        : '',

      storagePanelManufacturer_storagePanelType: this.project.solarPlant.solarPanel
        ? this.project.solarPlant.solarPanel.manufacturer +
          ' - ' +
          this.project.solarPlant.solarPanel.type
        : '',

      /** Wechselrichter (Hersteller - Typ) */
      inverterManufacturer_inverterType:
        this.project.solarPlant.storageSystem && this.project.solarPlant.storageSystem.inverter
          ? this.project.solarPlant.storageSystem.inverter.manufacturer +
            ' - ' +
            this.project.solarPlant.storageSystem.inverter.type
          : '',

      /** Batteriespeicher (Hersteller - Typ) */
      batteryManufacturer_batteryType:
        this.project.solarPlant.storageSystem && this.project.solarPlant.storageSystem.battery
          ? this.project.solarPlant.storageSystem.battery.manufacturer +
            ' - ' +
            this.project.solarPlant.storageSystem.battery.type
          : '',

      /** EnFluRi (Hersteller - Typ) */
      energyFlowDirectionSensorManufacturer_energyFlowDirectionSensorType:
        this.project.solarPlant.storageSystem &&
        this.project.solarPlant.storageSystem.energyFlowDirectionSensor
          ? this.project.solarPlant.storageSystem.energyFlowDirectionSensor.manufacturer +
            ' - ' +
            this.project.solarPlant.storageSystem.energyFlowDirectionSensor.type
          : '',

      /** EVU - PLZ Ort */
      powerCompanyZip_powerCompanyCity: this.project.powerCompany
        ? this.project.powerCompany.zip + ' ' + this.project.powerCompany.city
        : '',
    };
  }

  /**
   * Translate constants like salutations etc.
   */
  translate() {
    if (this.project.customer.salutation) {
      this.project.customer.salutation = i18n.t(this.project.customer.salutation);
    }
  }

  /**
   * Format date values
   */
  date() {
    this.project.customer.birthdayAt = this.project.customer.birthdayAt
      ? // ? dayjs(this.project.customer.birthdayAt).format('DD.MM.YYYY')
        dayjs(this.project.customer.birthdayAt)
          .tz('Europe/Berlin')
          .format('DD.MM.YYYY')
      : '';

    this.project.deliveryAt = this.project.deliveryAt
      ? dayjs(this.project.deliveryAt)
          .tz('Europe/Berlin')
          .format('DD.MM.YYYY')
      : '';

    this.project.installation.operationRegistrationAt = this.project.installation
      .operationRegistrationAt
      ? dayjs(this.project.installation.operationRegistrationAt)
          .tz('Europe/Berlin')
          .format('DD.MM.YYYY')
      : '';
  }

  /**
   * Calculate values
   */
  calculation() {
    if (this.project.solarPlant.storageSystem) {
      // Gesamt-Speicherkapazität (kWh)
      this.project.solarPlant.storageSystem.overallCapacity =
        this.project.solarPlant.storageSystem && this.project.solarPlant.storageSystem.battery
          ? this.project.solarPlant.storageSystem.batteryCount *
            this.project.solarPlant.storageSystem.battery.capacity
          : '';

      // Lieferleistung nom. (kW)
      this.project.solarPlant.storageSystem.inverterOverallPower = this.project.solarPlant
        .storageSystem
        ? this.project.solarPlant.storageSystem.inverterCount *
          this.project.solarPlant.storageSystem.inverter.apparentPower
        : '';

      /** Lieferleistung 70% (kW) */
      this.project.solarPlant.storageSystem.inverterOverallPower70 = this.project.solarPlant
        .storageSystem
        ? this.project.solarPlant.storageSystem.inverterCount *
          this.project.solarPlant.storageSystem.inverter.apparentPower *
          0.7
        : '';

      /** Wechselrichter Gesamtleistung */
      this.project.solarPlant.storageSystem.overallInverterPower =
        this.project.solarPlant.storageSystem.inverterCount *
        this.project.solarPlant.storageSystem.inverter.apparentPower;

      /** Wechselrichter - Wirkleistung je Phase (Wirkleistung/3) */
      this.project.solarPlant.storageSystem.inverter.activePowerPerPhase = this.project.solarPlant
        .storageSystem
        ? this.project.solarPlant.storageSystem.inverter.activePower / 3
        : '';
    }

    if (this.project.solarPlant.solarPanel) {
      /** Solarmodul - Leistung pro Modul (kWp) - Divide Module Power by 1000 */
      this.project.solarPlant.solarPanel.powerKwp = this.project.solarPlant.solarPanel.power / 1000;

      /** Solarmodul - Gesamtleistung (kWp) */
      this.project.solarPlant.solarPlantOverallPower =
        (this.project.solarPlant.solarPanelCount * this.project.solarPlant.solarPanel.power) / 1000;
    }
  }

  /**
   * Get first contact of the assigned electrician
   * @todo error fallback if no contact or attachments exists
   **/
  electrician() {
    if (!this.project.electrician) {
      this.project.electrician = {
        name: '',
        certificateNumber: '',
        street: '',
        streetNumber: '',
        zip: '',
        city: '',
        registeredIn: '',
        contacts: {
          firstname: '',
          lastname: '',
          position: '',
          phone: '',
          mobile: '',
          email: '',
        },
      };
    }
    this.project.electrician.firstContact =
      this.project.electrician.contacts.length > 0
        ? this.project.electrician.contacts[this.project.electrician.contacts.length - 1]
        : { firstname: '', lastname: '', phone: '', email: '', mobile: '', position: '' };

    /** Elektrofachkraft - Firma, Vorname Nachname */
    this.project.composite.electricianCompany_electricianFirstname_electricianLastname =
      this.project.electrician.name +
      ', ' +
      this.project.electrician.firstContact.firstname +
      ' ' +
      this.project.electrician.firstContact.lastname;

    /** Elektrofachkraft - Vorname Nachname */
    this.project.composite.electricianFirstname_electricianLastname =
      this.project.electrician.firstContact.firstname +
      ' ' +
      this.project.electrician.firstContact.lastname;

    /** Elektrofachkraft - PLZ Ort */
    this.project.composite.electricianZip_electricianCity =
      this.project.electrician.zip + ' ' + this.project.electrician.city;

    /** Elektrofachkraft - Straße Hausnummer */
    this.project.composite.electricianStreet_electricianStreetNumber =
      this.project.electrician.street + ' ' + this.project.electrician.streetNumber;

    /** Elektrofachkraft - Telefon, Email */
    this.project.composite.electricianPhone_electricianEmail =
      this.project.electrician.firstContact.phone +
      ' ' +
      this.project.electrician.firstContact.email;

    /** Elektrofachkraft - Firma, Ort */
    this.project.composite.electricianCompany_electricianCity =
      this.project.electrician.name + ', ' + this.project.electrician.city;

    /** Elektrofachkraft - Eingetragen bei, Ausweisnummer */
    this.project.composite.electricianCertificateNumber_electricianRegisteredIn =
      this.project.electrician.registeredIn + ', ' + this.project.electrician.certificateNumber;

    this.project.electrician.signature =
      this.project.electrician.signatureAttachments &&
      this.project.electrician.signatureAttachments.length > 0
        ? this.project.electrician.signatureAttachments[0].path
        : null;

    this.project.electrician.stamp =
      this.project.electrician.stampAttachments &&
      this.project.electrician.stampAttachments.length > 0
        ? this.project.electrician.stampAttachments[0].path
        : null;
  }
}
