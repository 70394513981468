var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"nav"},[_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Dashboard","link":"/dashboard","iconName":"flaticon-home","index":"dashboard","isHeader":""}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Projekte","link":"/projekte","iconName":"flaticon-star","index":"projects2","isHeader":""}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Tourenplanung Liste","link":"/tourenplanung","iconName":"flaticon-navigation","index":"tourenplanung","isHeader":""}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Terminplanung","link":"/planungskalender","iconName":"flaticon-calendar","index":"terminplanung","isHeader":""}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Termin Slots","link":"/terminkapazitaet","iconName":"flaticon-cloud","index":"terminplanung","isHeader":""}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Aufgaben","link":"/aufgaben","iconName":"flaticon-umbrella","index":"aufgaben","isHeader":""}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Kalender","link":"/kalender","iconName":"flaticon-calendar","index":"terminplanung","isHeader":""}}),(_vm.user && _vm.authorized)?_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Sales","link":"/sales/","iconName":"flaticon-binoculars","index":"sales","isHeader":"","childrenLinks":[{ header: 'Overview', link: '/sales/overview', index: 'AdminSales' }]}}):_vm._e(),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Personalverwaltung","link":"/personalverwaltung","iconName":"flaticon-users","index":"personalverwaltung","isHeader":"","childrenLinks":[
      { header: 'Benutzer', link: '/benutzer', index: 'benutzer' },
      { header: 'Mitarbeiter', link: '/mitarbeiter', index: 'mitarbeiter' },
      { header: 'Mandanten', link: '/mandanten', index: 'mandanten' },
      { header: 'Auftraggeber', link: '/auftraggeber', index: 'auftraggeber' },
      { header: 'EVU', link: '/evu', index: 'evu' },
      { header: 'Elektrofachkräfte', link: '/elektrofachkraft', index: 'elektrofachkraft' },
      {
        header: 'Messstellenbetreiber',
        link: '/messstellenbetreiber',
        index: 'messstellenbetreiber',
      },
    ]}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Anlagen","link":"/anlagen","iconName":"flaticon-power","index":"anlagen","isHeader":"","childrenLinks":[
      { header: 'Batteriespeicher', link: '/batterien', index: 'batterien' },
      {
        header: 'Wechselrichter',
        link: '/wechselrichter',
        index: 'wechselrichter',
      },
      { header: 'Module', link: '/module', index: 'module' },
      {
        header: 'EnFluRi',
        link: '/energieflussrichtungssensoren',
        index: 'energieflussrichtungssensoren',
      },
      { header: 'Wallboxen', link: '/wallboxen', index: 'wallboxen' },
      {
        header: 'Speichersysteme (alt)',
        link: '/speichersysteme',
        index: 'speichersysteme',
      },
    ]}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Material","link":"/material","iconName":"flaticon-price-tag","index":"material","isHeader":"","childrenLinks":[
      {
        header: 'Material Stammdaten',
        link: '/material/stammdaten',
        index: 'materialstammdaten',
      },
      {
        header: 'Lager',
        link: '/material/lager',
        index: 'materiallager',
      },
    ]}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Berichte","link":"/berichte","iconName":"flaticon-diploma","index":"berichte","isHeader":"","childrenLinks":[
      {
        header: 'Beispielbilder',
        link: '/berichte/beispielbilder',
        index: 'berichtebeispielbilder',
      },
    ]}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Editor","link":"/dokumente","iconName":"flaticon-document","index":"evu","isHeader":""}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Angebote","link":"/angebote","iconName":"flaticon-note","index":"angebote","isHeader":""}}),(_vm.user && _vm.authorized)?_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Academy","link":"/academy","iconName":"flaticon-diploma","index":"academy","isHeader":"","childrenLinks":[
      {
        header: 'Course',
        link: '/academy/kurse',
        index: 'kurse',
      },
      {
        header: 'Schüler',
        link: '/academy/schueler',
        index: 'students',
      },
      {
        header: 'Trainer',
        link: '/academy/trainer',
        index: 'students',
      },
      {
        header: 'Academy',
        link: '/academy',
        index: 'academy-page',
      },
      {
        header: 'Stundenplantermine',
        link: '/academy/lehrplan',
        index: 'academy-lehrplan',
      },
      {
        header: 'Calendar',
        link: '/academy/stundenplan',
        index: 'academy-calendar',
      },
      {
        header: 'Lehrinhalte',
        link: '/academy/lehrmaterial',
        index: 'lehrmaterial',
      },
      {
        header: 'Klasseninhalte',
        link: '/academy/klassen',
        index: 'klassen',
      },
    ]}}):_vm._e(),(_vm.user && _vm.authorized)?_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Bewerber","link":"/bewerber","iconName":"flaticon-radar","index":"aufgaben","isHeader":""}}):_vm._e(),(_vm.user && _vm.authorized)?_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Zeiterfassung","link":"/zeiterfassung/","iconName":"flaticon-binoculars","index":"adminTimeTracking","isHeader":""}}):_vm._e(),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Aktivitätsprotokoll","link":"/aktivitaetsprotokoll","iconName":"flaticon-note","index":"aktivitaetsprotokoll","isHeader":""}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":"Updates","link":"/updates","iconName":"flaticon-idea","index":"updates","isHeader":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }