import Vue from 'vue';
import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const defaultPowerCompany = () => {
  return {
    _id: '_new',
    number: '_new',
    company: '',
    website: '',
    description: '',
    documentsDescription: '',
    operatorOnSiteElectricMeterChange: 'NO',
    powgridRegistrationPerMailState: 'NO',
    powgridRegistrationOnlineState: 'NO',
    usePowerCompanyFormsState: 'NO',
    contacts: [],
    netRegistrationLinkedDocuments: [],
    operationRegistrationLinkedDocuments: [],
    documentAttachments: [],
  };
};

const getDefaultState = () => {
  return {
    powerCompanies: [],
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an powerCompany
 * used for all queries/mutations which return an powerCompany
 * @type {DocumentNode}
 */
const POWER_COMPANY_REQUEST_FIELDS = gql`
  fragment powerCompanyFields on PowerCompany {
    _id
    number
    company
    street
    zip
    city
    cosinusPhi
    registrationEmail
    registrationUrl
    description
    documentsDescription
    website
    operatorOnSiteElectricMeterChange
    powgridRegistrationPerMailState
    powgridRegistrationOnlineState
    usePowerCompanyFormsState
    powerCompanyFormsEmbeddedState
    operationRegistrationDocumentsApprovedState
    netRegistrationDocumentsApprovedState
    netRegistrationLinkedDocuments {
      id
      name
    }
    operationRegistrationLinkedDocuments {
      id
      name
    }
    documentAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    createdAt
    updatedAt
    contacts {
      firstname
      lastname
      position
      phone
      mobile
      email
    }
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} powerCompany
 */
const createPayload = (powerCompany) => {
  // remove __typename from position objects
  if (powerCompany.contacts) {
    powerCompany.contacts.map((contact) => delete contact.__typename);
  }

  const netRegistrationLinkedDocumentsIds = powerCompany.netRegistrationLinkedDocuments.map(
    (document) => {
      return document.id;
    }
  );
  const operationRegistrationLinkedDocumentsIds = powerCompany.operationRegistrationLinkedDocuments.map(
    (document) => {
      return document.id;
    }
  );

  /**
   * for text fields differentiate between "" and null: "" are sent to the server as empty value
   * deadlineAt date field: send NULL to server to remove a date
   */
  const payload = {
    ...(powerCompany.street ? { street: powerCompany.street } : {}),
    ...(powerCompany.zip !== null ? { zip: powerCompany.zip } : {}),
    ...(powerCompany.city !== null ? { city: powerCompany.city } : {}),
    ...(powerCompany.cosinusPhi !== null ? { cosinusPhi: powerCompany.cosinusPhi } : {}),
    ...(powerCompany.registrationUrl !== null
      ? { registrationUrl: powerCompany.registrationUrl }
      : {}),
    ...(powerCompany.registrationEmail !== null
      ? { registrationEmail: powerCompany.registrationEmail }
      : {}),
    ...(powerCompany.powgridRegistrationPerMailState !== null
      ? {
          powgridRegistrationPerMailState: powerCompany.powgridRegistrationPerMailState,
        }
      : {}),
    ...(powerCompany.operatorOnSiteElectricMeterChange !== null
      ? {
          operatorOnSiteElectricMeterChange: powerCompany.operatorOnSiteElectricMeterChange,
        }
      : {}),
    ...(powerCompany.powgridRegistrationOnlineState !== null
      ? {
          powgridRegistrationOnlineState: powerCompany.powgridRegistrationOnlineState,
        }
      : {}),
    ...(powerCompany.usePowerCompanyFormsState !== null
      ? { usePowerCompanyFormsState: powerCompany.usePowerCompanyFormsState }
      : {}),
    ...(powerCompany.powerCompanyFormsEmbeddedState !== null
      ? {
          powerCompanyFormsEmbeddedState: powerCompany.powerCompanyFormsEmbeddedState,
        }
      : {}),
    ...(powerCompany.netRegistrationDocumentsApprovedState !== null
      ? {
          netRegistrationDocumentsApprovedState: powerCompany.netRegistrationDocumentsApprovedState,
        }
      : {}),
    ...(powerCompany.operationRegistrationDocumentsApprovedState !== null
      ? {
          operationRegistrationDocumentsApprovedState:
            powerCompany.operationRegistrationDocumentsApprovedState,
        }
      : {}),
    company: powerCompany.company,
    description: powerCompany.description,
    documentsDescription: powerCompany.documentsDescription,
    website: powerCompany.website,
    contacts: powerCompany.contacts,
    netRegistrationLinkedDocuments: netRegistrationLinkedDocumentsIds,
    operationRegistrationLinkedDocuments: operationRegistrationLinkedDocumentsIds,
  };

  return payload;
};

/**
 * Actions
 */
const actions = {
  async fetchPowerCompanies({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            powerCompanies {
              ...powerCompanyFields
            }
          }
          ${POWER_COMPANY_REQUEST_FIELDS}
        `,
      });
      commit('SET_POWER_COMPANIES', response.data.powerCompanies);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchPowerCompaniesForClients({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            powerCompaniesForClients {
              ...powerCompanyFields
            }
          }
          ${POWER_COMPANY_REQUEST_FIELDS}
        `,
      });
      commit('SET_POWER_COMPANIES', response.data.powerCompaniesForClients);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchPowerCompanyByNumber({ commit, dispatch }, powerCompanyNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query powerCompanyByNumber($powerCompanyNumber: Int!) {
            powerCompanyByNumber(powerCompanyNumber: $powerCompanyNumber) {
              ...powerCompanyFields
            }
          }
          ${POWER_COMPANY_REQUEST_FIELDS}
        `,
        variables: {
          powerCompanyNumber: parseInt(powerCompanyNumber),
        },
      });
      commit('ADD_OR_UPDATE_POWER_COMPANY', response.data.powerCompanyByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async createPowerCompany({ commit, dispatch }, powerCompany) {
    const payload = createPayload(powerCompany);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createPowerCompany($payload: PowerCompanyInput!) {
            createPowerCompany(powerCompanyInput: $payload) {
              ...powerCompanyFields
            }
          }
          ${POWER_COMPANY_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'EVU gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_POWER_COMPANY', '_new'); // remove temporary _new powerCompany
      commit('ADD_OR_UPDATE_POWER_COMPANY', response.data.createPowerCompany);
      return response.data.createPowerCompany.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updatePowerCompany({ commit, dispatch }, powerCompany) {
    const payload = createPayload(powerCompany);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updatePowerCompany($powerCompanyNumber: Int!, $payload: PowerCompanyInput!) {
            updatePowerCompany(
              powerCompanyNumber: $powerCompanyNumber
              powerCompanyInput: $payload
            ) {
              ...powerCompanyFields
            }
          }
          ${POWER_COMPANY_REQUEST_FIELDS}
        `,
        variables: {
          powerCompanyNumber: powerCompany.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'EVU aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_POWER_COMPANY', response.data.updatePowerCompany);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },
  async deletePowerCompany({ commit, dispatch }, powerCompanyNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deletePowerCompany($powerCompanyNumber: Int!) {
            deletePowerCompany(powerCompanyNumber: $powerCompanyNumber)
          }
        `,
        variables: {
          powerCompanyNumber: powerCompanyNumber,
        },
      });
      dispatch('message', {
        message: 'EVU gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_POWER_COMPANY', powerCompanyNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Upload an image belonging to the powerCompany
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async uploadPowerCompanyAttachment({ commit, dispatch }, payload) {
    // console.log(payload);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation uploadPowerCompanyAttachment($attachmentInput: AttachmentWithTypeInput!) {
            uploadPowerCompanyAttachment(attachmentInput: $attachmentInput) {
              ...powerCompanyFields
            }
          }
          ${POWER_COMPANY_REQUEST_FIELDS}
        `,
        variables: {
          attachmentInput: {
            file: payload.file,
            referenceId: payload.parentId,
            referenceType: payload.parentType,
          },
        },
      });
      // console.log(response.data);
      // console.log(payload.parentType);
      commit('UPDATE_POWER_COMPANY_ATTACHMENTS', {
        updatedPowerCompany: response.data.uploadPowerCompanyAttachment,
        referenceType: payload.parentType,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Upload!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Delete PowerCompany Atrachment
   * @param commit
   * @param payload
   */
  async deletePowerCompanyAttachment({ commit, dispatch }, payload) {
    // console.log(payload)
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deletePowerCompanyAttachment(
            $powerCompanyId: ID!
            $attachmentId: ID!
            $attachmentType: String!
          ) {
            deletePowerCompanyAttachment(
              powerCompanyId: $powerCompanyId
              attachmentId: $attachmentId
              attachmentType: $attachmentType
            ) {
              ...powerCompanyFields
            }
          }
          ${POWER_COMPANY_REQUEST_FIELDS}
        `,
        variables: {
          powerCompanyId: payload.powerCompanyId,
          attachmentId: payload.attachmentId,
          attachmentType: payload.attachmentType,
        },
      });
      // console.log(response);
      commit('UPDATE_POWER_COMPANY', response.data.deletePowerCompanyAttachment);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new powerCompany object in store and return it
   * @param {*} param0
   * @param {*} powerCompany
   */
  initPowerCompany({ commit }) {
    commit('ADD_OR_UPDATE_POWER_COMPANY', defaultPowerCompany());
  },
  powerCompanyLocalUpdate({ commit }, powerCompany) {
    commit('ADD_OR_UPDATE_POWER_COMPANY', powerCompany);
  },

  powerCompanyCleanup({ commit }) {
    commit('REMOVE_POWER_COMPANY', '_new');
  },

  addEmptyPowerCompanyContact({ commit }, powerCompany) {
    commit('ADD_EMPTY_POWER_COMPANY_CONTACT', powerCompany);
  },
  removePowerCompanyContact({ commit }, index) {
    commit('REMOVE_POWER_COMPANY_CONTACT', index);
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_POWER_COMPANY_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_POWER_COMPANIES: (state, powerCompanies) =>
    powerCompanies ? (state.powerCompanies = powerCompanies) : (state.powerCompanies = []),
  ADD_POWER_COMPANY: (state, powerCompany) => state.powerCompanies.push(powerCompany),
  ADD_OR_UPDATE_POWER_COMPANY: (state, updatedPowerCompany) => {
    const existingPowerCompany = state.powerCompanies.find(
      (powerCompany) => powerCompany._id === updatedPowerCompany._id
    );
    if (existingPowerCompany) {
      Object.assign(existingPowerCompany, updatedPowerCompany);
    } else {
      state.powerCompanies.push(updatedPowerCompany);
    }
  },
  UPDATE_POWER_COMPANY: (state, updatedPowerCompany) => {
    const powerCompany = state.powerCompanies.find(
      (powerCompany) => powerCompany._id === updatedPowerCompany._id
    );
    Object.assign(powerCompany, updatedPowerCompany);
  },
  SET_POWER_COMPANY_TYPES: (state, powerCompanyTypes) => {
    state.powerCompanyTypes = [];
    powerCompanyTypes.map((powerCompanyType) => {
      state.powerCompanyTypes.push(powerCompanyType.name);
    });
  },
  REMOVE_POWER_COMPANY: (state, powerCompanyId) => {
    const index = state.powerCompanies.findIndex(
      (powerCompany) => powerCompany._id === powerCompanyId
    );
    state.powerCompanies.splice(index, 1);
  },
  ADD_EMPTY_POWER_COMPANY_CONTACT: (state, updatedPowerCompany) => {
    const company = state.powerCompanies.find(
      (powerCompany) => powerCompany.number == updatedPowerCompany.number
    );
    company.contacts.push({});
  },
  REMOVE_POWER_COMPANY_CONTACT: (state, { updatedPowerCompany, index }) => {
    const company = state.powerCompanies.find(
      (powerCompany) => powerCompany.number == updatedPowerCompany.number
    );
    company.contacts.splice(index, 1);
  },

  UPDATE_POWER_COMPANY_ATTACHMENTS: (state, { updatedPowerCompany, referenceType }) => {
    const powerCompany = state.powerCompanies.find(
      (powerCompany) => powerCompany._id === updatedPowerCompany._id
    );
    Vue.set(powerCompany, referenceType, updatedPowerCompany[referenceType]);
  },
};

/**
 * Getters
 */
const getters = {
  getPowerCompanies: (state) => state.powerCompanies,
  getPowerCompany: (state) => (powerCompanyId) =>
    state.powerCompanies.find((powerCompany) => powerCompany._id == powerCompanyId),
  getPowerCompanyByNumber: (state) => (powerCompanyNumber) =>
    state.powerCompanies.find((powerCompany) => powerCompany.number == powerCompanyNumber),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
