import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const defaultEmployer = () => {
  return {
    id: '_new',
    number: '_new',
    name: '',
  };
};

const getDefaultState = () => {
  return {
    employers: [],
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an employer
 * used for all queries/mutations which return an employer
 * @type {DocumentNode}
 */
const EMPLOYER_REQUEST_FIELDS = gql`
  fragment employerFields on Employer {
    id
    number
    name
    street
    zip
    city
    description
    createdAt
    updatedAt
    type
    client {
      id
      number
      name
    }
    contacts {
      gender
      firstname
      lastname
      position
      phone
      mobile
      email
    }
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} project
 */
const createPayload = (employer) => {
  // remove __typename from position objects
  if (employer.contacts) {
    employer.contacts.map((contact) => {
      delete contact.__typename;
    });
  }

  const payload = {
    ...(employer.name ? { name: employer.name } : {}),
    ...(employer.street ? { street: employer.street } : {}),
    ...(employer.zip !== null ? { zip: employer.zip } : {}),
    ...(employer.city !== null ? { city: employer.city } : {}),
    ...(employer.description !== null ? { description: employer.description } : {}),
    contacts: employer.contacts,
    client: employer.client ? employer.client.id : null,
  };
  return payload;
};

/**
 * Actions
 */
const actions = {
  async fetchEmployers({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            employers {
              ...employerFields
            }
          }
          ${EMPLOYER_REQUEST_FIELDS}
        `,
      });
      commit('SET_EMPLOYERS', response.data.employers);
    } catch (err) {
      throw new Error(err);
    }
  },

  /**
   * fetch all employers belonging to the currently logged in client
   * @param {*} param0
   * @param {*} clientId
   */
  async fetchEmployersByClient({ commit, dispatch }, clientId) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query employersByClient($clientId: ID!) {
            employersByClient(clientId: $clientId) {
              ...employerFields
            }
          }
          ${EMPLOYER_REQUEST_FIELDS}
        `,
        variables: {
          clientId: clientId,
        },
      });
      commit('SET_EMPLOYERS', response.data.employersByClient);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchEmployerByNumber({ commit, dispatch }, employerNumber) {
    try {
      // console.log(employerNumber);
      const response = await apolloClient.query({
        query: gql`
          query employerByNumber($employerNumber: Int!) {
            employerByNumber(employerNumber: $employerNumber) {
              ...employerFields
            }
          }
          ${EMPLOYER_REQUEST_FIELDS}
        `,
        variables: {
          employerNumber: parseInt(employerNumber),
        },
      });
      commit('ADD_OR_UPDATE_EMPLOYER', response.data.employerByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async createEmployer({ commit, dispatch }, employer) {
    const payload = createPayload(employer);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createEmployer($payload: EmployerInput!) {
            createEmployer(employerInput: $payload) {
              ...employerFields
            }
          }
          ${EMPLOYER_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Auftraggeber gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_EMPLOYER', '_new'); // remove temporary _new employer
      commit('ADD_OR_UPDATE_EMPLOYER', response.data.createEmployer);
      return response.data.createEmployer.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateEmployer({ commit, dispatch }, employer) {
    const payload = createPayload(employer);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateEmployer($employerNumber: Int!, $payload: EmployerInput!) {
            updateEmployer(employerNumber: $employerNumber, employerInput: $payload) {
              ...employerFields
            }
          }
          ${EMPLOYER_REQUEST_FIELDS}
        `,
        variables: {
          employerNumber: employer.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Auftraggeber aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_EMPLOYER', response.data.updateEmployer);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteEmployer({ commit, dispatch }, employerNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteEmployer($employerNumber: Int!) {
            deleteEmployer(employerNumber: $employerNumber)
          }
        `,
        variables: {
          employerNumber: employerNumber,
        },
      });
      dispatch('message', {
        message: 'Auftraggeber gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_EMPLOYER', employerNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new employer object in store and return it
   * @param {*} param0
   * @param {*} employer
   */
  async initEmployer({ commit }) {
    commit('ADD_OR_UPDATE_EMPLOYER', defaultEmployer());
  },

  employerLocalUpdate({ commit }, employer) {
    commit('ADD_OR_UPDATE_EMPLOYER', employer);
  },

  employerCleanup({ commit }) {
    commit('REMOVE_EMPLOYER', '_new');
  },
  addEmptyEmployerContact({ commit }, employer) {
    commit('ADD_EMPTY_EMPLOYER_CONTACT', employer);
  },
  removeEmployerContact({ commit }, index) {
    commit('REMOVE_EMPLOYER_CONTACT', index);
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_EMPLOYER_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_EMPLOYERS: (state, employers) =>
    employers ? (state.employers = employers) : (state.employers = []),
  ADD_EMPLOYER: (state, employer) => state.employers.push(employer),
  ADD_OR_UPDATE_EMPLOYER: (state, updatedEmployer) => {
    const existingEmployer = state.employers.find((employer) => employer.id === updatedEmployer.id);
    if (existingEmployer) {
      Object.assign(existingEmployer, updatedEmployer);
    } else {
      state.employers.push(updatedEmployer);
    }
  },
  REMOVE_EMPLOYER: (state, employerId) => {
    const index = state.employers.findIndex((employer) => employer.id === employerId);
    state.employers.splice(index, 1);
  },
  ADD_EMPTY_EMPLOYER_CONTACT: (state, updatedEmployer) => {
    const company = state.employers.find((employer) => employer.number == updatedEmployer.number);
    company.contacts.push({});
  },
  REMOVE_EMPLOYER_CONTACT: (state, { updatedEmployer, index }) => {
    const company = state.employers.find((employer) => employer.number == updatedEmployer.number);
    company.contacts.splice(index, 1);
  },
};

/**
 * Getters
 */
const getters = {
  getEmployers: (state) => state.employers,
  getEmployer: (state) => (employerId) =>
    state.employers.find((employer) => employer.id == employerId),
  getEmployerByNumber: (state) => (employerNumber) =>
    state.employers.find((employer) => employer.number == employerNumber),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
