import common from './common';
import customer from './customer';
import storageSystem from './storageSystem';
import solarPanel from './solarPanel';
import inverter from './inverter';
import inverter1 from './inverter1';
import inverter2 from './inverter2';
import inverterCommon from './inverterCommon';
import battery from './battery';
import batteryOld from './batteryOld';
import enfluri from './enfluri';
import enfluriOld from './enfluriOld';
import wallbox from './wallbox';
import extendedConfiguration from './extendedConfiguration';
import electrician from './electrician';
import powerCompany from './powerCompany';

const solarPlant = {
  key: '1',
  label: 'solarPlant',
  children: [
    battery,
    enfluri,
    solarPanel,
    wallbox,
    inverterCommon,
    inverter1,
    inverter2,
    extendedConfiguration,
    batteryOld,
    enfluriOld,
    storageSystem,
    inverter,
  ],
};

const documentFieldDefinitions = [common, customer, solarPlant, electrician, powerCompany];

export default documentFieldDefinitions;
