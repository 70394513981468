<template>
  <nav
    :class="{ sidebar: true, sidebarStatic, sidebarOpened }"
    @mouseenter="sidebarMouseEnter"
    @mouseleave="sidebarMouseLeave"
  >
    <header class="logo">
      <router-link :to="{ name: 'home' }">
        <img class="mb-1 mr-3 mt-3 sidebar-logo" src="../../assets/logo2.png" alt="Retis Energy" />
      </router-link>
    </header>

    <div v-if="isAdmin" class="nav-container">
      <AdminNavigation :activeItem="activeItem" />
    </div>

    <div v-if="isClient" class="nav-container">
      <ClientNavigation :activeItem="activeItem" />
    </div>

    <div class="sidebar-footer">
      <span v-if="isAdmin">
        <h5 class="navTitle">Q Power Solution GmbH</h5>
      </span>
      <ul class="sidebarLabels">
        <li>{{ getCurrentUser.firstname }} {{ getCurrentUser.lastname }}</li>
        <li>
          <a href="#">
            <i class="fa fa-circle text-warning mr-2" />
            <span class="labelName">Version {{ version }}</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapState, mapActions, createNamespacedHelpers } from 'vuex';
import isScreen from '@/core/screenHelper';
import AdminNavigation from './AdminNavigation';
import ClientNavigation from './ClientNavigation';

const { mapGetters } = createNamespacedHelpers('auth');

export default {
  name: 'Sidebar',
  components: {
    AdminNavigation,
    ClientNavigation,
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      showDemoContent: false,
    };
  },
  methods: {
    ...mapActions('layout', ['changeSidebarActive', 'switchSidebar']),
    setActiveByRoute() {
      const paths = this.$route.fullPath.split('/');
      paths.pop();
      this.changeSidebarActive(paths.join('/'));
    },
    sidebarMouseEnter() {
      if (!this.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
        this.switchSidebar(false);
        this.setActiveByRoute();
      }
    },
    sidebarMouseLeave() {
      if (!this.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      }
    },
  },
  created() {
    this.setActiveByRoute();
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'isAdmin', 'isClient']),
    ...mapState('layout', {
      sidebarStatic: (state) => state.sidebarStatic,
      sidebarOpened: (state) => !state.sidebarClose,
      activeItem: (state) => state.sidebarActiveElement,
    }),
  },
};
</script>

<!-- Sidebar styles should be scoped -->
<style src="./Sidebar.scss" lang="scss" scoped />
