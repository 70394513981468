export default {
  // Allgemein
  key: '3',
  label: 'common',
  children: [
    { key: '3.0', name: 'common.date', label: 'common.netRegistrationDate' },
    {
      key: '3.1',
      name: 'common.signature',
      label: 'common.customerSignature',
      width: 225,
      height: 60,
    },
    { key: '3.2', name: 'common.measuringConcept', label: 'measuringConcept' },
    { key: '3.3', name: 'common.selection', label: 'selection', width: 10, height: 10 },
    { key: '3.4', name: 'deliveryAt', label: 'deliveryAt' },
    {
      key: '3.5',
      name: 'composite.customerCity_deliveryAt',
      label: 'composite.customerCity_deliveryAt',
    },
    {
      key: '3.6',
      name: 'composite.customerCity_operationRegistrationInstallationAppointment',
      label: 'composite.customerCity_operationRegistrationInstallationAppointment',
    },
    // {
    //   key: '3.8',
    //   name: 'installation.operationRegistrationInstallationAppointment.installationAt',
    //   label: 'common.operationRegistrationInstallationAppointment',
    // },
    {
      key: 'installation.operationRegistrationAt',
      name: 'installation.operationRegistrationAt',
      label: 'common.operationRegistrationInstallationAppointment',
    },
    {
      key: 'common.operationRegistrationAt_today_plus_20_days',
      name: 'common.operationRegistrationAt_today_plus_20_days',
      label: 'common.operationRegistrationAt_today_plus_20_days',
    },
    { key: '3.7', name: 'common.freeText', label: 'freeText' },
  ],
};
