/**
 * Prepares the payload for sending to a GraphQL server by applying type validations based on the given type configuration.
 *
 * @param {Object} project - The project object to be validated.
 * @param {Object} typeConfig - The configuration object containing property names and their types.
 * @returns {Object} - The prepared payload to be sent to the GraphQL server with modified properties.
 */
export const preparePayloadForGraphQL = (project, typeConfig) => {
  const payload = {};

  for (const prop in typeConfig) {
    const type = typeConfig[prop];
    if (type === 'date') {
      validateDateProperty(payload, project, prop);
    } else if (type === 'string') {
      validateStringProperty(payload, project, prop);
    } else if (type === 'id') {
      validateIdProperty(payload, project, prop);
    }
  }
  return payload;
};

/**
 * Validates a date property and sets it to null if it's an empty string.
 *
 * @param {Object} payload - The payload object to be validated.
 * @param {Object} project - The project object to be used for validation.
 * @param {string} prop - The name of the property to be validated.
 */
const validateDateProperty = (payload, project, prop) => {
  if (project[prop] === '') {
    payload[prop] = null;
  } else {
    payload[prop] = project[prop];
  }
};

/**
 * Validates a string property.
 *
 * @param {Object} payload - The payload object to be validated.
 * @param {Object} project - The project object to be used for validation.
 * @param {string} prop - The name of the property to be validated.
 */
const validateStringProperty = (payload, project, prop) => {
  payload[prop] = project[prop];
};

/**
 * Converts a subdocument to just its ID, like it is saved in the database
 * keep as is if ID is already an ObjectID string
 *
 * @param {Object} payload - The payload object to be validated.
 * @param {Object} project - The project object to be used for validation.
 * @param {string} prop - The name of the property to be validated.
 */
export const validateIdProperty = (payload, srcObject, prop) => {
  if (typeof srcObject[prop] === 'string') {
    payload[prop] = srcObject[prop];
  } else if (Object.prototype.hasOwnProperty.call(srcObject, prop)) {
    const id = srcObject[prop]?.id || srcObject[prop]?._id || null; // If the property is not present in the srcObject object, it will not be added to the payload
    payload[prop] = id;
  }
};
