export default {
  // Wallbox
  key: '1.9',
  label: 'wallbox',
  children: [
    {
      key: '1.9.0',
      name: 'solarPlant.wallbox_manufacturer_type',
      label: 'manufacturer_type',
    },
    {
      key: '1.9.1',
      name: 'solarPlant.wallbox.manufacturer',
      label: 'manufacturer',
    },
    {
      key: '1.9.2',
      name: 'solarPlant.wallbox.type',
      label: 'type',
    },
    {
      key: '1.9.3',
      name: 'solarPlant.wallbox.maxNetInputPower',
      label: 'maxNetInputPower',
    },
    {
      key: '1.9.4',
      name: 'solarPlant.wallbox.maxNetOutputPower',
      label: 'maxNetOutputPower',
    },
    {
      key: '1.9.5',
      name: 'solarPlant.wallbox.minChargingPower',
      label: 'minChargingPower',
    },
    {
      key: '1.9.6',
      name: 'solarPlant.wallbox.maxChargingPower',
      label: 'maxChargingPower',
    },
    {
      key: '1.9.7',
      name: 'solarPlant.wallbox.activePowerControllable',
      label: 'activePowerControllable',
    },
    {
      key: '1.9.8',
      name: 'solarPlant.wallbox.chargingType',
      label: 'chargingType',
    },
    {
      key: 'solarPlant.wallbox.connectionTypes',
      name: 'solarPlant.wallbox.connectionTypes',
      label: 'connectionTypes',
      type: 'ARRAY',
    },
    {
      key: 'solarPlant.wallboxQuantity',
      name: 'solarPlant.wallboxQuantity',
      label: 'wallboxQuantity',
      type: 'INTEGER',
    },
    {
      key: 'solarPlant.wallboxChargingPointQuantity',
      name: 'solarPlant.wallboxChargingPointQuantity',
      label: 'wallboxChargingPointQuantity',
      type: 'INTEGER',
    },
  ],
};
