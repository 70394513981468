import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const defaultStorageSystem = () => {
  return {
    id: '_new',
    number: '_new',
    manufacturer: '',
    type: '',
    inverterCount: 0,
    energyFlowDirectionSensorCount: 0,
    batteryCount: 0,
    attachments: [],
  };
};

const getDefaultState = () => {
  return {
    storageSystems: [],
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an storageSystem
 * used for all queries/mutations which return an storageSystem
 * @type {DocumentNode}
 */
const STORAGE_SYSTEM_REQUEST_FIELDS = gql`
  fragment storageSystemFields on StorageSystem {
    id
    number
    manufacturer
    type
    inverterCount
    energyFlowDirectionSensorCount
    batteryCount
    createdAt
    updatedAt
    battery {
      id
      number
      manufacturer
      type
      capacity
      dischargePower
      batteryModuleCount
      batteryModuleCapacity
    }
    inverter {
      id
      number
      manufacturer
      type
      apparentPower
      activePower
      ratedCurrent
      shortCircuitCurrent
      nominalDcPower
      allowedChargeDischargeCurrent
    }
    energyFlowDirectionSensor {
      id
      number
      manufacturer
      type
    }
    attachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} project
 */
const createPayload = (storageSystem) => {
  /**
   * for text fields differentiate between "" and null: "" are sent to the server as empty value
   * deadlineAt date field: send NULL to server to remove a date
   */
  const payload = {
    ...(storageSystem.manufacturer ? { manufacturer: storageSystem.manufacturer } : {}),
    ...(storageSystem.type ? { type: storageSystem.type } : {}),
    ...(storageSystem.apparentPower
      ? { apparentPower: parseFloat(storageSystem.apparentPower) }
      : {}),
    ...(storageSystem.activePower ? { activePower: parseFloat(storageSystem.activePower) } : {}),
    ...(storageSystem.inverterCount
      ? { inverterCount: parseFloat(storageSystem.inverterCount) }
      : {}),
    ...(storageSystem.energyFlowDirectionSensorCount
      ? {
          energyFlowDirectionSensorCount: parseInt(storageSystem.energyFlowDirectionSensorCount),
        }
      : {}),
    ...(storageSystem.batteryCount ? { batteryCount: parseInt(storageSystem.batteryCount) } : {}),
    inverter: storageSystem.inverter ? storageSystem.inverter.id : null,
    battery: storageSystem.battery ? storageSystem.battery.id : null,
    energyFlowDirectionSensor: storageSystem.energyFlowDirectionSensor
      ? storageSystem.energyFlowDirectionSensor.id
      : null,
  };

  return payload;
};

/**
 * Actions
 */
const actions = {
  async fetchStorageSystems({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            storageSystems {
              ...storageSystemFields
            }
          }
          ${STORAGE_SYSTEM_REQUEST_FIELDS}
        `,
      });
      commit('SET_STORAGE_SYSTEMS', response.data.storageSystems);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchStorageSystemByNumber({ commit, dispatch }, storageSystemNumber) {
    try {
      // console.log(storageSystemNumber);
      const response = await apolloClient.query({
        query: gql`
          query storageSystemByNumber($storageSystemNumber: Int!) {
            storageSystemByNumber(storageSystemNumber: $storageSystemNumber) {
              ...storageSystemFields
            }
          }
          ${STORAGE_SYSTEM_REQUEST_FIELDS}
        `,
        variables: {
          storageSystemNumber: parseInt(storageSystemNumber),
        },
      });
      commit('ADD_OR_UPDATE_STORAGE_SYSTEM', response.data.storageSystemByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async createStorageSystem({ commit, dispatch }, storageSystem) {
    const payload = createPayload(storageSystem);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createStorageSystem($payload: StorageSystemInput!) {
            createStorageSystem(storageSystemInput: $payload) {
              ...storageSystemFields
            }
          }
          ${STORAGE_SYSTEM_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Speichersystem gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_STORAGE_SYSTEM', '_new'); // remove temporary _new storageSystem
      commit('ADD_OR_UPDATE_STORAGE_SYSTEM', response.data.createStorageSystem);
      return response.data.createStorageSystem.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateStorageSystem({ commit, dispatch }, storageSystem) {
    const payload = createPayload(storageSystem);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateStorageSystem($storageSystemNumber: Int!, $payload: StorageSystemInput!) {
            updateStorageSystem(
              storageSystemNumber: $storageSystemNumber
              storageSystemInput: $payload
            ) {
              ...storageSystemFields
            }
          }
          ${STORAGE_SYSTEM_REQUEST_FIELDS}
        `,
        variables: {
          storageSystemNumber: storageSystem.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Speichersystem aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_STORAGE_SYSTEM', response.data.updateStorageSystem);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteStorageSystem({ commit, dispatch }, storageSystemNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteStorageSystem($storageSystemNumber: Int!) {
            deleteStorageSystem(storageSystemNumber: $storageSystemNumber)
          }
        `,
        variables: {
          storageSystemNumber: storageSystemNumber,
        },
      });
      dispatch('message', {
        message: 'Speichersystem gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_STORAGE_SYSTEM', storageSystemNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Upload an image belonging to the storageSystem
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async uploadStorageSystemAttachment({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation uploadStorageSystemAttachment($attachmentInput: AttachmentInput!) {
            uploadStorageSystemAttachment(attachmentInput: $attachmentInput) {
              ...storageSystemFields
            }
          }
          ${STORAGE_SYSTEM_REQUEST_FIELDS}
        `,
        variables: {
          attachmentInput: {
            file: payload.file,
            referenceId: payload.parentId,
          },
        },
      });
      commit('UPDATE_STORAGE_SYSTEM', response.data.uploadStorageSystemAttachment);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Upload!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Delete StorageSystem Atrachment
   * @param commit
   * @param payload
   */
  async deleteStorageSystemAttachment({ commit, dispatch }, payload) {
    // console.log(payload)
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteStorageSystemAttachment($storageSystemId: ID!, $attachmentId: ID!) {
            deleteStorageSystemAttachment(
              storageSystemId: $storageSystemId
              attachmentId: $attachmentId
            ) {
              ...storageSystemFields
            }
          }
          ${STORAGE_SYSTEM_REQUEST_FIELDS}
        `,
        variables: {
          storageSystemId: payload.storageSystemId,
          attachmentId: payload.attachmentId,
        },
      });
      // console.log(response)
      commit('UPDATE_STORAGE_SYSTEM', response.data.deleteStorageSystemAttachment);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new storageSystem object in store and return it
   * @param {*} param0
   * @param {*} storageSystem
   */
  async initStorageSystem({ commit }) {
    commit('ADD_OR_UPDATE_STORAGE_SYSTEM', defaultStorageSystem());
  },

  storageSystemLocalUpdate({ commit }, storageSystem) {
    commit('ADD_OR_UPDATE_STORAGE_SYSTEM', storageSystem);
  },

  storageSystemCleanup({ commit }) {
    commit('REMOVE_STORAGE_SYSTEM', '_new');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_STORAGE_SYSTEM_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_STORAGE_SYSTEMS: (state, storageSystems) =>
    storageSystems ? (state.storageSystems = storageSystems) : (state.storageSystems = []),
  ADD_STORAGE_SYSTEM: (state, storageSystem) => state.storageSystems.push(storageSystem),
  ADD_OR_UPDATE_STORAGE_SYSTEM: (state, updatedStorageSystem) => {
    const existingStorageSystem = state.storageSystems.find(
      (storageSystem) => storageSystem.id === updatedStorageSystem.id
    );
    if (existingStorageSystem) {
      Object.assign(existingStorageSystem, updatedStorageSystem);
    } else {
      state.storageSystems.push(updatedStorageSystem);
    }
  },
  SET_STORAGE_SYSTEM_TYPES: (state, storageSystemTypes) => {
    state.storageSystemTypes = [];
    storageSystemTypes.map((storageSystemType) => {
      state.storageSystemTypes.push(storageSystemType.name);
    });
  },
  REMOVE_STORAGE_SYSTEM: (state, storageSystemId) => {
    const index = state.storageSystems.findIndex(
      (storageSystem) => storageSystem.id === storageSystemId
    );
    state.storageSystems.splice(index, 1);
  },
  UPDATE_STORAGE_SYSTEM: (state, updatedStorageSystem) => {
    const storageSystem = state.storageSystems.find(
      (storageSystem) => storageSystem.id === updatedStorageSystem.id
    );
    Object.assign(storageSystem, updatedStorageSystem);
  },
};

/**
 * Getters
 */
const getters = {
  getStorageSystems: (state) => state.storageSystems,
  getStorageSystem: (state) => (storageSystemId) =>
    state.storageSystems.find((storageSystem) => storageSystem.id == storageSystemId),
  getStorageSystemByNumber: (state) => (storageSystemNumber) =>
    state.storageSystems.find((storageSystem) => storageSystem.number == storageSystemNumber),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
