export default {
  // Elektrofachkraft
  key: '2',
  label: 'electrician',
  children: [
    { key: '2.0', name: 'electrician.name', label: 'company' },
    {
      key: '2.1',
      name: 'composite.electricianCompany_electricianCity',
      label: 'composite.electricianCompany_electricianCity',
    },
    {
      key: '2.2',
      name: 'composite.electricianCompany_electricianFirstname_electricianLastname',
      label: 'composite.electricianCompany_electricianFirstname_electricianLastname',
    },
    {
      key: '2.5',
      name: 'composite.electricianFirstname_electricianLastname',
      label: 'composite.electricianFirstname_electricianLastname',
    },
    { key: '2.6', name: 'electrician.street', label: 'street' },
    { key: '2.19', name: 'electrician.streetNumber', label: 'streetNumber' },
    {
      key: '2.20',
      name: 'composite.electricianStreet_electricianStreetNumber',
      label: 'composite.electricianStreet_electricianStreetNumber',
    },
    { key: '2.7', name: 'electrician.zip', label: 'zip' },
    { key: '2.8', name: 'electrician.city', label: 'city' },
    {
      key: '2.9',
      name: 'composite.electricianZip_electricianCity',
      label: 'composite.electricianZip_electricianCity',
    },
    {
      key: '2.13',
      name: 'composite.electricianPhone_electricianEmail',
      label: 'composite.electricianPhone_electricianEmail',
    },
    { key: '2.14', name: 'electrician.certificateNumber', label: 'certificateNumber' },
    { key: '2.15', name: 'electrician.registeredIn', label: 'registeredIn' },
    {
      key: '2.16',
      name: 'composite.electricianCertificateNumber_electricianRegisteredIn',
      label: 'composite.electricianCertificateNumber_electricianRegisteredIn',
    },
    { key: '2.17', name: 'electrician.signature', label: 'signature', height: 60 },
    { key: '2.18', name: 'electrician.stamp', label: 'stamp', height: 80 },
    { key: '2.3', name: 'electrician.firstContact.firstname', label: 'firstname' },
    { key: '2.4', name: 'electrician.firstContact.lastname', label: 'lastname' },
    { key: '2.10', name: 'electrician.firstContact.phone', label: 'phone' },
    { key: '2.11', name: 'electrician.firstContact.email', label: 'email' },
    { key: '2.12', name: 'electrician.firstContact.mobile', label: 'mobile' },
    {
      key: 'composite.electricianGuestEntryNumber',
      name: 'composite.electricianGuestEntryNumber',
      label: 'guestEntryNumber',
    },
  ],
};
