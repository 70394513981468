import store from '@/store';
import Vue from 'vue';

/**
 * Show flash messages in frontend
 */

/**
 * State
 */
const getDefaultState = () => {
  return {
    flashMessage: {
      message: null,
      type: 'success',
      error: false,
      lifetime: 1,
    },
  };
};
const state = getDefaultState();

/**
 * Actions
 */
const actions = {
  /**
   * Reset state in module
   * @param commit
   */
  reset({ commit }) {
    commit('SET_DEFAULT_STATE');
  },

  message({ commit }, message) {
    commit('SET_FLASH_MESSAGE', message);
  },

  /**
   * Let the message disappear
   * @param commit
   * @returns {Promise<void>}
   */
  async fadeFlashMessage({ commit }) {
    commit('DELETE_FLASH_MESSAGE');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_MESSAGE_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_FLASH_MESSAGE: async (state, msg) => {
    const timePrefix = '<em>' + Vue.prototype.$timeWithSeconds() + ' -</em> ';
    const typePrefix = msg.type == 'danger' ? 'Fehler: ' : '';
    state.flashMessage.message = timePrefix + typePrefix + msg.message;
    state.flashMessage.type = msg.type;
    state.flashMessage.lifetime = msg.lifetime;
  },
  DELETE_FLASH_MESSAGE: async (state) => {
    state.flashMessage.lifetime -= 1;

    if (state.flashMessage.lifetime <= 0) {
      Object.assign(state, getDefaultState());
    }
  },
};

/**
 * Getters
 */
const getters = {
  getFlashMessage: (state) => state.flashMessage,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
