import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const defaultOrder = () => {
  return {
    _id: '_new',
    number: '_new',
    name: '',
    item: [],
    orderType: 'VEHICLE',
  };
};

const getDefaultState = () => {
  return {
    orders: [],
    orderCount: 0,
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an order
 * used for all queries/mutations which return an order
 * @type {DocumentNode}
 */
const ORDER_REQUEST_FIELDS = gql`
  fragment orderFields on Order {
    _id
    number
    name
    items {
      count
      item {
        number
        description
      }
    }
    orderType
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} order
 */
const createPayload = (order) => {
  const payload = {
    ...{ name: order.name },
    ...{ orderType: order.orderType },
  };
  return payload;
};

/**
 * Actions
 */
const actions = {
  /**
   * Fetch a paginated, sorted and filtered order list
   * @param {*} param0
   * @param {*} payload
   */
  async fetchOrdersPaginated({ commit, dispatch }, { pagination, sorting, filters }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query ordersPaginated(
            $pagination: PaginationInput
            $sorting: SortingInput
            $filters: OrderFilterInput
          ) {
            ordersPaginated(pagination: $pagination, sorting: $sorting, filters: $filters) {
              orders {
                ...orderFields
              }
              orderCount
            }
          }
          ${ORDER_REQUEST_FIELDS}
        `,
        variables: {
          pagination: pagination,
          sorting: sorting,
          filters: filters,
        },
        fetchPolicy: 'network-only',
      });
      commit('SET_ORDERS', response.data.ordersPaginated.orders);
      commit('SET_ORDER_COUNT', response.data.ordersPaginated.orderCount);
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },

  async fetchOrderByNumber({ commit, dispatch }, orderNumber) {
    try {
      // console.log(orderNumber);
      const response = await apolloClient.query({
        query: gql`
          query orderByNumber($orderNumber: Int!) {
            orderByNumber(orderNumber: $orderNumber) {
              ...orderFields
            }
          }
          ${ORDER_REQUEST_FIELDS}
        `,
        variables: {
          orderNumber: parseInt(orderNumber),
        },
      });
      commit('ADD_OR_UPDATE_ORDER', response.data.orderByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async createOrder({ commit, dispatch }, order) {
    const payload = createPayload(order);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createOrder($payload: OrderInput!) {
            createOrder(orderInput: $payload) {
              ...orderFields
            }
          }
          ${ORDER_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Material gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_ORDER', '_new'); // remove temporary _new order
      commit('ADD_OR_UPDATE_ORDER', response.data.createOrder);
      return response.data.createOrder.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateOrder({ commit, dispatch }, order) {
    const payload = createPayload(order);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateOrder($orderNumber: Int!, $payload: OrderInput!) {
            updateOrder(orderNumber: $orderNumber, orderInput: $payload) {
              ...orderFields
            }
          }
          ${ORDER_REQUEST_FIELDS}
        `,
        variables: {
          orderNumber: order.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Material aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_ORDER', response.data.updateOrder);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteOrder({ commit, dispatch }, orderNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteOrder($orderNumber: Int!) {
            deleteOrder(orderNumber: $orderNumber)
          }
        `,
        variables: {
          orderNumber: orderNumber,
        },
      });
      dispatch('message', {
        message: 'Material gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_ORDER', orderNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new order object in store and return it
   * @param {*} param0
   * @param {*} order
   */
  async initOrder({ commit }) {
    commit('ADD_OR_UPDATE_ORDER', defaultOrder());
  },

  orderLocalUpdate({ commit }, order) {
    commit('ADD_OR_UPDATE_ORDER', order);
  },

  orderCleanup({ commit }) {
    commit('REMOVE_ORDER', '_new');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_ORDER_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_ORDERS: (state, orders) => (orders ? (state.orders = orders) : (state.orders = [])),
  ADD_ORDER: (state, order) => state.orders.push(order),
  ADD_OR_UPDATE_ORDER: (state, updatedOrder) => {
    const existingOrder = state.orders.find((order) => order._id === updatedOrder._id);
    if (existingOrder) {
      Object.assign(existingOrder, updatedOrder);
    } else {
      state.orders.push(updatedOrder);
    }
  },
  REMOVE_ORDER: (state, orderId) => {
    const index = state.orders.findIndex((order) => order._id === orderId);
    state.orders.splice(index, 1);
  },
  UPDATE_ORDER: (state, updatedOrder) => {
    const order = state.orders.find((order) => order._id === updatedOrder._id);
    Object.assign(order, updatedOrder);
  },
  SET_ORDER_COUNT: (state, orderCount) => (state.orderCount = orderCount),
};

/**
 * Getters
 */
const getters = {
  getOrders: (state) => state.orders,
  getOrder: (state) => (orderId) => state.orders.find((order) => order._id == orderId),
  getOrdersByType: (state) => (orderType) =>
    state.orders.filter((order) => order.type == orderType),
  getOrderByNumber: (state) => (orderNumber) =>
    state.orders.find((order) => order.number == orderNumber),
  getOrderCount: (state) => state.orderCount,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
