/**
 * Helper functions for formatting Electrican output
 */

/**
 * Determine the electricians guestEntryNumber for the powerCompany
 * that is assigned to the given project
 * @param {*} project
 */
export const guestEntryNumberByProject = (project) => {
  if (
    project.electrician &&
    project.electrician.id &&
    project.electrician.powerCompanies &&
    project.powerCompany
  ) {
    const entry = project.electrician.powerCompanies.find(
      (entry) => entry.powerCompany._id === project.powerCompany._id
    );
    return entry ? entry.guestEntryNumber : '';
  }
  return '';
};
