import { ApolloClient, from, InMemoryCache, ApolloLink } from 'apollo-boost';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import { getAuthToken } from './helpers/localStorage';
import { onError } from '@apollo/client/link/error';
import omitDeep from 'omit-deep';

const graphqlUri = window.VUE_APP_GRAPHQL_API
  ? window.VUE_APP_GRAPHQL_API
  : 'http://localhost:4000/graphql';
// console.log(graphqlUri);

// Create a new HttpLink to connect to your GraphQL API.
// According to the Apollo docs, this should be an absolute URI.
// const httpLink = new HttpLink({
//   // uri: 'http://localhost:4001/graphql',
//   uri: graphqlUri,
// });
const uploadLink = createUploadLink({
  // uri: process.env.VUE_APP_GRAPHQL_API,
  uri: graphqlUri,
});

// Create a new Middleware Link using setContext and add Auth Token
const middlewareLink = setContext(() => ({
  headers: {
    authorization: `Bearer ${getAuthToken()}`,
  },
}));

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      if (window.ENVIRONMENT === 'local') {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      }
    });
  if (networkError && process.env.ENVIRONMENT === 'local')
    console.error(`[Network error]: ${networkError}`);
});

// const link = middlewareLink.concat(httpLink);
// const link = middlewareLink.concat(uploadLink);
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

/**
 * Prevent "__typename" from being sent to the server
 * causes errors on uninitialized values
 */
const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, ['__typename']);
  }
  return forward(operation).map((data) => {
    return data;
  });
});

/**
 * Create the apollo client
 */
const apolloClient = new ApolloClient({
  // Tells Apollo to use the link chain with the http link we set up.
  link: from([errorLink, middlewareLink, uploadLink]),
  // Handles caching of results and mutations.
  cache: new InMemoryCache({
    addTypename: true,
  }),
  defaultOptions: defaultOptions,
  // Useful if you have the Apollo DevTools installed in your browser.
  connectToDevTools: false,
  // onError: ({ operation, response, graphQLErrors, networkError }) => {
  //     console.log(operation, response, graphQLErrors, networkError)
  // }
});

export default apolloClient;
