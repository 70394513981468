import gql from 'graphql-tag';

/**
 * Defines the fields which are requested for a project list
 * reduced fields only for list display!
 * @type {DocumentNode}
 */
export const PROJECT_LIST_REQUEST_FIELDS = gql`
  fragment projectListFields on Project {
    id
    number
    clientProjectNumber
    deliveryAt
    projectState
    netRegistrationRequirementsFullfilledState
    netRegistrationSentAt
    operationRegistrationSentAt
    initialInformationSentToCustomerAt
    itemPlanningFinishedState
    employer {
      id
      number
      name
      # client {
      #   id
      #   number
      #   name
      # }
    }
    client {
      id
      number
      name
    }
    customer {
      lastname
      firstname
      street
      streetNumber
      streetNumberSuffix
      zip
      city
      phone
      mobile
    }
    sales {
      state
      phase
      state
      package
      inCharge {
        firstname
        lastname
      }
    }
    powerCompany {
      company
      _id
      number
      netRegistrationDocumentsApprovedState
      operationRegistrationDocumentsApprovedState
    }
    installation {
      installationAppointments {
        installationTeam
        appointmentApprovedState
        requiredInstallationTimeinHours
        installationAt
        installationType
        calendarWeek
      }
      description
      electricMeterChangedAt
      operationRegistrationAt
      originalInstallationType
    }
    solarPlant {
      guaranteeCode
      extendedCode
      systemNumber
      yearOfConstruction
      solarPanelCount
      solarPlantOverallPower
      solarPanel {
        id
        number
        manufacturer
        type
        power
      }
    }
  }
`;
