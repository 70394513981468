import gql from 'graphql-tag';
import apolloClient from '../apollo';
import dayjs from 'dayjs';

import relativeTime from 'dayjs/plugin/relativeTime';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekday from 'dayjs/plugin/weekday';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import 'dayjs/locale/de';

dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(isoWeek);
dayjs.extend(weekday);
dayjs.extend(dayOfYear);
dayjs.locale('de');

/**
 * State
 */
const defaultTimetable = () => {
  return {
    id: '_new',
    number: '_new',
    startAt: dayjs()
      .startOf('day')
      .add(8, 'hour')
      .toDate(),
    endAt: dayjs()
      .startOf('day')
      .add(16, 'hour')
      .toDate(),
    contents: [],
    users: [],
  };
};

const getDefaultState = () => {
  return {
    timetables: [],
    timetableCount: 0,
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an timetable
 * used for all queries/mutations which return an timetable
 * @type {DocumentNode}
 */
const TIMETABLE_REQUEST_FIELDS = gql`
  fragment timetableFields on Timetable {
    id
    number
    description
    contents {
      id
      number
      name
    }
    users {
      id
      number
      firstname
      lastname
    }
    endAt
    startAt
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} timetable
 */
const createPayload = (timetable) => {
  // Verarbeitung der Inhalte und Benutzer IDs
  const contentIds = [];
  if (timetable.contents) {
    timetable.contents.map((content) => {
      contentIds.push(content.id);
    });
  }
  console.log('Processing contents:', contentIds);

  const userIds = [];
  if (timetable.users) {
    timetable.users.map((user) => {
      userIds.push(user.id);
    });
  }
  console.log('Processing users:', userIds);

  const payload = {
    ...(timetable.startAt ? { startAt: timetable.startAt } : {}),
    ...(timetable.endAt ? { endAt: timetable.endAt } : {}),
    description: timetable.description,
    contents: contentIds,
    users: userIds,
  };

  console.log('Payload contents:', payload.contents);
  console.log('Payload users:', payload.users);
  return payload;
};

/**
 * Actions
 */
const actions = {
  async fetchTimetablePages(
    { commit, dispatch },
    { page, pageSize, sortField, sortOrder, filters }
  ) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query timetablePages(
            $page: Int!
            $pageSize: Int!
            $sortField: String
            $sortOrder: Int
            $filters: TimetableFilterInput
          ) {
            timetablePages(
              page: $page
              pageSize: $pageSize
              sortField: $sortField
              sortOrder: $sortOrder
              filters: $filters
            ) {
              timetables {
                ...timetableFields
              }
              timetableCount
            }
          }
          ${TIMETABLE_REQUEST_FIELDS}
        `,
        variables: {
          page: page,
          pageSize: pageSize,
          sortField: sortField,
          sortOrder: sortOrder,
          filters: filters,
        },
        fetchPolicy: 'network-only',
      });

      console.log('Debugging Response data:', response.data);
      commit('SET_TIMETABLES', response.data.timetablePages.timetables);
      commit('SET_TIMETABLE_COUNT', response.data.timetablePages.timetableCount);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchTimetables({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query timetables {
            timetables {
              timetableCount
              timetables {
                id
                number
                startAt
                endAt
                description
                contents {
                  id
                  number
                  name
                }
                users {
                  id
                  number
                  firstname
                  lastname
                }
              }
            }
          }
        `,
      });
      console.log('FetchTimetable Response: ', response);
      commit('SET_TIMETABLES', response.data.timetables.timetables);
      commit('SET_TIMETABLE_COUNT', response.data.timetables.timetableCount);
    } catch (err) {
      console.error(err);
      throw new Error(err);
    }
  },

  async createTimetable({ commit, dispatch }, timetable) {
    const payload = createPayload(timetable);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createTimetable($payload: TimetableInput!) {
            createTimetable(timetableInput: $payload) {
              ...timetableFields
            }
          }
          ${TIMETABLE_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Klasse gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_TIMETABLE', '_new');
      commit('ADD_OR_UPDATE_TIMETABLE', response.data.createTimetable);
      return response.data.createTimetable.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },
  async updateTimetable({ commit, dispatch }, timetable) {
    const payload = createPayload(timetable);
    console.log('Payload:', payload);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateTimetable($timetableNumber: Int!, $payload: TimetableInput!) {
            updateTimetable(timetableNumber: $timetableNumber, timetableInput: $payload) {
              ...timetableFields
            }
          }
          ${TIMETABLE_REQUEST_FIELDS}
        `,
        variables: {
          timetableNumber: timetable.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Termin aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_TIMETABLE', response.data.updateTimetable);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteTimetable({ commit, dispatch }, timetableNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteTimetable($timetableNumber: Int!) {
            deleteTimetable(timetableNumber: $timetableNumber)
          }
        `,
        variables: {
          timetableNumber: timetableNumber,
        },
      });
      dispatch('message', {
        message: 'Klasse gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      // console.log(response.data.deleteTimetable);

      commit('REMOVE_TIMETABLE', timetableNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new timetable object in store and return it
   * @param {*} param0
   * @param {*} timetable
   */
  async initTimetable({ commit, dispatch }) {
    try {
      const timetable = defaultTimetable();
      const number = await dispatch('createTimetable', timetable);
      timetable.number = number;
      commit('ADD_OR_UPDATE_TIMETABLE', timetable);
    } catch (err) {
      console.error(err);
    }
  },

  /**
   * create a new timetable with existing data
   * @param {*} param0
   * @param {*} timetable
   */
  async duplicateTimetable({ commit }, { timetable, startAt }) {
    const duplicate = Object.assign(defaultTimetable(), {
      timetableType: timetable.timetableType,
      project: timetable.project,
      users: timetable.users,
      description: timetable.description,
      startAt: startAt,
      endAt: dayjs(startAt)
        .add(8, 'hour')
        .toDate(),
    });

    // console.log(duplicate);
    commit('ADD_OR_UPDATE_TIMETABLE', duplicate);
    return duplicate;
  },

  timetableLocalUpdate({ commit }, timetable) {
    commit('ADD_OR_UPDATE_TIMETABLE', timetable);
  },

  timetableCleanup({ commit }) {
    commit('REMOVE_TIMETABLE', '_new');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TIMETABLE_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TIMETABLES: (state, timetables) =>
    timetables ? (state.timetables = timetables) : (state.timetables = []),
  ADD_TIMETABLE: (state, timetable) => state.timetables.push(timetable),
  ADD_OR_UPDATE_TIMETABLE: (state, updatedTimetable) => {
    const index = state.timetables.findIndex((t) => t.id === updatedTimetable.id);
    if (index !== -1) {
      state.timetables[index] = { ...state.timetables[index], ...updatedTimetable };
    } else {
      state.timetables.push(updatedTimetable);
    }
  },
  REMOVE_TIMETABLE: (state, timetableNumber) => {
    const index = state.timetables.findIndex((timetable) => timetable.number === timetableNumber);
    if (index !== -1) {
      state.timetables.splice(index, 1);
    }
  },
  SET_TIMETABLE_COUNT: (state, timetableCount) => (state.timetableCount = timetableCount),
};

/**
 * Getters
 */
const getters = {
  getTimetables: (state) => {
    /**
     * timetables are grouped by weekday in the table view, map each startAt date to the weekday
     */
    state.timetables.map((timetable) => {
      timetable.weekday = dayjs(timetable.startAt).format('dddd DD.MM.YYYY');
      timetable.startAt = dayjs(timetable.startAt).toDate();
      timetable.endAt = dayjs(timetable.endAt).toDate();
      timetable.start = timetable.startAt;
      timetable.end = timetable.endAt;
    });
    return state.timetables;
  },
  getTimetable: (state) => (timetableId) =>
    state.timetables.find((timetable) => timetable.id == timetableId),
  getTimetableByNumber: (state) => (timetableNumber) =>
    state.timetables.find((timetable) => timetable.number == timetableNumber),
  getTimetableCount: (state) => state.timetableCount,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
