import gql from 'graphql-tag';
import apolloClient from '../apollo';
import Vue from 'vue';

/**
 * State
 */
const defaultElectrician = () => {
  return {
    id: '_new',
    number: '_new',
    name: '',
    certificateNumber: '',
    registeredIn: '',
    powerCompanies: [],
  };
};

const getDefaultState = () => {
  return {
    electricians: [],
    electricianAttachmentTypes: [],
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an electrician
 * used for all queries/mutations which return an electrician
 * @type {DocumentNode}
 */
const ELECTRICIAN_REQUEST_FIELDS = gql`
  fragment electricianFields on Electrician {
    id
    number
    name
    street
    streetNumber
    zip
    city
    certificateNumber
    registeredIn
    createdAt
    updatedAt
    contacts {
      gender
      firstname
      lastname
      position
      phone
      mobile
      email
    }
    certificateAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    signatureAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    stampAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    powerCompanies {
      powerCompany {
        number
        _id
        company
      }
      guestEntryNumber
    }
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} electrician
 */
const createPayload = (electrician) => {
  // remove __typename from position objects
  if (electrician.contacts) {
    electrician.contacts.map((contact) => {
      delete contact.__typename;
    });
  }

  const powerCompanies = electrician.powerCompanies.map((entry) => {
    return { powerCompany: entry.powerCompany._id, guestEntryNumber: entry.guestEntryNumber };
  });

  const payload = {
    ...(electrician.name ? { name: electrician.name } : {}),
    ...(electrician.street ? { street: electrician.street } : {}),
    ...(electrician.streetNumber ? { streetNumber: electrician.streetNumber } : {}),
    ...(electrician.certificateNumber ? { certificateNumber: electrician.certificateNumber } : {}),
    ...(electrician.registeredIn ? { registeredIn: electrician.registeredIn } : {}),
    ...(electrician.zip !== null ? { zip: electrician.zip } : {}),
    ...(electrician.city !== null ? { city: electrician.city } : {}),
    contacts: electrician.contacts,
    powerCompanies: powerCompanies,
  };
  return payload;
};

/**
 * Actions
 */
const actions = {
  async fetchElectricians({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            electricians {
              ...electricianFields
            }
          }
          ${ELECTRICIAN_REQUEST_FIELDS}
        `,
      });
      commit('SET_ELECTRICIANS', response.data.electricians);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchElectricianByNumber({ commit, dispatch }, electricianNumber) {
    try {
      // console.log(electricianNumber);
      const response = await apolloClient.query({
        query: gql`
          query electricianByNumber($electricianNumber: Int!) {
            electricianByNumber(electricianNumber: $electricianNumber) {
              ...electricianFields
            }
          }
          ${ELECTRICIAN_REQUEST_FIELDS}
        `,
        variables: {
          electricianNumber: parseInt(electricianNumber),
        },
      });
      commit('ADD_OR_UPDATE_ELECTRICIAN', response.data.electricianByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchElectricianAttachmentTypes({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "ElectricianAttachmentType") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_ELECTRICIAN_ATTACHMENT_TYPES', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },
  async createElectrician({ commit, dispatch }, electrician) {
    const payload = createPayload(electrician);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createElectrician($payload: ElectricianInput!) {
            createElectrician(electricianInput: $payload) {
              ...electricianFields
            }
          }
          ${ELECTRICIAN_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Elektrofachkraft gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_ELECTRICIAN', '_new'); // remove temporary _new electrician
      commit('ADD_OR_UPDATE_ELECTRICIAN', response.data.createElectrician);
      return response.data.createElectrician.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateElectrician({ commit, dispatch }, electrician) {
    const payload = createPayload(electrician);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateElectrician($electricianNumber: Int!, $payload: ElectricianInput!) {
            updateElectrician(electricianNumber: $electricianNumber, electricianInput: $payload) {
              ...electricianFields
            }
          }
          ${ELECTRICIAN_REQUEST_FIELDS}
        `,
        variables: {
          electricianNumber: electrician.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Elektrofachkraft aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_ELECTRICIAN', response.data.updateElectrician);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteElectrician({ commit, dispatch }, electricianNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteElectrician($electricianNumber: Int!) {
            deleteElectrician(electricianNumber: $electricianNumber)
          }
        `,
        variables: {
          electricianNumber: electricianNumber,
        },
      });
      dispatch('message', {
        message: 'Elektrofachkraft gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_ELECTRICIAN', electricianNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Upload an image belonging to the electrician
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async uploadElectricianAttachment({ commit, dispatch }, payload) {
    // console.log(payload);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation uploadElectricianAttachment($attachmentInput: AttachmentWithTypeInput!) {
            uploadElectricianAttachment(attachmentInput: $attachmentInput) {
              ...electricianFields
            }
          }
          ${ELECTRICIAN_REQUEST_FIELDS}
        `,
        variables: {
          attachmentInput: {
            file: payload.file,
            referenceId: payload.parentId,
            referenceType: payload.parentType,
          },
        },
      });
      commit('UPDATE_ELECTRICIAN_ATTACHMENTS', {
        updatedElectrician: response.data.uploadElectricianAttachment,
        referenceType: payload.parentType,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Upload!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Delete Electrician Atrachment
   * @param commit
   * @param payload
   */
  async deleteElectricianAttachment({ commit, dispatch }, payload) {
    // console.log(payload)
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteElectricianAttachment(
            $electricianId: ID!
            $attachmentId: ID!
            $attachmentType: String!
          ) {
            deleteElectricianAttachment(
              electricianId: $electricianId
              attachmentId: $attachmentId
              attachmentType: $attachmentType
            ) {
              ...electricianFields
            }
          }
          ${ELECTRICIAN_REQUEST_FIELDS}
        `,
        variables: {
          electricianId: payload.electricianId,
          attachmentId: payload.attachmentId,
          attachmentType: payload.attachmentType,
        },
      });
      // console.log(response)
      commit('UPDATE_ELECTRICIAN', response.data.deleteElectricianAttachment);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new electrician object in store and return it
   * @param {*} param0
   * @param {*} electrician
   */
  async initElectrician({ commit }) {
    commit('ADD_OR_UPDATE_ELECTRICIAN', defaultElectrician());
  },

  electricianLocalUpdate({ commit }, electrician) {
    commit('ADD_OR_UPDATE_ELECTRICIAN', electrician);
  },

  electricianCleanup({ commit }) {
    commit('REMOVE_ELECTRICIAN', '_new');
  },
  addEmptyElectricianContact({ commit }, electrician) {
    commit('ADD_EMPTY_ELECTRICIAN_CONTACT', electrician);
  },
  removeElectricianContact({ commit }, index) {
    commit('REMOVE_ELECTRICIAN_CONTACT', index);
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_ELECTRICIAN_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_ELECTRICIANS: (state, electricians) =>
    electricians ? (state.electricians = electricians) : (state.electricians = []),
  ADD_ELECTRICIAN: (state, electrician) => state.electricians.push(electrician),
  ADD_OR_UPDATE_ELECTRICIAN: (state, updatedElectrician) => {
    const existingElectrician = state.electricians.find(
      (electrician) => electrician.id === updatedElectrician.id
    );
    if (existingElectrician) {
      Object.assign(existingElectrician, updatedElectrician);
    } else {
      state.electricians.push(updatedElectrician);
    }
  },
  REMOVE_ELECTRICIAN: (state, electricianId) => {
    const index = state.electricians.findIndex((electrician) => electrician.id === electricianId);
    state.electricians.splice(index, 1);
  },
  ADD_EMPTY_ELECTRICIAN_CONTACT: (state, updatedElectrician) => {
    const company = state.electricians.find(
      (electrician) => electrician.number == updatedElectrician.number
    );
    company.contacts.push({});
  },
  REMOVE_ELECTRICIAN_CONTACT: (state, { updatedElectrician, index }) => {
    const company = state.electricians.find(
      (electrician) => electrician.number == updatedElectrician.number
    );
    company.contacts.splice(index, 1);
  },
  UPDATE_ELECTRICIAN: (state, updatedElectrician) => {
    const electrician = state.electricians.find(
      (electrician) => electrician.id === updatedElectrician.id
    );
    Object.assign(electrician, updatedElectrician);
  },
  UPDATE_ELECTRICIAN_ATTACHMENTS: (state, { updatedElectrician, referenceType }) => {
    const electrician = state.electricians.find(
      (electrician) => electrician.id === updatedElectrician.id
    );
    Vue.set(electrician, referenceType, updatedElectrician[referenceType]);
  },
  SET_ELECTRICIAN_ATTACHMENT_TYPES: (state, electricianAttachmentTypes) => {
    state.electricianAttachmentTypes = [];
    electricianAttachmentTypes.map((electricianAttachmentType) => {
      state.electricianAttachmentTypes.push(electricianAttachmentType.name);
    });
  },
};

/**
 * Getters
 */
const getters = {
  getElectricians: (state) => state.electricians,
  getElectrician: (state) => (electricianId) =>
    state.electricians.find((electrician) => electrician.id == electricianId),
  getElectricianByNumber: (state) => (electricianNumber) =>
    state.electricians.find((electrician) => electrician.number == electricianNumber),
  getElectricianAttachmentTypes: (state) => state.electricianAttachmentTypes,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
