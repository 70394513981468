<template>
  <section v-if="user" class="notifications card navbar-notifications">
    <footer class="cardFooter text-sm card-footer">
      <!-- <router-link :to="{ name: 'ProfilePage' }">
        <i class="la la-user" />
        Mein Profil
      </router-link> -->
      <a href="#" @click.prevent="logout" class="pull-right">
        <i class="la la-sign-out" />
        Abmelden
      </a>
    </footer>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Notification',
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUser']),
    ...mapGetters(['getUser']),
    user() {
      return this.getUser(this.getCurrentUser.id);
    },
  },
  methods: {
    ...mapActions('auth', ['logoutUser', 'refreshUser']),
    async logout() {
      await this.logoutUser();
      await this.$router.push('/login');
    },
    async setup() {
      await this.refreshUser();
    },
  },
  async created() {
    await this.setup();
  },
};
</script>
<style src="./Notifications.scss" lang="scss" />
