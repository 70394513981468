import gql from 'graphql-tag';
import apolloClient from '../apollo';

// State
const getDefaultTimeTracking = () => {
  return {
    user: null,
    project: null,
    appointment: null,
    startTime: null,
    stopTime: null,
    timeTrackingState: 'STARTED',
    timeTrackingTotalHours: null,
    number: '_new',
  };
};

const getDefaultState = () => {
  return {
    timeTrackings: [],
    timeTrackingCount: 0,
  };
};

const state = getDefaultState();

// Request Fields
const TIME_TRACKING_REQUEST_FIELDS = gql`
  fragment timeTrackingFields on TimeTracking {
    id
    number
    startTime
    stopTime
    timeTrackingState
    createdAt
    duration
    user {
      firstname
      lastname
      company
    }
    project {
      id
      number
      customer {
        firstname
        lastname
        zip
        street
        streetNumber
        streetNumberSuffix
        city
      }
    }
    appointment {
      id
      number
    }
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api for time tracking
 * @param {*} timeTracking
 */
const createPayload = (timeTracking) => {
  const payload = {
    ...(timeTracking.project ? { project: timeTracking.project.id } : {}),
    ...(timeTracking.appointment ? { appointment: timeTracking.appointment.id } : {}),
    ...(timeTracking.startTime ? { startTime: timeTracking.startTime } : {}),
    ...(timeTracking.stopTime ? { stopTime: timeTracking.stopTime } : {}),
    ...(timeTracking.timeTrackingState
      ? { timeTrackingState: timeTracking.timeTrackingState }
      : {}),
  };
  console.log(payload);

  return payload;
};

// Actions
const actions = {
  async fetchTimeTracking({ commit }, { page, pageSize, sortField, sortOrder, filters }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query timeTrackingsPaginated(
            $page: Int!
            $pageSize: Int!
            $sortField: String
            $sortOrder: Int
            $filters: TimeTrackingFilterInput
          ) {
            timeTrackingsPaginated(
              page: $page
              pageSize: $pageSize
              sortField: $sortField
              sortOrder: $sortOrder
              filters: $filters
            ) {
              timeTrackingCount
              timeTrackingTotalHours
              timeTrackings {
                ...timeTrackingFields
              }
            }
          }
          ${TIME_TRACKING_REQUEST_FIELDS}
        `,
        variables: { page, pageSize, sortField, sortOrder, filters },
        fetchPolicy: 'network-only',
      });

      commit('SET_TIME_TRACKINGS', response.data.timeTrackingsPaginated.timeTrackings);
      commit('SET_TIME_TRACKING_COUNT', response.data.timeTrackingsPaginated.timeTrackingCount);
      commit(
        'SET_TIME_TRACKING_TOTAL_HOURS',
        response.data.timeTrackingsPaginated.timeTrackingTotalHours
      );
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchTimeTrackingsByUser(
    { commit },
    { userId, page, pageSize, sortField, sortOrder, filters }
  ) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query timeTrackingsPaginatedByUser(
            $userId: ID!
            $page: Int!
            $pageSize: Int!
            $sortField: String
            $sortOrder: Int
            $filters: TimeTrackingFilterInput
          ) {
            timeTrackingsPaginatedByUser(
              userId: $userId
              page: $page
              pageSize: $pageSize
              sortField: $sortField
              sortOrder: $sortOrder
              filters: $filters
            ) {
              timeTrackingCount
              timeTrackingTotalHours
              timeTrackings {
                ...timeTrackingFields
              }
            }
          }
          ${TIME_TRACKING_REQUEST_FIELDS}
        `,
        variables: { userId, page, pageSize, sortField, sortOrder, filters },
        fetchPolicy: 'network-only',
      });

      commit('SET_TIME_TRACKINGS', response.data.timeTrackingsPaginatedByUser.timeTrackings);
      commit(
        'SET_TIME_TRACKING_COUNT',
        response.data.timeTrackingsPaginatedByUser.timeTrackingCount
      );
      commit(
        'SET_TIME_TRACKING_TOTAL_HOURS',
        response.data.timeTrackingsPaginatedByUser.timeTrackingTotalHours
      );
    } catch (err) {
      throw new Error(err);
    }
  },

  async createTimeTracking({ commit }, timeTracking) {
    const payload = createPayload(timeTracking);

    console.log(payload);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createTimeTracking($payload: TimeTrackingInput!) {
            createTimeTracking(timeTrackingInput: $payload) {
              ...timeTrackingFields
            }
          }
          ${TIME_TRACKING_REQUEST_FIELDS}
        `,
        variables: { payload: payload },
      });

      commit('ADD_TIME_TRACKING', response.data.createTimeTracking);
      console.log(response);
      return response.data.createTimeTracking.number;
    } catch (err) {
      throw new Error(err);
    }
  },
  async updateTimeTracking({ commit, dispatch }, timeTracking) {
    const payload = createPayload(timeTracking.timeTracking);
    console.log(payload);

    try {
      console.log('payload ', payload);

      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateTimeTracking($timeTrackingNumber: Int!, $payload: TimeTrackingInput!) {
            updateTimeTracking(
              timeTrackingNumber: $timeTrackingNumber
              timeTrackingInput: $payload
            ) {
              ...timeTrackingFields
            }
          }
          ${TIME_TRACKING_REQUEST_FIELDS}
        `,
        variables: {
          timeTrackingNumber: timeTracking.timeTracking.number,
          payload: payload,
        },
      });

      dispatch('message', {
        message: 'Zeiterfassung aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_TIME_TRACKING', response.data.updateTimeTracking);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },
  /**
   * create a new timeTracking object in store and return it
   * @param {*} param
   * @param {*} timeTracking
   */
  async initTimeTracking({ commit }) {
    commit('ADD_OR_UPDATE_TIME_TRACKING', getDefaultTimeTracking());
  },
};

const mutations = {
  SET_TIME_TRACKINGS: (state, timeTrackings) => (state.timeTrackings = timeTrackings),
  ADD_TIME_TRACKING: (state, newTimeTracking) => state.timeTrackings.push(newTimeTracking),
  SET_TIME_TRACKING_COUNT: (state, count) => (state.timeTrackingCount = count),
  SET_TIME_TRACKING_TOTAL_HOURS: (state, totalHours) => (state.timeTrackingTotalHours = totalHours),
  ADD_OR_UPDATE_TIME_TRACKING: (state, newTimeTracking) => {
    const existingTimeTracking = state.timeTrackings.find(
      (timeTracking) => timeTracking.id === newTimeTracking.id
    );
    if (existingTimeTracking) {
      Object.assign(existingTimeTracking, newTimeTracking);
    } else {
      state.timeTrackings.push(newTimeTracking);
    }
  },
};

// Getters
const getters = {
  getTimeTrackings: (state) => state.timeTrackings,
  getTimeTrackingCount: (state) => state.timeTrackingCount,
  gettimeTrackingTotalHours: (state) => state.timeTrackingTotalHours,
  getCurrentUser: (state) => state.currentUser,
  getTimeTrackingByNumber: (state) => (timeTrackingNumber) =>
    state.timeTrackings.find((timeTracking) => timeTracking.number == timeTrackingNumber),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
