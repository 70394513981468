import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const defaultItem = () => {
  return {
    _id: '_new',
    number: '_new',
    pilatNr: '',
    wholesaleNr: '',
    industryNr: '',
    description: '',
    unit: 'PIECE',
    type: 'STANDARD',
    price: 0,
    inventory: 0,
    addRemoveCount: 1,
    deleted: false,
  };
};

const getDefaultState = () => {
  return {
    items: [],
    itemUnits: [],
    itemCount: 0,
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an item
 * used for all queries/mutations which return an item
 * @type {DocumentNode}
 */
const ITEM_REQUEST_FIELDS = gql`
  fragment itemFields on Item {
    _id
    number
    nextItemNumber
    previousItemNumber
    pilatNr
    wholesaleNr
    industryNr
    description
    inventory
    unit
    type
    price
    createdAt
    updatedAt
    deleted
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} item
 */
const createPayload = (item) => {
  const payload = {
    ...{ pilatNr: item.pilatNr },
    ...{ wholesaleNr: item.wholesaleNr },
    ...{ industryNr: item.industryNr },
    ...(item.description ? { description: item.description } : {}),
    ...(item.unit ? { unit: item.unit } : {}),
    ...(item.type ? { type: item.type } : {}),
    ...(item.price ? { price: parseFloat(item.price) } : {}),
    ...(item.inventory ? { inventory: parseInt(item.inventory) } : {}),
  };
  return payload;
};

/**
 * Actions
 */
const actions = {
  /**
   * Fetch a paginated, sorted and filtered item list
   * @param {*} param0
   * @param {*} payload
   */
  async fetchItemsPaginated({ commit, dispatch }, { pagination, sorting, filters }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query itemsPaginated(
            $pagination: PaginationInput
            $sorting: SortingInput
            $filters: ItemFilterInput
          ) {
            itemsPaginated(pagination: $pagination, sorting: $sorting, filters: $filters) {
              items {
                ...itemFields
              }
              itemCount
            }
          }
          ${ITEM_REQUEST_FIELDS}
        `,
        variables: {
          pagination: pagination,
          sorting: sorting,
          filters: filters,
        },
        fetchPolicy: 'network-only',
      });
      commit('SET_ITEMS', response.data.itemsPaginated.items);
      commit('SET_ITEM_COUNT', response.data.itemsPaginated.itemCount);
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },

  async fetchItems({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            items {
              ...itemFields
            }
          }
          ${ITEM_REQUEST_FIELDS}
        `,
      });
      commit('SET_ITEMS', response.data.items);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchItemsByType({ commit, dispatch }, itemType) {
    try {
      // console.log(itemNumber);
      const response = await apolloClient.query({
        query: gql`
          query itemsByType($itemType: ItemType!) {
            itemsByType(itemType: $itemType) {
              ...itemFields
            }
          }
          ${ITEM_REQUEST_FIELDS}
        `,
        variables: {
          itemType: itemType,
        },
      });
      commit('SET_ITEMS', response.data.itemsByType);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchItemByBarcode({ commit, dispatch }, barcode) {
    try {
      // console.log('fetchItemByBarcode()', barcode, typeof barcode);
      const response = await apolloClient.query({
        query: gql`
          query itemByBarcode($barcode: String!) {
            itemByBarcode(barcode: $barcode) {
              ...itemFields
            }
          }
          ${ITEM_REQUEST_FIELDS}
        `,
        variables: {
          barcode: barcode,
        },
      });
      // console.log('fetchItemByBarcode()', response.data.itemByBarcode);
      if (response.data.itemByBarcode) {
        commit('ADD_OR_UPDATE_ITEM', response.data.itemByBarcode);
      }
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchItemByNumber({ commit, dispatch }, itemNumber) {
    try {
      // console.log(itemNumber);
      const response = await apolloClient.query({
        query: gql`
          query itemByNumber($itemNumber: Int!) {
            itemByNumber(itemNumber: $itemNumber) {
              ...itemFields
            }
          }
          ${ITEM_REQUEST_FIELDS}
        `,
        variables: {
          itemNumber: parseInt(itemNumber),
        },
      });
      commit('ADD_OR_UPDATE_ITEM', response.data.itemByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchItemUnits({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "ItemUnit") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_ITEM_UNITS', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  async createItem({ commit, dispatch }, item) {
    const payload = createPayload(item);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createItem($payload: ItemInput!) {
            createItem(itemInput: $payload) {
              ...itemFields
            }
          }
          ${ITEM_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Material gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_ITEM', '_new'); // remove temporary _new item
      commit('ADD_OR_UPDATE_ITEM', response.data.createItem);
      return response.data.createItem.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateItem({ commit, dispatch }, item) {
    const payload = createPayload(item);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateItem($itemNumber: Int!, $payload: ItemInput!) {
            updateItem(itemNumber: $itemNumber, itemInput: $payload) {
              ...itemFields
            }
          }
          ${ITEM_REQUEST_FIELDS}
        `,
        variables: {
          itemNumber: item.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Material aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_ITEM', response.data.updateItem);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteItem({ commit, dispatch }, itemNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteItem($itemNumber: Int!) {
            deleteItem(itemNumber: $itemNumber)
          }
        `,
        variables: {
          itemNumber: itemNumber,
        },
      });
      dispatch('message', {
        message: 'Material gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_ITEM', itemNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new item object in store and return it
   * @param {*} param0
   * @param {*} item
   */
  async initItem({ commit }) {
    commit('ADD_OR_UPDATE_ITEM', defaultItem());
  },

  itemLocalUpdate({ commit }, item) {
    commit('ADD_OR_UPDATE_ITEM', item);
  },

  itemCleanup({ commit }) {
    commit('REMOVE_ITEM', '_new');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_ITEM_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_ITEMS: (state, items) => (items ? (state.items = items) : (state.items = [])),
  ADD_ITEM: (state, item) => state.items.push(item),
  ADD_OR_UPDATE_ITEM: (state, updatedItem) => {
    const existingItem = state.items.find((item) => item._id === updatedItem._id);
    if (existingItem) {
      Object.assign(existingItem, updatedItem);
    } else {
      state.items.push(updatedItem);
    }
  },
  REMOVE_ITEM: (state, itemId) => {
    const index = state.items.findIndex((item) => item._id === itemId);
    state.items.splice(index, 1);
  },
  UPDATE_ITEM: (state, updatedItem) => {
    const item = state.items.find((item) => item._id === updatedItem._id);
    Object.assign(item, updatedItem);
  },
  SET_ITEM_UNITS: (state, itemUnits) => {
    state.itemUnits = [];
    itemUnits.map((itemUnit) => {
      state.itemUnits.push(itemUnit.name);
    });
  },
  SET_ITEM_COUNT: (state, itemCount) => (state.itemCount = itemCount),
};

/**
 * Getters
 */
const getters = {
  getItems: (state) => {
    state.items.map((item) => {
      Object.assign(item, { addRemoveCount: 1 });
    });

    return state.items;
  },
  getItem: (state) => (itemId) => state.items.find((item) => item._id == itemId),
  getItemsByType: (state) => (itemType) => state.items.filter((item) => item.type == itemType),
  getItemByNumber: (state) => (itemNumber) => state.items.find((item) => item.number == itemNumber),
  getItemByBarcode: (state) => (barcode) => {
    const item = state.items.find(
      (item) => item.wholesaleNr == barcode || item.industryNr == barcode
    );
    if (item) {
      Object.assign(item, { addRemoveCount: 1 });
    }

    return item;
  },
  getItemUnits: (state) => state.itemUnits,
  getItemCount: (state) => state.itemCount,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
