export default {
  // Weiterführende Konfiguration
  key: '1.10',
  label: 'extendedConfiguration',
  children: [
    {
      key: '1.10.1',
      name: 'solarPlant.emergencyPowerState',
      label: 'emergencyPowerState',
      translate: true,
    },
    {
      key: '1.10.2',
      name: 'solarPlant.cascadeConnectionState',
      label: 'cascadeConnectionState',
      translate: true,
    },
    {
      key: '1.10.3',
      name: 'solarPlant.networkSupplyManagementState',
      label: 'networkSupplyManagementState',
      translate: true,
    },
    {
      key: '1.10.10',
      name: 'solarPlant.networkSupplyManagementPermanentRestrictionState',
      label: 'networkSupplyManagementPermanentRestrictionState_inPercent',
      translate: true,
    },
    {
      key: '1.10.4',
      name: 'solarPlant.networkSupplyManagementXOverState',
      label: 'networkSupplyManagementXOverState',
      translate: true,
    },
    {
      key: '1.10.5',
      name: 'solarPlant.netOutputState',
      label: 'netOutputState',
      translate: true,
    },
    {
      key: '1.10.6',
      name: 'solarPlant.storageCouplingState',
      label: 'storageCouplingState',
      translate: true,
    },
    {
      key: '1.10.7',
      name: 'solarPlant.electricMeterMountingState',
      label: 'electricMeterMountingState',
      translate: true,
    },
    {
      key: '1.10.8',
      name: 'solarPlant.yearlyPower',
      label: 'yearlyPower',
    },
    {
      key: '1.10.9',
      name: 'solarPlant.electricMeterDeregistrationState',
      label: 'electricMeterDeregistrationState',
      translate: true,
    },
    {
      key: 'solarPlant_solarPlantType',
      name: 'solarPlant.solarPlantType',
      label: 'solarPlantType',
      translate: true,
    },
    {
      key: 'solarPlant_solarPlantTypeValueA',
      name: 'solarPlant.solarPlantTypeValueA',
      label: 'solarPlantTypeValueA',
      translate: true,
      type: 'INTEGER',
    },
    {
      key: 'solarPlant_solarPlantTypeValueB',
      name: 'solarPlant.solarPlantTypeValueB',
      label: 'solarPlantTypeValueB',
      translate: true,
      type: 'INTEGER',
    },
    {
      key: 'solarPlant_solarPlantTypeValueC',
      name: 'solarPlant.solarPlantTypeValueC',
      label: 'solarPlantTypeValueC',
      translate: true,
      type: 'INTEGER',
    },
    {
      key: 'solarPlant_islandOperationIntended',
      name: 'solarPlant.islandOperationIntended',
      label: 'islandOperationIntended',
      translate: true,
    },
    {
      key: 'solarPlant_motorStartup',
      name: 'solarPlant.motorStartup',
      label: 'motorStartup',
      translate: true,
    },
    {
      key: 'solarPlant_networkSupplyType',
      name: 'solarPlant.networkSupplyType',
      label: 'networkSupplyType',
      translate: true,
    },
    {
      key: 'solarPlant_houseFuseState',
      name: 'solarPlant.houseFuseState',
      label: 'houseFuseState',
      translate: true,
    },
    {
      key: 'solarPlant_measuringConcept',
      name: 'solarPlant.measuringConcept',
      label: 'measuringConcept',
      translate: false,
    },
  ],
};
