import gql from 'graphql-tag';
import apolloClient from '../apollo';
import { preparePayloadForGraphQL } from '../helpers/validate';

/**
 * State
 */
const getDefaultState = () => {
  return {
    posts: [],
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for a post
 * used for all queries/mutations which return a post
 * @type {DocumentNode}
 */
const POST_REQUEST_FIELDS = gql`
  fragment postFields on Post {
    id
    content
    createdAt
    updatedAt
    project {
      id
      number
    }
    user {
      number
      firstname
      lastname
      email
      avatar {
        path
      }
    }
  }
`;

/**
 * Actions
 */
const actions = {
  /**
   * Reset state in module
   * @param commit
   */
  reset({ commit }) {
    commit('SET_DEFAULT_STATE');
  },

  /**
   * Fetch all post from a user
   * @param commit
   * @param userId
   * @returns {Promise<void>}
   */
  async fetchPostsByUser({ commit }, userId) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query postsByUser($userId: ID!) {
            postsByUser(userId: $userId) {
              ...postFields
            }
          }
          ${POST_REQUEST_FIELDS}
        `,
        variables: {
          userId: userId,
        },
      });
      commit('SET_POSTS', response.data.postsByUser);
    } catch (err) {
      // console.log(err)
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  /**
   * Fetch all posts, filter by state
   * @param {*} param0
   * @param {*} state
   */
  async fetchPosts({ commit }, state) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query posts($state: PostState) {
            posts(state: $state) {
              ...postFields
            }
          }
          ${POST_REQUEST_FIELDS}
        `,
        variables: {
          state: state,
        },
      });
      commit('SET_POSTS', response.data.posts);
    } catch (err) {
      // console.log(err)
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  /**
   * Create a post
   * @param commit
   * @param post
   * @returns {Promise<void>}
   */
  async createPost({ commit }, post) {
    console.log('createPost', post);

    const validatedPayload = preparePayloadForGraphQL(post, {
      content: 'string',
      user: 'id',
      project: 'id',
    });
    console.log('createPost', validatedPayload);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createPost($payload: CreatePostInput!) {
            createPost(postInput: $payload) {
              ...postFields
            }
          }
          ${POST_REQUEST_FIELDS}
        `,
        variables: {
          payload: validatedPayload,
        },
      });
      // console.log(response.data.createTask)
      commit('ADD_POST', response.data.createPost);
      // commit('SET_FLASH_MESSAGE', {message: 'Kategorie erstellt!', type: 'is-success'})
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },

  /**
   * delete a post
   * @param commit
   * @param id
   * @returns {Promise<void>}
   */
  async deletePost({ commit }, id) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deletePost($postId: ID!) {
            deletePost(postId: $postId)
          }
        `,
        variables: {
          postId: id,
        },
      });
      // console.log(response)
      commit('REMOVE_POST', id);
      // commit('SET_FLASH_MESSAGE', {message: 'Kategorie gelöscht!', type: 'is-success'})
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_POST_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_POSTS: (state, posts) => (state.posts = posts),
  ADD_POST: (state, post) => state.posts.push(post),
  UPDATE_POST: (state, updatedPost) => {
    const post = state.posts.find((post) => post.id === updatedPost.id);
    Object.assign(post, updatedPost);
    // Vue.set(post, state.offers.length, updatedPost)
  },
  REMOVE_POST: (state, postId) => {
    const index = state.posts.findIndex((post) => post.id === postId);
    state.posts.splice(index, 1);
  },
};

/**
 * Getters
 */
const getters = {
  getPosts: (state) => state.posts,
  getPostsByUser: (state) => (userId) => state.posts.filter((post) => post.user.id === userId),
  getPost: (state) => (postId) => state.posts.find((post) => post.id === postId),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
