<template>
  <ul class="nav">
    <NavLink
      :activeItem="activeItem"
      header="Dashboard"
      link="/dashboard"
      iconName="flaticon-home"
      index="dashboard"
      isHeader
    />
    <NavLink
      :activeItem="activeItem"
      header="Projekte"
      link="/projekte"
      iconName="flaticon-file"
      index="projects"
      isHeader
    />
    <NavLink
      :activeItem="activeItem"
      header="Aufgaben"
      link="/aufgaben"
      iconName="flaticon-umbrella"
      index="aufgaben"
      isHeader
    />
    <NavLink
      v-if="user && authorized"
      :activeItem="activeItem"
      header="Bewerber"
      link="/bewerber"
      iconName="flaticon-radar"
      index="aufgaben"
      isHeader
    />
  </ul>
</template>

<script>
import NavLink from './NavLink/NavLink';
import { mapGetters } from 'vuex';
import { authorizeByUser } from '@/helpers/userCapabilities';

export default {
  components: { NavLink },
  props: ['activeItem'],
  methods: {
    authorizeByUser,
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUser']),
    ...mapGetters(['getUser']),
    user() {
      return this.getUser(this.getCurrentUser.id);
    },
    authorized() {
      return this.authorizeByUser(this.user, [
        'c.minte@q-p-s.com',
        'sebastian@mertenonline.de',
        'a.gladstone@eway-energy.com',
        'e.sciacca@q-p-s.com',
        'e.sciacca@ssp-elektro.com',
        'p.sciacca@q-p-s.com',
        'd.hackmann@eway-energy.com',
        'a.traunfellner@ssp-elektro.com',
        'd.hopp@eway-energy.com',
        'daniel@hopp.de',
        'w.gunsch@q-p-s.com',
        't.nguyen@q-p-s.com',
        's.tam@ssp-elektro.com',
      ]);
    },
  },
  created() {},
};
</script>

<style>
.sub-menu .router-link-active .icon {
  display: none;
}
</style>
