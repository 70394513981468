export default {
  // Solarmodul
  key: '1.1',
  label: 'solarPanel',
  children: [
    {
      key: '1.1.0',
      name: 'composite.storagePanelManufacturer_storagePanelType',
      label: 'composite.storagePanelManufacturer_storagePanelType',
    },
    { key: '1.1.1', name: 'solarPlant.solarPanel.manufacturer', label: 'manufacturer' },
    { key: '1.1.2', name: 'solarPlant.solarPanel.type', label: 'type' },
    {
      key: '1.1.3',
      name: 'solarPlant.solarPanelCount',
      label: 'solarPanelCount',
      type: 'INTEGER',
    },
    {
      key: '1.1.4',
      name: 'solarPlant.solarPanel.power',
      label: 'solarPlant.solarPanel.power',
      type: 'INTEGER',
    },
    {
      key: '1.1.5',
      name: 'solarPlant.solarPanel.powerKwp',
      label: 'solarPlant.solarPanel.powerKwp',
    },
    {
      key: '1.1.6',
      name: 'solarPlant.solarPlantOverallPower',
      label: 'solarPlantOverallPower',
    },
    { key: '1.1.7', name: 'solarPlant.presetInverterPower', label: 'presetInverterPower' },
  ],
};
