<template>
  <ul class="nav">
    <NavLink
      :activeItem="activeItem"
      header="Dashboard"
      link="/dashboard"
      iconName="flaticon-home"
      index="dashboard"
      isHeader
    />
    <NavLink
      :activeItem="activeItem"
      header="Projekte"
      link="/projekte"
      iconName="flaticon-star"
      index="projects2"
      isHeader
    />

    <NavLink
      :activeItem="activeItem"
      header="Tourenplanung Liste"
      link="/tourenplanung"
      iconName="flaticon-navigation"
      index="tourenplanung"
      isHeader
    />
    <NavLink
      :activeItem="activeItem"
      header="Terminplanung"
      link="/planungskalender"
      iconName="flaticon-calendar"
      index="terminplanung"
      isHeader
    />
    <NavLink
      :activeItem="activeItem"
      header="Termin Slots"
      link="/terminkapazitaet"
      iconName="flaticon-cloud"
      index="terminplanung"
      isHeader
    />

    <NavLink
      :activeItem="activeItem"
      header="Aufgaben"
      link="/aufgaben"
      iconName="flaticon-umbrella"
      index="aufgaben"
      isHeader
    />
    <NavLink
      :activeItem="activeItem"
      header="Kalender"
      link="/kalender"
      iconName="flaticon-calendar"
      index="terminplanung"
      isHeader
    />

    <NavLink
      v-if="user && authorized"
      :activeItem="activeItem"
      header="Sales"
      link="/sales/"
      iconName="flaticon-binoculars"
      index="sales"
      isHeader
      :childrenLinks="[{ header: 'Overview', link: '/sales/overview', index: 'AdminSales' }]"
    />

    <!-- <NavLink
      :activeItem="activeItem"
      header="Terminplanung"
      link="/terminplanung"
      iconName="flaticon-calendar"
      index="terminplanung"
      isHeader
      :childrenLinks="[
        { header: 'Planungskalender', link: '/planungskalender', index: 'planungskalender' },
      ]"
    /> -->
    <NavLink
      :activeItem="activeItem"
      header="Personalverwaltung"
      link="/personalverwaltung"
      iconName="flaticon-users"
      index="personalverwaltung"
      isHeader
      :childrenLinks="[
        { header: 'Benutzer', link: '/benutzer', index: 'benutzer' },
        { header: 'Mitarbeiter', link: '/mitarbeiter', index: 'mitarbeiter' },
        { header: 'Mandanten', link: '/mandanten', index: 'mandanten' },
        { header: 'Auftraggeber', link: '/auftraggeber', index: 'auftraggeber' },
        { header: 'EVU', link: '/evu', index: 'evu' },
        { header: 'Elektrofachkräfte', link: '/elektrofachkraft', index: 'elektrofachkraft' },
        {
          header: 'Messstellenbetreiber',
          link: '/messstellenbetreiber',
          index: 'messstellenbetreiber',
        },
      ]"
    />
    <NavLink
      :activeItem="activeItem"
      header="Anlagen"
      link="/anlagen"
      iconName="flaticon-power"
      index="anlagen"
      isHeader
      :childrenLinks="[
        { header: 'Batteriespeicher', link: '/batterien', index: 'batterien' },
        {
          header: 'Wechselrichter',
          link: '/wechselrichter',
          index: 'wechselrichter',
        },
        { header: 'Module', link: '/module', index: 'module' },
        {
          header: 'EnFluRi',
          link: '/energieflussrichtungssensoren',
          index: 'energieflussrichtungssensoren',
        },
        { header: 'Wallboxen', link: '/wallboxen', index: 'wallboxen' },
        {
          header: 'Speichersysteme (alt)',
          link: '/speichersysteme',
          index: 'speichersysteme',
        },
      ]"
    />
    <NavLink
      :activeItem="activeItem"
      header="Material"
      link="/material"
      iconName="flaticon-price-tag"
      index="material"
      isHeader
      :childrenLinks="[
        {
          header: 'Material Stammdaten',
          link: '/material/stammdaten',
          index: 'materialstammdaten',
        },
        {
          header: 'Lager',
          link: '/material/lager',
          index: 'materiallager',
        },
      ]"
    />
    <NavLink
      :activeItem="activeItem"
      header="Berichte"
      link="/berichte"
      iconName="flaticon-diploma"
      index="berichte"
      isHeader
      :childrenLinks="[
        {
          header: 'Beispielbilder',
          link: '/berichte/beispielbilder',
          index: 'berichtebeispielbilder',
        },
      ]"
    />
    <NavLink
      :activeItem="activeItem"
      header="Editor"
      link="/dokumente"
      iconName="flaticon-document"
      index="evu"
      isHeader
    />
    <NavLink
      :activeItem="activeItem"
      header="Angebote"
      link="/angebote"
      iconName="flaticon-note"
      index="angebote"
      isHeader
    />
    <NavLink
      v-if="user && authorized"
      :activeItem="activeItem"
      header="Academy"
      link="/academy"
      iconName="flaticon-diploma"
      index="academy"
      isHeader
      :childrenLinks="[
        {
          header: 'Course',
          link: '/academy/kurse',
          index: 'kurse',
        },
        {
          header: 'Schüler',
          link: '/academy/schueler',
          index: 'students',
        },
        {
          header: 'Trainer',
          link: '/academy/trainer',
          index: 'students',
        },
        {
          header: 'Academy',
          link: '/academy',
          index: 'academy-page',
        },
        {
          header: 'Stundenplantermine',
          link: '/academy/lehrplan',
          index: 'academy-lehrplan',
        },
        {
          header: 'Calendar',
          link: '/academy/stundenplan',
          index: 'academy-calendar',
        },
        {
          header: 'Lehrinhalte',
          link: '/academy/lehrmaterial',
          index: 'lehrmaterial',
        },
        {
          header: 'Klasseninhalte',
          link: '/academy/klassen',
          index: 'klassen',
        },
      ]"
    />

    <NavLink
      v-if="user && authorized"
      :activeItem="activeItem"
      header="Bewerber"
      link="/bewerber"
      iconName="flaticon-radar"
      index="aufgaben"
      isHeader
    />
    <!-- <NavLink
      v-if="user && authorized"
      :activeItem="activeItem"
      header="Dashboards"
      link="/dashboards/"
      iconName="flaticon-binoculars"
      index="dashboards"
      isHeader
      :childrenLinks="[{ header: 'Dev', link: '/dashboards/dev', index: 'dev' }]"
    /> -->
    <NavLink
      v-if="user && authorized"
      :activeItem="activeItem"
      header="Zeiterfassung"
      link="/zeiterfassung/"
      iconName="flaticon-binoculars"
      index="adminTimeTracking"
      isHeader
    />

    <NavLink
      :activeItem="activeItem"
      header="Aktivitätsprotokoll"
      link="/aktivitaetsprotokoll"
      iconName="flaticon-note"
      index="aktivitaetsprotokoll"
      isHeader
    />

    <NavLink
      :activeItem="activeItem"
      header="Updates"
      link="/updates"
      iconName="flaticon-idea"
      index="updates"
      isHeader
    />
  </ul>
</template>

<script>
import NavLink from './NavLink/NavLink';
import { mapGetters } from 'vuex';
import { authorizeByUser } from '@/helpers/userCapabilities';

export default {
  name: 'AdminNavigation',
  components: { NavLink },
  props: ['activeItem'],
  methods: {
    authorizeByUser,
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUser']),
    ...mapGetters(['getUser']),
    user() {
      return this.getUser(this.getCurrentUser.id);
    },
    authorized() {
      return this.authorizeByUser(this.user, [
        'c.minte@q-p-s.com',
        'sebastian@mertenonline.de',
        'a.gladstone@eway-energy.com',
        'e.sciacca@ssp-elektro.com',
        'p.sciacca@q-p-s.com',
        'e.sciacca@q-p-s.com',
        'd.hackmann@eway-energy.com',
        'a.eber@q-p-s.com',
        'p.sciacca@ssp-elektro.com',
        's.tam@ssp-elektro.com',
        'a.traunfellner@best-in-biz.com',
        'd.hopp@eway-energy.com',
        'w.gunsch@q-p-s.com',
        't.nguyen@q-p-s.com',
        'm.oezer@eway-energy.com',
        'd.tuncer@eway-energy.com',
        's.tam@ssp-elektro.com',
      ]);
    },
  },
  created() {},
};
</script>

<style>
.sub-menu .router-link-active .icon {
  display: none;
}
</style>
