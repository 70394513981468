/**
 * Calculate and format inverter inverter related values
 */
import { i18n } from '@/helpers/i18n';

export const inverterCount = (project) => {
  let count = 0;
  count += project.solarPlant.inverter1 ? 1 : 0;
  count += project.solarPlant.inverter2 ? 1 : 0;
  return count;
};

/**
 * Determine how many identical inverters are assigned to a project
 * @param {*} project
 * @returns
 */
export const inverterIdenticalCount = (project) => {
  if (!project.solarPlant.inverter1 && !project.solarPlant.inverter2) {
    return null;
  }

  if (project.solarPlant.inverter1 && project.solarPlant.inverter2) {
    if (project.solarPlant.inverter1.id == project.solarPlant.inverter2.id) {
      return 2;
    }
    return 1;
  }
  return 1;
};

/**
 * Nennleistung Inverter 1, formatiert
 * @param {*} project
 * @returns
 */
export const formatInverter1Power = (project) => {
  if (project.solarPlant.inverter1) {
    return i18n.n(project.solarPlant.inverter1.nominalPower, 'decimal_single');
  }
  return i18n.n(0, 'decimal_single');
};

/**
 * Nennleistung Inverter 2, formatiert
 * @param {*} project
 * @returns
 */
export const formatInverter2Power = (project) => {
  if (project.solarPlant.inverter2) {
    return i18n.n(project.solarPlant.inverter2.nominalPower, 'decimal_single');
  }
  return i18n.n(0, 'decimal_single');
};

/**
 * Scheinleistung Summe Inverter 1 + 2, formatiert
 * @param {*} project
 * @returns
 */
export const formatInverterApparentPowerOverall = (project) => {
  const inverter1Power =
    project.solarPlant && project.solarPlant.inverter1
      ? project.solarPlant.inverter1.apparentPower
      : 0;
  const inverter2Power =
    project.solarPlant && project.solarPlant.inverter2
      ? project.solarPlant.inverter2.apparentPower
      : 0;
  return i18n.n(inverter1Power + inverter2Power, 'decimal_single');
};

/**
 * Wirkleistung Summe Inverter 1 + 2, formatiert
 * @param {*} project
 * @returns
 */
export const formatInverterActivePowerOverall = (project) => {
  const inverter1Power =
    project.solarPlant && project.solarPlant.inverter1
      ? project.solarPlant.inverter1.activePower
      : 0;
  const inverter2Power =
    project.solarPlant && project.solarPlant.inverter2
      ? project.solarPlant.inverter2.activePower
      : 0;
  return i18n.n(inverter1Power + inverter2Power, 'decimal_single');
};

/**
 * Nennleistung Summe Inverter 1 + 2, formatiert
 * @param {*} project
 * @returns
 */
export const inverterNominalPowerOverall = (project) => {
  const inverter1Power =
    project.solarPlant && project.solarPlant.inverter1
      ? project.solarPlant.inverter1.nominalPower
      : 0;
  const inverter2Power =
    project.solarPlant && project.solarPlant.inverter2
      ? project.solarPlant.inverter2.nominalPower
      : 0;
  const powerOverall = inverter1Power + inverter2Power;
  return powerOverall;
};

/**
 * Nennleistung Summe, formatiert
 * @param {*} project
 * @returns
 */
export const formatInverterNominalPowerOverall = (project) => {
  return i18n.n(inverterNominalPowerOverall(project), 'decimal_single');
};

/**
 * Wirkleistung Summe
 * @param {*} project
 * @returns
 */
export const inverterActivePowerOverall = (project) => {
  const inverter1Power =
    project.solarPlant &&
    project.solarPlant.inverter1 &&
    typeof project.solarPlant.inverter1.activePower === 'number'
      ? project.solarPlant.inverter1.activePower
      : 0;
  const inverter2Power =
    project.solarPlant &&
    project.solarPlant.inverter2 &&
    typeof project.solarPlant.inverter2.activePower === 'number'
      ? project.solarPlant.inverter2.activePower
      : 0;
  const powerOverall = inverter1Power + inverter2Power;
  // console.log(powerOverall);
  return powerOverall;
};

/**
 * nominalPower / number of selected phases in netOutputState
 * @param {*} project
 * @returns
 */
export const formatInverterNominalPowerPerPhase = (project) => {
  const phases = phaseCount(project.solarPlant.netOutputStates);
  const power = inverterNominalPowerOverall(project);
  // console.log(power, phases, power / phases);
  return i18n.n(power / phases, 'decimal_triple');
};

/**
 * activePower / number of selected phases in netOutputState
 * @param {*} project
 * @returns
 */
export const formatInverterActivePowerPerPhase = (project) => {
  const phases = phaseCount(project.solarPlant.netOutputStates);
  const power = inverterActivePowerOverall(project);
  return i18n.n(power / phases, 'decimal_triple');
};

export const formatInverterActivePowerByPhase = (project, phase) => {
  const nrPhases = phaseCount(project.solarPlant.netOutputStates);
  const power = inverterActivePowerOverall(project);

  if (project.solarPlant.netOutputStates.find((state) => state === 'THREEWAY_CURRENT')) {
    return i18n.n(power / nrPhases, 'decimal_triple');
  }

  if (project.solarPlant.netOutputStates.find((state) => state === phase)) {
    return i18n.n(power / nrPhases, 'decimal_triple');
  }

  return i18n.n(0, 'decimal_triple');
};
export const formatInverterNominalPowerByPhase = (project, phase) => {
  const nrPhases = phaseCount(project.solarPlant.netOutputStates);
  const power = inverterNominalPowerOverall(project);

  if (project.solarPlant.netOutputStates.find((state) => state === 'THREEWAY_CURRENT')) {
    return i18n.n(power / nrPhases, 'decimal_triple');
  }

  if (project.solarPlant.netOutputStates.find((state) => state === phase)) {
    return i18n.n(power / nrPhases, 'decimal_triple');
  }

  return i18n.n(0, 'decimal_triple');
};

export const phaseCount = (netOutputStates) => {
  if (
    netOutputStates.length === 1 &&
    (netOutputStates[0] === 'L1' || netOutputStates[0] === 'L2' || netOutputStates[0] === 'L3')
  ) {
    return 1;
  }

  if (netOutputStates.length === 1 && netOutputStates[0] === 'THREEWAY_CURRENT') {
    return 3;
  }
  if (netOutputStates.length === 1) {
    return 1;
  }
  if (netOutputStates.length === 2) {
    return 2;
  }
  if (netOutputStates.length === 3) {
    return 3;
  }

  return 1;
};

export const formatInverterCompositeType = (project) => {
  if (
    project.solarPlant.inverter1 &&
    project.solarPlant.inverter2 &&
    project.solarPlant.inverter1.id !== project.solarPlant.inverter2.id
  ) {
    return project.solarPlant.inverter1.type + ' / ' + project.solarPlant.inverter2.type;
  }
  if (project.solarPlant.inverter1) {
    return project.solarPlant.inverter1.type;
  }
  if (project.solarPlant.inverter2) {
    return project.solarPlant.inverter2.type;
  }
};

export const formatInverterCompositeManufacturer = (project) => {
  if (
    project.solarPlant.inverter1 &&
    project.solarPlant.inverter2 &&
    project.solarPlant.inverter1.manufacturer !== project.solarPlant.inverter2.manufacturer
  ) {
    return (
      project.solarPlant.inverter1.manufacturer + ' / ' + project.solarPlant.inverter2.manufacturer
    );
  }
  if (project.solarPlant.inverter1) {
    return project.solarPlant.inverter1.manufacturer;
  }
  if (project.solarPlant.inverter2) {
    return project.solarPlant.inverter2.manufacturer;
  }
};
export const formatInverterCompositeManufacturerType = (project) => {
  return (
    formatInverterCompositeManufacturer(project) + ' - ' + formatInverterCompositeType(project)
  );
};
