export const hasRole = (user, requiredRole) => {
  if (requiredRole === 'ADMIN') {
    return user.role === 'ADMIN';
  }
  if (requiredRole === 'EMPLOYER') {
    return user.role === 'ADMIN' || user.role === 'EMPLOYER';
  }
  return true;
};

/**
 * Determine if a user is a customer
 * @todo consolidate with same function in auth.js
 * @param {*} user
 */
// export const isEmployer = (user) => user.role === 'EMPLOYER';

/**
 * check if user has one of the required roles
 * @param {*} user
 * @param {*} authorizedRoles
 * @param {*} companyContextSlug
 * @returns
 */
export const authorize = (user, authorizedRoles, routeSlug) => {
  // if user is a customer he can only access resources of his own company
  // if (
  //   (user.role === 'EMPLOYER') &&
  //   user.company.slug != routeSlug
  // ) {
  //   return false;
  // }

  // check if user has one of the required roles
  let authorized = false;
  authorizedRoles.map((role) => {
    if (role === user.role) {
      authorized = true;
    }
  });

  return authorized;
};

/**
 * check if the users email matches one of the authorizedUsers
 * @param {*} user
 * @param {*} authorizedUsers
 * @returns
 */
export const authorizeByUser = (user, authorizedUsers) => {
  let authorized = false;
  authorizedUsers.map((email) => {
    if (email === user.email) {
      authorized = true;
    }
  });

  return authorized;
};
