export default {
  // Wechselrichter Allgemein
  key: 'inverterCommon',
  label: 'inverterCommon',
  children: [
    {
      // Anzahl Wechselrichter
      key: 'inverterCommon_inverterCount',
      name: 'solarPlant.inverterCount',
      label: 'count',
      type: 'INTEGER',
    },
    {
      // Anzahl Wechselrichter Baugleich
      key: 'inverterCommon_inverterIdenticalCount',
      name: 'solarPlant.inverterIdenticalCount',
      label: 'identicalCount',
      type: 'INTEGER',
    },
    {
      // Zusammengesetzter Hersteller aus Wechselrichter 1 und 2 (mit / getrennt)
      key: 'inverterCommon_compositeManufacturer',
      name: 'solarPlant.inverterCompositeManufacturer',
      label: 'compositeManufacturer',
    },
    {
      // Zusammengesetzter Wechselrichter-Typ aus Wechselrichter 1 und 2 (mit / getrennt)
      key: 'inverterCommon_compositeType',
      name: 'solarPlant.inverterCompositeType',
      label: 'compositeType',
    },
    {
      // Zusammengesetzter Wechselrichter-Hersteller und Typ
      key: 'inverterCommon_inverterCompositeManufacturerType',
      name: 'solarPlant.inverterCompositeManufacturerType',
      label: 'compositeManufacturerType',
    },
    {
      // Scheinleistung Gesamt
      key: 'inverterCommon_apparentPowerOverall',
      name: 'solarPlant.apparentPowerOverall',
      label: 'apparentPowerOverall',
    },
    {
      // Wirkleistung Gesamt
      key: 'inverterCommon_activePowerOverall',
      name: 'solarPlant.activePowerOverall',
      label: 'activePowerOverall',
    },
    {
      // Nennleistung Gesamt
      key: 'inverterCommon_nominalPowerOverall',
      name: 'solarPlant.nominalPowerOverall',
      label: 'nominalPowerOverall',
    },
    {
      // Nennleistung pro Phase
      key: 'inverterCommon_nominalPowerPerPhase',
      name: 'solarPlant.nominalPowerPerPhase',
      label: 'nominalPowerPerPhase',
    },
    {
      // Nennleistung Phase 1
      key: 'inverterCommon_nominalPowerPhase1',
      name: 'solarPlant.nominalPowerPhase1',
      label: 'nominalPowerPhase1',
    },
    {
      // Nennleistung Phase 2
      key: 'inverterCommon_nominalPowerPhase2',
      name: 'solarPlant.nominalPowerPhase2',
      label: 'nominalPowerPhase2',
    },
    {
      // Nennleistung Phase 3
      key: 'inverterCommon_nominalPowerPhase3',
      name: 'solarPlant.nominalPowerPhase3',
      label: 'nominalPowerPhase3',
    },
    {
      // Wirkleistung pro Phase
      key: 'inverterCommon_activePowerPerPhase',
      name: 'solarPlant.activePowerPerPhase',
      label: 'activePowerPerPhase',
    },
    {
      // Wirkleistung Phase 1
      key: 'inverterCommon_activePowerPhase1',
      name: 'solarPlant.activePowerPhase1',
      label: 'activePowerPhase1',
    },
    {
      // Wirkleistung Phase 2
      key: 'inverterCommon_activePowerPhase2',
      name: 'solarPlant.activePowerPhase2',
      label: 'activePowerPhase2',
    },
    {
      // Wirkleistung Phase 3
      key: 'inverterCommon_activePowerPhase3',
      name: 'solarPlant.activePowerPhase3',
      label: 'activePowerPhase3',
    },
  ],
};
