export default {
  // Wechelrichter 2 V2
  key: '1.6',
  label: 'inverter2',
  children: [
    {
      key: 'inverter2_count',
      name: 'solarPlant.inverter2_count',
      label: 'count',
      type: 'INTEGER',
    },
    {
      key: '1.6.0',
      name: 'solarPlant.inverter2_manufacturer_type',
      label: 'manufacturer_type',
    },
    {
      key: '1.6.1',
      name: 'solarPlant.inverter2.manufacturer',
      label: 'manufacturer',
    },
    { key: '1.6.2', name: 'solarPlant.inverter2.type', label: 'type' },
    {
      key: '1.6.3',
      name: 'solarPlant.inverter2.apparentPower',
      label: 'apparentPower',
    },
    {
      key: '1.6.4',
      name: 'solarPlant.inverter2.activePower',
      label: 'activePower',
    },
    {
      key: '1.6.5',
      name: 'solarPlant.inverter2.activePowerPerPhase',
      label: 'activePowerPerPhase',
    },
    {
      key: '1.6.7',
      name: 'solarPlant.inverter2.ratedCurrent',
      label: 'ratedCurrent',
    },
    {
      key: '1.6.8',
      name: 'solarPlant.inverter2.shortCircuitCurrent',
      label: 'shortCircuitCurrent',
    },
    {
      key: '1.6.9',
      name: 'solarPlant.inverter2.nominalDcPower',
      label: 'nominalDcPower',
    },
    {
      key: 'inverter2_nominalPower',
      name: 'solarPlant.inverter2.nominalPower',
      label: 'nominalPower',
    },
    {
      key: 'inverter2_nominalVoltage',
      name: 'solarPlant.inverter2.nominalVoltage',
      label: 'nominalVoltage',
    },
    {
      key: '1.6.10',
      name: 'solarPlant.inverter2.allowedChargeDischargeCurrent',
      label: 'allowedChargeDischargeCurrent',
    },
    {
      key: 'inverter2_gridAndPlantProtectionIntegrated',
      name: 'solarPlant.inverter2.gridAndPlantProtectionIntegrated',
      label: 'gridAndPlantProtectionIntegrated',
    },
    {
      key: 'inverter2_gridAndPlantProtectionType',
      name: 'solarPlant.inverter2.gridAndPlantProtectionType',
      label: 'gridAndPlantProtectionType',
      translate: true,
    },
  ],
};
