import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const getDefaultState = () => {
  return {
    roles: [],
  };
};
const state = getDefaultState();

/**
 * Actions
 */
const actions = {
  /**
   * Reset state in module
   * @param commit
   */
  reset({ commit }) {
    commit('SET_DEFAULT_STATE');
  },
  /**
   * Fetch all userRoles from GraphQL API using an introspection query
   * @param commit
   * @returns {Promise<void>}
   */
  async fetchUserRoles({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "UserRole") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      // console.log(response.data.__type.enumValues)
      commit('SET_ROLES', response.data.__type.enumValues);
    } catch (err) {
      // console.log(err)
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_ROLES: (state, roles) => (state.roles = roles),
};

/**
 * Getters
 */
const getters = {
  getUserRoles: (state) => state.roles,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
