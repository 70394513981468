<template>
  <div id="app" class="spinner">
    <FulfillingBouncingCircleSpinner :animation-duration="1800" :size="size" color="#49b4ed" />
  </div>
</template>

<script>
// To use minified css and js files instead of .vue single file components:
// import 'epic-spinners/dist/lib/epic-spinners.min.css'
// import {AtomSpinner} from 'epic-spinners/dist/lib/epic-spinners.min.js'

import { HalfCircleSpinner } from 'epic-spinners';
import { FulfillingBouncingCircleSpinner } from 'epic-spinners';

export default {
  name: 'Spinner',
  components: {
    FulfillingBouncingCircleSpinner,
  },
  props: {
    size: {
      default: 25,
    },
  },
};
</script>

<style scoped>
.spinner {
  display: inline-block;
}
</style>
