import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const getDefaultState = () => {
  return {};
};
const state = getDefaultState();

/**
 * Actions
 */
const actions = {
  async fetchSignedUrl({ commit }, { attachmentId, downloadType }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query signedUrl($attachmentId: ID!, $downloadType: String!) {
            signedUrl(attachmentId: $attachmentId, downloadType: $downloadType)
          }
        `,
        variables: {
          attachmentId: attachmentId,
          downloadType: downloadType,
        },
      });
      return response.data.signedUrl;
    } catch (err) {
      throw new Error(err);
    }
  },
};

/**
 * Mutations
 */
const mutations = {};

/**
 * Getters
 */
const getters = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
