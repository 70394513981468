<template>
  <div class="auth-page">
    <b-container>
      <h5 class="auth-logo">
        <img class="image" src="../../assets/qps.png" alt="Q Power Solution GmbH" />
      </h5>
      <Widget class="widget-auth mx-auto" title="<h3 class='mt-0'>Willkommen</h3>" customHeader>
        <p class="widget-auth-info">Bitte mit E-Mail-Adresse einloggen.</p>
        <form class="mt" @submit.prevent="login">
          <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">{{
            errorMessage
          }}</b-alert>
          <div class="form-group">
            <input
              autofocus
              class="form-control no-border"
              autocomplete="username"
              ref="email"
              required
              type="email"
              name="email"
              placeholder="E-mail"
            />
          </div>
          <div class="form-group">
            <input
              class="form-control no-border"
              autocomplete="current-password"
              ref="password"
              required
              type="password"
              name="current-password"
              placeholder="Passwort"
            />
          </div>
          <b-button type="submit" size="sm" class="auth-btn mb-3" variant="bg">
            <div v-if="isLoading">
              <span>{{ waitingMessage }}</span>
              <Spinner :size="15" class="login-spinner" />
            </div>
            <span v-else>Login</span>
          </b-button>
        </form>
      </Widget>
    </b-container>
    <footer class="auth-footer">{{ year }} &copy; Q Power Solution GmbH</footer>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import dayjs from 'dayjs';
import { mapState, mapActions, mapGetters } from 'vuex';
import Spinner from '@/components/Spinner.vue';

export default {
  name: 'LoginPage',
  components: { Widget, Spinner },
  data() {
    return {
      waitingMessage: '',
      waitingLevel: 0,
    };
  },
  computed: {
    ...mapState('auth', {
      isFetching: (state) => state.isFetching,
      errorMessage: (state) => state.errorMessage,
      year() {
        return dayjs().format('YYYY');
      },
    }),
    ...mapGetters(['isLoading']),
  },
  methods: {
    ...mapActions('auth', ['loginUser', 'graphqlLogin', 'receiveToken']),
    generateWaitingMessage() {
      const messages = [
        ['Lädt ...', "Gleich geht's los ...", 'Bitte warten ...'],
        [
          'Mache Kafee ...',
          'Tee aufbrühen ...',
          'Berechne Raumzeitkoordinaten ...',
          'Wir sind gleich soweit ...',
          "Wenn's mal wieder länger dauert ...",
          'Server neustarten ...',
          'Autopilot aktivieren ...',
          'Umlaufbahn berechnen ...',
        ],
      ];

      const messageIndex = this.randomInteger(0, messages[this.waitingLevel].length - 1);
      this.waitingMessage = messages[this.waitingLevel][messageIndex];

      if (this.waitingLevel === 0) {
        this.waitingLevel++;
      }
    },
    randomInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    async login() {
      const email = this.$refs.email.value;
      const password = this.$refs.password.value;

      const interval = setInterval(this.generateWaitingMessage, 3000);

      if (email.length !== 0 && password.length !== 0) {
        await this.graphqlLogin({ email, password });
        clearInterval(interval);
        await this.$router.push('/').catch((err) => {});
      }
    },
  },
  created() {
    this.generateWaitingMessage();
  },
  mounted() {},
};
</script>

<style scoped>
.login-spinner {
  padding-left: 5px;
  position: relative;
  top: 3px;
}
.auth-logo img {
  width: 240px;
}
</style>
