import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const defaultItemStorage = () => {
  return {
    _id: '_new',
    number: '_new',
    name: '',
    item: [],
    itemStorageType: 'VEHICLE',
  };
};

const getDefaultState = () => {
  return {
    itemStorages: [],
    itemStorageCount: 0,
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an itemStorage
 * used for all queries/mutations which return an itemStorage
 * @type {DocumentNode}
 */
const ITEM_STORAGE_REQUEST_FIELDS = gql`
  fragment itemStorageFields on ItemStorage {
    _id
    number
    name
    items {
      count
      item {
        number
        description
      }
    }
    itemStorageType
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} itemStorage
 */
const createPayload = (itemStorage) => {
  const payload = {
    ...{ name: itemStorage.name },
    ...{ itemStorageType: itemStorage.itemStorageType },
  };
  return payload;
};

/**
 * Actions
 */
const actions = {
  /**
   * Fetch a paginated, sorted and filtered itemStorage list
   * @param {*} param0
   * @param {*} payload
   */
  async fetchItemStoragesPaginated({ commit, dispatch }, { pagination, sorting, filters }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query itemStoragesPaginated(
            $pagination: PaginationInput
            $sorting: SortingInput
            $filters: ItemStorageFilterInput
          ) {
            itemStoragesPaginated(pagination: $pagination, sorting: $sorting, filters: $filters) {
              itemStorages {
                ...itemStorageFields
              }
              itemStorageCount
            }
          }
          ${ITEM_STORAGE_REQUEST_FIELDS}
        `,
        variables: {
          pagination: pagination,
          sorting: sorting,
          filters: filters,
        },
        fetchPolicy: 'network-only',
      });
      commit('SET_ITEM_STORAGES', response.data.itemStoragesPaginated.itemStorages);
      commit('SET_ITEM_STORAGE_COUNT', response.data.itemStoragesPaginated.itemStorageCount);
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },

  async fetchItemStorageByNumber({ commit, dispatch }, itemStorageNumber) {
    try {
      // console.log(itemStorageNumber);
      const response = await apolloClient.query({
        query: gql`
          query itemStorageByNumber($itemStorageNumber: Int!) {
            itemStorageByNumber(itemStorageNumber: $itemStorageNumber) {
              ...itemStorageFields
            }
          }
          ${ITEM_STORAGE_REQUEST_FIELDS}
        `,
        variables: {
          itemStorageNumber: parseInt(itemStorageNumber),
        },
      });
      commit('ADD_OR_UPDATE_ITEM_STORAGE', response.data.itemStorageByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async createItemStorage({ commit, dispatch }, itemStorage) {
    const payload = createPayload(itemStorage);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createItemStorage($payload: ItemStorageInput!) {
            createItemStorage(itemStorageInput: $payload) {
              ...itemStorageFields
            }
          }
          ${ITEM_STORAGE_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Material gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_ITEM_STORAGE', '_new'); // remove temporary _new itemStorage
      commit('ADD_OR_UPDATE_ITEM_STORAGE', response.data.createItemStorage);
      return response.data.createItemStorage.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateItemStorage({ commit, dispatch }, itemStorage) {
    const payload = createPayload(itemStorage);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateItemStorage($itemStorageNumber: Int!, $payload: ItemStorageInput!) {
            updateItemStorage(itemStorageNumber: $itemStorageNumber, itemStorageInput: $payload) {
              ...itemStorageFields
            }
          }
          ${ITEM_STORAGE_REQUEST_FIELDS}
        `,
        variables: {
          itemStorageNumber: itemStorage.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Material aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_ITEM_STORAGE', response.data.updateItemStorage);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteItemStorage({ commit, dispatch }, itemStorageNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteItemStorage($itemStorageNumber: Int!) {
            deleteItemStorage(itemStorageNumber: $itemStorageNumber)
          }
        `,
        variables: {
          itemStorageNumber: itemStorageNumber,
        },
      });
      dispatch('message', {
        message: 'Material gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_ITEM_STORAGE', itemStorageNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new itemStorage object in store and return it
   * @param {*} param0
   * @param {*} itemStorage
   */
  async initItemStorage({ commit }) {
    commit('ADD_OR_UPDATE_ITEM_STORAGE', defaultItemStorage());
  },

  itemStorageLocalUpdate({ commit }, itemStorage) {
    commit('ADD_OR_UPDATE_ITEM_STORAGE', itemStorage);
  },

  itemStorageCleanup({ commit }) {
    commit('REMOVE_ITEM_STORAGE', '_new');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_ITEM_STORAGE_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_ITEM_STORAGES: (state, itemStorages) =>
    itemStorages ? (state.itemStorages = itemStorages) : (state.itemStorages = []),
  ADD_ITEM_STORAGE: (state, itemStorage) => state.itemStorages.push(itemStorage),
  ADD_OR_UPDATE_ITEM_STORAGE: (state, updatedItemStorage) => {
    const existingItemStorage = state.itemStorages.find(
      (itemStorage) => itemStorage._id === updatedItemStorage._id
    );
    if (existingItemStorage) {
      Object.assign(existingItemStorage, updatedItemStorage);
    } else {
      state.itemStorages.push(updatedItemStorage);
    }
  },
  REMOVE_ITEM_STORAGE: (state, itemStorageId) => {
    const index = state.itemStorages.findIndex((itemStorage) => itemStorage._id === itemStorageId);
    state.itemStorages.splice(index, 1);
  },
  UPDATE_ITEM_STORAGE: (state, updatedItemStorage) => {
    const itemStorage = state.itemStorages.find(
      (itemStorage) => itemStorage._id === updatedItemStorage._id
    );
    Object.assign(itemStorage, updatedItemStorage);
  },
  SET_ITEM_STORAGE_COUNT: (state, itemStorageCount) => (state.itemStorageCount = itemStorageCount),
};

/**
 * Getters
 */
const getters = {
  getItemStorages: (state) => state.itemStorages,
  getItemStorage: (state) => (itemStorageId) =>
    state.itemStorages.find((itemStorage) => itemStorage._id == itemStorageId),
  getItemStoragesByType: (state) => (itemStorageType) =>
    state.itemStorages.filter((itemStorage) => itemStorage.type == itemStorageType),
  getItemStorageByNumber: (state) => (itemStorageNumber) =>
    state.itemStorages.find((itemStorage) => itemStorage.number == itemStorageNumber),
  getItemStorageCount: (state) => state.itemStorageCount,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
