export default {
  // EnFluRi
  key: '1.8',
  label: 'energyFlowDirectionSensor_short',
  children: [
    {
      key: '1.8.0',
      name: 'solarPlant.energyFlowDirectionSensor_manufacturer_type',
      label: 'manufacturer_type',
    },
    {
      key: '1.8.1',
      name: 'solarPlant.energyFlowDirectionSensor.manufacturer',
      label: 'manufacturer',
    },
    {
      key: '1.8.2',
      name: 'solarPlant.energyFlowDirectionSensor.type',
      label: 'type',
    },
    {
      key: '1.8.3',
      name: 'solarPlant.energyFlowDirectionSensorCount',
      label: 'energyFlowDirectionSensorCount',
      type: 'INTEGER',
    },
  ],
};
