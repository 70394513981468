import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from './helpers/i18n';

Vue.use(BootstrapVue);
Vue.config.productionTip = false;

/**
 * Dayjs Helper Functions
 */
import VueDayjs from './plugins/vue-dayjs';
Vue.use(VueDayjs, {});

/**
 * Filter helper functions
 */
import VueCustomFilters from '@/plugins/filters';
Vue.use(VueCustomFilters, {});

Vue.prototype.$eventHub = new Vue(); // Global event bus

import PrimeVue from 'primevue/config';
Vue.use(PrimeVue, {
  locale: {
    accept: 'Yes',
    reject: 'No',
    choose: 'Choose',
    upload: 'Upload',
    cancel: 'Cancel',
    dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
    dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    monthNames: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
    monthNamesShort: [
      'Jan',
      'Feb',
      'Mär',
      'Apr',
      'Mai',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Dez',
    ],
    today: 'Heute',
    clear: 'Clear',
    weekHeader: 'KW',
    firstDayOfWeek: 1,
    dateFormat: 'dd.mm.yy',
    weak: 'Weak',
    medium: 'Medium',
    strong: 'Strong',
    passwordPrompt: 'Enter a password',
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
