import gql from 'graphql-tag';
import apolloClient from '../apollo';
import dayjs from 'dayjs';
import Vue from 'vue';

import 'dayjs/locale/de';

/**
 * State
 */
const defaultApplicant = () => {
  return {
    id: '_new',
    number: '_new',
    applicantState: 'NEW',
  };
};

const getDefaultState = () => {
  return {
    applicants: [],
    applicantCount: 0,
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an applicant
 * used for all queries/mutations which return an applicant
 * @type {DocumentNode}
 */
const APPLICANT_REQUEST_FIELDS = gql`
  fragment applicantFields on Applicant {
    id
    number
    firstname
    lastname
    position
    email
    phone
    zip
    startOfWork
    applicantState
    notes
    createdAt
    updatedAt
    locationOfDeployment
    role
    applicantAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} applicant
 */
const createPayload = (applicant) => {
  const payload = {
    ...(applicant.applicantState ? { applicantState: applicant.applicantState } : {}),
    firstname: applicant.firstname,
    lastname: applicant.lastname,
    position: applicant.position,
    email: applicant.email,
    locationOfDeployment: applicant.locationOfDeployment,
    role: applicant.role,
    phone: applicant.phone,
    zip: applicant.zip,
    startOfWork: applicant.startOfWork,
    notes: applicant.notes,
  };
  return payload;
};

/**
 * Actions
 */
const actions = {
  async fetchApplicants({ commit, dispatch }, { page, pageSize, sortField, sortOrder, filters }) {
    const args = { page, pageSize, sortField, sortOrder, filters };
    try {
      const response = await apolloClient.query({
        query: gql`
          query applicants(
            $page: Int!
            $pageSize: Int!
            $sortField: String
            $sortOrder: Int
            $filters: ApplicantFilterInput
          ) {
            applicants(
              page: $page
              pageSize: $pageSize
              sortField: $sortField
              sortOrder: $sortOrder
              filters: $filters
            ) {
              applicants {
                ...applicantFields
              }
              applicantCount
            }
          }
          ${APPLICANT_REQUEST_FIELDS}
        `,
        variables: {
          page: page,
          pageSize: pageSize,
          sortField: sortField,
          sortOrder: sortOrder,
          filters: filters,
        },
        fetchPolicy: 'network-only',
      });
      commit('SET_APPLICANTS', response.data.applicants.applicants);
      commit('SET_APPLICANT_COUNT', response.data.applicants.applicantCount);
    } catch (err) {
      throw new Error(err);
    }
  },

  async createApplicant({ commit, dispatch }, applicant) {
    const payload = createPayload(applicant);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createApplicant($payload: ApplicantInput!) {
            createApplicant(applicantInput: $payload) {
              ...applicantFields
            }
          }
          ${APPLICANT_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Aufgabe gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_APPLICANT', '_new'); // remove temporary _new applicant
      commit('ADD_OR_UPDATE_APPLICANT', response.data.createApplicant);
      return response.data.createApplicant.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateApplicant({ commit, dispatch }, applicant) {
    const payload = createPayload(applicant);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateApplicant($applicantNumber: Int!, $payload: ApplicantInput!) {
            updateApplicant(applicantNumber: $applicantNumber, applicantInput: $payload) {
              ...applicantFields
            }
          }
          ${APPLICANT_REQUEST_FIELDS}
        `,
        variables: {
          applicantNumber: applicant.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Aufgabe aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_APPLICANT', response.data.updateApplicant);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteApplicant({ commit, dispatch }, applicantNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteApplicant($applicantNumber: Int!) {
            deleteApplicant(applicantNumber: $applicantNumber)
          }
        `,
        variables: {
          applicantNumber: applicantNumber,
        },
      });
      dispatch('message', {
        message: 'Aufgabe gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      // console.log(response.data.deleteApplicant);

      commit('REMOVE_APPLICANT', applicantNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Upload an image belonging to the applicant
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async uploadApplicantAttachment({ commit, dispatch }, payload) {
    // console.log(payload);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation uploadApplicantAttachment($attachmentInput: AttachmentWithTypeInput!) {
            uploadApplicantAttachment(attachmentInput: $attachmentInput) {
              ...applicantFields
            }
          }
          ${APPLICANT_REQUEST_FIELDS}
        `,
        variables: {
          attachmentInput: {
            file: payload.file,
            referenceId: payload.parentId,
            referenceType: payload.parentType,
          },
        },
      });
      commit('UPDATE_APPLICANT_ATTACHMENTS', {
        updatedApplicant: response.data.uploadApplicantAttachment,
        referenceType: payload.parentType,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Upload!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Delete Applicant Atrachment
   * @param commit
   * @param payload
   */
  async deleteApplicantAttachment({ commit, dispatch }, payload) {
    // console.log(payload)
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteApplicantAttachment(
            $applicantId: ID!
            $attachmentId: ID!
            $attachmentType: String!
          ) {
            deleteApplicantAttachment(
              applicantId: $applicantId
              attachmentId: $attachmentId
              attachmentType: $attachmentType
            ) {
              ...applicantFields
            }
          }
          ${APPLICANT_REQUEST_FIELDS}
        `,
        variables: {
          applicantId: payload.applicantId,
          attachmentId: payload.attachmentId,
          attachmentType: payload.attachmentType,
        },
      });
      // console.log(response)
      commit('UPDATE_APPLICANT', response.data.deleteApplicantAttachment);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new applicant object in store and return it
   * @param {*} param0
   * @param {*} applicant
   */
  async initApplicant({ commit }) {
    commit('ADD_OR_UPDATE_APPLICANT', defaultApplicant());
  },

  /**
   * create a new applicant with existing data
   * @param {*} param0
   * @param {*} applicant
   */
  async duplicateApplicant({ commit }, { applicant, installationStartAt }) {
    const duplicate = Object.assign(defaultApplicant(), {
      applicantType: applicant.applicantType,
      project: applicant.project,
      employees: applicant.employees,
      description: applicant.description,
      installationStartAt: installationStartAt,
      installationEndAt: dayjs(installationStartAt)
        .add(8, 'hour')
        .toDate(),
    });

    // console.log(duplicate);
    commit('ADD_OR_UPDATE_APPLICANT', duplicate);
    return duplicate;
  },

  applicantLocalUpdate({ commit }, applicant) {
    commit('ADD_OR_UPDATE_APPLICANT', applicant);
  },

  applicantCleanup({ commit }) {
    commit('REMOVE_APPLICANT', '_new');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_APPLICANT_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_APPLICANTS: (state, applicants) =>
    applicants ? (state.applicants = applicants) : (state.applicants = []),
  ADD_APPLICANT: (state, applicant) => state.applicants.push(applicant),
  UPDATE_APPLICANT: (state, updatedApplicant) => {
    const applicant = state.applicants.find((applicant) => applicant.id === updatedApplicant.id);
    Object.assign(applicant, updatedApplicant);
  },
  ADD_OR_UPDATE_APPLICANT: (state, updatedApplicant) => {
    const existingApplicant = state.applicants.find(
      (applicant) => applicant.id === updatedApplicant.id
    );
    if (existingApplicant) {
      Object.assign(existingApplicant, updatedApplicant);
    } else {
      state.applicants.push(updatedApplicant);
    }
  },
  REMOVE_APPLICANT: (state, applicantNumber) => {
    // console.log(applicantNumber);
    const index = state.applicants.findIndex((applicant) => applicant.number === applicantNumber);
    state.applicants.splice(index, 1);
  },
  SET_APPLICANT_COUNT: (state, applicantCount) => (state.applicantCount = applicantCount),
  UPDATE_APPLICANT_ATTACHMENTS: (state, { updatedApplicant, referenceType }) => {
    const applicant = state.applicants.find((applicant) => applicant.id === updatedApplicant.id);
    Vue.set(applicant, referenceType, updatedApplicant[referenceType]);
  },
};

/**
 * Getters
 */
const getters = {
  getApplicants: (state) => {
    return state.applicants;
  },
  getApplicant: (state) => (applicantId) =>
    state.applicants.find((applicant) => applicant.id == applicantId),
  getApplicantByNumber: (state) => (applicantNumber) =>
    state.applicants.find((applicant) => applicant.number == applicantNumber),
  getApplicantCount: (state) => state.applicantCount,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
