import Vue from 'vue';
import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const defaultReportTemplateImage = () => {
  return {
    id: '_new',
    number: '_new',
    templateAttachments: [],
  };
};

const getDefaultState = () => {
  return {
    reportTemplateImages: [],
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for a ReportTemplateImage
 * used for all queries/mutations which return a ReportTemplateImage
 * @type {DocumentNode}
 */
const REPORT_TEMPLATE_IMAGE_REQUEST_FIELDS = gql`
  fragment reportTemplateImageFields on ReportTemplateImage {
    id
    number
    category
    templateAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
  }
`;

/**
 * Actions
 */
const actions = {
  /**
   * @param {*} param0
   * @param {*} payload
   */
  async fetchReportTemplateImages({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            reportTemplateImages {
              ...reportTemplateImageFields
            }
          }
          ${REPORT_TEMPLATE_IMAGE_REQUEST_FIELDS}
        `,
      });
      commit('SET_REPORT_TEMPLATE_IMAGES', response.data.reportTemplateImages);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchReportTemplateImageByNumber({ commit, dispatch }, reportTemplateImageNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query reportTemplateImageByNumber($reportTemplateImageNumber: Int!) {
            reportTemplateImageByNumber(reportTemplateImageNumber: $reportTemplateImageNumber) {
              ...reportTemplateImageFields
            }
          }
          ${REPORT_TEMPLATE_IMAGE_REQUEST_FIELDS}
        `,
        variables: {
          reportTemplateImageNumber: parseInt(reportTemplateImageNumber),
        },
      });
      commit('ADD_OR_UPDATE_REPORT_TEMPLATE_IMAGE', response.data.reportTemplateImageByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  /**
   * Upload an image belonging to the reportTemplateImage
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async uploadReportTemplateImageAttachment({ commit, dispatch }, payload) {
    // console.log(payload);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation uploadReportTemplateImageAttachment($attachmentInput: AttachmentWithTypeInput!) {
            uploadReportTemplateImageAttachment(attachmentInput: $attachmentInput) {
              ...reportTemplateImageFields
            }
          }
          ${REPORT_TEMPLATE_IMAGE_REQUEST_FIELDS}
        `,
        variables: {
          attachmentInput: {
            file: payload.file,
            referenceId: payload.parentId,
            referenceType: payload.parentType,
          },
        },
      });
      // console.log(response.data);
      // console.log(payload.parentType);
      commit('UPDATE_REPORT_TEMPLATE_IMAGE_ATTACHMENTS', {
        updatedReportTemplateImage: response.data.uploadReportTemplateImageAttachment,
        referenceType: payload.parentType,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Upload!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Delete ReportTemplateImage Atrachment
   * @param commit
   * @param payload
   */
  async deleteReportTemplateImageAttachment({ commit, dispatch }, payload) {
    // console.log(payload)
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteReportTemplateImageAttachment(
            $reportTemplateImageId: ID!
            $attachmentId: ID!
            $attachmentType: String!
          ) {
            deleteReportTemplateImageAttachment(
              reportTemplateImageId: $reportTemplateImageId
              attachmentId: $attachmentId
              attachmentType: $attachmentType
            ) {
              ...reportTemplateImageFields
            }
          }
          ${REPORT_TEMPLATE_IMAGE_REQUEST_FIELDS}
        `,
        variables: {
          reportTemplateImageId: payload.reportTemplateImageId,
          attachmentId: payload.attachmentId,
          attachmentType: payload.attachmentType,
        },
      });
      // console.log(response);
      commit('UPDATE_REPORT_TEMPLATE_IMAGE', response.data.deleteReportTemplateImageAttachment);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_REPORT_TEMPLATE_IMAGES: (state, reportTemplateImages) =>
    reportTemplateImages
      ? (state.reportTemplateImages = reportTemplateImages)
      : (state.reportTemplateImages = []),

  ADD_OR_UPDATE_REPORT_TEMPLATE_IMAGE: (state, updatedReportTemplateImage) => {
    const existingReportTemplateImage = state.reportTemplateImages.find(
      (reportTemplateImage) => reportTemplateImage.id === updatedReportTemplateImage.id
    );
    if (existingReportTemplateImage) {
      Object.assign(existingReportTemplateImage, updatedReportTemplateImage);
    } else {
      state.reportTemplateImages.push(updatedReportTemplateImage);
    }
  },
  UPDATE_REPORT_TEMPLATE_IMAGE: (state, updatedReportTemplateImage) => {
    const reportTemplateImage = state.reportTemplateImages.find(
      (reportTemplateImage) => reportTemplateImage.id === updatedReportTemplateImage.id
    );
    Object.assign(reportTemplateImage, updatedReportTemplateImage);
  },
  UPDATE_REPORT_TEMPLATE_IMAGE_ATTACHMENTS: (
    state,
    { updatedReportTemplateImage, referenceType }
  ) => {
    const reportTemplateImage = state.reportTemplateImages.find(
      (reportTemplateImage) => reportTemplateImage.id === updatedReportTemplateImage.id
    );
    Vue.set(reportTemplateImage, referenceType, updatedReportTemplateImage[referenceType]);
  },
};

/**
 * Getters
 */
const getters = {
  getReportTemplateImages: (state) => state.reportTemplateImages,
  getReportTemplateImage: (state) => (reportTemplateImageId) =>
    state.reportTemplateImages.find(
      (reportTemplateImage) => reportTemplateImage.id == reportTemplateImageId
    ),
  getReportTemplateImageByNumber: (state) => (reportTemplateImageNumber) =>
    state.reportTemplateImages.find(
      (reportTemplateImage) => reportTemplateImage.number == reportTemplateImageNumber
    ),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
