import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const defaultEnergyFlowDirectionSensor = () => {
  return {
    id: '_new',
    number: '_new',
    manufacturer: '',
    type: '',
    attachments: [],
  };
};

const getDefaultState = () => {
  return {
    energyFlowDirectionSensors: [],
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an energyFlowDirectionSensor
 * used for all queries/mutations which return an energyFlowDirectionSensor
 * @type {DocumentNode}
 */
const ENERGY_FLOW_DIRECTION_SENSOR_REQUEST_FIELDS = gql`
  fragment energyFlowDirectionSensorFields on EnergyFlowDirectionSensor {
    id
    number
    manufacturer
    type
    createdAt
    updatedAt
    attachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
  }
`;

/**
 * Actions
 */
const actions = {
  async fetchEnergyFlowDirectionSensors({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            energyFlowDirectionSensors {
              ...energyFlowDirectionSensorFields
            }
          }
          ${ENERGY_FLOW_DIRECTION_SENSOR_REQUEST_FIELDS}
        `,
      });
      commit('SET_ENERGY_FLOW_DIRECTION_SENSORS', response.data.energyFlowDirectionSensors);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchEnergyFlowDirectionSensorByNumber(
    { commit, dispatch },
    energyFlowDirectionSensorNumber
  ) {
    try {
      // console.log(energyFlowDirectionSensorNumber);
      const response = await apolloClient.query({
        query: gql`
          query energyFlowDirectionSensorByNumber($energyFlowDirectionSensorNumber: Int!) {
            energyFlowDirectionSensorByNumber(
              energyFlowDirectionSensorNumber: $energyFlowDirectionSensorNumber
            ) {
              ...energyFlowDirectionSensorFields
            }
          }
          ${ENERGY_FLOW_DIRECTION_SENSOR_REQUEST_FIELDS}
        `,
        variables: {
          energyFlowDirectionSensorNumber: parseInt(energyFlowDirectionSensorNumber),
        },
      });
      commit(
        'ADD_OR_UPDATE_ENERGY_FLOW_DIRECTION_SENSOR',
        response.data.energyFlowDirectionSensorByNumber
      );
    } catch (err) {
      throw new Error(err);
    }
  },

  async createEnergyFlowDirectionSensor({ commit, dispatch }, energyFlowDirectionSensor) {
    /**
     * for text fields differentiate between "" and null: "" are sent to the server as empty value
     * deadlineAt date field: send NULL to server to remove a date
     */
    const payload = {
      ...(energyFlowDirectionSensor.manufacturer
        ? { manufacturer: energyFlowDirectionSensor.manufacturer }
        : {}),
      ...(energyFlowDirectionSensor.type ? { type: energyFlowDirectionSensor.type } : {}),
    };
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createEnergyFlowDirectionSensor($payload: EnergyFlowDirectionSensorInput!) {
            createEnergyFlowDirectionSensor(energyFlowDirectionSensorInput: $payload) {
              ...energyFlowDirectionSensorFields
            }
          }
          ${ENERGY_FLOW_DIRECTION_SENSOR_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Energieflussrichtungssensor gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_ENERGY_FLOW_DIRECTION_SENSOR', '_new'); // remove temporary _new energyFlowDirectionSensor
      commit(
        'ADD_OR_UPDATE_ENERGY_FLOW_DIRECTION_SENSOR',
        response.data.createEnergyFlowDirectionSensor
      );
      return response.data.createEnergyFlowDirectionSensor.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateEnergyFlowDirectionSensor({ commit, dispatch }, energyFlowDirectionSensor) {
    /**
     * for text fields differentiate between "" and null: "" are sent to the server as empty value
     * deadlineAt date field: send NULL to server to remove a date
     */
    const payload = {
      ...(energyFlowDirectionSensor.manufacturer
        ? { manufacturer: energyFlowDirectionSensor.manufacturer }
        : {}),
      ...(energyFlowDirectionSensor.type ? { type: energyFlowDirectionSensor.type } : {}),
    };

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateEnergyFlowDirectionSensor(
            $energyFlowDirectionSensorNumber: Int!
            $payload: EnergyFlowDirectionSensorInput!
          ) {
            updateEnergyFlowDirectionSensor(
              energyFlowDirectionSensorNumber: $energyFlowDirectionSensorNumber
              energyFlowDirectionSensorInput: $payload
            ) {
              ...energyFlowDirectionSensorFields
            }
          }
          ${ENERGY_FLOW_DIRECTION_SENSOR_REQUEST_FIELDS}
        `,
        variables: {
          energyFlowDirectionSensorNumber: energyFlowDirectionSensor.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Energieflussrichtungssensor aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit(
        'ADD_OR_UPDATE_ENERGY_FLOW_DIRECTION_SENSOR',
        response.data.updateEnergyFlowDirectionSensor
      );
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteEnergyFlowDirectionSensor({ commit, dispatch }, energyFlowDirectionSensorNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteEnergyFlowDirectionSensor($energyFlowDirectionSensorNumber: Int!) {
            deleteEnergyFlowDirectionSensor(
              energyFlowDirectionSensorNumber: $energyFlowDirectionSensorNumber
            )
          }
        `,
        variables: {
          energyFlowDirectionSensorNumber: energyFlowDirectionSensorNumber,
        },
      });
      dispatch('message', {
        message: 'Energieflussrichtungssensor gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_ENERGY_FLOW_DIRECTION_SENSOR', energyFlowDirectionSensorNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Upload an image belonging to the energyFlowDirectionSensor
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async uploadEnergyFlowDirectionSensorAttachment({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation uploadEnergyFlowDirectionSensorAttachment($attachmentInput: AttachmentInput!) {
            uploadEnergyFlowDirectionSensorAttachment(attachmentInput: $attachmentInput) {
              ...energyFlowDirectionSensorFields
            }
          }
          ${ENERGY_FLOW_DIRECTION_SENSOR_REQUEST_FIELDS}
        `,
        variables: {
          attachmentInput: {
            file: payload.file,
            referenceId: payload.parentId,
          },
        },
      });
      commit(
        'UPDATE_ENERGY_FLOW_DIRECTION_SENSOR',
        response.data.uploadEnergyFlowDirectionSensorAttachment
      );
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Upload!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Delete EnergyFlowDirectionSensor Atrachment
   * @param commit
   * @param payload
   */
  async deleteEnergyFlowDirectionSensorAttachment({ commit, dispatch }, payload) {
    // console.log(payload)
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteEnergyFlowDirectionSensorAttachment(
            $energyFlowDirectionSensorId: ID!
            $attachmentId: ID!
          ) {
            deleteEnergyFlowDirectionSensorAttachment(
              energyFlowDirectionSensorId: $energyFlowDirectionSensorId
              attachmentId: $attachmentId
            ) {
              ...energyFlowDirectionSensorFields
            }
          }
          ${ENERGY_FLOW_DIRECTION_SENSOR_REQUEST_FIELDS}
        `,
        variables: {
          energyFlowDirectionSensorId: payload.energyFlowDirectionSensorId,
          attachmentId: payload.attachmentId,
        },
      });
      // console.log(response)
      commit(
        'UPDATE_ENERGY_FLOW_DIRECTION_SENSOR',
        response.data.deleteEnergyFlowDirectionSensorAttachment
      );
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new energyFlowDirectionSensor object in store and return it
   * @param {*} param0
   * @param {*} energyFlowDirectionSensor
   */
  async initEnergyFlowDirectionSensor({ commit }) {
    commit('ADD_OR_UPDATE_ENERGY_FLOW_DIRECTION_SENSOR', defaultEnergyFlowDirectionSensor());
  },

  energyFlowDirectionSensorLocalUpdate({ commit }, energyFlowDirectionSensor) {
    commit('ADD_OR_UPDATE_ENERGY_FLOW_DIRECTION_SENSOR', energyFlowDirectionSensor);
  },

  energyFlowDirectionSensorCleanup({ commit }) {
    commit('REMOVE_ENERGY_FLOW_DIRECTION_SENSOR', '_new');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_ENERGY_FLOW_DIRECTION_SENSOR_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_ENERGY_FLOW_DIRECTION_SENSORS: (state, energyFlowDirectionSensors) =>
    energyFlowDirectionSensors
      ? (state.energyFlowDirectionSensors = energyFlowDirectionSensors)
      : (state.energyFlowDirectionSensors = []),
  ADD_ENERGY_FLOW_DIRECTION_SENSOR: (state, energyFlowDirectionSensor) =>
    state.energyFlowDirectionSensors.push(energyFlowDirectionSensor),
  ADD_OR_UPDATE_ENERGY_FLOW_DIRECTION_SENSOR: (state, updatedEnergyFlowDirectionSensor) => {
    const existingEnergyFlowDirectionSensor = state.energyFlowDirectionSensors.find(
      (energyFlowDirectionSensor) =>
        energyFlowDirectionSensor.id === updatedEnergyFlowDirectionSensor.id
    );
    if (existingEnergyFlowDirectionSensor) {
      Object.assign(existingEnergyFlowDirectionSensor, updatedEnergyFlowDirectionSensor);
    } else {
      state.energyFlowDirectionSensors.push(updatedEnergyFlowDirectionSensor);
    }
  },
  SET_ENERGY_FLOW_DIRECTION_SENSOR_TYPES: (state, energyFlowDirectionSensorTypes) => {
    state.energyFlowDirectionSensorTypes = [];
    energyFlowDirectionSensorTypes.map((energyFlowDirectionSensorType) => {
      state.energyFlowDirectionSensorTypes.push(energyFlowDirectionSensorType.name);
    });
  },
  REMOVE_ENERGY_FLOW_DIRECTION_SENSOR: (state, energyFlowDirectionSensorId) => {
    const index = state.energyFlowDirectionSensors.findIndex(
      (energyFlowDirectionSensor) => energyFlowDirectionSensor.id === energyFlowDirectionSensorId
    );
    state.energyFlowDirectionSensors.splice(index, 1);
  },
  UPDATE_ENERGY_FLOW_DIRECTION_SENSOR: (state, updatedEnergyFlowDirectionSensor) => {
    const energyFlowDirectionSensor = state.energyFlowDirectionSensors.find(
      (energyFlowDirectionSensor) =>
        energyFlowDirectionSensor.id === updatedEnergyFlowDirectionSensor.id
    );
    Object.assign(energyFlowDirectionSensor, updatedEnergyFlowDirectionSensor);
  },
};

/**
 * Getters
 */
const getters = {
  getEnergyFlowDirectionSensors: (state) => state.energyFlowDirectionSensors,
  getEnergyFlowDirectionSensor: (state) => (energyFlowDirectionSensorId) =>
    state.energyFlowDirectionSensors.find(
      (energyFlowDirectionSensor) => energyFlowDirectionSensor.id == energyFlowDirectionSensorId
    ),
  getEnergyFlowDirectionSensorByNumber: (state) => (energyFlowDirectionSensorNumber) =>
    state.energyFlowDirectionSensors.find(
      (energyFlowDirectionSensor) =>
        energyFlowDirectionSensor.number == energyFlowDirectionSensorNumber
    ),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
