export default {
  // Kunde
  key: '0',
  label: 'customer',
  children: [
    {
      key: '0.0',
      label: 'personalData',
      children: [
        { key: '0.0.1', name: 'customer.salutation', label: 'salutation' },
        { key: '0.0.2', name: 'customer.company', label: 'company' },
        { key: '0.0.3', name: 'customer.firstname', label: 'firstname' },
        { key: '0.0.4', name: 'customer.lastname', label: 'lastname' },
        {
          key: '0.0.5',
          name: 'composite.customerLastname_customerFirstname',
          label: 'composite.customerLastname_customerFirstname',
        },
        {
          key: '0.0.19',
          name: 'composite.customerFirstname_customerLastname',
          label: 'composite.customerFirstname_customerLastname',
        },
        {
          key: 'composite.customerFirstname_customerLastname_no_comma',
          name: 'composite.customerFirstname_customerLastname_no_comma',
          label: 'composite.customerFirstname_customerLastname_no_comma',
        },
        {
          key: '0.0.6',
          name: 'composite.customerLastname_customerFirstname_customerBirthday',
          label: 'composite.customerLastname_customerFirstname_customerBirthday',
        },
        { key: '0.0.7', name: 'customer.street', label: 'street' },
        { key: '0.0.8', name: 'customer.streetNumber', label: 'streetNumber' },
        {
          key: 'customer.streetNumberSuffix',
          name: 'customer.streetNumberSuffix',
          label: 'streetNumberSuffixShort',
        },
        // {
        //   key: '0.0.20',
        //   name: 'composite.customerStreet_customerStreetNumber_customerZip_customerCity',
        //   label: 'composite.customerStreet_customerStreetNumber_customerZip_customerCity',
        // },
        {
          key: 'composite.customerStreetNumber_customerStreetNumberSuffix',
          name: 'composite.customerStreetNumber_customerStreetNumberSuffix',
          label: 'composite.customerStreetNumber_customerStreetNumberSuffix',
        },
        {
          key: 'composite.customerStreet_customerStreetNumber_customerStreetNumberSuffix',
          name: 'composite.customerStreet_customerStreetNumber_customerStreetNumberSuffix',
          label: 'composite.customerStreet_customerStreetNumber_customerStreetNumberSuffix',
        },
        {
          key:
            'composite.customerStreet_customerStreetNumber_customerStreetNumberSuffix_customerZip_customerCity',
          name:
            'composite.customerStreet_customerStreetNumber_customerStreetNumberSuffix_customerZip_customerCity',
          label:
            'composite.customerStreet_customerStreetNumber_customerStreetNumberSuffix_customerZip_customerCity',
        },
        // {
        //   key: '0.0.9',
        //   name: 'composite.customerStreet_customerStreetNumber',
        //   label: 'street_streetNumber',
        // },
        { key: '0.0.10', name: 'customer.zip', label: 'zip' },
        { key: '0.0.11', name: 'customer.city', label: 'city' },
        {
          key: '0.0.12',
          name: 'composite.customerZip_customerCity',
          label: 'composite.customerZip_customerCity',
        },
        {
          key: '0.0.13',
          name: 'composite.customerZip_customerCity_customerCityPart',
          label: 'composite.customerZip_customerCity_customerCityPart',
        },

        { key: '0.0.14', name: 'customer.phone', label: 'phone' },
        {
          key: '0.0.15',
          name: 'composite.customerPhone_customerEmail',
          label: 'composite.customerPhone_customerEmail',
        },
        { key: '0.0.16', name: 'customer.mobile', label: 'mobile' },
        { key: '0.0.17', name: 'customer.email', label: 'email' },
        { key: '0.0.18', name: 'customer.birthdayAt', label: 'birthdayAt', type: 'DATE' },
      ],
    },
    {
      key: '0.1',
      label: 'bank_and_tax',
      children: [
        { key: '0.1.0', name: 'customer.taxNumber', label: 'taxNumber' },
        { key: '0.1.1', name: 'customer.bank', label: 'bank' },
        { key: '0.1.2', name: 'customer.iban', label: 'iban' },
        { key: '0.1.3', name: 'customer.bic', label: 'bic' },
        { key: '0.1.4', name: 'customer.accountHolder', label: 'accountHolder' },
      ],
    },
    {
      key: '0.2',
      label: 'real_estate',
      children: [
        { key: '0.2.1', name: 'customer.field', label: 'field' },
        { key: '0.2.2', name: 'customer.fieldSector', label: 'fieldSector' },
        { key: '0.2.3', name: 'customer.cityPart', label: 'cityPart' },
        { key: '0.2.4', name: 'customer.boundary', label: 'boundary' },
        { key: '0.2.5', name: 'customer.electricMeterNumber', label: 'electricMeterNumber' },
        { key: '0.2.6', name: 'customer.electricMeterNumber2', label: 'electricMeterNumber2' },
        { key: '0.2.7', name: 'customer.electricMeterNumber3', label: 'electricMeterNumber3' },
        {
          key: 'customer_real_estate',
          name: 'customer.cityPart_fieldSector',
          label: 'cityPart_fieldSector',
        },
      ],
    },
  ],
};
