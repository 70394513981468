import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const defaultWallbox = () => {
  return {
    id: '_new',
    number: '_new',
    manufacturer: '',
    type: '',
    maxNetInputPower: 0,
    maxNetOutputPower: 0,
    minChargingPower: 0,
    maxChargingPower: 0,
    activePowerControllable: 'NO',
    chargingType: null,
    connectionType: null,
    attachments: [],
  };
};

const getDefaultState = () => {
  return {
    wallboxes: [],
    chargingTypes: [],
    connectionTypes: [],
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an wallbox
 * used for all queries/mutations which return an wallbox
 * @type {DocumentNode}
 */
const WALLBOX_REQUEST_FIELDS = gql`
  fragment wallboxFields on Wallbox {
    id
    number
    manufacturer
    type
    maxNetInputPower
    maxNetOutputPower
    minChargingPower
    maxChargingPower
    activePowerControllable
    chargingType
    connectionType
    connectionTypes
    shiftFactorCosphiType
    createdAt
    updatedAt
    attachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} project
 */
const createPayload = (wallbox) => {
  /**
   * for text fields differentiate between "" and null: "" are sent to the server as empty value
   * deadlineAt date field: send NULL to server to remove a date
   */
  const payload = {
    ...(wallbox.manufacturer ? { manufacturer: wallbox.manufacturer } : {}),
    ...(wallbox.type ? { type: wallbox.type } : {}),
    ...(wallbox.maxNetInputPower ? { maxNetInputPower: parseFloat(wallbox.maxNetInputPower) } : {}),
    ...(wallbox.maxNetOutputPower
      ? { maxNetOutputPower: parseFloat(wallbox.maxNetOutputPower) }
      : {}),
    ...(wallbox.minChargingPower ? { minChargingPower: parseFloat(wallbox.minChargingPower) } : {}),
    ...(wallbox.maxChargingPower ? { maxChargingPower: parseFloat(wallbox.maxChargingPower) } : {}),
    activePowerControllable: wallbox.activePowerControllable,
    chargingType: wallbox.chargingType,
    connectionType: wallbox.connectionType,
    connectionTypes: wallbox.connectionTypes,
    shiftFactorCosphiType: wallbox.shiftFactorCosphiType,
  };

  return payload;
};

/**
 * Actions
 */
const actions = {
  async fetchWallboxes({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            wallboxes {
              ...wallboxFields
            }
          }
          ${WALLBOX_REQUEST_FIELDS}
        `,
      });
      commit('SET_WALLBOXES', response.data.wallboxes);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchWallboxByNumber({ commit, dispatch }, wallboxNumber) {
    try {
      // console.log(wallboxNumber);
      const response = await apolloClient.query({
        query: gql`
          query wallboxByNumber($wallboxNumber: Int!) {
            wallboxByNumber(wallboxNumber: $wallboxNumber) {
              ...wallboxFields
            }
          }
          ${WALLBOX_REQUEST_FIELDS}
        `,
        variables: {
          wallboxNumber: parseInt(wallboxNumber),
        },
      });
      commit('ADD_OR_UPDATE_WALLBOX', response.data.wallboxByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchChargingTypes({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "ChargingType") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_CHARGING_TYPES', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  async fetchConnectionTypes({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "ConnectionType") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_CONNECTION_TYPES', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  async createWallbox({ commit, dispatch }, wallbox) {
    const payload = createPayload(wallbox);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createWallbox($payload: WallboxInput!) {
            createWallbox(wallboxInput: $payload) {
              ...wallboxFields
            }
          }
          ${WALLBOX_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Wallbox gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_WALLBOX', '_new'); // remove temporary _new wallbox
      commit('ADD_OR_UPDATE_WALLBOX', response.data.createWallbox);
      return response.data.createWallbox.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateWallbox({ commit, dispatch }, wallbox) {
    const payload = createPayload(wallbox);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateWallbox($wallboxNumber: Int!, $payload: WallboxInput!) {
            updateWallbox(wallboxNumber: $wallboxNumber, wallboxInput: $payload) {
              ...wallboxFields
            }
          }
          ${WALLBOX_REQUEST_FIELDS}
        `,
        variables: {
          wallboxNumber: wallbox.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Wallbox aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_WALLBOX', response.data.updateWallbox);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteWallbox({ commit, dispatch }, wallboxNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteWallbox($wallboxNumber: Int!) {
            deleteWallbox(wallboxNumber: $wallboxNumber)
          }
        `,
        variables: {
          wallboxNumber: wallboxNumber,
        },
      });
      dispatch('message', {
        message: 'Wallbox gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_WALLBOX', wallboxNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Upload an image belonging to the wallbox
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async uploadWallboxAttachment({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation uploadWallboxAttachment($attachmentInput: AttachmentInput!) {
            uploadWallboxAttachment(attachmentInput: $attachmentInput) {
              ...wallboxFields
            }
          }
          ${WALLBOX_REQUEST_FIELDS}
        `,
        variables: {
          attachmentInput: {
            file: payload.file,
            referenceId: payload.parentId,
          },
        },
      });
      commit('UPDATE_WALLBOX', response.data.uploadWallboxAttachment);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Upload!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Delete Wallbox Atrachment
   * @param commit
   * @param payload
   */
  async deleteWallboxAttachment({ commit, dispatch }, payload) {
    // console.log(payload)
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteWallboxAttachment($wallboxId: ID!, $attachmentId: ID!) {
            deleteWallboxAttachment(wallboxId: $wallboxId, attachmentId: $attachmentId) {
              ...wallboxFields
            }
          }
          ${WALLBOX_REQUEST_FIELDS}
        `,
        variables: {
          wallboxId: payload.wallboxId,
          attachmentId: payload.attachmentId,
        },
      });
      // console.log(response)
      commit('UPDATE_WALLBOX', response.data.deleteWallboxAttachment);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new wallbox object in store and return it
   * @param {*} param0
   * @param {*} wallbox
   */
  async initWallbox({ commit }) {
    commit('ADD_OR_UPDATE_WALLBOX', defaultWallbox());
  },

  wallboxLocalUpdate({ commit }, wallbox) {
    commit('ADD_OR_UPDATE_WALLBOX', wallbox);
  },

  wallboxCleanup({ commit }) {
    commit('REMOVE_WALLBOX', '_new');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_WALLBOX_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_WALLBOXES: (state, wallboxes) =>
    wallboxes ? (state.wallboxes = wallboxes) : (state.wallboxes = []),
  ADD_WALLBOX: (state, wallbox) => state.wallboxes.push(wallbox),
  ADD_OR_UPDATE_WALLBOX: (state, updatedWallbox) => {
    const existingWallbox = state.wallboxes.find((wallbox) => wallbox.id === updatedWallbox.id);
    if (existingWallbox) {
      Object.assign(existingWallbox, updatedWallbox);
    } else {
      state.wallboxes.push(updatedWallbox);
    }
  },
  SET_WALLBOX_TYPES: (state, wallboxTypes) => {
    state.wallboxTypes = [];
    wallboxTypes.map((wallboxType) => {
      state.wallboxTypes.push(wallboxType.name);
    });
  },
  REMOVE_WALLBOX: (state, wallboxId) => {
    const index = state.wallboxes.findIndex((wallbox) => wallbox.id === wallboxId);
    state.wallboxes.splice(index, 1);
  },
  UPDATE_WALLBOX: (state, updatedWallbox) => {
    const wallbox = state.wallboxes.find((wallbox) => wallbox.id === updatedWallbox.id);
    Object.assign(wallbox, updatedWallbox);
  },
  SET_CHARGING_TYPES: (state, chargingTypes) => {
    state.chargingTypes = [];
    chargingTypes.map((chargingType) => {
      state.chargingTypes.push(chargingType.name);
    });
  },
  SET_CONNECTION_TYPES: (state, connectionTypes) => {
    state.connectionTypes = [];
    connectionTypes.map((connectionType) => {
      state.connectionTypes.push(connectionType.name);
    });
  },
};

/**
 * Getters
 */
const getters = {
  getWallboxes: (state) => state.wallboxes,
  getWallbox: (state) => (wallboxId) => state.wallboxes.find((wallbox) => wallbox.id == wallboxId),
  getWallboxByNumber: (state) => (wallboxNumber) =>
    state.wallboxes.find((wallbox) => wallbox.number == wallboxNumber),
  getChargingTypes: (state) => state.chargingTypes,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
