export default {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        filterDateFrom(value, filter) {
          if (
            filter === undefined ||
            filter === null ||
            filter === false ||
            (typeof filter === 'string' && filter.trim() === '')
          ) {
            return true;
          }

          if (value === undefined || value === null) {
            return false;
          }

          // console.log('val:', value);
          // console.log('filter:', filter);

          return this.$dateIsAfter(value, filter);
        },
        filterYesNo(value, filter) {
          if (filter === 'YES' && value) {
            return true;
          }
          if (filter === 'NO' && !value) {
            return true;
          }

          if (!filter) return true;
        },
        filterDate(value, filter) {
          // console.log(value);
          // console.log(filter);
          if (
            filter === undefined ||
            filter === null ||
            (typeof filter === 'string' && filter.trim() === '')
          ) {
            return true;
          }

          if (value === undefined || value === null) {
            return false;
          }

          return this.$date(value).indexOf(filter) >= 0 ? true : false;
        },
        filterDateRange(value, filter) {
          if (!filter || filter.length < 1) {
            return true;
          }
          if (!filter[1]) {
            return true;
          }

          if (value === undefined || value === null) {
            return false;
          }
          // console.log('val:', value);
          // console.log('filter:', filter);
          return true;
          // return this.$dateIsBetween(value, filter);
        },
      },
    });
  },
};
