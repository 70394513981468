export default {
  // Wechelrichter 1 V2
  key: 'inverter1',
  label: 'inverter1',
  children: [
    {
      key: 'inverter1_count',
      name: 'solarPlant.inverter1_count',
      label: 'count',
      type: 'INTEGER',
    },
    {
      key: '1.5.0',
      name: 'solarPlant.inverter1_manufacturer_type',
      label: 'manufacturer_type',
    },
    {
      key: '1.5.1',
      name: 'solarPlant.inverter1.manufacturer',
      label: 'manufacturer',
    },
    { key: '1.5.2', name: 'solarPlant.inverter1.type', label: 'type' },
    {
      key: '1.5.3',
      name: 'solarPlant.inverter1.apparentPower',
      label: 'apparentPower',
    },
    {
      key: '1.5.4',
      name: 'solarPlant.inverter1.activePower',
      label: 'activePower',
    },
    {
      key: '1.5.5',
      name: 'solarPlant.inverter1.activePowerPerPhase',
      label: 'activePowerPerPhase',
    },
    {
      key: '1.5.7',
      name: 'solarPlant.inverter1.ratedCurrent',
      label: 'ratedCurrent',
    },
    {
      key: '1.5.8',
      name: 'solarPlant.inverter1.shortCircuitCurrent',
      label: 'shortCircuitCurrent',
    },
    {
      key: '1.5.9',
      name: 'solarPlant.inverter1.nominalDcPower',
      label: 'nominalDcPower',
    },
    {
      key: 'inverter1_nominalPower',
      name: 'solarPlant.inverter1.nominalPower',
      label: 'nominalPower',
    },
    {
      key: 'inverter1_nominalVoltage',
      name: 'solarPlant.inverter1.nominalVoltage',
      label: 'nominalVoltage',
    },
    {
      key: '1.5.10',
      name: 'solarPlant.inverter1.allowedChargeDischargeCurrent',
      label: 'allowedChargeDischargeCurrent',
    },
    {
      key: 'inverter1_gridAndPlantProtectionIntegrated',
      name: 'solarPlant.inverter1.gridAndPlantProtectionIntegrated',
      label: 'gridAndPlantProtectionIntegrated',
    },
    {
      key: 'inverter1_gridAndPlantProtectionType',
      name: 'solarPlant.inverter1.gridAndPlantProtectionType',
      label: 'gridAndPlantProtectionType',
      translate: true,
    },
    {
      key: 'inverter1_gridAndPlantProtectionIntegratedMultipliedBy230',
      name: 'solarPlant.gridAndPlantProtectionIntegratedMultipliedBy230',
      label: 'gridAndPlantProtectionIntegratedMultipliedBy230',
    },
  ],
};
