export default {
  // Batteriespeicher
  key: 'battery',
  label: 'battery',
  children: [
    {
      key: '1.7.0',
      name: 'solarPlant.battery_manufacturer_type',
      label: 'manufacturer_type',
    },
    {
      key: '1.7.1',
      name: 'solarPlant.battery.manufacturer',
      label: 'manufacturer',
    },
    { key: '1.7.2', name: 'solarPlant.battery.type', label: 'type' },
    {
      key: 'battery_batteryQuantity',
      name: 'solarPlant.batteryQuantity',
      label: 'count',
      type: 'INTEGER',
    },
    {
      key: '1.7.4',
      name: 'solarPlant.battery.capacity',
      label: 'capacity_kwh',
    },
    {
      key: '1.7.5',
      name: 'solarPlant.battery.dischargePower',
      label: 'dischargePower',
    },
    {
      key: '1.7.6',
      name: 'solarPlant.battery.batteryModuleCount',
      label: 'batteryModuleCount',
      type: 'INTEGER',
    },
    {
      key: '1.7.7',
      name: 'solarPlant.battery.batteryModuleCapacity',
      label: 'batteryModuleCapacity',
    },
    {
      key: 'battery_usableCapacity',
      name: 'solarPlant.battery.usableCapacity',
      label: 'usableCapacity',
    },
    {
      key: 'battery_ratedVoltage',
      name: 'solarPlant.battery.ratedVoltage',
      label: 'ratedVoltage',
    },
    {
      key: 'battery_connectionType',
      name: 'solarPlant.battery.connectionType',
      label: 'connectionType',
      translate: true,
    },
    {
      key: 'battery_hasBatteryText',
      name: 'solarPlant.hasBatteryText',
      label: 'hasBatteryText',
      translate: true,
    },
  ],
};
