import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const defaultBattery = () => {
  return {
    id: '_new',
    number: '_new',
    manufacturer: '',
    type: '',
    attachments: [],
    usableCapacity: 0,
    ratedVoltage: 0,
    connectionType: null,
  };
};

const getDefaultState = () => {
  return {
    batteries: [],
    batteryConnectionTypes: [],
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an battery
 * used for all queries/mutations which return an battery
 * @type {DocumentNode}
 */
const BATTERY_REQUEST_FIELDS = gql`
  fragment batteryFields on Battery {
    id
    number
    manufacturer
    type
    capacity
    dischargePower
    batteryModuleCount
    batteryModuleCapacity
    usableCapacity
    ratedVoltage
    connectionType
    attachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} project
 */
const createPayload = (battery) => {
  const payload = {
    ...(battery.manufacturer ? { manufacturer: battery.manufacturer } : {}),
    ...(battery.type ? { type: battery.type } : {}),
    ...(battery.capacity ? { capacity: parseFloat(battery.capacity) } : {}),
    ...(battery.usableCapacity ? { usableCapacity: parseFloat(battery.usableCapacity) } : {}),
    ...(battery.ratedVoltage ? { ratedVoltage: parseFloat(battery.ratedVoltage) } : {}),
    ...(battery.dischargePower ? { dischargePower: parseFloat(battery.dischargePower) } : {}),
    ...(battery.batteryModuleCount
      ? { batteryModuleCount: parseInt(battery.batteryModuleCount) }
      : {}),
    ...(battery.batteryModuleCapacity
      ? { batteryModuleCapacity: parseFloat(battery.batteryModuleCapacity) }
      : {}),
    connectionType: battery.connectionType,
  };

  return payload;
};

/**
 * Actions
 */
const actions = {
  async fetchBatteries({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            batteries {
              ...batteryFields
            }
          }
          ${BATTERY_REQUEST_FIELDS}
        `,
      });
      commit('SET_BATTERYS', response.data.batteries);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchBatteryByNumber({ commit, dispatch }, batteryNumber) {
    try {
      // console.log(batteryNumber);
      const response = await apolloClient.query({
        query: gql`
          query batteryByNumber($batteryNumber: Int!) {
            batteryByNumber(batteryNumber: $batteryNumber) {
              ...batteryFields
            }
          }
          ${BATTERY_REQUEST_FIELDS}
        `,
        variables: {
          batteryNumber: parseInt(batteryNumber),
        },
      });
      commit('ADD_OR_UPDATE_BATTERY', response.data.batteryByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async createBattery({ commit, dispatch }, battery) {
    const payload = createPayload(battery);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createBattery($payload: BatteryInput!) {
            createBattery(batteryInput: $payload) {
              ...batteryFields
            }
          }
          ${BATTERY_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Batteriespeicher gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_BATTERY', '_new'); // remove temporary _new battery
      commit('ADD_OR_UPDATE_BATTERY', response.data.createBattery);
      return response.data.createBattery.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateBattery({ commit, dispatch }, battery) {
    const payload = createPayload(battery);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateBattery($batteryNumber: Int!, $payload: BatteryInput!) {
            updateBattery(batteryNumber: $batteryNumber, batteryInput: $payload) {
              ...batteryFields
            }
          }
          ${BATTERY_REQUEST_FIELDS}
        `,
        variables: {
          batteryNumber: battery.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Batteriespeicher aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_BATTERY', response.data.updateBattery);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteBattery({ commit, dispatch }, batteryNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteBattery($batteryNumber: Int!) {
            deleteBattery(batteryNumber: $batteryNumber)
          }
        `,
        variables: {
          batteryNumber: batteryNumber,
        },
      });
      dispatch('message', {
        message: 'Batteriespeicher gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_BATTERY', batteryNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Upload an image belonging to the battery
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async uploadBatteryAttachment({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation uploadBatteryAttachment($attachmentInput: AttachmentInput!) {
            uploadBatteryAttachment(attachmentInput: $attachmentInput) {
              ...batteryFields
            }
          }
          ${BATTERY_REQUEST_FIELDS}
        `,
        variables: {
          attachmentInput: {
            file: payload.file,
            referenceId: payload.parentId,
          },
        },
      });
      commit('UPDATE_BATTERY', response.data.uploadBatteryAttachment);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Upload!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Delete Battery Atrachment
   * @param commit
   * @param payload
   */
  async deleteBatteryAttachment({ commit, dispatch }, payload) {
    // console.log(payload)
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteBatteryAttachment($batteryId: ID!, $attachmentId: ID!) {
            deleteBatteryAttachment(batteryId: $batteryId, attachmentId: $attachmentId) {
              ...batteryFields
            }
          }
          ${BATTERY_REQUEST_FIELDS}
        `,
        variables: {
          batteryId: payload.batteryId,
          attachmentId: payload.attachmentId,
        },
      });
      // console.log(response)
      commit('UPDATE_BATTERY', response.data.deleteBatteryAttachment);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async fetchBatteryConnectionTypes({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "BatteryConnectionType") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_BATTERY_CONNECTION_TYPES', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  /**
   * create a new battery object in store and return it
   * @param {*} param0
   * @param {*} battery
   */
  async initBattery({ commit }) {
    commit('ADD_OR_UPDATE_BATTERY', defaultBattery());
  },

  batteryLocalUpdate({ commit }, battery) {
    commit('ADD_OR_UPDATE_BATTERY', battery);
  },

  batteryCleanup({ commit }) {
    commit('REMOVE_BATTERY', '_new');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_BATTERY_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_BATTERYS: (state, batteries) =>
    batteries ? (state.batteries = batteries) : (state.batteries = []),
  ADD_BATTERY: (state, battery) => state.batteries.push(battery),
  ADD_OR_UPDATE_BATTERY: (state, updatedBattery) => {
    const existingBattery = state.batteries.find((battery) => battery.id === updatedBattery.id);
    if (existingBattery) {
      Object.assign(existingBattery, updatedBattery);
    } else {
      state.batteries.push(updatedBattery);
    }
  },
  SET_BATTERY_TYPES: (state, batteryTypes) => {
    state.batteryTypes = [];
    batteryTypes.map((batteryType) => {
      state.batteryTypes.push(batteryType.name);
    });
  },
  REMOVE_BATTERY: (state, batteryId) => {
    const index = state.batteries.findIndex((battery) => battery.id === batteryId);
    state.batteries.splice(index, 1);
  },
  UPDATE_BATTERY: (state, updatedBattery) => {
    const battery = state.batteries.find((battery) => battery.id === updatedBattery.id);
    Object.assign(battery, updatedBattery);
  },
  SET_BATTERY_CONNECTION_TYPES: (state, batteryConnectionTypes) => {
    state.batteryConnectionTypes = [];
    batteryConnectionTypes.map((connectionType) => {
      state.batteryConnectionTypes.push(connectionType.name);
    });
  },
};

/**
 * Getters
 */
const getters = {
  getBatteries: (state) => state.batteries,
  getBattery: (state) => (batteryId) => state.batteries.find((battery) => battery.id == batteryId),
  getBatteryByNumber: (state) => (batteryNumber) =>
    state.batteries.find((battery) => battery.number == batteryNumber),
  getBatteryConnectionTypes: (state) => state.batteryConnectionTypes,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
