/** Define all fields which are used in the document editor
 * to create dynamic documents
 */
import store from '@/store';
import documentFieldDefinitions from '../helpers/documentEditor/documentFieldDefinitions';

/**
 * State
 */
const getDefaultState = () => {
  return {
    fields: [],
  };
};
const state = getDefaultState();
const netOutputStateCheckboxFields = () => {
  const children = [];
  const netOutputStates = store.getters['getNetOutputStates'];

  for (let i = 0; i < netOutputStates.length; i++) {
    const value = netOutputStates[i];
    children.push({
      key: 'smartCheckboxes.netOutputStates_' + i,
      name: 'solarPlant.netOutputStates',
      label: value,
      type: 'CHECKBOX',
      value: value,
      width: 10,
      height: 10,
    });
  }

  /** 1 phasig */
  children.push({
    key: 'smartCheckboxes.netOutputStates_one_phase',
    name: 'solarPlant.netOutputStates',
    label: 'ONE_PHASE',
    type: 'SPECIAL',
    value: 'ONE_PHASE',
    width: 10,
    height: 10,
  });
  /** 2 phasig */
  children.push({
    key: 'smartCheckboxes.netOutputStates_two_phases',
    name: 'solarPlant.netOutputStates',
    label: 'TWO_PHASES',
    type: 'SPECIAL',
    value: 'TWO_PHASES',
    width: 10,
    height: 10,
  });
  /** 3 phasig */
  children.push({
    key: 'smartCheckboxes.netOutputStates_three_phases',
    name: 'solarPlant.netOutputStates',
    label: 'THREE_PHASES',
    type: 'SPECIAL',
    value: 'THREE_PHASES',
    width: 10,
    height: 10,
  });
  /** 3 phasig/Drehstrom */
  children.push({
    key: 'smartCheckboxes.netOutputStates_three_phases_or_threeway_current',
    name: 'solarPlant.netOutputStates',
    label: 'THREE_PHASES_OR_THREEWAY',
    type: 'SPECIAL',
    value: 'THREE_PHASES_OR_THREEWAY',
    width: 10,
    height: 10,
  });

  return {
    key: 'netOutputState',
    label: 'netOutputState',
    children: children,
  };
};

const networkSupplyManagementStateFields = () => {
  const children = [];
  const networkSupplyManagementStates = store.getters['getNetworkSupplyManagementStates'];
  const networkSupplyManagementPermanentRestrictionStates =
    store.getters['getNetworkSupplyManagementPermanentRestrictionStates'];

  /** FRE ja checkbox */
  children.push({
    key: 'smartCheckboxes.networkSupplyManagementState_fre_yes',
    name: 'solarPlant.networkSupplyManagementState',
    label: 'FRE_YES',
    type: 'CHECKBOX',
    value: 'FRE',
    width: 10,
    height: 10,
  });

  /** FRE nein checkbox */
  children.push({
    key: 'smartCheckboxes.networkSupplyManagementState_fre_no',
    name: 'solarPlant.networkSupplyManagementState',
    label: 'FRE_NO',
    type: 'CHECKBOX_INVERTED',
    value: 'FRE',
    width: 10,
    height: 10,
  });

  /** dauerhafte Begrenzung: ja */
  children.push({
    key: 'smartCheckboxes.networkSupplyManagementState_permanent_restriction_yes',
    name: 'solarPlant.networkSupplyManagementState',
    label: 'PERMANENT_RESTRICTION_YES',
    type: 'CHECKBOX',
    value: 'PERMANENT_RESTRICTION',
    width: 10,
    height: 10,
  });

  /** dauerhafte Begrenzung: nein (wenn FRE oder keine Auswahl) */
  children.push({
    key: 'smartCheckboxes.networkSupplyManagementState_permanent_restriction_no',
    name: 'solarPlant.networkSupplyManagementState',
    label: 'PERMANENT_RESTRICTION_NO',
    type: 'CHECKBOX_INVERTED',
    value: 'PERMANENT_RESTRICTION',
    width: 10,
    height: 10,
  });

  /** dauerhafte Begrenzung auf 70%: ja */
  children.push({
    key: 'smartCheckboxes.networkSupplyManagementPermanentRestrictionState_70_yes',
    name: 'solarPlant.networkSupplyManagementPermanentRestrictionState',
    label: 'PERMANENT_RESTRICTION_70_PERCENT_YES',
    type: 'CHECKBOX',
    value: 'PERCENT_70',
    width: 10,
    height: 10,
  });
  /** dauerhafte Begrenzung auf 70%: nein */
  children.push({
    key: 'smartCheckboxes.networkSupplyManagementPermanentRestrictionState_70_no',
    name: 'solarPlant.networkSupplyManagementPermanentRestrictionState',
    label: 'PERMANENT_RESTRICTION_70_PERCENT_NO',
    type: 'CHECKBOX_INVERTED',
    value: 'PERCENT_70',
    width: 10,
    height: 10,
  });

  return {
    key: 'networkSupplyManagementState',
    label: 'networkSupplyManagementState',
    children: children,
  };
};

const batteryConnectionTypeCheckboxFields = () => {
  const children = [];

  children.push({
    key: 'smartCheckboxes.networkSupplyManagementState_ac_yes',
    name: 'solarPlant.battery.connectionType',
    label: 'AC_COUPLED',
    type: 'CHECKBOX',
    value: 'AC_COUPLED',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.networkSupplyManagementState_dc_yes',
    name: 'solarPlant.battery.connectionType',
    label: 'DC_COUPLED',
    type: 'CHECKBOX',
    value: 'DC_COUPLED',
    width: 10,
    height: 10,
  });

  return {
    key: 'batteryConnectionType',
    label: 'batteryConnectionType',
    children: children,
  };
};

const inverter1GridAndPlantProtectionTypeCheckboxFields = () => {
  const children = [];

  children.push({
    key: 'smartCheckboxes.inverter1GridAndPlantProtectionType_integrated_yes',
    name: 'solarPlant.inverter1.gridAndPlantProtectionType',
    label: 'INTEGRATED',
    type: 'CHECKBOX',
    value: 'INTEGRATED',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.inverter1GridAndPlantProtectionType_central_yes',
    name: 'solarPlant.inverter1.gridAndPlantProtectionType',
    label: 'CENTRAL',
    type: 'CHECKBOX',
    value: 'CENTRAL',
    width: 10,
    height: 10,
  });

  return {
    key: 'inverter1GridAndPlantProtectionType',
    label: 'inverter1GridAndPlantProtectionType',
    children: children,
  };
};
const inverter2GridAndPlantProtectionTypeCheckboxFields = () => {
  const children = [];

  children.push({
    key: 'smartCheckboxes.inverter2GridAndPlantProtectionType_integrated_yes',
    name: 'solarPlant.inverter2.gridAndPlantProtectionType',
    label: 'INTEGRATED',
    type: 'CHECKBOX',
    value: 'INTEGRATED',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.inverter2GridAndPlantProtectionType_central_yes',
    name: 'solarPlant.inverter2.gridAndPlantProtectionType',
    label: 'CENTRAL',
    type: 'CHECKBOX',
    value: 'CENTRAL',
    width: 10,
    height: 10,
  });

  return {
    key: 'inverter2GridAndPlantProtectionType',
    label: 'inverter2GridAndPlantProtectionType',
    children: children,
  };
};

const solarPlant_solarPlantType_checkboxes = () => {
  const children = [];

  children.push({
    key: 'smartCheckboxes.solarPlant_solarPlantType_new_construction_yes',
    name: 'solarPlant.solarPlantType',
    label: 'NEW_CONSTRUCTION',
    type: 'CHECKBOX',
    value: 'NEW_CONSTRUCTION',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.solarPlant_solarPlantType_expansion_yes',
    name: 'solarPlant.solarPlantType',
    label: 'EXPANSION',
    type: 'CHECKBOX',
    value: 'EXPANSION',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.solarPlant_solarPlantType_demolition_yes',
    name: 'solarPlant.solarPlantType',
    label: 'DEMOLITION',
    type: 'CHECKBOX',
    value: 'DEMOLITION',
    width: 10,
    height: 10,
  });

  return {
    key: 'solarPlantType',
    label: 'solarPlantType',
    children: children,
  };
};
const solarPlant_networkSupplyType_checkboxes = () => {
  const children = [];

  children.push({
    key: 'smartCheckboxes.solarPlant_networkSupplyType_excess_supply_yes',
    name: 'solarPlant.networkSupplyType',
    label: 'EXCESS_SUPPLY_yes',
    type: 'CHECKBOX',
    value: 'EXCESS_SUPPLY',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.solarPlant_networkSupplyType_excess_supply_no',
    name: 'solarPlant.networkSupplyType',
    label: 'EXCESS_SUPPLY_no',
    type: 'CHECKBOX_INVERTED',
    value: 'EXCESS_SUPPLY',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.solarPlant_networkSupplyType_full_supply_yes',
    name: 'solarPlant.networkSupplyType',
    label: 'FULL_SUPPLY_yes',
    type: 'CHECKBOX',
    value: 'FULL_SUPPLY',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.solarPlant_networkSupplyType_full_supply_no',
    name: 'solarPlant.networkSupplyType',
    label: 'FULL_SUPPLY_no',
    type: 'CHECKBOX_INVERTED',
    value: 'FULL_SUPPLY',
    width: 10,
    height: 10,
  });

  return {
    key: 'networkSupplyType',
    label: 'networkSupplyType',
    children: children,
  };
};

const solarPlant_islandOperationIntended_checkboxes = () => {
  const children = [];

  children.push({
    key: 'smartCheckboxes.solarPlant_islandOperationIntended_yes',
    name: 'solarPlant.islandOperationIntended',
    label: 'ISLAND_OPERATION_INTENDED_YES',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.solarPlant_islandOperationIntended_no',
    name: 'solarPlant.islandOperationIntended',
    label: 'ISLAND_OPERATION_INTENDED_NO',
    type: 'CHECKBOX',
    value: 'NO',
    width: 10,
    height: 10,
  });

  return {
    key: 'islandOperationIntended',
    label: 'islandOperationIntended',
    children: children,
  };
};

/** Notstromsystem Checkboxen */
const solarPlant_emergencyPowerState_checkboxes = () => {
  const children = [];

  children.push({
    key: 'smartCheckboxes.emergencyPowerState_ac_yes',
    name: 'solarPlant.emergencyPowerState',
    label: 'EMERGENCY_POWER_STATE_YES',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.emergencyPowerState_dc_no',
    name: 'solarPlant.emergencyPowerState',
    label: 'EMERGENCY_POWER_STATE_NO',
    type: 'CHECKBOX',
    value: 'NO',
    width: 10,
    height: 10,
  });

  return {
    key: 'emergencyPowerState',
    label: 'emergencyPowerState',
    children: children,
  };
};

const solarPlant_motorStartup_checkboxes = () => {
  const children = [];

  children.push({
    key: 'smartCheckboxes.solarPlant_motorStartup_yes',
    name: 'solarPlant.motorStartup',
    label: 'MOTOR_STARTUP_YES',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.solarPlant_motorStartup_no',
    name: 'solarPlant.motorStartup',
    label: 'MOTOR_STARTUP_NO',
    type: 'CHECKBOX',
    value: 'NO',
    width: 10,
    height: 10,
  });

  return {
    key: 'motorStartup',
    label: 'motorStartup',
    children: children,
  };
};
const solarPlant_electricMeterMountingState_checkboxes = () => {
  const children = [];

  children.push({
    key: 'smartCheckboxes.solarPlant_electricMeterMountingState_three_point',
    name: 'solarPlant.electricMeterMountingState',
    label: 'THREE_POINT_MOUNTING',
    type: 'CHECKBOX',
    value: 'THREE_POINT_MOUNTING',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.solarPlant_electricMeterMountingState_push',
    name: 'solarPlant.electricMeterMountingState',
    label: 'PUSH_MOUNTING',
    type: 'CHECKBOX',
    value: 'PUSH_MOUNTING',
    width: 10,
    height: 10,
  });

  return {
    key: 'electricMeterMountingState',
    label: 'electricMeterMountingState',
    children: children,
  };
};
const solarPlant_hasBattery_checkboxes = () => {
  const children = [];

  children.push({
    key: 'smartCheckboxes.solarPlant_hasBattery_checkboxes_yes',
    name: 'solarPlant.hasBattery',
    label: 'BATTERY_YES',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });

  children.push({
    key: 'smartCheckboxes.solarPlant_hasBattery_checkboxes_no',
    name: 'solarPlant.hasBattery',
    label: 'BATTERY_NO',
    type: 'CHECKBOX_INVERTED',
    value: 'YES',
    width: 10,
    height: 10,
  });

  return {
    key: 'battery',
    label: 'battery',
    children: children,
  };
};

const solarPlant_hasInverter2_checkboxes = () => {
  const children = [];

  children.push({
    key: 'smartCheckboxes.solarPlant_hasInverter2_checkboxes_yes',
    name: 'solarPlant.hasInverter2',
    label: 'INVERTER_2_YES',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });

  return {
    key: 'inverter2',
    label: 'inverter2',
    children: children,
  };
};

/**
 * Leistungsangaben
 * @returns
 */
const powerRatings_checkboxes = () => {
  const children = [];

  children.push({
    key: 'smartCheckboxes.powerRatings_overall_power_lt_7690',
    name: 'powerRatings_overall_power_lt_7690',
    label: 'powerRatings_overall_power_lt_7690',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.powerRatings_overall_power_7690_to_10000',
    name: 'powerRatings_overall_power_7690_to_10000',
    label: 'powerRatings_overall_power_7690_to_10000',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.powerRatings_overall_power_get_10000',
    name: 'powerRatings_overall_power_get_10000',
    label: 'powerRatings_overall_power_get_10000',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.powerRatings_overall_power_lt_30000',
    name: 'powerRatings_overall_power_lt_30000',
    label: 'powerRatings_overall_power_lt_30000',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.powerRatings_overall_power_lte_30000',
    name: 'powerRatings_overall_power_lte_30000',
    label: 'powerRatings_overall_power_lte_30000',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.powerRatings_overall_power_gt_30000',
    name: 'powerRatings_overall_power_gt_30000',
    label: 'powerRatings_overall_power_gt_30000',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.powerRatings_overall_power_lte_25000',
    name: 'powerRatings_overall_power_lte_25000',
    label: 'powerRatings_overall_power_lte_25000',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.powerRatings_overall_power_gt_25000',
    name: 'powerRatings_overall_power_gt_25000',
    label: 'powerRatings_overall_power_gt_25000',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.powerRatings_overall_power_gt_100000',
    name: 'powerRatings_overall_power_gt_100000',
    label: 'powerRatings_overall_power_gt_100000',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });

  const batteryChildren = [];

  batteryChildren.push({
    key: 'smartCheckboxes.powerRatings_battery_power_gt_3420',
    name: 'powerRatings_battery_power_gt_3420',
    label: 'powerRatings_battery_power_gt_3420',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  batteryChildren.push({
    key: 'smartCheckboxes.powerRatings_battery_power_lte_10000',
    name: 'powerRatings_battery_power_lte_10000',
    label: 'powerRatings_battery_power_lte_10000',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  batteryChildren.push({
    key: 'smartCheckboxes.powerRatings_battery_power_gt_10000',
    name: 'powerRatings_battery_power_gt_10000',
    label: 'powerRatings_battery_power_gt_10000',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });

  return {
    key: 'powerRatings',
    label: 'powerRatings',
    children: [
      {
        key: 'powerRatings.kwp',
        label: 'kwp',
        children: children,
      },
      {
        key: 'powerRatings.battery',
        label: 'battery',
        children: batteryChildren,
      },
    ],
  };
};

const solarPlant_wallbox_checkboxes = () => {
  const children = [];

  children.push({
    key: 'smartCheckboxes.solarPlant_hasWallbox_checkboxes_yes',
    name: 'solarPlant.hasWallbox',
    label: 'WALLBOX_YES',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.solarPlant_hasWallbox_checkboxes_no',
    name: 'solarPlant.hasWallbox',
    label: 'WALLBOX_NO',
    type: 'CHECKBOX',
    value: 'NO',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.solarPlant_wallboxChargingTypeAC',
    name: 'solarPlant.wallboxChargingTypeAC',
    label: 'chargingTypeAC',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.solarPlant_wallboxChargingTypeDC',
    name: 'solarPlant.wallboxChargingTypeDC',
    label: 'chargingTypeDC',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.solarPlant_wallboxConnectionTypeAlternatingCurrent',
    name: 'solarPlant.wallboxConnectionTypeAlternatingCurrent',
    label: 'wallboxConnectionTypeAlternatingCurrent',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.solarPlant_wallboxConnectionTypeThreewayCurrent',
    name: 'solarPlant.wallboxConnectionTypeThreewayCurrent',
    label: 'wallboxConnectionTypeThreewayCurrent',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.solarPlant_wallboxConnectionTypeL1',
    name: 'solarPlant.wallboxConnectionTypeL1',
    label: 'wallboxConnectionTypeL1',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.solarPlant_wallboxConnectionTypeL2',
    name: 'solarPlant.wallboxConnectionTypeL2',
    label: 'wallboxConnectionTypeL2',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.solarPlant_wallboxConnectionTypeL3',
    name: 'solarPlant.wallboxConnectionTypeL3',
    label: 'wallboxConnectionTypeL3',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.solarPlant_activePowerControllable_checkboxes_yes',
    name: 'solarPlant.wallbox.activePowerControllable',
    label: 'activePowerControllableYes',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.solarPlant_activePowerControllable_checkboxes_no',
    name: 'solarPlant.wallbox.activePowerControllable',
    label: 'activePowerControllableNo',
    type: 'CHECKBOX_INVERTED',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.wallbox_shiftFactorCosphiType_inductive_yes',
    name: 'solarPlant.wallbox_shiftFactorCosphiType_inductive',
    label: 'wallbox_shiftFactorCosphiType_inductive_yes',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.wallbox_shiftFactorCosphiType_capacitive_yes',
    name: 'solarPlant.wallbox_shiftFactorCosphiType_capacitive',
    label: 'wallbox_shiftFactorCosphiType_capacitive_yes',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });

  return {
    key: 'wallbox',
    label: 'wallbox',
    children: children,
  };
};

/** @deprecated */
const solarPlant_storageCouplingState_checkboxes = () => {
  const children = [];

  children.push({
    key: 'smartCheckboxes.solarPlant_storageCouplingState_checkboxes_ac_yes',
    name: 'solarPlant.storageCouplingState',
    label: 'AC',
    type: 'CHECKBOX',
    value: 'AC',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.solarPlant_storageCouplingState_checkboxes_dc_yes',
    name: 'solarPlant.storageCouplingState',
    label: 'DC',
    type: 'CHECKBOX',
    value: 'DC',
    width: 10,
    height: 10,
  });

  return {
    key: 'storageCouplingState',
    label: 'storageCouplingState',
    children: children,
  };
};

const measurementPointOperator_checkboxes = () => {
  const children = [];

  children.push({
    key: 'smartCheckboxes.measurementPointOperator_checkboxes_powerCompany_yes',
    name: 'measurementPointOperator_powerCompany',
    label: 'measurementPointOperator_powerCompany',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });
  children.push({
    key: 'smartCheckboxes.measurementPointOperator_checkboxes_thirdParty_yes',
    name: 'measurementPointOperator_thirdParty',
    label: 'measurementPointOperator_thirdParty',
    type: 'CHECKBOX',
    value: 'YES',
    width: 10,
    height: 10,
  });

  return {
    key: 'measurementPointOperator',
    label: 'measurementPointOperator',
    children: children,
  };
};

/**
 * Actions
 */
const actions = {
  async initDocumentFields({ commit, dispatch }) {
    await dispatch('fetchNetOutputStates');
    await dispatch('fetchNetworkSupplyManagementStates');
    await dispatch('fetchNetworkSupplyManagementPermanentRestrictionStates');
    commit('ADD_STATIC_FIELDS');
    commit('ADD_SMART_CHECKBOX_CATEGORY');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_DOCUMENT_FIELDS_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  ADD_STATIC_FIELDS: (state) => {
    state.fields = [];
    Object.assign(state.fields, documentFieldDefinitions);
  },
  ADD_SMART_CHECKBOX_CATEGORY: (state) => {
    const children = [];
    children.push(netOutputStateCheckboxFields());
    children.push(networkSupplyManagementStateFields());
    children.push(batteryConnectionTypeCheckboxFields());
    children.push(inverter1GridAndPlantProtectionTypeCheckboxFields());
    children.push(inverter2GridAndPlantProtectionTypeCheckboxFields());
    children.push(solarPlant_solarPlantType_checkboxes());
    children.push(solarPlant_islandOperationIntended_checkboxes());
    children.push(solarPlant_emergencyPowerState_checkboxes());
    children.push(solarPlant_motorStartup_checkboxes());
    children.push(solarPlant_networkSupplyType_checkboxes());
    children.push(solarPlant_electricMeterMountingState_checkboxes());
    children.push(solarPlant_hasBattery_checkboxes());
    children.push(solarPlant_hasInverter2_checkboxes());
    children.push(solarPlant_wallbox_checkboxes());
    children.push(measurementPointOperator_checkboxes());
    children.push(powerRatings_checkboxes());
    // children.push(solarPlant_storageCouplingState_checkboxes());

    state.fields.push({
      key: 'smartCheckboxes',
      label: 'smartCheckboxes',
      children: children,
    });
  },
};

/**
 * Getters
 */
const getters = {
  getDocumentFields: (state) => state.fields,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
