import gql from 'graphql-tag';
import apolloClient from '../apollo';
import jwt from 'jsonwebtoken';
import { getAuthToken } from '../helpers/localStorage';
import store from '@/store';

/**
 * State
 */
const getDefaultState = () => {
  return {
    user: {},
    token: null,
    tokenExpiration: null,
    isFetching: false,
    errorMessage: '',
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for a user
 * used for all queries/mutations which return a user
 * @type {DocumentNode}
 */
const USER_REQUEST_FIELDS = gql`
  fragment userFields on User {
    id
    number
    firstname
    lastname
    email
    role
    createdAt
    position
    company
    projectExportBuildState
    projectOfferExportBuildState
    orderExportBuildState
    employer {
      id
      number
      name
      street
      zip
      city
      type
      electricians {
        name
        id
        number
      }
    }
    client {
      id
      number
      name
      street
      zip
      city
      type
      electricians {
        name
        id
        number
      }
      employers {
        name
        id
        number
      }
    }
    avatar {
      id
      path
    }
  }
`;

/**
 * Actions
 */
const actions = {
  /**
   * Reset state in module
   * @param commit
   */
  reset({ commit }) {
    commit('SET_DEFAULT_STATE');
  },

  /**
   * Send credentials to GraphQL API and receive a json web token to authenticate further requests
   * @param commit
   * @param logindata
   * @returns {Promise<void>}
   */
  async graphqlLogin({ dispatch, commit }, logindata) {
    // Login process:
    // - send credentials to backend
    // - save token + user data to user object
    // - protect routes, check user object
    // - show error message on false login
    try {
      const response = await apolloClient.query({
        query: gql`
          query login($input: LoginInput!) {
            login(loginInput: $input) {
              user {
                ...userFields
              }
              token
              tokenExpiration
            }
          }
          ${USER_REQUEST_FIELDS}
        `,
        variables: {
          input: {
            email: logindata.email,
            password: logindata.password,
          },
        },
      });
      if (response.errors) {
        // console.log(response.errors)
        dispatch('loginError', response.errors[0].message);
      } else {
        // console.log(response.data.login);
        commit('SET_USER', response.data.login);
        dispatch('updateLocalStorage', response.data.login);
        this.dispatch('addOrUpdateCurrentUser', response.data.login.user);
        commit('LOGIN_SUCCESS');
      }
    } catch (err) {
      // console.log(err)
      dispatch('loginError', err);
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  /**
   *
   * @param dispatch
   * @param commit
   * @returns {Promise<void>}
   */
  async refreshUserLegacy({ dispatch, commit }) {
    const currentUser = store.getters['auth/getCurrentUser'];
    if (!currentUser.id) {
      return;
    }

    try {
      const response = await apolloClient.query({
        query: gql`
          query user($userId: ID!) {
            user(userId: $userId) {
              ...userFields
            }
          }
          ${USER_REQUEST_FIELDS}
        `,
        variables: {
          userId: currentUser.id,
        },
      });
      commit('SET_USER', response.data.user);
      this.dispatch('addOrUpdateCurrentUser', response.data.user);
    } catch (e) {
      // console.log(e)
    }
  },

  /**
   * Update user data and JWT Token from server
   * @param {*} param0
   * @returns
   */
  async refreshUser({ dispatch, commit }) {
    const currentUser = store.getters['auth/getCurrentUser'];
    if (!currentUser.id) {
      return;
    }

    try {
      const response = await apolloClient.query({
        query: gql`
          query userAndToken($userId: ID!) {
            userAndToken(userId: $userId) {
              user {
                ...userFields
              }
              token
              tokenExpiration
            }
          }
          ${USER_REQUEST_FIELDS}
        `,
        variables: {
          userId: currentUser.id,
        },
      });
      // console.log(response.data.userAndToken);
      commit('SET_USER', response.data.userAndToken);
      dispatch('updateLocalStorage', response.data.userAndToken);
      this.dispatch('addOrUpdateCurrentUser', response.data.userAndToken.user);
    } catch (e) {
      console.log(e);
    }
  },

  /**
   * If a JWT is saved in localStorage and not expired log in with that token
   * @param commit
   * @returns {Promise<void>}
   */
  loginFromStorage({ commit, dispatch }) {
    const localUser = JSON.parse(localStorage.getItem('user'));
    if (localUser) {
      // check expiration
      // get expiration from jwt and convert to milliseconds
      const token = getAuthToken();
      if (!token) {
        return false;
      }
      const expiration = jwt.decode(token).exp * 1000;

      // const now = Date.now() + (1000 * 60 * 60)
      const now = Date.now();
      // console.log(now, expiration, (expiration - now) / 1000 / 60 / 60);
      // console.log(`Now: ${new Date(now).toLocaleDateString()} ${new Date(now).toLocaleTimeString()}`)
      // console.log(`Expires: ${new Date(expiration).toLocaleDateString()} ${new Date(expiration).toLocaleTimeString()}`)
      if (now > expiration) {
        // console.log(`Token expired on ${new Date(expiration).toLocaleDateString()} ${new Date(expiration).toLocaleTimeString()}`)
        dispatch('logoutUser');
        return false;
      }
      // console.log('Logging in from local storage: ' + localUser.email)
      commit('SET_USER', {
        token: getAuthToken(),
        tokenExpiration: localStorage.getItem('tokenExpiration'),
        user: localUser,
      });
      // this.dispatch('addOrUpdateCurrentUser', localUser);
    }
  },

  /**
   * request an async build of a project export
   * @param {*} param0
   * @param {*} projectId
   */
  async buildUserProjectExport({ commit, dispatch }, userId) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation buildProjectExport($userId: ID!) {
            buildProjectExport(userId: $userId)
          }
        `,
        variables: {
          userId: userId,
        },
      });
      commit('UPDATE_PROJECT_EXPORT_BUILD_STATE', {
        projectExportBuildState: response.data.buildProjectExport,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * request an async build of a order export
   * @param {*} param0
   * @param {*} projectId
   */
  async buildUserOrderExport({ commit, dispatch }, { userId, projectNumber }) {
    if (typeof projectNumber === 'undefined' || projectNumber === null) {
      throw new Error('Project Number is undefined or null');
    }

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation buildOrderExport($userId: ID!, $projectNumber: Int!) {
            buildOrderExport(userId: $userId, projectNumber: $projectNumber)
          }
        `,
        variables: {
          userId: userId,
          projectNumber: projectNumber,
        },
      });
      commit('UPDATE_ORDER_EXPORT_BUILD_STATE', {
        orderExportBuildState: response.data.buildOrderExport,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Erstellen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },
  /**
   * request an async build of a project offer export
   * @param {*} param0
   * @param {*} projectId
   */
  async buildUserProjectOfferExport({ commit, dispatch }, userId) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation buildProjectOfferExport($userId: ID!) {
            buildProjectOfferExport(userId: $userId)
          }
        `,
        variables: {
          userId: userId,
        },
      });
      commit('UPDATE_PROJECT_OFFER_EXPORT_BUILD_STATE', {
        projectExportBuildState: response.data.buildProjectOfferExport,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Save Token and Userdata to local storage
   * @param dispatch
   * @param token
   */
  updateLocalStorage({ dispatch }, { token, user }) {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
  },

  /**
   *
   * @param dispatch
   */
  async logoutUser({ commit }) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    // axios.defaults.headers.common['Authorization'] = "";
    commit('SET_DEFAULT_STATE');
    // await router.push('/login');
  },
  loginError({ commit }, payload) {
    commit('LOGIN_FAILURE', payload);
  },
  // receiveLogin({ commit }) {
  //   commit('LOGIN_SUCCESS');
  //   router.push('/app/users');
  // },
  requestLogin({ commit }) {
    commit('LOGIN_REQUEST');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  LOGIN_FAILURE(state, payload) {
    state.isFetching = false;
    state.errorMessage = payload;
  },
  LOGIN_SUCCESS(state) {
    state.isFetching = false;
    state.errorMessage = '';
  },
  LOGIN_REQUEST(state) {
    state.isFetching = true;
  },
  SET_USER: (state, auth) => {
    Object.assign(state, auth);
    // localStorage.setItem('USER', JSON.stringify(state.user))
  },
  UPDATE_PROJECT_EXPORT_BUILD_STATE: (state, { projectExportBuildState }) => {
    state.user.projectExportBuildState = projectExportBuildState;
  },
  UPDATE_ORDER_EXPORT_BUILD_STATE: (state, { orderExportBuildState }) => {
    state.user.orderExportBuildState = orderExportBuildState;
  },
  UPDATE_PROJECT_OFFER_EXPORT_BUILD_STATE: (state, { projectOfferExportBuildState }) => {
    state.user.projectOfferExportBuildState = projectOfferExportBuildState;
  },
};

/**
 * Getters
 */
const getters = {
  isAuth: (state) => (state.user.token ? true : false),
  getAuthToken: (state) => localStorage.getItem('token'),
  isAdmin: (state) => state.user.role === 'ADMIN',
  isEmployer: (state) => state.user.role === 'EMPLOYER',
  isClient: (state) => state.user.role === 'CLIENT',
  isGuest: (state) => state.user.role === 'GUEST',

  getCurrentUser: (state) => state.user,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
