<template>
  <div>
    <MobileHeader />
    <div class="mobile-content">
      <transition name="router-animation"> <router-view />\ </transition>
    </div>
  </div>
</template>

<script>
import MobileHeader from '@/components/Header/MobileHeader';

export default {
  name: 'MobileLayout',
  components: { MobileHeader },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },
  methods: {},
  computed: {},
};
</script>
