import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@/language';

Vue.use(VueI18n);

const numberFormats = {
  de: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
    currency_rounded: {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    decimal_triple: {
      style: 'decimal',
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    },
    decimal_single: {
      style: 'decimal',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
  },
};

const getLocaleFromStorage = () => {
  const storedLocale = localStorage.getItem('locale');

  if (storedLocale) {
    return storedLocale;
  }
  const defaultLocale = 'de';
  localStorage.setItem('locale', defaultLocale);
  return defaultLocale;
};

export const i18n = new VueI18n({
  locale: getLocaleFromStorage(),
  fallbackLocale: 'de',
  messages,
  numberFormats,
});
