import apolloClient from '../apollo';
import gql from 'graphql-tag';

const defaultSession = () => {
  return {
    _id: '_new',
    number: '_new',
    name: '',
    description: '',
    contents: [],
  };
};

const getDefaultState = () => {
  return {
    sessions: [],
    sessionCount: 0,
  };
};
const state = getDefaultState();

/**
 * Sanitize inputs and create payload to send to the GraphQL API for session.
 * @param {*} session
 */
const createSessionPayload = (session) => {
  const payload = {
    ...(session.name ? { name: session.name.trim() } : {}),
    ...(session.description ? { description: session.description.trim() } : {}),
    contents: session.contents
      ? session.contents
          .map((contentId) => {
            if (typeof contentId === 'string') {
              return { content: contentId };
            } else {
              return null;
            }
          })
          .filter((item) => item !== null)
      : [],
  };

  return payload;
};

export const actions = {
  async fetchSessions({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            sessions {
              sessionCount
              sessions {
                id
                number
                description
                name
                contents {
                  content
                }
              }
            }
          }
        `,
      });
      commit('SET_SESSIONS', response.data.sessions.sessions);
    } catch (err) {
      console.error('Error fetching sessions:', err);
    }
  },
  async fetchSessionByNumber({ commit }, sessionNumber) {
    const parsedNumber = parseInt(sessionNumber, 10);
    if (isNaN(parsedNumber)) {
      console.error('Invalid session number:', sessionNumber);
      return;
    }

    try {
      const response = await apolloClient.query({
        query: gql`
          query SessionByNumber($sessionNumber: Int!) {
            sessionByNumber(sessionNumber: $sessionNumber) {
              id
              number
              name
              description
              contents {
                content
              }
            }
          }
        `,
        variables: { sessionNumber: parsedNumber },
      });
      commit('ADD_OR_UPDATE_SESSION', response.data.sessionByNumber);
    } catch (err) {
      console.error('Error fetching session by number:', err);
      throw new Error(err.message);
    }
  },

  async createSession({ commit }, session) {
    const payload = createSessionPayload(session);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createSession($payload: SessionInput!) {
            createSession(sessionInput: $payload) {
              id
              number
              name
              description
            }
          }
        `,
        variables: { payload },
      });
      commit('ADD_SESSION', response.data.createSession);
      return response.data.createSession.number;
    } catch (err) {
      console.error('Error creating session:', err);
      throw new Error(err);
    }
  },

  async updateSession({ commit }, session) {
    const payload = createSessionPayload(session);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateSession($number: Int!, $payload: SessionInput!) {
            updateSession(sessionNumber: $number, sessionInput: $payload) {
              number
              name
              description
              contents {
                content
              }
            }
          }
        `,
        variables: { number: session.number, payload },
      });
      commit('UPDATE_SESSION', response.data.updateSession);
      return response.data.updateSession.number;
    } catch (err) {
      console.error('Error updating session:', err);
      throw new Error(err);
    }
  },

  /**
   * create a new session object in store and return it
   * @param {*} param0
   * @param {*} session
   */
  async initSession({ commit }) {
    commit('ADD_OR_UPDATE_SESSION', defaultSession());
  },

  async deleteSession({ commit }, number) {
    try {
      await apolloClient.mutate({
        mutation: gql`
          mutation deleteSession($number: Int!) {
            deleteSession(sessionNumber: $number)
          }
        `,
        variables: { number },
      });
      commit('REMOVE_SESSION', number);
    } catch (err) {
      console.error('Error deleting session:', err);
    }
  },
};

export const mutations = {
  ADD_OR_UPDATE_SESSION: (state, updatedSession) => {
    const existingSession = state.sessions.find((session) => session._id === updatedSession._id);
    if (existingSession) {
      Object.assign(existingSession, updatedSession);
    } else {
      state.sessions.push(updatedSession);
    }
  },
  SET_SESSIONS(state, sessions) {
    state.sessions = sessions;
  },
  ADD_SESSION(state, session) {
    state.sessions.push(session);
  },
  UPDATE_SESSION(state, updatedSession) {
    const index = state.sessions.findIndex((session) => session.number === updatedSession.number);
    if (index !== -1) {
      state.sessions[index] = updatedSession;
    }
  },
  REMOVE_SESSION(state, number) {
    state.sessions = state.sessions.filter((session) => session.number !== number);
  },
};

export const getters = {
  getSessions: (state) => state.sessions,
  getSessionCount: (state) => state.sessions.length,
  getSessionByNumber: (state) => (number) => {
    return state.sessions.find((session) => session.number === number);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
