import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const defaultStudent = () => {
  return {
    _id: '_new',
    number: '_new',
    firstname: '',
    lastname: '',
    studentNr: '',
    users: [],
  };
};

const getDefaultState = () => {
  return {
    students: [],
    studentCount: 0,
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an student
 * used for all queries/mutations which return an student
 * @type {DocumentNode}
 */
const STUDENT_REQUEST_FIELDS = gql`
  fragment studentFields on Student {
    _id
    number
    firstname
    lastname
    studentNr
    users {
      id
      number
      firstname
      lastname
      email
    }
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} student
 */
const createPayload = (student) => {
  /** Only send ID's for users */
  if (student.users) {
    const ids = [];
    student.users.map((user) => {
      ids.push(user.id);
    });
    student.users = ids;
  }

  const payload = {
    ...(student.firstname ? { firstname: student.firstname } : {}),
    ...(student.lastname ? { lastname: student.lastname } : {}),
    ...(student.studentNr ? { studentNr: student.lastname } : {}),

    users: student.users,
  };

  console.log(payload);
  return payload;
};

/**
 * Actions
 */
const actions = {
  /**
   * Fetch a paginated, sorted and filtered student list
   * @param {*} param0
   * @param {*} payload
   */
  async fetchStudents({ commit, dispatch }, { page, pageSize, sortField, sortOrder, filters }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query students(
            $page: Int!
            $pageSize: Int!
            $sortField: String
            $sortOrder: Int
            $filters: StudentFilterInput
          ) {
            students(
              page: $page
              pageSize: $pageSize
              sortField: $sortField
              sortOrder: $sortOrder
              filters: $filters
            ) {
              students {
                ...studentFields
              }
              studentCount
            }
          }
          ${STUDENT_REQUEST_FIELDS}
        `,
        variables: {
          page: page,
          pageSize: pageSize,
          sortField: sortField,
          sortOrder: sortOrder,
          filters: filters,
        },
        fetchPolicy: 'network-only',
      });
      commit('SET_STUDENTS', response.data.students.students);
      commit('SET_STUDENT_COUNT', response.data.students.studentCount);
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },

  async fetchStudentByNumber({ commit, dispatch }, studentNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query studentByNumber($studentNumber: Int!) {
            studentByNumber(studentNumber: $studentNumber) {
              ...studentFields
            }
          }
          ${STUDENT_REQUEST_FIELDS}
        `,
        variables: {
          studentNumber: parseInt(studentNumber),
        },
      });
      commit('ADD_OR_UPDATE_STUDENT', response.data.studentByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async createStudent({ commit, dispatch }, student) {
    const payload = createPayload(student);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createStudent($payload: StudentInput!) {
            createStudent(studentInput: $payload) {
              ...studentFields
            }
          }
          ${STUDENT_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Auftraggeber gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_STUDENT', '_new'); // remove temporary _new student
      commit('ADD_OR_UPDATE_STUDENT', response.data.createStudent);
      return response.data.createStudent.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateStudent({ commit, dispatch }, student) {
    const payload = createPayload(student);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateStudent($studentNumber: Int!, $payload: StudentInput!) {
            updateStudent(studentNumber: $studentNumber, studentInput: $payload) {
              ...studentFields
            }
          }
          ${STUDENT_REQUEST_FIELDS}
        `,
        variables: {
          studentNumber: student.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Auftraggeber aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_STUDENT', response.data.updateStudent);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteStudent({ commit, dispatch }, studentNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteStudent($studentNumber: Int!) {
            deleteStudent(studentNumber: $studentNumber)
          }
        `,
        variables: {
          studentNumber: studentNumber,
        },
      });
      dispatch('message', {
        message: 'Auftraggeber gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_STUDENT', studentNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new student object in store and return it
   * @param {*} param0
   * @param {*} student
   */
  async initStudent({ commit }) {
    commit('ADD_OR_UPDATE_STUDENT', defaultStudent());
  },

  studentLocalUpdate({ commit }, student) {
    commit('ADD_OR_UPDATE_STUDENT', student);
  },

  studentCleanup({ commit }) {
    commit('REMOVE_STUDENT', '_new');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_STUDENT_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_STUDENTS: (state, students) =>
    students ? (state.students = students) : (state.students = []),
  ADD_STUDENT: (state, student) => state.students.push(student),
  ADD_OR_UPDATE_STUDENT: (state, updatedStudent) => {
    const existingStudent = state.students.find((student) => student._id === updatedStudent._id);
    if (existingStudent) {
      Object.assign(existingStudent, updatedStudent);
    } else {
      state.students.push(updatedStudent);
    }
  },
  REMOVE_STUDENT: (state, studentId) => {
    const index = state.students.findIndex((student) => student._id === studentId);
    state.students.splice(index, 1);
  },
  SET_STUDENT_COUNT: (state, studentCount) => (state.studentCount = studentCount),
};

/**
 * Getters
 */
const getters = {
  getStudentCount: (state) => state.studentCount,
  getStudents: (state) => state.students,
  getStudent: (state) => (studentId) => state.students.find((student) => student._id == studentId),
  getStudentByNumber: (state) => (studentNumber) =>
    state.students.find((student) => student.number == studentNumber),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
