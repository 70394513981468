export default {
  // Wechelrichter
  key: '1.2',
  label: 'inverter_old',
  children: [
    {
      key: '1.2.0',
      name: 'composite.inverterManufacturer_inverterType',
      label: 'composite.inverterManufacturer_inverterType',
    },
    {
      key: '1.2.1',
      name: 'solarPlant.storageSystem.inverter.manufacturer',
      label: 'manufacturer',
    },
    { key: '1.2.2', name: 'solarPlant.storageSystem.inverter.type', label: 'type' },
    { key: '1.2.3', name: 'solarPlant.storageSystem.inverterCount', label: 'inverterCount' },
    {
      key: '1.2.4',
      name: 'solarPlant.storageSystem.inverter.apparentPower',
      label: 'apparentPower',
    },
    {
      key: '1.2.5',
      name: 'solarPlant.storageSystem.inverter.activePower',
      label: 'activePower',
    },
    {
      key: '1.2.7',
      name: 'solarPlant.storageSystem.inverter.activePowerPerPhase',
      label: 'activePowerPerPhase',
    },
    {
      key: '1.2.6',
      name: 'solarPlant.storageSystem.overallInverterPower',
      label: 'inverterOverallPower',
    },
    {
      key: '1.2.8',
      name: 'solarPlant.storageSystem.inverter.ratedCurrent',
      label: 'ratedCurrent',
    },
    {
      key: '1.2.9',
      name: 'solarPlant.storageSystem.inverter.shortCircuitCurrent',
      label: 'shortCircuitCurrent',
    },
    {
      key: '1.2.10',
      name: 'solarPlant.storageSystem.inverter.nominalDcPower',
      label: 'nominalDcPower',
    },
    {
      key: '1.2.11',
      name: 'solarPlant.storageSystem.inverter.allowedChargeDischargeCurrent',
      label: 'allowedChargeDischargeCurrent',
    },
  ],
};
