'use strict';

/**
 * Module dependencies.
 * @private
 */

const dayjs = require('dayjs');
import relativeTime from 'dayjs/plugin/relativeTime';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekday from 'dayjs/plugin/weekday';
import 'dayjs/locale/de';

dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(isoWeek);
dayjs.extend(weekday);
dayjs.locale('de');

export default {
  install(Vue, options) {
    Vue.prototype.$dayjs = dayjs;
    Vue.prototype.$time = (date) => dayjs(date).format('HH:mm');
    Vue.prototype.$timeWithSeconds = (date) => dayjs(date).format('HH:mm:ss');
    Vue.prototype.$date = (date) => dayjs(date).format('DD.MM.YYYY');
    Vue.prototype.$week = (date) => dayjs(date).isoWeek();
    Vue.prototype.$weekDay = (date) => dayjs(date).format('dddd');
    Vue.prototype.$datetime = (date) => dayjs(date).format('DD.MM.YYYY HH:mm');
    Vue.prototype.$datetimeWithSeconds = (date) => dayjs(date).format('DD.MM.YYYY HH:mm:ss');
    Vue.prototype.$fromNow = (date) => dayjs(date).fromNow();
    Vue.prototype.$dateIsAfter = (date, compareTo) => dayjs(date).isAfter(compareTo, 'hour');
    Vue.prototype.$dateIsBetween = (date, range) =>
      dayjs(date).isBetween(range[0], range[1], 'day', '[]');
    Vue.prototype.$calendarFormat = (originalDate) => {
      const dateObject = new Date(originalDate);
      return originalDate && dateObject && !isNaN(dateObject) ? dateObject : originalDate;
    };
    Vue.prototype.$calendarFormat2 = (originalDate) => {
      if (originalDate instanceof Date) {
        return originalDate;
      }
      // convert iso dates
      const isoRegex = /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])T(0[0-9]|1[0-9]|2[0-3]):(\d{2}):(\d{2})\.(\d{3})Z$/;
      if (isoRegex.test(originalDate)) {
        return dayjs(originalDate).format('DD.MM.YYYY');
      }

      return originalDate;
      // return originalDate && dateObject && !isNaN(dateObject) ? dateObject : originalDate;
    };
  },
};
